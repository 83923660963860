import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';

const HRLeaves = (props) => {
    // const {form, handleForm} = props;
    const { fixNavbar } = props;
    let [isDropDown, set_isDropDown] = useState(false);



    return (
        <React.Fragment>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                <div className="container-fluid">
                    <Link to={'/'} className="btn btn-primary mb-3" ><i className="fa fa-long-arrow-left" /> Back</Link>
                    <div className="justify-content-between align-items-center mb-3">
                        <div className="row">
                            {/* Leave History Table */}
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header mb-4 pb-3 border-bottom">
                                        {/* <h3 className="card-title">Leave History</h3> */}
                                        <div className="card-options">
                                            <form>
                                                <div className="row">
                                                    <div className="input-group float-left mr-2" style={{ width: 'unset' }}>
                                                        <input type="text" className="form-control form-control-sm" placeholder="Employe ID" name="emp_id" />
                                                    </div>
                                                    <div className="input-group float-left mr-2" style={{ width: 'unset' }}>
                                                        <input type="text" className="form-control form-control-sm" placeholder="Leave Type" name="type" />
                                                    </div>
                                                    <div className="input-group float-left mr-2" style={{ width: 'unset' }}>
                                                        <input type="text" className="form-control form-control-sm" placeholder="Leave Status" name="status" />
                                                    </div>
                                                    <div className="input-group float-left mr-2" style={{ width: 'unset' }}>
                                                        <input type="date" className="form-control form-control-sm" placeholder="From Date" name="from" />
                                                    </div>
                                                    <div className="input-group float-left mr-2" style={{ width: 'unset' }}>
                                                        <input type="date" className="form-control form-control-sm" placeholder="To Date" name="to" />
                                                    </div>
                                                    <div className="float-left mr-2" style={{ width: 'unset' }}>
                                                        <button className="btn btn-primary btn-sm" type="submit">
                                                            {/* <span className="fe fe-search" /> */}
                                                                Search
                                                            </button>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-striped table-vcenter text-nowrap mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Employee Name</th>
                                                        <th>Leave Type</th>
                                                        <th>Date From</th>
                                                        <th>Date To</th>
                                                        <th>No of Days</th>
                                                        <th>Reasons</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td>Planned Leave</td>
                                                        <td>
                                                            <span>14 Jun, 2022</span>
                                                        </td>
                                                        <td>
                                                            <span>15 Jun, 2022</span>
                                                        </td>
                                                        <td className="w40">2</td>
                                                        <td>Health Checkup</td>
                                                        <td><span className="tag tag-success">Active</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td>Planned Leave</td>
                                                        <td>
                                                            <span>14 Jun, 2022</span>
                                                        </td>
                                                        <td>
                                                            <span>15 Jun, 2022</span>
                                                        </td>
                                                        <td className="w40">2</td>
                                                        <td>Health Checkup</td>
                                                        <td><span className="tag tag-orange">Pending</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td>Planned Leave</td>
                                                        <td>
                                                            <span>14 Jun, 2022</span>
                                                        </td>
                                                        <td>
                                                            <span>15 Jun, 2022</span>
                                                        </td>
                                                        <td className="w40">2</td>
                                                        <td>Health Checkup</td>
                                                        <td><span className="tag tag-gray">Done</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td>Planned Leave</td>
                                                        <td>
                                                            <span>14 Jun, 2022</span>
                                                        </td>
                                                        <td>
                                                            <span>15 Jun, 2022</span>
                                                        </td>
                                                        <td className="w40">2</td>
                                                        <td>Health Checkup</td>
                                                        <td><span className="tag tag-success">Active</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td>Planned Leave</td>
                                                        <td>
                                                            <span>14 Jun, 2022</span>
                                                        </td>
                                                        <td>
                                                            <span>15 Jun, 2022</span>
                                                        </td>
                                                        <td className="w40">2</td>
                                                        <td>Health Checkup</td>
                                                        <td><span className="tag tag-orange">Pending</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td>Planned Leave</td>
                                                        <td>
                                                            <span>14 Jun, 2022</span>
                                                        </td>
                                                        <td>
                                                            <span>15 Jun, 2022</span>
                                                        </td>
                                                        <td className="w40">2</td>
                                                        <td>Health Checkup</td>
                                                        <td><span className="tag tag-gray">Done</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td>Planned Leave</td>
                                                        <td>
                                                            <span>14 Jun, 2022</span>
                                                        </td>
                                                        <td>
                                                            <span>15 Jun, 2022</span>
                                                        </td>
                                                        <td className="w40">2</td>
                                                        <td>Health Checkup</td>
                                                        <td><span className="tag tag-success">Active</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td>Planned Leave</td>
                                                        <td>
                                                            <span>14 Jun, 2022</span>
                                                        </td>
                                                        <td>
                                                            <span>15 Jun, 2022</span>
                                                        </td>
                                                        <td className="w40">2</td>
                                                        <td>Health Checkup</td>
                                                        <td><span className="tag tag-orange">Pending</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td>Planned Leave</td>
                                                        <td>
                                                            <span>14 Jun, 2022</span>
                                                        </td>
                                                        <td>
                                                            <span>15 Jun, 2022</span>
                                                        </td>
                                                        <td className="w40">2</td>
                                                        <td>Health Checkup</td>
                                                        <td><span className="tag tag-gray">Done</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td>Planned Leave</td>
                                                        <td>
                                                            <span>14 Jun, 2022</span>
                                                        </td>
                                                        <td>
                                                            <span>15 Jun, 2022</span>
                                                        </td>
                                                        <td className="w40">2</td>
                                                        <td>Health Checkup</td>
                                                        <td><span className="tag tag-gray">Done</span></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <nav className="mt-3" aria-label="Page navigation example">
                                            <div className="dropdown float-left">
                                                <a className="btn btn-light dropdown-toggle" onClick={() => { set_isDropDown(true) }}> 10 </a>

                                                <ul className={`dropdown-menu shadow-lg ${isDropDown ? 'd-block' : ''}`} style={{ width: '70px', minWidth: '1px', top: 'auto', bottom: '39px' }} aria-labelledby="dropdownMenuLink">
                                                    <li><a className="dropdown-item" onClick={() => { set_isDropDown(false) }}>10</a></li>
                                                    <li><a className="dropdown-item" onClick={() => { set_isDropDown(false) }}>50</a></li>
                                                    <li><a className="dropdown-item" onClick={() => { set_isDropDown(false) }}>100</a></li>
                                                </ul>
                                            </div>
                                            <ul className="pagination float-right pagination-sm">
                                                <li className="page-item">
                                                    <a className="page-link" href="#" aria-label="Previous">
                                                        <span aria-hidden="true">&laquo;</span>
                                                    </a>
                                                </li>
                                                <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                <li className="page-item">
                                                    <a className="page-link" href="#" aria-label="Next">
                                                        <span aria-hidden="true">&raquo;</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default HRLeaves;