import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import api from "./api";

import { capitalizeFunc, deleteFunc, randomItem } from "../../../functions";
import {
  message,
  Modal,
  Table,
  Button,
  Input,
  Space,
  DatePicker,
  Tag,
} from "antd";

import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import moment from "moment";

import AddEmployeeComp from "./AddEmployeeComp";
import { emp_initialState } from "./formDataState";
import AvatarComp from "../../general/AvatarComp";

const { confirm } = Modal;

const Users = (props) => {
  const { fixNavbar } = props;
  const [openModal, set_openModal] = useState(false);
  const [formData, set_formData] = useState(emp_initialState);
  const [employeeList, set_employeeList] = useState([]);
  const [isUpdating, set_isUpdating] = useState(false);
  const [refetch, set_refetch] = useState(false);
  const [saveNext, set_saveNext] = useState(1);

  const [hasTyped, set_hasTyped] = useState({
    first_name: false,
    official_email: false,
    phone_number: false,
    employee_code: false,
    designation_id: false,
    adhar_no: false,
    pan_no: false,
  });

  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });
  // const [view, set_view] = useState("list");
  // const [updateForm, set_updateForm] = useState(null);
  // const [isSubmit, set_isSubmit] = useState(false);

  // const [selectedDept, set_selectedDept] = useState([]);
  // const [sameAsResidencial, setSameAsResidencial] = useState(0);

  // const [permanentAddress, set_permanentAddress] = useState({
  //   permanent_address: "",
  //   permanent_city: "",
  //   permanent_state: "",
  //   permanent_country: "",
  //   permanent_pincode: "",
  // });

  // const [updateAttachmentList, set_updateAttachmentList] = useState([]);
  // const [attachmentList, set_attachmentList] = useState([]);
  // const [fileList, setFileList] = useState([]);

  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  useEffect(() => {
    getEmployeeList();
  }, [openModal, refetch]);

  const reset = () => {
    set_formData(emp_initialState);
    set_hasTyped({
      first_name: false,
      official_email: false,
    });
  };
  // const onUploadChange = async ({ fileList }) => {
  //   const newFileList = fileList.filter((each) => each.url);
  //   setFileList(fileList);
  //   set_updateAttachmentList([...newFileList, ...attachmentList]);
  // };

  // const onChangeSwitch = (checked) => {
  //   if (checked) {
  //     if (updateForm !== null) {
  //       set_updateForm((prev) => ({
  //         ...prev,
  //         same_address: 1,
  //         permanent_address: updateForm?.["residence_address"],
  //         permanent_city: updateForm?.["residence_city"],
  //         permanent_state: updateForm?.["residence_state"],
  //         permanent_country: updateForm?.["residence_country"],
  //         permanent_pincode: updateForm?.["residence_pincode"],
  //       }));
  //     } else {
  //       setSameAsResidencial(1);
  //       set_permanentAddress({
  //         permanent_address: form?.["residence_address"]?.value,
  //         permanent_city: form?.["residence_city"]?.value,
  //         permanent_state: form?.["residence_state"]?.value,
  //         permanent_country: form?.["residence_country"]?.value,
  //         permanent_pincode: form?.["residence_pincode"]?.value,
  //       });
  //     }
  //   } else {
  //     if (updateForm !== null) {
  //       set_updateForm((prev) => ({
  //         ...prev,
  //         same_address: 0,
  //         permanent_address: "",
  //         permanent_city: "",
  //         permanent_state: "",
  //         permanent_country: "",
  //         permanent_pincode: "",
  //       }));
  //     } else {
  //       setSameAsResidencial(0);
  //       set_permanentAddress({
  //         permanent_address: "",
  //         permanent_city: "",
  //         permanent_state: "",
  //         permanent_country: "",
  //         permanent_pincode: "",
  //       });
  //     }
  //   }
  // };

  // const handleUpdateForm = (e, regex, req) => {
  //   const { name, value, checked } = e.target;
  //   set_updateForm((prev) => ({ ...prev, [name]: value }));
  // };

  // const createEmployee = async () => {
  //   const emp_form = {};

  //   for (const item in form) {
  //     emp_form[item] = form[item].value;
  //   }
  //   try {
  //     const res = await api.createEmployee({
  //       data: {
  //         avatar: attachmentList.length ? attachmentList[0].url : null,
  //         // department_id: selectedDept,
  //         ...emp_form,
  //         same_address: sameAsResidencial,
  //         ...permanentAddress,
  //       },
  //     });
  //     if (res.data.resCode === 200) {
  //       message.success(res.data.resMessage);
  //       setRes({
  //         message: "Found",
  //         type: "success",
  //         title: "SuccessFully",
  //       });
  //     } else {
  //       message.error(res.data.resMessage);
  //       setRes({
  //         message: res.data.message,
  //         type: "danger",
  //         title: "Error",
  //       });
  //     }
  //   } catch (err) {
  //     message.error("Something went wrong!");
  //     setRes({
  //       message: "Something went wrong!",
  //       type: "danger",
  //       title: "Error",
  //     });
  //   }
  // };

  // const updateEmployee = async (employee_id) => {
  //   try {
  //     const res = await api
  //       .updateEmployee({
  //         employee_id,
  //         data: {
  //           ...updateForm,
  //           avatar: updateAttachmentList[0]?.url,
  //           // department_id: selectedDept,
  //           // ...permanentAddress,
  //         },
  //       })
  //       .then((data) => data.data);
  //     if (res.resCode === 200) {
  //       message.success("updated successfully");
  //       setRes({
  //         message: "Found",
  //         type: "success",
  //         title: "SuccessFully",
  //       });
  //     } else {
  //       setRes({
  //         message: res.data.message,
  //         type: "danger",
  //         title: "Error",
  //       });
  //     }
  //   } catch (err) {
  //     message.error("Something went wrong!");
  //     setRes({
  //       message: "Something went wrong!",
  //       type: "danger",
  //       title: "Error",
  //     });
  //   }
  // };

  const showModal = () => {
    set_openModal(true);
  };
  const closeModal = () => {
    set_openModal(false);
  };

  const handleformData = (e) => {
    const { id, name, value } = e.target;
    if (id) {
      set_formData((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          [name]: value,
        },
      }));
    } else {
      set_formData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    set_hasTyped((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const getEmployeeList = async () => {
    try {
      const res = await api.getEmployeeList().then((data) => data.data);
      if (res.resCode === 200) {
        set_employeeList(res?.data?.data || []);
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const deleteEmployee = async (employee_id) => {
    try {
      const res = await api
        .deleteEmployee({
          employee_id,
        })
        .then((data) => data.data);
      if (res.resCode === 200) {
        message.warn("Employee deleted successfully");
        set_refetch(!refetch);
        setRes({
          message: "Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const showConfirm = (id) => {
    if (employeeList.length < 2) {
      confirm({
        title: "Not able to delete",
        icon: <CloseCircleOutlined />,
        content: "Minimum one user required",
      });
    } else {
      deleteFunc(() => {
        deleteEmployee(id);
      });
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, close, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
    close();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {dataIndex === "joining_date" ? (
          <div className="tw-w-full tw-mb-2">
            <DatePicker
              className="tw-w-full"
              onChange={(_, dateString) =>
                setSelectedKeys(dateString ? [dateString] : [])
              }
            />
          </div>
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            allowClear
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "flex",
            }}
          />
        )}

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, close, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      );
    },
  });

  const data = employeeList.map((each, idx) => {
    return {
      key: each.id || idx,
      avatar: <AvatarComp info={each} />,
      employee: `${capitalizeFunc(each?.first_name)} ${capitalizeFunc(
        each?.last_name
      )} \n ${each?.official_email.toLowerCase()}`,

      // (
      // <div>
      //   <h6 className="mb-0 tw-capitalize">
      //     {`${each.first_name || "-"} ${each.last_name || ""}`.toLowerCase()}
      //   </h6>
      //   <span className="tw-lowercase">{each.official_email}</span>
      // </div>
      // ),
      contact_no: each?.phone_number || `-`,
      role: each?.employee_details?.role_type || "-",

      joining_date: each?.employee_details?.joining_date
        ? moment(each?.employee_details?.joining_date).format("DD-MM-YYYY")
        : "",
      designation: capitalizeFunc(
        each?.employee_details?.designation_name || `-`
      ),
      department: capitalizeFunc(
        each?.employee_details?.department_name || `-`
      ),
      action: (
        <>
          <button
            type="button"
            className="btn btn-icon"
            title="Edit"
            onClick={() => {
              set_formData(each);
              set_isUpdating(true);
              showModal();
            }}
          >
            <i className="fa fa-edit" />
          </button>
          <button
            type="button"
            className="btn btn-icon js-sweetalert"
            title="Delete"
            data-type="confirm"
            onClick={() => {
              showConfirm(each.id);
            }}
          >
            <i className="fa fa-trash-o text-danger" />
          </button>
        </>
      ),
    };
  });

  const columns = [
    {
      title: "Photo",
      width: 25,
      dataIndex: "avatar",
      key: "avatar",
      fixed: "left",
    },
    {
      title: "Employee",
      width: 50,
      dataIndex: "employee",
      key: "employee",
      fixed: "left",
      ...getColumnSearchProps("employee"),
    },
    {
      title: "Contact No.",
      dataIndex: "contact_no",
      key: "contact_no",
      width: 40,
      ...getColumnSearchProps("contact_no"),
    },
    {
      title: "",
      dataIndex: "role",
      key: "role",
      width: 40,
      render: (tag) => {
        let color = "geekblue";
        if (tag === "super admin") {
          color = "volcano";
        }
        if (tag === "admin") {
          color = "green";
        }
        return (
          <div className="tw-flex tw-justify-center tw-items-center">
            <Tag color={color} key={tag} className="tw-capitalize">
              {tag.toLowerCase()}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Joining Date",
      width: 40,
      dataIndex: "joining_date",
      key: "joining_date",
      ...getColumnSearchProps("joining_date"),
    },

    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      width: 50,
      ...getColumnSearchProps("designation"),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: 50,
      ...getColumnSearchProps("department"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 40,
    },
  ];

  return (
    <>
      <div>
        <div className={`section-body ${fixNavbar ? "marginTop" : ""}`}>
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center">
              <Link to={"/"} className="btn btn-primary mb-3">
                <i className="fa fa-long-arrow-left" /> Back
              </Link>
              {/* <ul className="nav nav-tabs page-header-tab">
								<li className="nav-item">
									<a className="nav-link active" id="user-tab" data-toggle="tab" href="#user-list" > List </a>
								</li>
							</ul> */}
              <div className="header-action">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    showModal();
                    reset();
                    set_saveNext(1);
                  }}
                >
                  <i className="fe fe-plus mr-2" /> Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-3 p-1">
          <div className="container-fluid">
            <div className="tab-content mt-3">
              <div className="tab-pane fade show active">
                <div className="card">
                  <div className="card-header"></div>
                  <div className="card-body">
                    <Table
                      // size="middle"
                      columns={columns}
                      dataSource={data}
                      scroll={{
                        x: 1500,
                        y: 300,
                      }}
                      pagination={{
                        defaultPageSize: 6,
                      }}
                    />
                  </div>
                </div>
              </div>
              <AddEmployeeComp
                openModal={openModal}
                showModal={showModal}
                closeModal={closeModal}
                formData={formData}
                handleformData={handleformData}
                isUpdating={isUpdating}
                saveNext={saveNext}
                set_saveNext={set_saveNext}
                hasTyped={hasTyped}
              />
              {/* {view === "form" && updateForm === null ? (
                <>
                  <ProfileFormComp
                    createEmployee={createEmployee}
                    form={form}
                    handleForm={handleForm}
                    fileLength={1}
                    onUploadChange={onUploadChange}
                    fileList={fileList}
                    set_attachmentList={set_attachmentList}
                    isSubmit={isSubmit}
                    set_isSubmit={set_isSubmit}
                    selectedDept={selectedDept}
                    set_selectedDept={set_selectedDept}
                    permanentAddress={permanentAddress}
                    set_permanentAddress={set_permanentAddress}
                    onChangeSwitch={onChangeSwitch}
                    sameAsResidencial={sameAsResidencial}
                    setSameAsResidencial={setSameAsResidencial}
                    set_updateForm={set_updateForm}
                    set_view={set_view}
                  />
                </>
              ) : null}

              {view === "form" && updateForm !== null ? (
                <>
                  <ProfileFormComp
                    updateEmployee={updateEmployee}
                    form={form}
                    handleForm={handleUpdateForm}
                    isSubmit={isSubmit}
                    set_isSubmit={set_isSubmit}
                    selectedDept={selectedDept}
                    set_selectedDept={set_selectedDept}
                    permanentAddress={permanentAddress}
                    set_permanentAddress={set_permanentAddress}
                    updateForm={updateForm}
                    sameAsResidencial={sameAsResidencial}
                    setSameAsResidencial={setSameAsResidencial}
                    onChangeSwitch={onChangeSwitch}
                    set_updateForm={set_updateForm}
                    set_view={set_view}
                    fileList={fileList}
                    onUploadChange={onUploadChange}
                    set_attachmentList={set_attachmentList}
                  />
                </>
              ) : null} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Users);
