import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);

let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}

const getITModuleTile = () => {
  return axios({
    method: "get",
    url: "/api/income-tax-module",
    headers: headers,
  });
};

const getITModule = (params) => {
  const { module } = params;
  return axios({
    method: "get",
    url: `/api/get-income-tax-detail?module=${module}`,
    headers: headers,
  });
};

const saveITModule = (params) => {
  const { data, module } = params;
  return axios({
    method: "post",
    url: `/api/save-income-tax-detail?module=${module}`,
    headers: headers,
    data: data,
  });
};

const updateYearLimit = (params) => {
  const { module, age, particular } = params;
  return axios({
    method: "get",
    url: `/api/get-year-limit?module=${module}&age=${age}&particular=${particular}`,
    headers: headers,
  });
};

export default {
  getITModuleTile,
  getITModule,
  saveITModule,
  updateYearLimit,
};
