import { Avatar, Image, message, Modal, Upload } from "antd";
import React, { useEffect, useState } from "react";

import { LoadingOutlined, PlusOutlined, EyeOutlined } from "@ant-design/icons";
import api from "./api";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const AvatarComp = ({ info, size = 30, changeAvatar, getAttachURL }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [hover, setHover] = useState(false);
  const [showPreviewIcon, set_showPreviewIcon] = useState(false);

  useEffect(() => {
    info.avatar && setImageUrl(info.avatar);
  }, [info.avatar]);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const dummyRequest = async ({ file, onSuccess }) => {
    const data = new FormData();

    try {
      const res = await api.uploadAttachment({
        data: data,
        uploadType: "img",
      });
      console.log(res);
      if (res.status === 200) {
        getAttachURL({ name: file.name, status: "done", url: res.data });
      }
    } catch (err) {
      console.log(err);
    }
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
    }
    window.open(src);
  };

  if (changeAvatar) {
    return (
      <>
        <Upload
          customRequest={dummyRequest}
          name="avatar"
          className="avatar-upload"
          listType="picture-card"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {imageUrl ? (
            <>
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: "100%",
                }}
                onMouseEnter={() => {
                  set_showPreviewIcon(true);
                }}
                onMouseLeave={() => {
                  set_showPreviewIcon(false);
                }}
              />
              {showPreviewIcon ? (
                <div
                  onMouseEnter={handleMouseEnter}
                  style={{
                    position: "absolute",
                    // color: "gray",
                    // backgroundColor: "rgba(255,255,255,0.5)",
                    display: "grid",
                    placeItems: "center",
                    padding: "2px",
                    borderRadius: "20%",
                  }}
                >
                  <EyeOutlined
                    style={{
                      color: "gray",
                    }}
                  />
                </div>
              ) : null}
            </>
          ) : (
            <Avatar size={size}>
              {`${info?.first_name?.[0] || ""}${
                info?.last_name?.[0] || ""
              }`.toUpperCase()}
            </Avatar>
          )}
        </Upload>
        <Modal
          title={null}
          closable={false}
          open={hover}
          onCancel={handleMouseLeave}
          footer={null}
          width={400}
        >
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: "100%",
            }}
          />
        </Modal>
      </>
    );
  }

  return (
    <>
      {info.avatar ? (
        <Avatar src={info.avatar} size={size} />
      ) : (
        <Avatar size={size}>
          {`${info?.first_name?.[0]}${
            info?.last_name?.[0] || ""
          }`.toUpperCase()}
        </Avatar>
      )}
    </>
  );
};

export default AvatarComp;
