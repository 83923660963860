import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);
let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}

const uploadAttachment = (params) => {
  const { data, uploadType } = params;
  return axios({
    method: "post",
    url: `/api/upload-attachment/${uploadType}`,
    headers: headers,
    data: data,
  });
};
export default {
  uploadAttachment,
};
