export const emp_initialState = {
  title: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  phone_number: "",
  alternate_contact_no: "",
  personal_email: "",
  official_email: "",
  avatar: "",
  residence_address: "",
  permanent_address: "",
  permanent_country: "",
  permanent_city: "",
  permanent_state: "",
  permanent_pincode: "",
  dob: "",
  gender: "",
  blood_group: "",
  religion: "",
  martial_status: "",
  nationality: "",

  employee_details: {
    employee_id: "",
    manager_id: "",
    office_id: "",
    department_id: "",
    role_id: "",
    designation_id: "",
    notice_period: "",
    probation_period: "",
    employee_code: "",
    joining_date: "",
    relieving_date: "",
    working_days: "",
    designation_name: "",
    department_name: "",
    manager_first_name: "",
    manager_last_name: "",
    office_name: "",
  },
  employee_bank_details: {
    bank_name: "",
    bank_account: "",
    bank_ifsc: "",
    bank_address: "",
    employee_id: "",
  },
  employee_document_details: {
    adhar_no: "",
    pan_no: "",
    passport_no: "",
    national_id: "",
    employee_id: "",
  },
  employee_family_details: [],
  employee_education_details: [],
  employee_experience_details: [],
};
