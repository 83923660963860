import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import React from "react";
import { capitalizeFunc, deleteFunc } from "../../functions";
import DividerComp from "../general/DividerComp";
import InfoRowComp from "../general/InfoRowComp";
import UploadComp from "../HRMS/MyDocuments/UploadComp";
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { countryList } from "../HRMS/AddDocument/countryList";
import {
  educationalDetails,
  familyDetails,
  personalDetails,
} from "./profile_state";
import moment from "moment";
import api from "./api";
import { useEffect } from "react";
const { RangePicker } = DatePicker;
const EducationInfo = ({ data }) => {
  const [show_Modal, set_show_Modal] = useState(false);
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });
  const [tableData, set_tableData] = useState(
    data?.employee_education_details && data?.employee_education_details.length
      ? data?.employee_education_details
      : [educationalDetails()]
  );

  useEffect(() => {
    set_tableData(
      data?.employee_education_details &&
        data?.employee_education_details.length
        ? data?.employee_education_details
        : [educationalDetails()]
    );
  }, [data]);

  const handleChange = (e, id) => {
    const { name, value } = e.target;

    set_tableData((prev) => {
      return prev.map((each, idx) => {
        if (idx === id) {
          return {
            ...each,
            [name]: value,
          };
        }
        return each;
      });
    });
  };

  const handleSubmit = () => {
    for (let obj of tableData) {
      for (let key in obj) {
        if (!obj[key]) {
          message.error("All feilds are mandatory");
          return;
        }
      }
    }
    set_show_Modal(false);
    saveEducationInfo();
  };

  const handleAddMore = () => {
    set_tableData((prev) => {
      return [...prev, educationalDetails()];
    });
  };

  const handleDelete = (id) => {
    deleteFunc(() => {
      deleteHandler(id);
    });
  };

  const deleteHandler = (id) => {
    set_tableData((prev) => {
      return prev.filter((each, idx) => idx !== id);
    });
  };

  const saveEducationInfo = async () => {
    if (!data?.id) {
      return;
    }
    try {
      const res = await api.saveEducationInfo({
        data: { employee_education_details: JSON.stringify(tableData) },
        employee_id: data?.id,
      });
      if (res.data.resCode === 200) {
        message.success(res.data.resMessage);
        setRes({
          message: "Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        message.error(res.data.resMessage);
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  return (
    <>
      <div className="card p-3 tw-relative tw-h-[94%]">
        <div className="row tw-mb-2">
          <div className="col-11 tw-font-bold tw-text-[1rem] ">
            Education Information
          </div>
          <div className="col-1 ">
            <Button
              shape="circle"
              size="small"
              className="tw-flex tw-justify-center tw-items-center"
              onClick={() => set_show_Modal(true)}
            >
              <EditOutlined />
            </Button>
          </div>
        </div>

        {tableData.map((each, idx) => {
          return (
            <React.Fragment key={idx}>
              {Object.values(each).filter(Boolean).length > 2 ? (
                <Row className="tw-items-stretch">
                  <Col
                    span={3}
                    className="tw-flex tw-flex-col tw-items-center "
                  >
                    <div className="tw-w-3 tw-h-3 tw-rounded-full tw-bg-black tw-opacity-30 " />
                    <div className="tw-w-[2px] tw-h-full tw-bg-black tw-opacity-30 "></div>
                  </Col>
                  <Col span={21} className="-tw-mt-1 mb-4">
                    <p className="mb-0 tw-font-bold">{each.institute}</p>
                    <p className="mb-0 tw-font-semibold tw-opacity-75 ">
                      {each.address}
                    </p>
                    <p className="mb-0 tw-font-semibold tw-opacity-75 tw-text-[0.7rem]">
                      {`${each.start}-${each.end}`}
                    </p>
                  </Col>
                </Row>
              ) : (
                <div className="tw-flex tw-justify-center tw-items-center">
                  <Button
                    type="text"
                    size="small"
                    onClick={() => set_show_Modal(true)}
                    className="tw-flex tw-justify-center tw-items-center"
                  >
                    <PlusCircleOutlined />
                    <span className="tw-mt-1">Please add details</span>
                  </Button>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <Modal
        title={
          <div className="tw-flex tw-justify-center tw-items-center tw-font-bold tw-text-[1.1rem]">
            Education Information
          </div>
        }
        open={show_Modal}
        onCancel={() => {
          set_show_Modal(false);
        }}
        footer={
          <div className="tw-flex tw-justify-center tw-items-center">
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button
              onClick={() => {
                set_show_Modal(false);
              }}
            >
              Close
            </Button>
          </div>
        }
        width={600}
        maskClosable={false}
      >
        <div className="tw-w-full tw-max-h-[500px] tw-overflow-y-scroll">
          {tableData.map((each, idx) => {
            return (
              <div className="card border p-2 px-4" key={idx}>
                <div className="row clearfix tw-mb-4">
                  <div className="col-11 tw-font-bold ">
                    <p className="tw-mb-0">{`Education#${idx + 1}`}</p>
                  </div>
                  <div className="col-1">
                    <Button
                      shape="circle"
                      size="small"
                      className="tw-flex tw-justify-center tw-items-center"
                      onClick={() => handleDelete(idx)}
                    >
                      <DeleteOutlined className="tw-text-rose-600" />
                    </Button>
                  </div>
                </div>
                <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
                  {/* Institute */}
                  <div className="col-md-6">
                    <p className="tw-mb-0">Institute</p>
                    <Input
                      placeholder="Institute"
                      name="institute"
                      value={capitalizeFunc(each.institute || "")}
                      onChange={(e) => {
                        handleChange(e, idx);
                      }}
                    />
                  </div>
                  {/*Degree */}
                  <div className="col-md-6">
                    <p className="tw-mb-0">
                      Academic/Professional Qualification
                    </p>
                    <Input
                      placeholder="Academic/Professional Qualification"
                      name="qualification"
                      value={capitalizeFunc(each.qualification || "")}
                      onChange={(e) => {
                        handleChange(e, idx);
                      }}
                    />
                  </div>
                </div>
                <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
                  {/* start */}
                  <div className="col-md-4">
                    <p className="tw-mb-0">Start</p>
                    <DatePicker
                      allowClear={false}
                      format={"YYYY"}
                      style={{
                        width: "100%",
                      }}
                      picker="year"
                      defaultValue={each.start && moment(each.start)}
                      onChange={(date, dateString) => {
                        handleChange(
                          {
                            target: {
                              name: "start",
                              value: date.format("YYYY"),
                            },
                          },
                          idx
                        );
                      }}
                      placeholder="Select Year"
                      disabledDate={(currentDate) => {
                        const inputDate = currentDate.startOf("day");
                        const todayDate = moment().startOf("day");
                        return inputDate.isAfter(todayDate);
                      }}
                    />
                  </div>
                  {/* end */}
                  <div className="col-md-4">
                    <p className="tw-mb-0">End</p>
                    <DatePicker
                      allowClear={false}
                      format={"YYYY"}
                      picker="year"
                      style={{
                        width: "100%",
                      }}
                      defaultValue={each.end && moment(each.end)}
                      onChange={(date, dateString) => {
                        handleChange(
                          {
                            target: {
                              name: "end",
                              value: date.format("YYYY"),
                            },
                          },
                          idx
                        );
                      }}
                      placeholder="Select Year"
                      disabledDate={(currentDate) => {
                        const inputDate = currentDate.startOf("day");
                        const todayDate = moment().startOf("day");
                        return inputDate.isAfter(todayDate);
                      }}
                    />
                  </div>
                  {/* Grade */}
                  <div className="col-md-4">
                    <p className="tw-mb-0">Result/Grade</p>
                    <Input
                      placeholder="Result/Grade"
                      name="grade"
                      value={capitalizeFunc(each.grade || "")}
                      onChange={(e) => {
                        handleChange(e, idx);
                      }}
                    />
                  </div>
                </div>
                <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
                  {/* address */}
                  <div className="col-md-12">
                    <p className="tw-mb-0">Institute Address</p>
                    <Input
                      placeholder="Address"
                      name="address"
                      value={capitalizeFunc(each.address || "")}
                      onChange={(e) => {
                        handleChange(e, idx);
                      }}
                    />
                  </div>
                </div>
                <div className="row tw-justify-end ">
                  <Button
                    type="text"
                    className="tw-flex tw-justify-center tw-items-center tw-text-green-600"
                    onClick={handleAddMore}
                  >
                    <PlusCircleOutlined />{" "}
                    <span className="tw-mt-1">Add more</span>
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

export default EducationInfo;
