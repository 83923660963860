import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { SearchOutlined } from "@ant-design/icons";
import WithForms from "../../../features/WithForms/WithForms";
import regex from "../../../features/Services/validator";
import SingleCalendar from "react-single-calendar";
import api from "./api";
import {
  Modal,
  ApiModal,
  LeaveStatusModal,
} from "../../../features/modal/Modal";
import { capitalizeFunc, pageNumberFromUrl } from "../../../functions";
import { Button, DatePicker, Input, Select, Space, Table } from "antd";
import moment from "moment";
import Highlighter from "react-highlight-words";
const { RangePicker } = DatePicker;
const Team = (props) => {
  const { fixNavbar, form, handleForm } = props;
  const [isSubmit, set_isSubmit] = useState(false);
  const [dateString, set_dateString] = useState("");
  let [temp, setTemp] = useState(""); // teamp
  let [modal, setModal] = useState("");
  const [type, set_Type] = useState([]);
  const [search, set_Search] = "";
  const [pagination, setPagination] = useState([]);

  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });
  const [variable, set_Variable] = useState("");

  //data view
  const [dataViewType, setDataViewType] = useState("year");

  //filter target
  const [Leave, set_Leaves] = useState([]);
  const [filter, set_Filter] = useState([Leave]);

  // filter
  const [leaveTypes, setLeaveTypes] = useState([""]);

  const [filterList, setFilterList] = useState({
    employee_name: "",
    leave_type: "",
    leave_status: "",
    from_date: "",
    to_date: "",
    dates: [],
  });
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  // action status
  const [status, setStatus] = useState("pending");

  const [leaveId, setLeaveId] = useState();

  useEffect(() => {
    getLeaves(dataViewType);
    getAttendance();
    getleavetype();
  }, [dataViewType, status, modal]);

  const getleavetype = async () => {
    try {
      const res = await api.getleavetype().then((data) => data.data);
      if (res.resCode === 200) {
        set_Type(res?.data?.data || []);
        setPagination(res?.data?.links || []);
        setLeaveTypes((prev) => {
          return [
            ...prev,
            ...(res?.data?.data || []).map((each) => each.leave_type),
          ];
        });
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const getAttendance = async () => {
    try {
      const res = await api.getAttendance().then((data) => data.data);
      if (res.resCode === 200) {
        set_Variable(res ? res.data : []);
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const getLeaves = async (data_filter, pageNumber = 1) => {
    try {
      const res = await api
        .getLeaves({ data_filter: dataViewType, pageNumber })
        .then((data) => data.data);
      if (res.resCode === 200) {
        set_Leaves(res?.data?.data || []);
        setPagination(res?.data?.links);
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  // filter
  const handleFilter = (e) => {
    // getLeaves(dataViewType);
    const { name, value } = e.target;
    // handleForm(e, regex, req);
    setFilterList((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // // filter by type
  // const handleFilterType = (e, regex, req) => {
  //   getLeaves(dataViewType);
  //   const { value } = e.target;
  //   handleForm(e, regex, req);
  //   setFilterList((prev) => {
  //     return {
  //       ...prev,
  //       leave_type: leaveTypes[value],
  //     };
  //   });
  // };

  // filter by dataview
  const handleFilterDataView = (e) => {
    const { value } = e.target;
    if (value === "Daily") {
      setDataViewType("day");
    }
    if (value === "Weekly") {
      setDataViewType("week");
    }
    if (value === "Monthly") {
      setDataViewType("month");
    }
    if (value === "Yearly") {
      setDataViewType("year");
    }
  };

  // // filter by status
  // const handleFilterStatus = (e, regex, req) => {
  //   getLeaves(dataViewType);
  //   const { value } = e.target;
  //   handleForm(e, regex, req);
  //   setFilterList((prev) => {
  //     return {
  //       ...prev,
  //       leave_status: value,
  //     };
  //   });
  // };

  const dateFormatter = (date) => {
    let dt = new Date(date);
    let m = dt.getMonth();
    let d = dt.getDate();
    let yyyy = dt.getFullYear();
    let mm = m < 9 ? "0" + (m + 1) : m + 1;
    let dd = d < 9 ? "0" + d : d;
    const dateString = `${yyyy}-${mm}-${dd}`;
    return dateString;
  };

  //filter handler
  const filterHandler = () => {
    const { employee_name, leave_type, leave_status, dates } = filterList;
    if (employee_name) {
      set_Leaves((prev) =>
        prev.filter((each) =>
          (each.employee_name || "")
            .toLowerCase()
            .includes(employee_name.toLowerCase())
        )
      );
    }

    if (leave_type) {
      set_Leaves((prev) => {
        return prev.filter((each) => each.leave_type === leave_type);
      });
    }

    if (leave_status) {
      set_Leaves((prev) => {
        return prev.filter((each) => {
          return each.leave_status.toLowerCase() === leave_status.toLowerCase();
        });
      });
    }
    //filter by from-date - to-date
    const filterDateHandler = (fromDate, toDate) => {
      const min = Date.parse(fromDate);
      const max = Date.parse(toDate);

      set_Leaves((prev) => {
        return prev.filter((each) => {
          return (
            Date.parse(each.from_date) >= min && Date.parse(each.to_date) <= max
          );
        });
      });
    };
    if (dates.length > 1) {
      const min = moment(dates[0]).format("YYYY-MM-DD");
      const max = moment(dates[1]).format("YYYY-MM-DD");

      set_Leaves((prev) => {
        return prev.filter((each) => {
          return each.from_date >= min && each.to_date <= max;
        });
      });
    }
  };

  const resetFilter = () => {
    getLeaves();
    setFilterList({
      employee_name: "",
      leave_type: "",
      leave_status: "",
      from_date: "",
      to_date: "",
      dates: [],
    });
    setStatus("pending");
  };

  // handle action
  const handleModalSubmit = async (data, leaveId) => {
    const payload = {
      id: leaveId,
      reporting_head_remark: data,
    };
    try {
      if (status === "approved") {
        await api.leaveAccept(payload);
      } else {
        await api.leaveReject(payload);
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    setModal("");
    setModal("api");
  };

  const handleAction = (e, id) => {
    const { value } = e.target;
    setStatus(value);
    setLeaveId(id);
    setModal(value);
    setTemp(value);
  };

  const leavModal =
    modal === "approved" || modal === "rejected" ? (
      <Modal>
        <LeaveStatusModal
          modalData={{ status: temp }}
          dismiss={() => {
            setModal("");
            setTemp("");
            setStatus("pending");
          }}
          submit={(data) => handleModalSubmit(data, leaveId)}
        />
      </Modal>
    ) : null;
  const apiModal =
    modal === "api" ? (
      <Modal>
        <ApiModal
          modalData={{
            resType: "success",
            resMessage: `Request has been ${temp}ed successfully`,
            resTitle: "Leave Status",
          }}
          dismiss={() => {
            setModal("");
            setTemp("");
          }}
        />
      </Modal>
    ) : null;

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, close, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
    close();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {dataIndex === "applied_on" ||
        dataIndex === "from" ||
        dataIndex === "to" ? (
          <div className="tw-w-full tw-mb-2">
            <DatePicker
              format="DD-MM-YYYY"
              className="tw-w-full"
              onChange={(_, dateString) =>
                setSelectedKeys(dateString ? [dateString] : [])
              }
            />
          </div>
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search`}
            allowClear
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "flex",
            }}
          />
        )}

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, close, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (dataIndex === `duration`) {
        const [start_val, end_val] = value.split("-");
        const recordTime = moment.duration(record[dataIndex]).asMilliseconds();
        const valueTime_start = moment
          .duration(start_val, "minutes")
          .asMilliseconds();
        const valueTime_end = moment
          .duration(end_val, "minutes")
          .asMilliseconds();

        return (
          recordTime > valueTime_start &&
          recordTime <= valueTime_end &&
          record[dataIndex]
        );
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const data = Leave.map((each, idx) => {
    return {
      key: each.id,
      employee: capitalizeFunc(each.employee_name || ""),
      applied_on: moment(each.created_at).format("DD-MM-YYYY") || "-",
      leave_type: capitalizeFunc(each.leave_type || "-"),
      from: moment(each.from_date).format("DD-MM-YYYY") || "-",
      to: moment(each.to_date).format("DD-MM-YYYY") || `-`,
      leaves: each.no_of_days || `-`,
      reasons: capitalizeFunc(each.employee_remark || ""),
      status: capitalizeFunc(each.leave_status || "-"),
      comments: capitalizeFunc(each.reporting_head_remark || ""),
      actions: (
        <Select
          disabled={each.leave_status !== "pending"}
          defaultValue={each.leave_status || status}
          style={{
            width: "100%",
          }}
          onChange={(value) => {
            handleAction({ target: { name: "leave_status", value } }, each.id);
          }}
          options={[
            {
              value: "pending",
              label: "Pending",
            },
            {
              value: "approved",
              label: "Approved",
            },
            {
              value: "rejected",
              label: "Rejected",
            },
          ]}
        />
      ),
    };
  });

  const columns = [
    {
      title: "Employee",
      width: 50,
      dataIndex: "employee",
      key: "employee",
      fixed: "left",
      // ...getColumnSearchProps("employee"),
    },
    {
      title: "Applied On",
      width: 50,
      dataIndex: "applied_on",
      key: "applied_on",
      // ...getColumnSearchProps("applied_on"),
    },
    {
      title: "Leave Type",
      width: 50,
      dataIndex: "leave_type",
      key: "leave_type",
      // ...getColumnSearchProps("leave_type"),
    },
    {
      title: "From",
      width: 50,
      dataIndex: "from",
      key: "from",
      // ...getColumnSearchProps("from"),
    },
    {
      title: "To",
      width: 50,
      dataIndex: "to",
      key: "to",
      // ...getColumnSearchProps("to"),
    },
    {
      title: "Leaves",
      dataIndex: "leaves",
      key: "leaves",
      width: 30,
      // ...getColumnSearchProps("leaves"),
    },
    {
      title: "Reasons",
      dataIndex: "reasons",
      key: "reasons",
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 50,
      // ...getColumnSearchProps("status"),
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      width: 50,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 50,
    },
  ];

  return (
    <React.Fragment>
      <div
        className={`section-body tw-relative ${fixNavbar ? "marginTop" : ""} `}
      >
        <div className="tw-absolute md:-tw-top-[3.2rem] -tw-top-[3rem] tw-left-[10rem] tw-flex tw-gap-2 tw-justify-center tw-items-center">
          <p className="tw-w-[9rem] tw-m-0">Data View</p>
          <div className="card tw-m-0">
            <select
              className={`form-control custom-select ${
                (form.status && isSubmit) || (!form.status && isSubmit)
                  ? "border-danger"
                  : ""
              }`}
              name="leave_status"
              onChange={(e) => handleFilterDataView(e)}
            >
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
              <option value="Yearly" selected>
                Yearly
              </option>
            </select>
          </div>
        </div>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="col-lg-12 pl-0 pr-0">
              <div className="row">
                <div className="col-lg-3 col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title align-center">Today Present</h3>
                      <span className="d-flex justify-content-center align-items-center">
                        {variable.present}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title align-center">
                        Planned Leaves
                      </h3>
                      <span className="d-flex justify-content-center align-items-center">
                        {variable.planned_leave}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title align-center">
                        Unplanned Leaves
                      </h3>
                      <span className="d-flex justify-content-center align-items-center">
                        {variable.unplanned_leave}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title align-center">
                        Pending Request
                      </h3>
                      <span className="d-flex justify-content-center align-items-center">
                        {variable.pending_leave}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-2 col-md-2">
                  <Input
                    autoComplete="off"
                    type="text"
                    placeholder="Employee Name"
                    value={filterList.employee_name}
                    name="employee_name"
                    onChange={handleFilter}
                  />
                </div>
                <div className="col-lg-2 col-md-2">
                  <Select
                    style={{
                      width: "100%",
                    }}
                    value={filterList.leave_type}
                    onChange={(value) => {
                      handleFilter({ target: { name: "leave_type", value } });
                    }}
                    placeholder="Select Leave Type"
                    options={[
                      { value: "", label: "Select Leave Type" },
                      ...type.map((each) => ({
                        value: each.leave_type,
                        label: capitalizeFunc(each.leave_type),
                      })),
                    ]}
                  />
                </div>

                <div className="col-lg-2 col-md-2">
                  <Select
                    style={{
                      width: "100%",
                    }}
                    value={filterList.leave_status}
                    onChange={(value) => {
                      handleFilter({
                        target: { name: "leave_status", value },
                      });
                    }}
                    placeholder="Select Leave Status"
                    options={[
                      {
                        value: "",
                        label: "Select Leave Status",
                      },
                      {
                        value: "pending",
                        label: "Pending",
                      },
                      {
                        value: "approved",
                        label: "Approved",
                      },
                      {
                        value: "rejected",
                        label: "Rejected",
                      },
                    ]}
                  />
                </div>

                <div className="col-lg-3 col-md-3">
                  <RangePicker
                    format="DD-MM-YYYY"
                    className="tw-w-full"
                    value={filterList.dates}
                    onChange={(dates, dateString) =>
                      handleFilter({
                        target: { name: "dates", value: dates },
                      })
                    }
                  />
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="tw-flex tw-justify-center tw-items-center tw-gap-4 tw-w-full">
                    <button
                      className="btn btn-primary tw-flex-[2]"
                      onClick={filterHandler}
                    >
                      Search
                    </button>
                    <button
                      className="btn btn-secondary tw-flex-[1]"
                      onClick={resetFilter}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card alert alert-light">
            <Table
              columns={columns}
              dataSource={data}
              scroll={{
                x: 1500,
                y: 300,
              }}
              pagination={{
                defaultPageSize: 6,
              }}
            />
          </div>
        </div>
      </div>
      {leavModal}
      {apiModal}
    </React.Fragment>
  );
};
export default WithForms(Team);
