import React, { useCallback } from "react";
import { Table, Button, Upload, message } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { data80c } from "./it_data";
import { useState } from "react";
import { useEffect } from "react";
import api from "./api";

const CompHP = ({ set_show_Modal }) => {
  const [tableData, set_tableData] = useState([]);
  const [isModuleUpdate, set_isModuleUpdate] = useState(0);
  const [isLoading, set_isLoading] = useState(true);

  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  const getITModule = async () => {
    try {
      const res = await api.getITModule({ module: "HP" });
      if (res.data.resCode === 200) {
        set_tableData(res?.data?.data || []);
        set_isModuleUpdate(res?.data?.data_found || 0);
        set_isLoading(false);
        setRes({
          message: res.data.resMessage,
          type: "success",
          title: "Success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const handleChange = useCallback((event, id, each_obj) => {
    const { name, value } = event.target;
    set_tableData((prev) => {
      const updated = prev.map((each, idx) => {
        if (idx === id) {
          return {
            ...each,
            [name]: value,
          };
        }
        return { ...each };
      });
      return updated;
    });
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        const res = await api.saveITModule({
          module: "HP",
          data: { data: JSON.stringify(tableData) },
        });

        if (res.data.resCode === 200) {
          message.success(res.data?.resMessage);
          setRes({
            message: res.data.resMessage,
            type: "success",
            title: "Success",
          });
        } else {
          message.error(res.data?.resMessage);
          setRes({
            message: res.data.message,
            type: "danger",
            title: "Error",
          });
        }
      } catch (err) {
        message.error("Something went wrong!");
        setRes({
          message: "Something went wrong!",
          type: "danger",
          title: "Error",
        });
      }
      set_show_Modal(false);
    },
    [tableData, set_show_Modal]
  );

  const resetForm = () => {
    const emptInputs = tableData.map((each) => ({
      ...each,
      planned_amount: 0,
      actual_amount: 0,
    }));
    set_tableData(emptInputs);
  };

  const modalfooter = (isModuleUpdate) => {
    return (
      <div className="tw-flex tw-gap-2 tw-justify-center">
        {isModuleUpdate ? (
          <Button onClick={handleSubmit}>Update</Button>
        ) : (
          <Button onClick={handleSubmit}>Save</Button>
        )}
        <Button onClick={resetForm}>Clear</Button>
      </div>
    );
  };

  const columns = [
    {
      title: "Particulars",
      width: 100,
      dataIndex: "particulars",
      key: "particulars",
      fixed: "left",
    },
    {
      title: "Year Limit",
      dataIndex: "year_limit",
      key: "year_limit",
      width: 40,
    },
    {
      title: "Planned",
      dataIndex: "planned",
      key: "planned",
      width: 40,
    },
    {
      title: "Actual",
      dataIndex: "actual",
      key: "actual",
      width: 40,
    },
    {
      title: "Approved",
      dataIndex: "approved",
      key: "approved",
      width: 40,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 40,
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      width: 40,
    },
  ];
  const data = tableData.map((each, i) => {
    return {
      key: i,
      particulars: each.particular,
      year_limit: `₹${each.year_limit}`,
      planned: (
        <input
          name={"planned_amount"}
          onChange={(e) => handleChange(e, i, each)}
          type="text"
          className="form-control"
          placeholder="Enter Amount"
          value={each.planned_amount}
        />
      ),
      actual: (
        <input
          name={"actual_amount"}
          onChange={(e) => handleChange(e, i, each)}
          type="text"
          className="form-control"
          placeholder="Enter Amount"
          value={each.actual_amount}
        />
      ),
      approved: each.approved_amount,
      status: "-",
      remark: (
        <Upload>
          <Button type="link" icon={<PaperClipOutlined />} />
        </Upload>
      ),
    };
  });

  useEffect(() => {
    getITModule();
  }, []);

  return (
    <div className="tw-text-[0.8rem]">
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        scroll={{
          x: 1100,
          y: 347,
        }}
        pagination={false}
      />

      <div className="tw-p-4">{modalfooter(isModuleUpdate)}</div>
    </div>
  );
};

export default CompHP;
