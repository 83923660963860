import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);

let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}
const addDesignation = (params) => {
  const data = params;
  return axios({
    method: "post",
    url: "/api/save-designation",
    headers: headers,
    data: data,
  });
};
const getDesignation = (params) => {
  return axios({
    method: "get",
    url: "/api/designation-list",
    headers: headers,
  });
};
const updateDesignation = (params) => {
  const { id } = params;
  return axios({
    method: "post",
    url: "/api/update-designation/" + id,
    headers: headers,
    data: params,
  });
};
const deleteDesignation = (params) => {
  const { id, data } = params;
  return axios({
    method: "post",
    url: "/api/delete-designation/" + id,
    headers: headers,
    data: data,
  });
};

export default {
  addDesignation,
  getDesignation,
  updateDesignation,
  deleteDesignation,
};
