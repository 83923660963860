import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { capitalizeFunc } from "../../../functions";
import api from "./api";

const LeaveBalanceComp = ({ viewBtn }) => {
  const [leaveView, set_leaveView] = useState("pie");
  const [leaves, set_leaves] = useState([]);
  const [leave, set_leave] = useState({
    series: [],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },

      labels: [],
      legend: {
        position: "bottom",
        markers: {
          fillColors: [
            "#45322E",
            "#308446",
            "#102C54",
            "#6D3F5B",
            "#F5D033",
            "#763C28",
            "#CBD0CC",
            "#e65b3e",
          ],
        },
      },
      fill: {
        colors: [
          "#45322E",
          "#308446",
          "#102C54",
          "#6D3F5B",
          "#F5D033",
          "#763C28",
          "#CBD0CC",
          "#e65b3e",
        ],
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  const getleavebalance = async () => {
    try {
      const res = await api.getleavebalance().then((data) => data.data);
      if (res.resCode === 200) {
        set_leaves(res?.data || []);
        const seriesData = res?.data.map(
          (each) => remainingLeave(each.total_leave, each.used_leave) ?? 0
        );
        const leaveLabels = res?.data.map((each) =>
          capitalizeFunc(each.leave_type)
        );

        set_leave((prev) => ({
          ...prev,
          series: seriesData,
          options: {
            ...prev.options,
            labels: leaveLabels,
          },
        }));
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const remainingLeave = (totalLeave, usedLeave) => {
    return usedLeave
      ? Number(totalLeave) - Number(usedLeave)
      : Number(totalLeave);
  };

  useEffect(() => {
    getleavebalance();
  }, []);
  return (
    <div className="card">
      <div className="card-header tw-justify-between">
        <h3 className="card-title">Leave Balance</h3>
        <div className="tw-flex tw-gap-2 tw-items-center">
          <i
            className={`fa fa-th-list tw-cursor-pointer text-${
              leaveView === "list" ? "dark" : "secondary"
            }`}
            onClick={() => {
              set_leaveView("list");
            }}
            aria-hidden="true"
          />
          <i
            className={`fa fa-pie-chart tw-cursor-pointer text-${
              leaveView === "pie" ? "dark" : "secondary"
            }`}
            onClick={() => {
              set_leaveView("pie");
            }}
            aria-hidden="true"
          />
        </div>
      </div>
      <div className="card-body">
        {leaveView === "pie" ? (
          <div className="col-md-12">
            <ReactApexChart
              options={leave.options}
              series={leave.series}
              type="pie"
              width="100%"
            />
          </div>
        ) : (
          <div className="col-md-12">
            <ul className="list-group mb-3 mt-2">
              <li className="list-group-item py-1 tw-capitalize tw-bg-slate-100 tw-font-bold">
                <span>Leave</span>
                <div className="float-right d-flex ">
                  <label className="custom-control">
                    <span className="">TL</span>
                  </label>
                  <label className="custom-control">
                    <span className="">RL</span>
                  </label>
                </div>
              </li>
              {leaves.map((item, i) => {
                return (
                  <li
                    key={`l-div-li-${i}`}
                    className="list-group-item py-1 tw-capitalize "
                  >
                    <span>{item.leave_type}</span>
                    <div className="float-right tw-flex">
                      <label className="custom-control ">
                        <span className="badge badge-secondary">
                          {item.total_leave}
                        </span>
                      </label>
                      <label className="custom-control ">
                        <span className="badge badge-primary">
                          {item.remaining_leave}
                        </span>
                      </label>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {viewBtn ? (
          <div className="card-footer">
            <a
              href="/employee/leave"
              className="btn btn-block btn-primary btn-sm"
            >
              View
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LeaveBalanceComp;
