const regex = {
  name: new RegExp(/[a-zA-Z0-9 ]+/),
  address: new RegExp(/^[a-zA-Z0-9_\s.,:;/]*$/),
  lname: new RegExp(/^[a-zA-Z]+$/),
  email: new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  ),
  mobile: new RegExp(/^(9|8|7|6)([0-9]{9})$/),
  countryCode: new RegExp(/^[+|0][0-9]{2,4}$/),
  pin: new RegExp(/^[0-9]{6}$/),
  pan: new RegExp(/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/),
  gst: new RegExp(
    /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[a-zA-Z0-9]{1}$/
  ),
  allName: new RegExp(/^[a-zA-Z0-9 ]+$/),
  password: new RegExp(
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/
  ),
  number: new RegExp(/^[0-9]*$/),
  allItem: new RegExp(/^[a-zA-Z0-9_\s-.,:;/]*$/),
  anyChar: new RegExp(/^[.]*/),
  aadhaar: new RegExp(
    /^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/
  ),
};

export default {
  value: regex,
};
