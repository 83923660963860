import React, { useState, useEffect } from 'react';
import SearchForm from './SearchForm';
import Fullcalender from '../../common/fullcalender';
import {Link} from 'react-router-dom';

const HRAttendance = (props) => {
    // const {form, handleForm} = props;
    const { fixNavbar } = props;
    let [isDropDown, set_isDropDown] = useState(false);
    const [isEvent, set_isEvent] = useState(false);
    const [eventData, set_eventData] = useState([
        {
            title: 'Meeting',
            start: '2022-09-20T10:30:00',
            end: '2022-09-20T12:30:00'
        },
        {
            title: 'Birthday Party',
            start: '2022-09-13T07:00:00'
        },
        // {
        //     title: 'Click for Google',
        //     url: 'http://google.com/',
        //     start: '2022-09-28'
        // }
    ])


    return (
        <React.Fragment>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="col-md-12">
                            <Link to={'/'} className="btn btn-primary mb-3" ><i className="fa fa-long-arrow-left" /> Back</Link>
                            <div className="alert alert-light">
                                <SearchForm />
                            </div>
                        </div>
                    </div>
                    <div className="justify-content-between align-items-center mb-3">
                        <div className="row">
                            {/* Leave History Table */}
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header mb-4 pb-3 border-bottom">
                                        <h3 className="card-title">Attendance</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            {!isEvent &&
                                                <table className="table table-hover table-striped table-vcenter text-nowrap mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Employee Name</th>
                                                            <th>Total Days</th>
                                                            <th>Working Days</th>
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>#AD1245</td>
                                                            <td>Prabhu Rajendram</td>
                                                            <td>22</td>
                                                            <td> 18</td>
                                                            <td><span className="tag tag-primary" onClick={() => set_isEvent(true)}>Details</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>#AD1245</td>
                                                            <td>Prabhu Rajendram</td>
                                                            <td>22</td>
                                                            <td> 18</td>
                                                            <td><span className="tag tag-primary" onClick={() => set_isEvent(true)}>Details</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>#AD1245</td>
                                                            <td>Prabhu Rajendram</td>
                                                            <td>22</td>
                                                            <td> 18</td>
                                                            <td><span className="tag tag-primary" onClick={() => set_isEvent(true)}>Details</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>#AD1245</td>
                                                            <td>Prabhu Rajendram</td>
                                                            <td>22</td>
                                                            <td> 18</td>
                                                            <td><span className="tag tag-primary" onClick={() => set_isEvent(true)}>Details</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>#AD1245</td>
                                                            <td>Prabhu Rajendram</td>
                                                            <td>22</td>
                                                            <td> 18</td>
                                                            <td><span className="tag tag-primary" onClick={() => set_isEvent(true)}>Details</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>#AD1245</td>
                                                            <td>Prabhu Rajendram</td>
                                                            <td>22</td>
                                                            <td> 18</td>
                                                            <td><span className="tag tag-primary" onClick={() => set_isEvent(true)}>Details</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>#AD1245</td>
                                                            <td>Prabhu Rajendram</td>
                                                            <td>22</td>
                                                            <td> 18</td>
                                                            <td><span className="tag tag-primary" onClick={() => set_isEvent(true)}>Details</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>#AD1245</td>
                                                            <td>Prabhu Rajendram</td>
                                                            <td>22</td>
                                                            <td> 18</td>
                                                            <td><span className="tag tag-primary" onClick={() => set_isEvent(true)}>Details</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>#AD1245</td>
                                                            <td>Prabhu Rajendram</td>
                                                            <td>22</td>
                                                            <td> 18</td>
                                                            <td><span className="tag tag-primary" onClick={() => set_isEvent(true)}>Details</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>#AD1245</td>
                                                            <td>Prabhu Rajendram</td>
                                                            <td>22</td>
                                                            <td> 18</td>
                                                            <td><span className="tag tag-primary" onClick={() => set_isEvent(true)}>Details</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>#AD1245</td>
                                                            <td>Prabhu Rajendram</td>
                                                            <td>22</td>
                                                            <td> 18</td>
                                                            <td><span className="tag tag-primary" onClick={() => set_isEvent(true)}>Details</span></td>
                                                        </tr>


                                                    </tbody>
                                                </table>
                                            }

                                            {isEvent &&
                                                <>
                                                    <button className="btn btn-primary mb-3" onClick={() => set_isEvent(false)}><i className="fa fa-long-arrow-left" /> Back</button>
                                                    <Fullcalender data={eventData} />
                                                </>
                                            }
                                        </div>
                                        <nav className="mt-3" aria-label="Page navigation example">
                                            <div className="dropdown float-left">
                                                <a className="btn btn-light dropdown-toggle" onClick={() => { set_isDropDown(true) }}> 10 </a>

                                                <ul className={`dropdown-menu shadow-lg ${isDropDown ? 'd-block' : ''}`} style={{ width: '70px', minWidth: '1px', top: 'auto', bottom: '39px' }} aria-labelledby="dropdownMenuLink">
                                                    <li><a className="dropdown-item" onClick={() => { set_isDropDown(false) }}>10</a></li>
                                                    <li><a className="dropdown-item" onClick={() => { set_isDropDown(false) }}>50</a></li>
                                                    <li><a className="dropdown-item" onClick={() => { set_isDropDown(false) }}>100</a></li>
                                                </ul>
                                            </div>
                                            <ul className="pagination float-right pagination-sm">
                                                <li className="page-item">
                                                    <a className="page-link" href="#" aria-label="Previous">
                                                        <span aria-hidden="true">&laquo;</span>
                                                    </a>
                                                </li>
                                                <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                <li className="page-item">
                                                    <a className="page-link" href="#" aria-label="Next">
                                                        <span aria-hidden="true">&raquo;</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default HRAttendance;