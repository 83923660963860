import React, { useState } from "react";
import { Calendar, Badge, Tag } from "antd";
import { employeeData } from "./sampleData";
import moment from "moment";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { v4 as uuidv4 } from "uuid";
const getStatus = (status) => {
  switch (status) {
    case "P":
      return (
        <Tag
          color="green"
          className="tw-w-full tw-py-1 tw-m-0 tw-text-[0.8rem]"
        >
          {"Present"}
        </Tag>
      );
    case "A":
      return (
        <Tag color="red" className="tw-w-full tw-py-1 tw-m-0 tw-text-[0.8rem]">
          {"Absent"}
        </Tag>
      );
    case "Casual Leave":
      return (
        <Tag
          color="volcano"
          className="tw-w-full tw-py-1 tw-m-0 tw-text-[0.8rem]"
        >
          {"Casual Leave"}
        </Tag>
      );
    case "Sick Leave":
      return (
        <Tag color="gold" className="tw-w-full tw-py-1 tw-m-0 tw-text-[0.8rem]">
          {"Sick Leave"}
        </Tag>
      );
    default:
      if (Array.isArray(status)) {
        return status.map((each) => {
          return (
            <React.Fragment key={uuidv4()}>{getStatus(each)}</React.Fragment>
          );
        });
      }
      return status;
  }
};

const AttendanceView = () => {
  const attendanceData = [
    {
      status: "P",
      date: `2023-04-01`,
    },
    {
      status: "A",
      date: `2023-04-02`,
    },
    {
      status: "P",
      date: `2023-04-03`,
    },
    {
      status: ["P", "Sick Leave"],
      date: `2023-04-04`,
    },
    {
      status: "Casual Leave",
      date: `2023-04-05`,
    },
    {
      status: "A",
      date: `2023-04-06`,
    },
    {
      status: "Sick Leave",
      date: `2023-04-07`,
    },
  ];

  const dateCellRender = (value) => {
    const dateString = value.format("YYYY-MM-DD");
    let attendanceStatus;
    let status;
    for (let atten of attendanceData) {
      let date1 = moment(atten.date);
      let date2 = moment(dateString);
      if (
        moment(date1).isSame(date2, "date") &&
        moment(date1).isSame(date2, "month") &&
        moment(date1).isSame(date2, "year")
      ) {
        status = atten.status;
        attendanceStatus = true;
      }
    }

    return (
      attendanceStatus && (
        <div className="tw-flex tw-flex-col tw-gap-2 tw-text-center">
          {getStatus(status)}
        </div>
      )
    );
  };

  return (
    <div className="tw-w-[95%]">
      <Calendar dateCellRender={dateCellRender} />
    </div>
  );
};

export default AttendanceView;
