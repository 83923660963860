import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import RenderFeilds from "./RenderFeilds";
import UploadComp from "./UploadComp";

const DocComp = ({
  each_doc,
  addMoreHandler,
  deleteDocHandler,
  removeEdit,
  form,
  infoFormOnChange,
  onDateChange,
  set_uploadFileList,
  uploadFileList,
  each_info,
  each_doc_id,
  emp_id,
  each_doc_type,
  deleteDocument,
  datePicker,
  attachment,
  updateDocument,
  attachmentList,
  set_attachmentList,
}) => {
  const [editDoc, setEditDoc] = useState(false);
  const [fileList, setFileList] = useState(attachment || []);
  const [updateAttachmentList, set_updateAttachmentList] = useState([]);
  const onUploadChange = async ({ fileList }) => {
    const newFileList = fileList.filter((each) => each.url);
    setFileList(fileList);
    set_updateAttachmentList([...newFileList, ...attachmentList]);
  };

  const updateDocHandler = () => {
    setEditDoc(!editDoc);
    if (editDoc) {
      updateDocument(
        each_info,
        each_doc_id,
        emp_id,
        each_doc_type,
        updateAttachmentList
      );
    }
  };

  useEffect(() => {
    if (removeEdit) {
      setEditDoc(true);
    } else {
      setEditDoc(false);
    }
  }, []);

  const addmore = (each_doc) => {
    if (
      each_doc?.title === "Educational Certificates" ||
      each_doc?.title === "Previous Employment Details"
    ) {
      return (
        <button
          className="btn btn-primary"
          onClick={() => addMoreHandler(each_doc)}
        >
          Add more
        </button>
      );
    }
  };
  const deleteDoc = (each_doc, id) => {
    if (
      each_doc?.title === "Educational Certificates" ||
      each_doc?.title === "Previous Employment Details"
    ) {
      return (
        <div
          className="tw-text-red-400 hover:tw-text-red-600 tw-cursor-pointer"
          onClick={() => {}}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
          </svg>
        </div>
      );
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        {!removeEdit && (
          <div className="col-md-1 float-right tw-flex md:tw-flex-col tw-items-center tw-justify-between tw-z-50">
            <div className="form-group">
              <button
                className="btn btn-primary"
                onClick={() => updateDocHandler(each_doc?.title)}
              >
                {editDoc ? "Save" : "Edit"}
              </button>
            </div>
            <div className="form-group">
              <div
                className="tw-text-red-400 hover:tw-text-red-600 tw-cursor-pointer"
                onClick={() => deleteDocument(each_doc_id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg>
              </div>
            </div>
          </div>
        )}
        <div className={!removeEdit ? "col-md-11" : "col-md-12"}>
          {each_doc?.docs?.map((doc, idx) => {
            return (
              <React.Fragment key={`${doc?.doc}-${idx}`}>
                {doc?.doc && (
                  <div className="row">
                    <div
                      className={
                        "col-md-6 tw-font-bold tw-text-gray-500 tw-flex tw-items-center tw-gap-4"
                      }
                    >
                      <label className="m-0 tw-mt-1">{doc?.doc}</label>
                      {deleteDoc(each_doc, idx)}
                    </div>
                  </div>
                )}
                {doc?.headings.map((each_heading, idx) => {
                  return (
                    <div className="row" key={`${each_heading}-${idx}`}>
                      <RenderFeilds
                        each_info={each_info}
                        each_heading={each_heading}
                        each_heading_key={idx + 1}
                        editDoc={editDoc}
                        form={form}
                        datePicker={datePicker}
                        infoFormOnChange={infoFormOnChange}
                        onDateChange={onDateChange}
                        removeDisable={true}
                      />
                    </div>
                  );
                })}
              </React.Fragment>
            );
          })}

          <div
            className={`float-right tw-text-right ${
              !editDoc && "tw-pointer-events-none"
            }`}
          >
            <UploadComp
              onUploadChange={onUploadChange}
              fileList={fileList}
              set_attachmentList={set_attachmentList}
            />

            <small className={`tw-text-red-400 ${!editDoc && "tw-opacity-50"}`}>
              *Only JPEG/PNG/DOCX/PDF file allowed{" "}
            </small>
          </div>

          {addmore(each_doc)}
        </div>
      </div>
    </div>
  );
};

export default DocComp;
