import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Report = (props) => {
  const { fixNavbar } = props;
  return (
    <React.Fragment>
      <div className={`section-body ${fixNavbar ? "marginTop" : ""}`}>
        <div className="card">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center mb-3"></div>
            <div class=" row d-flex justify-content">
              <div class="col-lg-6 col-md-6">
                <h5>Payroll Overview</h5>
                <br />
                <i className="fa fa-chevron-right"></i>
                <span>Payroll Summary</span>
                <br />
                <br />
                <span>
                  <i className="fa fa-chevron-right"></i>Salary Register
                </span>
                <br />
                <br />
                <span>
                  <Link to="/salary/payslip">
                    <i className="fa fa-chevron-right"></i>Employee's Salary
                    Statement
                  </Link>
                </span>
                <br />
                <br />
                <span>
                  <i className="fa fa-chevron-right"></i>Leave Encashment
                  Summary
                </span>
                <br />
                <br />
                <span>
                  <i className="fa fa-chevron-right"></i>LOP Summary
                </span>
                <br />
                <br />
                <span>
                  <i className="fa fa-chevron-right"></i>Variable Pay Earning
                  Report
                </span>
                <br />
                <br />
                <br />
                <h5>Deduction Summary</h5>
                <br />
              </div>
              {/* <div class="col-lg-4 col-md-4">
     <h5>Statutory Reports</h5><br/>
 <span><i className='fa fa-chevron-right'></i>EPF Summary</span><br/><br/>
      <span><i className='fa fa-chevron-right'></i>EPF-ECR Report</span><br/><br/>
      <span><i className='fa fa-chevron-right'></i>ESI Summary</span><br/><br/>
      <span><i className='fa fa-chevron-right'></i>ESIC Return Report</span><br/><br/>
      <span><i className='fa fa-chevron-right'></i>PF Summary</span><br/><br/>
      <span><i className='fa fa-chevron-right'></i>PT Monthly Statement</span><br/><br/>
      <span><i className='fa fa-chevron-right'></i>PT Annual Return Statement</span><br/>
    </div> */}
              <div class="col-lg-6 col-md-6">
                <h5>Employee Report</h5>
                <br />
                <span>
                  <i className="fa fa-chevron-right"></i>Employee's CTC Details
                </span>
                <br />
                <br />
                <span>
                  <i className="fa fa-chevron-right"></i>Employee's Perquisite
                  Summary
                </span>
                <br />
                <br />
                <span>
                  <i className="fa fa-chevron-right"></i>Employee Termination
                  Report
                </span>
                <br />
                <br />
                <span>
                  <i className="fa fa-chevron-right"></i>Salary Revision Report
                </span>
                <br />
                <br />
                <span>
                  <i className="fa fa-chevron-right"></i>Gratuity Calculator
                </span>
                <br />
                <br />
                <span>
                  <i className="fa fa-chevron-right"></i>Insurance Summary
                </span>
                <br />
                <br />
                <span>
                  <i className="fa fa-chevron-right"></i>ID Validate Report
                </span>
                <br />
                <br />
                <br />
                {/* <span><i className='fa fa-chevron-right'></i>Id Validation Report</span><br/><br/><br/> */}
                <h5>Payroll Journal</h5>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Report;
