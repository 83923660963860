import React from "react";

const InfoRowComp = ({ name, value, imp }) => {
  return (
    <div className="row tw-mb-1">
      <div className="col-md-4 tw-font-semibold">{`${name}`}</div>
      <div
        className={`col-md-8 tw-font-semibold ${!imp ? "tw-opacity-75" : ""}`}
      >
        {value || "-"}
      </div>
    </div>
  );
};

export default InfoRowComp;
