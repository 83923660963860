import React, { useState, useEffect } from 'react';
import SearchForm from './SearchForm';
import { Link } from 'react-router-dom';

const HROverTime = (props) => {
    // const {form, handleForm} = props;
    const { fixNavbar } = props;
    let [isDropDown, set_isDropDown] = useState(false);


    return (
        <React.Fragment>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="col-md-12">
                            <Link to={'/'} className="btn btn-primary mb-3" ><i className="fa fa-long-arrow-left" /> Back</Link>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-6 col-md-4 col-xl-3">
                            <div className="card">
                                <div className="card-body ribbon">
                                    <div className="ribbon-box green">12</div>
                                    <Link to="/hr-overtime" className="my_sort_cut text-muted">
                                        <i className="fa fa-user-o" />
                                        <span>Overtime Employes</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3">
                            <div className="card">
                                <div className="card-body ribbon">
                                    <div className="ribbon-box orange">120</div>
                                    <Link to="/hr-overtime" className="my_sort_cut text-muted">
                                        <i className="fa fa-clock-o" />
                                        <span>Overtime Hours</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3">
                            <div className="card">
                                <div className="card-body ribbon">
                                    <div className="ribbon-box orange">4</div>
                                    <Link to="/hr-overtime" className="my_sort_cut text-muted">
                                        <i className="fa fa-hourglass-end" />
                                        <span>Pending Requests</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3">
                            <div className="card">
                                <div className="card-body ribbon">
                                    <div className="ribbon-box pink">0</div>
                                    <Link to="/hr-overtime" className="my_sort_cut text-muted">
                                        <i className="fa fa-ban" />
                                        <span>Rejected</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="justify-content-between align-items-center mb-3">
                        <div className="card alert">
                            <SearchForm />
                        </div>
                    </div>
                    <div className="justify-content-between align-items-center mb-3">
                        <div className="row">
                            {/* Leave History Table */}
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header mb-4 pb-3 border-bottom">
                                        <h3 className="card-title">Attendance</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">

                                            <table className="table table-hover table-striped table-vcenter text-nowrap mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#Serial No</th>
                                                        <th>Employee Name</th>
                                                        <th>OT Date</th>
                                                        <th>OT Hours</th>
                                                        <th>Description</th>
                                                        <th>Status</th>
                                                        <th>Approved By</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>#AD1245</td>
                                                        <td>Prabhu Rajendram</td>
                                                        <td><span> 23 Jul, 2022 </span></td>
                                                        <td><span>2</span></td>
                                                        <td>Working of flow-chart</td>
                                                        <td>Approved</td>
                                                        <td>Manager1</td>
                                                    </tr>



                                                </tbody>
                                            </table>



                                        </div>
                                        <nav className="mt-3" aria-label="Page navigation example">
                                            <div className="dropdown float-left">
                                                <a className="btn btn-light dropdown-toggle" onClick={() => { set_isDropDown(true) }}> 10 </a>

                                                <ul className={`dropdown-menu shadow-lg ${isDropDown ? 'd-block' : ''}`} style={{ width: '70px', minWidth: '1px', top: 'auto', bottom: '39px' }} aria-labelledby="dropdownMenuLink">
                                                    <li><a className="dropdown-item" onClick={() => { set_isDropDown(false) }}>10</a></li>
                                                    <li><a className="dropdown-item" onClick={() => { set_isDropDown(false) }}>50</a></li>
                                                    <li><a className="dropdown-item" onClick={() => { set_isDropDown(false) }}>100</a></li>
                                                </ul>
                                            </div>
                                            <ul className="pagination float-right pagination-sm">
                                                <li className="page-item">
                                                    <a className="page-link" href="#" aria-label="Previous">
                                                        <span aria-hidden="true">&laquo;</span>
                                                    </a>
                                                </li>
                                                <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                <li className="page-item">
                                                    <a className="page-link" href="#" aria-label="Next">
                                                        <span aria-hidden="true">&raquo;</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default HROverTime;