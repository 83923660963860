import React from "react";

const CellComp = ({
  style,
  className,
  align = "center",
  justify = "left",
  bg,
  children,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: justify,
        alignItems: align,
        backgroundColor: bg,
        borderBottom: "1px solid rgba(0,0,0,0.03)",
        width: "100%",
        ...style,
      }}
      className={`${className} hide-side-arrow `}
    >
      {children}
    </div>
  );
};

export default CellComp;
