import React from "react";
import { Divider } from "antd";

const DividerComp = ({ text, line = false }) => {
  if (line) {
    return (
      <Divider orientation="left" orientationMargin="0" className="tw-mb-2">
        <p className="tw-font-bold tw-text-[10px] tw-text-gray-400">{text}</p>
      </Divider>
    );
  } else {
    return (
      <p className="tw-w-full tw-font-bold tw-text-[10px] tw-text-gray-400 pl-2">
        {text}
      </p>
    );
  }
};

export default DividerComp;
