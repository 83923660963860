import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);

let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}
const getProfile = (params) => {
  const data = params;

  return axios({
    method: "get",
    url: "/api/profile",
    headers: headers,
  });
};

const getDesignation = (params) => {
  const { url, data } = params;
  return axios({
    method: "get",
    url:
      url || "/api/designation-list",
    headers: headers,
    data: data,
  });
};

const getDepartment = (params) => {
  const { url, data } = params;
  return axios({
    method: "get",
    url:
      url || "/api/department-list",

    headers: headers,
    data: data,
  });
};

const getBranch = (params) => {
  const { url, data } = params;
  return axios({
    method: "get",
    url: url || "/api/office-list",
    headers: headers,
    data: data,
  });
};

const getSalaryStructure = (params) => {
  let { ctc, data } = params;
  return axios({
    method: "get",
    url:
      ctc &&
      `/api/salary-structure?ctc=${ctc}`,
    headers: headers,
    data: data,
  });
};

const updateSalary = (params) => {
  let { ctc, emp_id, data } = params;
  console.log(params);
  return axios({
    method: "post",
    url:
      ctc &&
      emp_id &&
      `/api/update-payroll/${emp_id}?ctc=${ctc}`,
    headers: headers,
    data: data,
  });
};
const reviseSalary = (params) => {
  let { ctc, emp_id, data } = params;
  console.log(params);
  return axios({
    method: "post",
    url:
      ctc &&
      emp_id &&
      `/api/revise-payroll/${emp_id}?ctc=${ctc}`,
    headers: headers,
    data: data,
  });
};

const getSalaryCalculation = (params) => {
  const { ctc, data } = params;
  return axios({
    method: "post",
    url: `/api/salary-calculation?ctc=${ctc}`,
    headers: headers,
    data: JSON.stringify(data),
  });
};

const getEmployeeList = (params) => {
  const { url, data } = params;
  return axios({
    method: "get",
    url: url || `/api/employee-list`,
    headers: headers,
    data: data,
  });
};

export default {
  getProfile,
  getDesignation,
  getDepartment,
  getBranch,
  getSalaryStructure,
  getSalaryCalculation,
  getEmployeeList,
  updateSalary,
  reviseSalary,
};
