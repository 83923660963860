import React, { useState, forwardRef } from "react";
import { EyeOutlined } from "@ant-design/icons";

const BreakComp = ({ handleShowBreaks, eyeRef, index, date }) => {
  return (
    <div
      ref={(ref) => (eyeRef[index] = ref)}
      className="tw-w-[1rem] tw-cursor-pointer tw-relative "
      onMouseEnter={() => handleShowBreaks(true, index, date)}
      onMouseLeave={() => handleShowBreaks(false, index, date)}
    >
      <EyeOutlined />
    </div>
  );
};

export default BreakComp;
