import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { concatenate_str } from "../../../functions";
import { Collapse, message } from "antd";
import { DatePicker } from "antd";
import UploadComp from "./UploadComp";
import {
  bank_account_details,
  current_employee_letters,
  driving_license,
  educational_certificates,
  emirates_id,
  medical_insurance,
  passport,
  previous_employment_details,
  visa_details,
  medical_insurance1,
} from "../AddDocument/documentsData";
import EditButton from "./EditButton";
import DocComp from "./DocComp";
import api from "./api";
import WithForms from "../../../features/WithForms/WithForms";
import { each } from "jquery";

const { Panel } = Collapse;

const MyDocuments = (props) => {
  const { fixNavbar, handleForm, form } = props;
  const [edu_cert, set_edu_cert] = useState(educational_certificates);
  const [sr_no, set_sr_no] = useState(2);
  const [uploadFileList, set_uploadFileList] = useState([]);
  const [documentList, set_documentList] = useState([]);
  const [datePicker, set_datePicker] = useState({});
  const [isSubmit, set_isSubmit] = useState(false);
  const [docUI, set_docUI] = useState([]);
  const [docValues, set_docValues] = useState([]);
  const [attachmentList, set_attachmentList] = useState([]);

  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  useEffect(() => {
    getDocumentList();
  }, [isSubmit]);

  const onDateChange = (date, dateString, feild_label) => {
    set_datePicker((prev) => ({
      ...prev,
      [feild_label]: { value: dateString },
    }));
  };

  const infoFormOnChange = (e) => {
    handleForm(e);
  };

  const deleteDocument = async (each_doc_id) => {
    try {
      const res = await api.deleteDocument({
        doc_id: each_doc_id,
      });
      if (res.data.resCode === 200) {
        message.info("delete successfully");
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    set_isSubmit(!isSubmit);
  };

  const getDocumentList = async () => {
    try {
      const res = await api.getDocumentList();
      console.log(res);
      if (res.data.resCode === 200) {
        set_documentList(res?.data?.data);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const updateDocument = async (
    each_info,
    each_doc_id,
    emp_id,
    each_doc_type,
    updateAttachmentList
  ) => {
    const obj = { ...form, ...datePicker };
    const jsonForm = {};
    for (let key in obj) {
      jsonForm[key] = obj[key].value;
    }
    const updatedJsonForm = { ...each_info, ...jsonForm };

    try {
      const res = await api.updateDocument({
        data: {
          document_info: JSON.stringify(updatedJsonForm),
          document_type: each_doc_type,
          atttachment: JSON.stringify(updateAttachmentList),
          employee_id: emp_id,
        },
        doc_id: each_doc_id,
      });
      if (res.data.resCode === 200) {
        message.success("update successfully");
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    set_isSubmit(!isSubmit);
  };
  console.log(documentList);
  return (
    <>
      <div
        className={`section-body tw-min-h-[calc(100vh-9rem)] ${
          fixNavbar ? "marginTop" : ""
        } `}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="col-lg-12 col-md-12 ">
              <Collapse accordion bordered={false} defaultActiveKey={1}>
                {documentList.map((each_doc, idx) => {
                  return (
                    <Panel
                      header={
                        <h3 className="card-title tw-font-bold tw-mt-1">
                          {JSON.parse(each_doc.document_html)[0]?.title}
                        </h3>
                      }
                      key={idx + 1}
                    >
                      <DocComp
                        each_doc_id={each_doc.id}
                        emp_id={each_doc.employee_id}
                        each_doc_type={each_doc.document_type}
                        attachment={JSON.parse(each_doc.atttachment)}
                        each_doc={JSON.parse(each_doc.document_html)[0]}
                        each_info={JSON.parse(each_doc.document_info)}
                        form={form}
                        infoFormOnChange={infoFormOnChange}
                        onDateChange={onDateChange}
                        set_uploadFileList={set_uploadFileList}
                        uploadFileList={uploadFileList}
                        updateDocument={updateDocument}
                        deleteDocument={deleteDocument}
                        attachmentList={attachmentList}
                        set_attachmentList={set_attachmentList}
                      />
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WithForms(MyDocuments);
