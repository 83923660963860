import { Modal, Select } from "antd";
import React, { useState } from "react";
import api from "./api";

const ChangeStatusComp = ({
  attendance_id,
  status,
  updateTeamList,
  set_updateTeamList,
}) => {
  const [selectValue, set_selectValue] = useState(status);
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  const warning = (value) => {
    Modal.confirm({
      title: "This is a warning message",
      content: "Change employee attendance?",
      onOk() {
        updateTeamAttendanceAction(value);
        set_updateTeamList(!updateTeamList);
      },
      onCancel() {
        set_selectValue(status);
      },
    });
  };

  const selectValueHandler = (value) => {
    set_selectValue(value);
    warning(value);
  };

  const updateTeamAttendanceAction = async (override_availability) => {
    try {
      const res = await api.updateTeamAttendanceAction({
        attendance_id,
        data: {
          override_availability,
        },
      });
      if (res.data.resCode === 200) {
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  return (
    <Select
      placeholder="select"
      value={selectValue}
      className="tw-w-full"
      onChange={selectValueHandler}
      options={[
        {
          value: "half day",
          label: "Half Day",
        },
        {
          value: "full day",
          label: "Full Day",
        },
        {
          value: `lop`,
          label: "LOP",
        },
      ]}
    />
  );
};

export default React.memo(ChangeStatusComp);
