import React from "react";
import { useStopwatch } from "react-timer-hook";

function StopWatchComp({ addTodayBreak }) {
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: true });

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h1>react-timer-hook</h1>
        <p>Stopwatch Demo</p>
        <div style={{ fontSize: "100px" }}>
          <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:
          <span>{seconds}</span>
        </div>
        <p>{isRunning ? "Running" : "Not running"}</p>
        <button onClick={start}>Start</button>
        <button onClick={pause}>Pause</button>
        <button onClick={reset}>Reset</button>
      </div>
      <div className="tw-w-[50%] card tw-mx-auto p-2 border">
        <div className="tw-text-[2.5rem] tw-font-bold tw-mb-8 tw-mt-8 tw-text-center">
          <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:
          <span>{seconds}</span>
        </div>
        <div className="buttons tw-flex tw-justify-center tw-gap-8">
          <button
            className="btn btn-success"
            onClick={() => {
              pause();
              addTodayBreak();
            }}
          >
            Punch out
          </button>
        </div>
      </div>
    </>
  );
}

export default StopWatchComp;
