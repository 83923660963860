import React from "react";
import { Input, Button, message, Modal } from "antd";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import api from "./api";

const initialState = {
  username: "",
  oldPass: "",
  newPass: "",
  confirm_newPass: "",
};
const initialErrorState = {
  isErrorPass: false,
  errorMsgPass: "",
};

const ResetComp = () => {
  const router = useHistory();
  const [errorInfo, set_errorInfo] = useState(initialErrorState);
  const [resetInputs, set_resetInputs] = useState(initialState);
  const [sendMsgOtp, set_sendOtpMsg] = useState("");
  const [passwordChangedMsg, set_passwordChangedMsg] = useState("");
  const [isSuccess, set_isSuccess] = useState(false);
  const [successModal, set_successModal] = useState(false);
  const [showPassChange, set_showPassChange] = useState(false);

  const successModalOk = () => {
    set_successModal(false);
    setTimeout(() => {
      router.push("/dashboard");
    }, 500);
  };

  const resetInputsHandler = (e) => {
    const { name, value } = e.target;
    set_resetInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const passwordChangedHandler = async () => {
    set_sendOtpMsg("");
    const { username, oldPass, newPass, confirm_newPass } = resetInputs;
    if (!username || !oldPass || !newPass || !confirm_newPass) {
      set_isSuccess(false);
      set_errorInfo((prev) => ({
        ...prev,
        isErrorPass: true,
        errorMsgPass: "please fill mandatory feilds",
      }));
      return;
    }

    try {
      const res = await api.resetPassword({
        username: username,
        old_password: oldPass,
        new_password: newPass,
        confirm_new_password: confirm_newPass,
      });
      const data = res.data.data;
      if (res.data.resCode === 200) {
        set_isSuccess(true);
        set_successModal(true);
        set_passwordChangedMsg(res.data.resMessage);
      } else {
        set_isSuccess(false);
        set_passwordChangedMsg(data.msg);
      }
    } catch (err) {
      console.log(err);
    }
    set_resetInputs(initialState);
  };

  return (
    <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-8 tw-bg-gray-50">
      <div className="tw-w-[300px] tw-p-4 tw-rounded-md tw-mb-4 tw-text-center">
        <div className="tw-text-left tw-mt-4">
          <p className="tw-mb-1 tw-italic text-[0.5rem]">User Name</p>
          <Input
            type="text"
            name="username"
            status={!resetInputs.username && errorInfo.isErrorPass && "error"}
            value={resetInputs.username}
            onChange={resetInputsHandler}
          />
          {!resetInputs.username && errorInfo.isErrorPass && (
            <p
              className={"tw-text-red-600 tw-italic tw-mb-0 tw-text-[0.75rem]"}
            >
              {errorInfo.errorMsgPass}
            </p>
          )}
        </div>
        <div className="tw-text-left tw-mt-4">
          <p className="tw-mb-1 tw-italic text-[0.5rem]">Old Password</p>
          <Input
            type="password"
            name="oldPass"
            status={!resetInputs.oldPass && errorInfo.isErrorPass && "error"}
            value={resetInputs.oldPass}
            onChange={resetInputsHandler}
          />
          {!resetInputs.oldPass && errorInfo.isErrorPass && (
            <p
              className={"tw-text-red-600 tw-italic tw-mb-0 tw-text-[0.75rem]"}
            >
              {errorInfo.errorMsgPass}
            </p>
          )}
        </div>
        <div className="tw-text-left tw-mt-4">
          <p className="tw-mb-1 tw-italic text-[0.5rem]">New password</p>
          <Input
            type="password"
            name="newPass"
            status={!resetInputs.newPass && errorInfo.isErrorPass && "error"}
            value={resetInputs.newPass}
            onChange={resetInputsHandler}
          />
          {!resetInputs.newPass && errorInfo.isErrorPass && (
            <p
              className={"tw-text-red-600 tw-italic tw-mb-0 tw-text-[0.75rem]"}
            >
              {errorInfo.errorMsgPass}
            </p>
          )}
        </div>
        <div className="tw-text-left tw-mt-4">
          <p className="tw-mb-1 tw-italic text-[0.5rem]">
            Confirm new password
          </p>
          <Input
            name="confirm_newPass"
            status={
              !resetInputs.confirm_newPass && errorInfo.isErrorPass && "error"
            }
            value={resetInputs.confirm_newPass}
            onChange={resetInputsHandler}
          />
          {!resetInputs.confirm_newPass && errorInfo.isErrorPass && (
            <p
              className={"tw-text-red-600 tw-italic tw-mb-0 tw-text-[0.75rem]"}
            >
              {errorInfo.errorMsgPass}
            </p>
          )}
        </div>
        <div className="tw-flex tw-justify-center tw-items-center tw-mt-4 ">
          <Button
            type="primary"
            className="tw-bg-green-500 tw-border-none hover:tw-bg-green-600 tw-mx-auto"
            onClick={passwordChangedHandler}
          >
            Submit
          </Button>

          <p
            className={`${
              isSuccess ? "tw-text-green-600" : "tw-text-red-600"
            }  tw-italic tw-mb-0 tw-text-[0.75rem]`}
          >
            {passwordChangedMsg}
          </p>
        </div>
      </div>

      <Modal footer={null} open={successModal} closable={false}>
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
          <h4 className="tw-text-green-600 tw-mb-8">{passwordChangedMsg}</h4>
          <Button
            type="primary"
            className="tw-bg-green-600 tw-text-white tw-font-bold tw-border-none"
            onClick={successModalOk}
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ResetComp;
