import { DatePicker, Select } from "antd";
import moment from "moment";
import React from "react";
import { memo } from "react";
import { useEffect } from "react";
import { capitalizeFunc } from "../../functions";

const ProfessionalInfoForm = ({
  form,
  handleForm,
  designation,
  department,
  branch,
  set_selectedDept,
  managerList,
  updateForm,
  selectedDept,
  disabled,
  roleList,
  isSubmit,
}) => {
  const departmentChange = (value) => {
    set_selectedDept(value);
    console.log(value);
  };

  const departmentOptions = [
    {
      value: "",
      label: "Select",
    },
  ];
  for (let i in department) {
    departmentOptions.push({
      value: department[i].id,
      label: capitalizeFunc(department[i].department_name),
    });
  }

  return (
    <div className="card">
      <div className="tw-px-8">
        <div className="card-header">
          <h3 className="card-title">Professional Details</h3>
        </div>
        <div className="card-body">
          {/* row-1 */}
          <div className="row clearfix tw-mb-4">
            {/* employee code */}
            <div className="col-sm-6 col-md-3">
              <div className="form-group">
                <label className="form-label">Employee Code</label>
                <input
                  type="text"
                  name="employee_code"
                  onChange={(e) => handleForm(e)}
                  className="form-control"
                  placeholder="Employee code"
                  disabled
                  value={
                    updateForm?.["employee_code"] ||
                    form?.employee_code?.value ||
                    ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="row clearfix tw-mb-4">
            {/* date of joining */}
            <div className="col-sm-6 col-md-3">
              <div className="form-group">
                <label className="form-label">Date of Joining</label>

                <DatePicker
                  allowClear={false}
                  format={"DD-MM-YYYY"}
                  style={{
                    width: "100%",
                  }}
                  onChange={(date, dateString) => {
                    handleForm(
                      {
                        target: {
                          name: "joining_date",
                          value: date.format("YYYY-MM-DD"),
                        },
                      },
                      null,
                      "required"
                    );
                  }}
                  defaultValue={
                    (form?.joining_date?.value &&
                      moment(form?.joining_date?.value)) ||
                    (updateForm?.["joining_date"] &&
                      moment(updateForm?.["joining_date"]))
                  }
                  status={
                    (!updateForm?.["joining_date"] &&
                      form.joining_date &&
                      form.joining_date.error &&
                      isSubmit) ||
                    (!updateForm?.["joining_date"] &&
                      !form.joining_date &&
                      isSubmit)
                      ? "error"
                      : ""
                  }
                  disabled={disabled}
                  placeholder="Date of Joining"
                />
                {!updateForm?.["joining_date"] &&
                  form.joining_date &&
                  form.joining_date.error &&
                  isSubmit && (
                    <small className="text-danger">
                      This feild is required
                    </small>
                  )}
                {!updateForm?.["joining_date"] &&
                  !form.joining_date &&
                  isSubmit && (
                    <small className="text-danger">
                      This feild is required
                    </small>
                  )}
              </div>
            </div>

            {/* probation period */}
            <div className="col-sm-6 col-md-3">
              <div className="form-group">
                <label className="form-label">Probation Period</label>
                <select
                  name="probation_period"
                  onChange={(e) => handleForm(e)}
                  className="form-control custom-select"
                  disabled={disabled}
                  value={
                    updateForm?.["probation_period"] ||
                    form?.probation_period?.value ||
                    ""
                  }
                >
                  <option value="">Select</option>
                  <option value="15 days">15 days</option>
                  <option value="1 month">1 month</option>
                  <option value="3 months">3 months</option>
                  <option value="6 months">6 months</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row clearfix tw-mb-4">
            {/* Working days */}
            <div className="col-sm-6 col-md-3">
              <div className="form-group">
                <label className="form-label">Working Days</label>
                <select
                  name="working_days"
                  onChange={(e) => handleForm(e)}
                  className="form-control custom-select"
                  disabled={disabled}
                  value={
                    updateForm?.["working_days"] ||
                    form?.working_days?.value ||
                    ""
                  }
                >
                  <option value="">Select</option>
                  <option value="5">5 days</option>
                  <option value="6">6 days</option>
                </select>
              </div>
            </div>

            {/* Relieving date name */}
            <div className="col-sm-6 col-md-3">
              <div className="form-group">
                <label className="form-label">Relieving Date</label>

                <DatePicker
                  allowClear={false}
                  format={"DD-MM-YYYY"}
                  style={{
                    width: "100%",
                  }}
                  onChange={(date, dateString) => {
                    handleForm(
                      {
                        target: {
                          name: "relieving_date",
                          value: date.format("YYYY-MM-DD"),
                        },
                      },
                      null,
                      "required"
                    );
                  }}
                  defaultValue={
                    (form?.relieving_date?.value &&
                      moment(form?.relieving_date?.value || "2000-01-01")) ||
                    (updateForm?.["relieving_date"] &&
                      moment(updateForm?.["relieving_date"]))
                  }
                  status={
                    (!updateForm?.["joining_date"] &&
                      form.joining_date &&
                      form.joining_date.error &&
                      isSubmit) ||
                    (!updateForm?.["joining_date"] &&
                      !form.joining_date &&
                      isSubmit)
                      ? "error"
                      : ""
                  }
                  disabled={disabled}
                  placeholder="Relieving Date"
                />
              </div>
            </div>
          </div>
          <div className="row clearfix tw-mb-4">
            {/* Designation */}
            <div className="col-sm-4 col-md-3">
              <div className="form-group">
                <label className="form-label">Designation</label>
                <select
                  name="designation_id"
                  onChange={(e) => {
                    handleForm(e);
                  }}
                  className="form-control custom-select"
                  value={
                    updateForm?.["designation_id"] ||
                    form?.designation_id?.value ||
                    ""
                  }
                  disabled={disabled}
                >
                  <option value="">Select</option>
                  {designation.map((each, idx) => (
                    <option key={idx} value={each.id}>
                      {capitalizeFunc(each.designation_name)}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* department */}
            <div className="col-sm-4 col-md-3">
              <div className="form-group">
                <label className="form-label">Department</label>
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  onChange={(value) => {
                    handleForm({
                      target: {
                        name: "department_id",
                        value: value,
                      },
                    });
                  }}
                  options={departmentOptions}
                  value={
                    updateForm?.["department_id"] ||
                    form?.["department_id"]?.value ||
                    ""
                  }
                  disabled={disabled}
                />
              </div>
            </div>
            {/* role-type */}
            <div className="col-sm-4 col-md-3">
              <div className="form-group">
                <label className="form-label">Role</label>
                <select
                  name="role"
                  onChange={(e) => handleForm(e)}
                  className="form-control custom-select tw-capitalize"
                  defaultValue={capitalizeFunc(
                    updateForm?.["role"] ||
                      // || form?.["role"].value
                      ""
                  )}
                  disabled={disabled}
                >
                  <option value="">Select</option>
                  {roleList.map((each, idx) => (
                    <option key={idx} value={each.name}>
                      {(each.name || "").toLowerCase()}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row clearfix tw-mb-4">
            {/* Reporting Manager */}
            <div className="col-sm-6 col-md-3">
              <div className="form-group">
                <label className="form-label">Reporting Manager</label>
                <select
                  name="manager_id"
                  onChange={(e) => handleForm(e)}
                  className="form-control custom-select tw-capitalize"
                  value={
                    updateForm?.["manager_id"] ||
                    form?.["manager_id"]?.value ||
                    ""
                  }
                  disabled={disabled}
                >
                  <option value="">Select</option>
                  {managerList.map((each, idx) => (
                    <option key={idx} value={each.id}>
                      {capitalizeFunc(
                        `${each.first_name || ""} ${each.last_name || ""}`
                      )}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Branch */}
            <div className="col-sm-6 col-md-3">
              <div className="form-group">
                <label className="form-label">Branch</label>
                <select
                  name="office_id"
                  onChange={(e) => handleForm(e)}
                  className="form-control custom-select tw-capitalize"
                  defaultValue={
                    updateForm?.["office_id"] ||
                    // || form?.["office_id"].value
                    ""
                  }
                  disabled={disabled}
                >
                  <option value="">Select</option>
                  {branch.map((each, idx) => (
                    <option key={idx} value={each.id}>
                      {capitalizeFunc(each.office_name)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ProfessionalInfoForm);
