import React from "react";

const SalaryButton = ({ color, text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`tw-py-[0.5rem] tw-px-[0.8rem] tw-rounded-md tw-text-[0.7rem] tw-outline-none tw-border-none 
        ${
          color === "green" &&
          "tw-bg-green-200 hover:tw-bg-green-300 tw-text-green-800"
        } 
        ${
          color === "orange" &&
          "tw-bg-orange-200 hover:tw-bg-orange-300 tw-text-orange-800"
        } 
        `}
    >
      {text}
    </button>
  );
};

export default SalaryButton;
