import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Input, Modal, Row, Table } from "antd";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { employeeData, teamData } from "./sampleData";
import { useRef } from "react";
import { useSelector } from "react-redux";

function TeamAttendanceSheet() {
  const darkmode = useSelector((state) => state.settings.isDarkMode);
  const [searchData, set_searchData] = useState({
    employee_name: "",
    month: moment(),
    year: moment(),
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setselectedDate] = useState({});
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    set_searchData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [attendanceRes, setAttendanceRes] = useState(employeeData);
  const [teamAtt, set_teamAtt] = useState(teamData);

  function renderText(text) {
    if (Array.isArray(text)) {
      return text.map((each) => {
        return (
          <React.Fragment key={uuidv4()}>{renderText(each)}</React.Fragment>
        );
      });
    }
    if (text === "P") {
      return <div className="tw-text-green-500">{text}</div>;
    }

    return <div className="tw-text-red-500">{text}</div>;
  }

  function validMonthYear(year, month, attenData) {
    let isValidMonthYear = false;
    const fullDate = `${year}-${month}-${1}`;
    for (let d of attenData) {
      if (
        moment(fullDate).isSame(d.date, "month") &&
        moment(fullDate).isSame(d.date, "year")
      ) {
        isValidMonthYear = true;
      }
    }
    return isValidMonthYear ? attenData : [];
  }

  function getMonthDatesAndDays(year, month) {
    const daysInMonth = new Date(year, month, 0).getDate();
    const monthDates = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    const firstDayOfMonth = new Date(year, month, 1);
    const firstDayOfWeek = firstDayOfMonth.getDay();
    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const monthDaysAndDays = [];

    for (let i = 0; i < daysInMonth; i++) {
      const day = monthDates[i];
      const fullDate = `${year}-${month}-${day}`;
      const dayOfWeek = dayNames[(firstDayOfWeek + i) % 7];
      monthDaysAndDays.push({ day, dayOfWeek, fullDate });
    }
    return monthDaysAndDays;
  }

  function compareMonthAndYear(date1, date2) {
    const momentDate1 = moment(date1);
    const momentDate2 = moment(date2);
    return (
      momentDate1.isSame(momentDate2, "month") &&
      momentDate1.isSame(momentDate2, "year")
    );
  }

  const daysOfMonth = getMonthDatesAndDays(
    moment(searchData.year).format("YYYY"),
    moment(searchData.month).format("MM")
  ).map((each, idx) => {
    const isData = validMonthYear(
      moment(searchData.year).format("YYYY"),
      moment(searchData.month).format("MM"),
      teamData
    );
    return {
      title: (
        <div className="text-center ">
          <div
            className="tw-mb-2 tw-cursor-pointer tw-relative tw-z-50"
            onClick={() => {
              setselectedDate(isData[idx] || {});
              showModal();
            }}
          >
            <div className="tw-flex tw-text-[0.7rem] tw-font-semibold">
              {isData.length ? (
                <>
                  <div className="tw-text-green-500 tw-flex">
                    {`${isData[idx]?.present_employee?.total || ""}`}
                    <span className="tw-text-slate-500/50">|</span>
                  </div>
                  <div className="tw-text-red-500">
                    {`${isData[idx]?.absent_employee?.total || ""}`}
                  </div>
                </>
              ) : null}
            </div>
            {/* <div
              className=" tw-text-[0.7rem] tw-flex tw-justify-center tw-items-center tw-pb-[4px] "
              style={{
                borderBottom: "1px solid rgba(33,33,33,0.5)",
              }}
            >
              <EyeOutlined />
            </div> */}
          </div>
          <div className="text-center">
            <div>{each.day}</div>
            <div className="tw-text-[0.5rem]">{each.dayOfWeek}</div>
          </div>
        </div>
      ),
      width: 10,
      dataIndex: each.day,
      key: each.day,
      align: "center",
      // render: (text) => {
      //     if (text === "P") {
      //       return <div className="tw-text-green-500">{text}</div>;
      //     }
      //   return <div className="tw-text-red-500">{text}</div>;
      // },
      render: (text) => renderText(text),
    };
  });

  const columns = [
    {
      title: (
        <div className="text-center ">
          <div className="tw-mb-2 tw-cursor-pointer tw-relative tw-z-50">
            <div className="tw-flex tw-text-[0.7rem] tw-font-semibold tw-text-slate-500/50">
              Attendance Summary
            </div>
            {/* <div
            className=" tw-text-[0.7rem] tw-flex tw-justify-center tw-items-center tw-pb-[4px] "
            style={{
              borderBottom: "1px solid rgba(33,33,33,0.5)",
            }}
          >
            <EyeOutlined />
          </div> */}
          </div>
          <div className="text-center">
            <div>&nbsp;</div>
            <div className="tw-text-[0.5rem]">&nbsp;</div>
          </div>
        </div>
      ),
      width: 40,
      dataIndex: "employee_name",
      key: "employee_name",
    },
    ...daysOfMonth,
    // {
    //   title: "Total",
    //   width: 15,
    //   dataIndex: "emp_total_present",
    //   key: "emp_total_present",
    // },
  ];

  const data = attendanceRes.map((each_employee) => {
    const date1 = `${each_employee.year}-${each_employee.month}-1`;
    const date2 = `${moment(searchData.year).format("YYYY")}-${moment(
      searchData.month
    ).format("MM")}-1`;

    const isSameMonthAndYear = compareMonthAndYear(date1, date2);

    if (!isSameMonthAndYear) {
      setAttendanceRes([]);
    }
    const obj = {};

    for (let idx in each_employee.emp_attendance) {
      const each = each_employee.emp_attendance[idx];
      obj[idx] = each.status;
    }

    return {
      key: uuidv4(),
      employee_name: each_employee.name,
      // emp_total_present: each_employee.total_present,
      ...obj,
    };
  });

  const filterDataHandler = () => {
    const filteredArray = attendanceRes.filter((each_emp) =>
      each_emp.name
        .toLowerCase()
        .includes(searchData.employee_name.toLowerCase())
    );
    setAttendanceRes(filteredArray);
  };

  const handleReset = () => {
    setAttendanceRes(employeeData);
    set_searchData({
      employee_name: "",
      month: moment(),
      year: moment(),
    });
  };

  useEffect(() => {
    setAttendanceRes(employeeData);
  }, [searchData.month, searchData.year]);

  return (
    <div className="attendance-sheet">
      <div
        className={`tw-flex tw-justify-between tw-items-center tw-gap-8 tw-mb-4 tw-py-4 -tw-mx-5 tw-px-5 ${
          darkmode ? "tw-bg-slate-800" : "tw-bg-slate-50"
        }`}
      >
        <Input
          placeholder="Employee Name"
          name="employee_name"
          onChange={handleChange}
          value={searchData.employee_name}
          className={`tw-flex-1 ${
            darkmode ? "tw-bg-[#272c31] tw-text-[#7d8490]" : ""
          } `}
        />
        <DatePicker
          format="MMM"
          defaultValue={searchData.month}
          onChange={(date, dateString) => {
            handleChange({ target: { name: "month", value: date } });
          }}
          picker="month"
          className={`tw-flex-1 ${
            darkmode ? "tw-bg-[#272c31] tw-text-[#7d8490]" : ""
          } `}
        />
        <DatePicker
          format="YYYY"
          defaultValue={searchData.year}
          onChange={(date, dateString) => {
            handleChange({ target: { name: "year", value: date } });
          }}
          picker="year"
          className={`tw-flex-1 ${
            darkmode ? "tw-bg-[#272c31] tw-text-[#7d8490]" : ""
          } `}
        />
        <Button
          type="primary"
          onClick={filterDataHandler}
          className={`tw-flex-1 ${
            darkmode ? "tw-bg-[#272c31] tw-text-[#7d8490]" : ""
          } `}
        >
          Search
        </Button>
        <Button
          onClick={handleReset}
          className={`tw-flex-1 ${
            darkmode ? "tw-bg-[#272c31] tw-text-[#7d8490]" : ""
          } `}
        >
          Reset
        </Button>
      </div>

      <div className="relative">
        <Table
          size="small"
          columns={columns}
          dataSource={data}
          scroll={{
            x: 1500,
            y: 300,
          }}
          pagination={{
            defaultPageSize: 6,
          }}
        />
      </div>
      <Modal
        title={
          <div className="tw-flex tw-justify-center tw-items-center tw-font-bold">
            {moment(selectedDate?.date).format("DD-MM-YYYY")}
          </div>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="row gap-2">
          <div className="col-6">
            <div className="tw-flex tw-justify-between alert alert-success tw-font-semibold [&>p]:tw-mb-0">
              <p className="col-10 p-0">Present </p>
              <p className="col-2 p-0">
                {selectedDate?.present_employee?.total}
              </p>
            </div>
            <div className="tw-text-green-900 tw-font-semibold [&>p]:tw-mb-0 [&>p]:tw-pl-3">
              {selectedDate?.present_employee?.employee.map((each, idx) => {
                return <p key={uuidv4()}>{each}</p>;
              })}
            </div>{" "}
          </div>

          <div className="col-6">
            <div className="tw-flex tw-justify-between  alert alert-danger tw-font-semibold [&>p]:tw-mb-0">
              <p className="col-10 p-0">Absent </p>
              <p className="col-2 p-0">
                {selectedDate?.absent_employee?.total}
              </p>
            </div>
            <div className="tw-text-red-900 tw-font-semibold [&>p]:tw-mb-0 [&>p]:tw-pl-3">
              {selectedDate?.absent_employee?.employee.map((each, idx) => {
                return <p key={uuidv4()}>{each}</p>;
              })}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default TeamAttendanceSheet;
