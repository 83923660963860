import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);
let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}
const getDocumentList = (params) => {
  const data = params;
  return axios({
    method: "get",
    url: "/api/document-list",
    headers: headers,
    data: data,
  });
};

const updateDocument = (params) => {
  const { data, doc_id } = params;

  return axios({
    method: "post",
    url: `/api/update-document/${doc_id}`,
    headers: headers,
    data: data,
  });
};

const deleteDocument = (params) => {
  const { data, doc_id } = params;

  return axios({
    method: "post",
    url: `/api/delete-document/${doc_id}`,
    headers: headers,
    data: data,
  });
};

const uploadAttachment = (params) => {
  const { data, uploadType } = params;
  return axios({
    method: "post",
    url: `/api/upload-attachment/${uploadType}`,
    headers: headers,
    data: data,
  });
};
export default {
  getDocumentList,
  updateDocument,
  deleteDocument,
  uploadAttachment,
};
