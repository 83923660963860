import { Table, Col, Row } from "antd";
import React, { useRef, useState } from "react";
import BreakComp from "./BreakComp";
import { useEffect } from "react";
import api from "./api";
import moment from "moment";
import { capitalizeFunc } from "../../../functions";
const AttendanceHistoryComp = () => {
  const [attendanceList, set_attendanceList] = useState([]);
  const [dataSource, set_dataSource] = useState([]);
  const [showBreaks, set_showBreaks] = useState({
    breakBool: false,
    date: "",
  });
  const eyeRef = useRef([]).current;
  const [elementPosition, setElementPositions] = useState({});
  const containerRef = useRef(null);
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  const handleShowBreaks = (breakBool, index, date) => {
    const element = eyeRef[index];
    const containerElement = containerRef.current;
    if (element && containerRef) {
      const boundingRect = element.getBoundingClientRect();
      const containerRect = containerElement.getBoundingClientRect();
      const elementTop = boundingRect.top - containerRect.top + 10;
      const elementLeft = boundingRect.left - containerRect.left + 20;
      setElementPositions({ elementTop, elementLeft });
    }
    set_showBreaks({ breakBool, date });
  };

  const columns = [
    {
      title: "Date",
      width: 70,
      dataIndex: "date",
      key: "date",
      fixed: "left",
    },
    {
      title: "Check-in",
      dataIndex: "checkin",
      key: "checkin",
      width: 50,
    },
    {
      title: "Check-out",
      dataIndex: "checkout",
      key: "checkout",
      width: 50,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      width: 50,
    },
    {
      title: "Break",
      dataIndex: "break",
      key: "break",
      width: 30,
    },
    // {
    //   title: "Supervisor",
    //   dataIndex: "supervisor",
    //   key: "supervisor",
    //   width: 70,
    // },
    // {
    //   title: "Location",
    //   dataIndex: "location",
    //   key: "location",
    //   width: 70,
    // },
    {
      title: "Work Mode",
      dataIndex: "workmode",
      key: "workmode",
      width: 50,
    },
  ];

  const getAttendanceList = async () => {
    try {
      const res = await api.getAttendanceList();
      if (res.data.resCode === 200) {
        set_attendanceList(res?.data?.data?.data || []);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  useEffect(() => {
    getAttendanceList();
  }, []);

  const data = attendanceList.map((each, i) => ({
    key: i,
    date: moment(each?.attendance_date).format("DD-MM-YYYY"),
    checkin: each?.check_in?.split(" ")[1],
    checkout: each?.check_out?.split(" ")[1],
    duration: each?.working_hours || `-`,
    break: (
      <BreakComp
        handleShowBreaks={handleShowBreaks}
        eyeRef={eyeRef}
        index={i}
        date={each?.attendance_date}
      />
    ),
    // supervisor: capitalizeFunc(each.supervisor || `-`),
    // location: capitalizeFunc(each.location || `-`),
    workmode: capitalizeFunc(each?.shift_mode || "-"),
  }));

  return (
    <div className="tw-relative " ref={containerRef}>
      <Table
        columns={columns}
        dataSource={data}
        scroll={{
          x: 1500,
          y: 300,
        }}
        pagination={{
          defaultPageSize: 6,
        }}
      />
      <div
        className="tw-w-[25rem] tw-rounded-md tw-z-50 tw-p-2 tw-bg-gray-100 tw-transition-opacity tw-duration-300 tw-shadow-md"
        style={{
          position: "absolute",
          fontSize: "0.6rem",
          top: elementPosition.elementTop,
          left: elementPosition.elementLeft,
          opacity: showBreaks.breakBool ? 1 : 0,
        }}
      >
        <Row
          gutter={1}
          className="tw-py-1"
          style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}
        >
          <Col span={4}>Date</Col>
          <Col span={4}>Type</Col>
          <Col span={4}>Duration</Col>
          <Col span={4}>Start</Col>
          <Col span={4}>End</Col>
          <Col span={4}>Comment</Col>
        </Row>
        {attendanceList.map((each, idx1) => {
          return each?.employee_breaks.map((breaks, idx) => {
            let isRightBreak = breaks?.break_date === showBreaks.date;
            return (
              <React.Fragment key={idx1 + "-" + idx}>
                {isRightBreak && (
                  <Row gutter={1} className="tw-py-1" key={idx}>
                    <Col span={4}>{breaks?.break_date || ""}</Col>
                    <Col span={4}>
                      {capitalizeFunc(breaks?.break_type || "")}
                    </Col>
                    <Col span={4}>{breaks?.break_duration || ""}</Col>
                    <Col span={4}>
                      {(breaks?.break_start_time || "").split(" ")[1]}
                    </Col>
                    <Col span={4}>
                      {(breaks?.break_end_time || "").split(" ")[1]}
                    </Col>
                    <Col span={4}>{capitalizeFunc(breaks?.remark || "")}</Col>
                  </Row>
                )}
              </React.Fragment>
            );
          });
        })}
      </div>
    </div>
  );
};

export default AttendanceHistoryComp;
