import React from "react";
import { useState } from "react";
import WithForms from "../../../features/WithForms/WithForms";
import { lowerCase, toFixed } from "../../../functions";
import CellComp from "../../general/CellComp";
import DividerComp from "../../general/DividerComp";
import api from "./api";
import RowComp from "./RowComp";
import SalaryButton from "./SalaryButton";

const SalaryInfoForm = ({
  form,
  handleForm,
  isSubmit,
  salaryStructure,
  totalSalary,
  set_SalaryStructure,
  getSalaryCalculation,
  getSalaryStructure,
  defaultSalary,
  updateSalary,
  reviseSalary,
  view,
  set_view,
}) => {
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [showReviseBtn, setShowReviseBtn] = useState(false);

  const handleInputs = (e) => {
    handleForm(e);
    const { name, value } = e.target;
    set_SalaryStructure((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        value: value,
      },
    }));
  };

  const handleSelect = (e) => {
    const { name, value } = e.target;
    set_SalaryStructure((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        type: value,
      },
    }));
  };

  const grayStyle = {
    backgroundColor: "rgb(254, 247, 247)",
    margin: "0 -2rem",
    padding: "0 2rem",
    marginBottom: "-1rem",
    paddingBottom: "1rem",
  };

  return (
    <div className="card">
      <div className="tw-px-8">
        <div className="card-header">
          <h3 className="card-title">Salary Details</h3>
        </div>
        <div className="card-body">
          <div>
            <div className="tw-relative tw-flex tw-flex-col sm:tw-flex-row tw-justify-between tw-items-center tw-border-2 tw-border-gray-100 tw-mb-8">
              <p className="mb-0">Annual CTC</p>
              <div className="tw-flex tw-justify-between tw-items-center ">
                <div
                  style={{
                    border: "1px solid rgba(0,0,0,0.5) ",
                    borderRight: "none",
                  }}
                  className="flex-[2] tw-text-center tw-flex tw-justify-center tw-items-center tw-h-[2rem] tw-w-[2rem] tw-bg-gray-200 tw-rounded-tl-md tw-rounded-bl-md"
                  dangerouslySetInnerHTML={{ __html: "₹" }}
                />
                <input
                  type="number"
                  name="ctc_cal"
                  defaultValue={defaultSalary}
                  onChange={handleInputs}
                  className={`tw-h-[2rem] tw-rounded-tr-md tw-rounded-br-md
                  flex-5 tw-shadow tw-appearance-none 
                  tw-border tw-py-2 tw-px-3 
                  focus:tw-border-blue-300 focus:tw-outline-none focus:tw-ring-2
                  ${
                    (form.ctc && isSubmit) || (!form.ctc && isSubmit)
                      ? "tw-border-red-300"
                      : ""
                  }`}
                />

                <div className="flex-3 ml-2">per year</div>
                <div className="tw-absolute tw-top-[2rem]">
                  {form.ctc && form.ctc.error && isSubmit && (
                    <small className="text-danger">
                      This feild is required
                    </small>
                  )}
                  {!form.ctc && isSubmit && (
                    <small className="text-danger">
                      This feild is required
                    </small>
                  )}
                </div>
              </div>
              <div className="tw-flex tw-gap-2">
                <div className="tw-h-[2rem] ">
                  <button
                    onClick={() => {
                      getSalaryStructure(form?.ctc_cal?.value || defaultSalary);
                      form?.ctc_cal?.value && setShowUpdateBtn(true);
                      form?.ctc_cal?.value && setShowReviseBtn(true);
                    }}
                    className="tw-py-[0.5rem] tw-px-[0.8rem] tw-rounded-md tw-text-[0.8rem] tw-font-bold tw-outline-none tw-border-none tw-bg-blue-200 hover:tw-bg-blue-300 tw-text-blue-800"
                  >
                    Salary Calculate
                  </button>
                </div>

                {view === "revise" && (
                  <div
                    className={`tw-h-[2rem] ${
                      showUpdateBtn
                        ? ""
                        : "tw-opacity-30 tw-pointer-events-none"
                    } `}
                  >
                    <button
                      onClick={() => {
                        updateSalary();
                        set_view("");
                      }}
                      className="tw-py-[0.5rem] tw-px-[0.8rem] tw-rounded-md tw-text-[0.8rem] tw-font-bold tw-outline-none tw-border-none tw-bg-green-200 hover:tw-bg-green-300 tw-text-green-800"
                    >
                      Update salary
                    </button>
                  </div>
                )}
                {view === "update" && (
                  <div
                    className={`tw-h-[2rem] ${
                      showReviseBtn
                        ? ""
                        : "tw-opacity-30 tw-pointer-events-none"
                    } `}
                  >
                    <button
                      onClick={() => {
                        reviseSalary();
                        set_view("");
                      }}
                      className="tw-py-[0.5rem] tw-px-[0.8rem] tw-rounded-md tw-text-[0.8rem] tw-font-bold tw-outline-none tw-border-none tw-bg-orange-200 hover:tw-bg-orange-300 tw-text-orange-800"
                    >
                      Revise salary
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="tw-w-full">
              {/* header */}
              <div className="tw-flex tw-items-center tw-justify-between tw-font-bold tw-mb-4 border-bottom">
                <div className="flex-1">Salary Component</div>
                <div className="flex-1">Calculation Type</div>
                <div className="flex-1 text-right ">Monthly Amount</div>
                <div className="flex-1 text-right ">Annual Amount</div>
              </div>
              {/* body */}
              <div>
                {Object.keys(salaryStructure).map((each, idx) => {
                  switch (lowerCase(salaryStructure[each]?.name)) {
                    case undefined:
                      return null;
                    case lowerCase("Basic"):
                      return (
                        <div key={idx}>
                          <DividerComp text="Earnings" line={false} />
                          <RowComp
                            salaryStructure={salaryStructure}
                            each={each}
                            handleInputs={handleInputs}
                            handleSelect={handleSelect}
                            getSalaryCalculation={getSalaryCalculation}
                            setShowReviseBtn={setShowReviseBtn}
                          />
                        </div>
                      );

                    case lowerCase("Medical Allowance"):
                      return (
                        <div key={idx}>
                          <RowComp
                            salaryStructure={salaryStructure}
                            each={each}
                            handleInputs={handleInputs}
                            handleSelect={handleSelect}
                            getSalaryCalculation={getSalaryCalculation}
                            setShowReviseBtn={setShowReviseBtn}
                          />
                          <DividerComp text="Gross Total" line={false} />
                          <div className="tw-flex tw-mb-4 tw-bg-gray-50 tw-font-bold -tw-mx-[2rem] tw-px-[2rem] tw-flex-col sm:tw-flex-row">
                            <CellComp className="tw-flex-1">
                              Gross Total
                            </CellComp>
                            <CellComp className="tw-flex-1 ">
                              <div className=" tw-flex tw-items-center tw-mb-2 tw-w-[14rem]">
                                -
                              </div>
                            </CellComp>
                            <CellComp className="tw-flex-1" justify="flex-end">
                              {toFixed(totalSalary.MONTHLY_GROSS_SALARY)}
                            </CellComp>
                            <CellComp className="tw-flex-1" justify="flex-end">
                              {toFixed(totalSalary.ANNUALLY_GROSS_SALARY)}
                            </CellComp>
                          </div>
                        </div>
                      );
                    case lowerCase("Provident Fund Employee"):
                      return (
                        <div
                          key={idx}
                          style={{ ...grayStyle, paddingTop: "1rem" }}
                        >
                          <DividerComp text="Total Deduction" line={false} />
                          <RowComp
                            salaryStructure={salaryStructure}
                            each={each}
                            handleInputs={handleInputs}
                            handleSelect={handleSelect}
                            getSalaryCalculation={getSalaryCalculation}
                            setShowReviseBtn={setShowReviseBtn}
                          />
                        </div>
                      );

                    case lowerCase("Provident Fund Employer"):
                      return (
                        <div key={idx} style={grayStyle}>
                          <RowComp
                            salaryStructure={salaryStructure}
                            each={each}
                            handleInputs={handleInputs}
                            handleSelect={handleSelect}
                            getSalaryCalculation={getSalaryCalculation}
                            setShowReviseBtn={setShowReviseBtn}
                          />
                        </div>
                      );
                    case lowerCase("Employee State Insurance (Employee)"):
                      return (
                        <div key={idx} style={grayStyle}>
                          <RowComp
                            salaryStructure={salaryStructure}
                            each={each}
                            handleInputs={handleInputs}
                            handleSelect={handleSelect}
                            getSalaryCalculation={getSalaryCalculation}
                            setShowReviseBtn={setShowReviseBtn}
                            name={"ESI (Employee Contribution)"}
                          />
                        </div>
                      );
                    case lowerCase("Employee State Insurance (Employer)"):
                      return (
                        <div key={idx} style={grayStyle}>
                          <RowComp
                            salaryStructure={salaryStructure}
                            each={each}
                            handleInputs={handleInputs}
                            handleSelect={handleSelect}
                            getSalaryCalculation={getSalaryCalculation}
                            setShowReviseBtn={setShowReviseBtn}
                            name={"ESI (Employer Contribution)"}
                          />
                        </div>
                      );
                    case lowerCase("Professional Taxes"):
                      return (
                        <div key={idx} style={grayStyle}>
                          <RowComp
                            salaryStructure={salaryStructure}
                            each={each}
                            handleInputs={handleInputs}
                            handleSelect={handleSelect}
                            getSalaryCalculation={getSalaryCalculation}
                            setShowReviseBtn={setShowReviseBtn}
                          />
                        </div>
                      );
                    case lowerCase("Bonus"):
                      return (
                        <div key={idx} style={grayStyle}>
                          <RowComp
                            salaryStructure={salaryStructure}
                            each={each}
                            handleInputs={handleInputs}
                            handleSelect={handleSelect}
                            getSalaryCalculation={getSalaryCalculation}
                            setShowReviseBtn={setShowReviseBtn}
                          />
                        </div>
                      );
                    case lowerCase("Gratuity"):
                      return (
                        <div key={idx} style={grayStyle}>
                          <RowComp
                            salaryStructure={salaryStructure}
                            each={each}
                            handleInputs={handleInputs}
                            handleSelect={handleSelect}
                            getSalaryCalculation={getSalaryCalculation}
                            setShowReviseBtn={setShowReviseBtn}
                          />
                        </div>
                      );
                    case lowerCase("Tax Deducted At Source"):
                      return (
                        <div key={idx} style={grayStyle}>
                          <RowComp
                            salaryStructure={salaryStructure}
                            each={each}
                            handleInputs={handleInputs}
                            handleSelect={handleSelect}
                            getSalaryCalculation={getSalaryCalculation}
                            setShowReviseBtn={setShowReviseBtn}
                          />
                        </div>
                      );
                    case lowerCase("Loans"):
                      return (
                        <div key={idx} style={grayStyle}>
                          <RowComp
                            salaryStructure={salaryStructure}
                            each={each}
                            handleInputs={handleInputs}
                            handleSelect={handleSelect}
                            getSalaryCalculation={getSalaryCalculation}
                            setShowReviseBtn={setShowReviseBtn}
                          />
                        </div>
                      );
                    case lowerCase("Debit"):
                      return (
                        <div key={idx} style={grayStyle}>
                          <RowComp
                            salaryStructure={salaryStructure}
                            each={each}
                            handleInputs={handleInputs}
                            handleSelect={handleSelect}
                            getSalaryCalculation={getSalaryCalculation}
                            setShowReviseBtn={setShowReviseBtn}
                          />
                        </div>
                      );
                    case lowerCase("Advance"):
                      return (
                        <div key={idx}>
                          <div
                            style={{ ...grayStyle, paddingBottom: "0.2rem" }}
                          >
                            <RowComp
                              salaryStructure={salaryStructure}
                              each={each}
                              handleInputs={handleInputs}
                              handleSelect={handleSelect}
                              getSalaryCalculation={getSalaryCalculation}
                              setShowReviseBtn={setShowReviseBtn}
                            />
                          </div>
                          <div className="tw-flex tw-mb-4 tw-bg-red-50 tw-font-bold -tw-mx-[2rem] tw-px-[2rem] tw-flex-col sm:tw-flex-row">
                            <CellComp className="tw-flex-1">
                              Total Deduction
                            </CellComp>
                            <CellComp className="tw-flex-1 ">
                              <div className=" tw-flex tw-items-center tw-mb-2 tw-w-[14rem]">
                                -
                              </div>
                            </CellComp>
                            <CellComp className="tw-flex-1" justify="flex-end">
                              {toFixed(totalSalary.MONTHLY_TOTAL_DEDUCTION)}
                            </CellComp>
                            <CellComp className="tw-flex-1" justify="flex-end">
                              {toFixed(totalSalary.ANNUALLY_TOTAL_DEDUCTION)}
                            </CellComp>
                          </div>
                        </div>
                      );

                    default:
                      return (
                        <RowComp
                          key={idx}
                          salaryStructure={salaryStructure}
                          each={each}
                          handleInputs={handleInputs}
                          handleSelect={handleSelect}
                          getSalaryCalculation={getSalaryCalculation}
                          setShowReviseBtn={setShowReviseBtn}
                        />
                      );
                  }
                })}

                {/* ========== GROSS TOTAL ============ */}
                <div className="tw-mt-4">
                  <DividerComp text="Net Salary" line={false} />

                  {/* row-2 g_total */}
                  <div className="tw-flex tw-mb-4 tw-bg-blue-100 tw-font-bold -tw-mx-[2rem] tw-px-[2rem] tw-flex-col sm:tw-flex-row">
                    <CellComp className="tw-flex-1">Net Salary</CellComp>
                    <CellComp className="tw-flex-1 ">
                      <div className=" tw-flex tw-items-center tw-mb-2 tw-w-[14rem]">
                        -
                      </div>
                    </CellComp>
                    <CellComp className="tw-flex-1" justify="flex-end">
                      {totalSalary.MONTHLY_TOTAL
                        ? toFixed(totalSalary.MONTHLY_TOTAL)
                        : "-"}
                    </CellComp>
                    <CellComp className="tw-flex-1" justify="flex-end">
                      {totalSalary.MONTHLY_TOTAL
                        ? toFixed(totalSalary.ANNUALLY_TOTAL)
                        : "-"}
                    </CellComp>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryInfoForm;
