import React, { useState, useEffect } from "react";
import WithForms from "../../../features/WithForms/WithForms";
import regex from "../../../features/Services/validator";

const Expense = (props) => {
  const { fixNavbar, form, handleForm } = props;
  const [isSubmit, set_isSubmit] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    let errorCount = 0;

    for (const key in form) {
      if (form[key].error) {
        ++errorCount;
      }
    }
    set_isSubmit(true);
    console.log("form", form);
    // User will be set after getting success result in api
    // navigate(redirectPath, { replace: true })
  };
  return (
    <React.Fragment>
      <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="col-lg-12 col-md-12">
              <form className="card" onSubmit={handleSubmit}>
                <div className="card-body">
                  {/* <h3 className="card-title">Apply Expense</h3> */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group mb-0">
                        <label className="form-label">Description</label>
                        <textarea
                          rows={5}
                          className={`form-control bg-white ${
                            (form.reason && form.reason.error && isSubmit) ||
                            (!form.reason && isSubmit)
                              ? "border-danger"
                              : ""
                          }`}
                          placeholder="Description for Expense"
                          defaultValue=""
                          name="reason"
                          onChange={(e) => handleForm(e, null, "required")}
                        />
                        {form.reason && form.reason.error && isSubmit && (
                          <small className="text-danger">
                            Please enter leave reason
                          </small>
                        )}
                        {!form.reason && isSubmit && (
                          <small className="text-danger">
                            Leave reason is required
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="form-group">
                        <label className="form-label">Paid Details</label>
                        <select
                          className={`form-control custom-select ${
                            (form.type && form.type.error && isSubmit) ||
                            (!form.type && isSubmit)
                              ? "border-danger"
                              : ""
                          }`}
                          name="type"
                          onChange={(e) =>
                            handleForm(e, regex.value.allName, "required")
                          }
                        >
                          <option>Select</option>
                          <option value="Cash">Cash</option>
                          <option value="Cheque">Cheque</option>
                          <option value="Card">Card</option>
                          <option value="Net Banking">Net Banking </option>
                          <option value="UPI">UPI</option>
                        </select>

                        {/* <input type="text" className={`form-control ${form.brand && form.brand.error ? 'border-danger' : ''}`} id="brand" placeholder="Brand Name" name="brand" onChange={(e) => handleForm(e, regex.value.allName, 'required')} /> */}
                        {form.type && form.type.error && isSubmit && (
                          <small className="text-danger">
                            Please select correct leave type
                          </small>
                        )}
                        {!form.type && isSubmit && (
                          <small className="text-danger">
                            Leave type is required
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="form-group">
                        <label className="form-label">Approver</label>
                        <input
                          type="text"
                          className={`form-control bg-white ${
                            (form.approver &&
                              form.approver.error &&
                              isSubmit) ||
                            (!form.approver && isSubmit)
                              ? "border-danger"
                              : ""
                          }`}
                          placeholder="Approver"
                          defaultValue=""
                          name="approver"
                          onChange={(e) => handleForm(e, null, "required")}
                        />
                        {form.approver && form.reason.error && isSubmit && (
                          <small className="text-danger">
                            Amount is required
                          </small>
                        )}
                        {!form.approver && isSubmit && (
                          <small className="text-danger">
                            Amount is required
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="form-group">
                        <label className="form-label">Amount</label>
                        <input
                          type="number"
                          className={`form-control bg-white ${
                            (form.name && form.name.error && isSubmit) ||
                            (!form.name && isSubmit)
                              ? "border-danger"
                              : ""
                          }`}
                          placeholder="Amount"
                          defaultValue=""
                          name="number"
                          onChange={(e) => handleForm(e, null, "required")}
                        />
                        {form.name && form.reason.error && isSubmit && (
                          <small className="text-danger">
                            Amount is required
                          </small>
                        )}
                        {!form.name && isSubmit && (
                          <small className="text-danger">
                            Amount is required
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12">
                      <div className="form-group">
                        <label className="form-label">Upload Reciept</label>
                        <input
                          type="file"
                          className={`form-control bg-white ${
                            (form.name && form.name.error && isSubmit) ||
                            (!form.name && isSubmit)
                              ? "border-danger"
                              : ""
                          }`}
                          placeholder="Amount"
                          defaultValue=""
                          name="number"
                          onChange={(e) => handleForm(e, null, "required")}
                        />
                        {form.name && form.reason.error && isSubmit && (
                          <small className="text-danger">
                            Reciept is required
                          </small>
                        )}
                        {!form.name && isSubmit && (
                          <small className="text-danger">
                            Reciept is required
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button type="button" className="btn btn-secondary mr-2">
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Claim
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default WithForms(Expense);
