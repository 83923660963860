import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import api from "./api";
import { useHistory } from "react-router-dom";
import WithForms from "../../../features/WithForms/WithForms";
import regex from "../../../features/Services/validator";
import SingleCalendar from "react-single-calendar";
import { Link } from "react-router-dom";
import {
  message,
  Modal,
  Table,
  Button,
  Input,
  Space,
  DatePicker,
  Avatar,
  Radio,
} from "antd";
import {
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { capitalizeFunc, deleteFunc } from "../../../functions";
const { confirm } = Modal;
const Department = (props) => {
  const { fixNavbar } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSubmit, set_isSubmit] = useState(false);
  const [hasTyped, set_hasTyped] = useState({
    department_name: false,
  });

  const [id, set_Id] = useState(null);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [dataForm, set_dataForm] = useState({
    department_name: "",
  });

  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  const [department, set_Department] = useState([]);

  useEffect(() => {
    getDepartment();
  }, [isSubmit]);

  const reset = () => {
    set_dataForm({
      department_name: "",
    });
    set_isSubmit(false);
    set_hasTyped({
      department_name: false,
    });
    set_Id(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    set_dataForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    set_hasTyped((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleAdd = () => {
    set_isSubmit(true);
    for (let key in dataForm) {
      if (!dataForm[key].length) {
        return;
      }
    }
    addDepartment();
    setIsModalOpen(false);
  };
  const handleUpdate = () => {
    set_isSubmit(true);
    updateDepartment(id, dataForm);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    set_isSubmit(false);
    reset();
  };

  const updateDepartment = async (id, data) => {
    try {
      const res = await api.updateDepartment({
        id,
        department_name: dataForm.department_name,
      });
      if (res.data.resCode === 200) {
        message.success(capitalizeFunc(res.data.resMessage));
        setRes({
          resMsg: res.data.resMessage,
          type: "success",
        });
      } else {
        message.error(capitalizeFunc(res.data.resMessage));
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    reset();
    getDepartment();
  };
  const getDepartment = async () => {
    try {
      const res = await api.getDepartment().then((data) => data.data);
      if (res.resCode === 200) {
        set_Department(res?.data?.data || []);
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.resMessage,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const addDepartment = async () => {
    try {
      const res = await api.addDepartment(dataForm);
      if (res.data.resCode === 200) {
        message.success(res.data.resMessage);
        setRes({
          resMsg: res.data.resMessage,
          type: "success",
        });
      } else {
        message.error(res.data.resMessage);
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    reset();
    getDepartment();
  };
  const deleteHandler = async (id) => {
    try {
      const authres = await api.deleteDepartment({ id });
      if (authres.data.resCode === 200) {
        message.info(capitalizeFunc(authres.data.resMessage));
        setRes({
          resMsg: authres.data.resMessage,
          type: "success",
        });
      } else {
        message.error(capitalizeFunc(authres.data.resMessage));
        setRes({
          message: authres.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    reset();
    getDepartment();
  };

  const showConfirm = (id) => {
    deleteFunc(() => {
      deleteHandler(id);
      set_isSubmit(!isSubmit);
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, close, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
    close();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          allowClear
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "flex",
          }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, close, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      );
    },
  });

  const data = department.map((each, idx) => {
    return {
      key: idx,
      sr_no: idx + 1,
      department_name: capitalizeFunc(each.department_name || "-"),
      remarks: capitalizeFunc(each.remarks || "-"),
      action: (
        <>
          <button
            type="button"
            className="btn btn-icon btn-sm"
            title="Edit"
            onClick={() => {
              set_Id(each.id);
              setIsModalOpen(true);
              set_dataForm(each);
            }}
          >
            <i className="fa fa-edit" />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-sm js-sweetalert"
            title="Delete"
            data-type="confirm"
            onClick={() => showConfirm(each.id)}
          >
            <i className="fa fa-trash-o text-danger" />
          </button>
        </>
      ),
    };
  });

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "sr_no",
      key: "sr_no",
      fixed: "left",
      width: 10,
    },
    {
      title: "Department Name",
      dataIndex: "department_name",
      key: "department_name",
      width: 30,
      ...getColumnSearchProps("department_name"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: 40,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 20,
    },
  ];

  const isInputError = (str, key) => {
    str = String(str);
    return (
      (hasTyped[key] && !str.trim().length > 0) ||
      (!str.trim().length && isSubmit)
    );
  };

  console.log(id);
  return (
    <>
      <div>
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center">
              <Link to={"/dashboard"} className="btn btn-primary mb-3">
                <i className="fa fa-long-arrow-left" /> Back
              </Link>
              <div className="header-action">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    showModal();
                  }}
                >
                  <i className="fe fe-plus mr-2" /> Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-3 p-1">
          <div className="container-fluid">
            <div className="tab-content mt-3">
              <div className="tab-pane fade show active">
                <div className="card">
                  <div className="card-body">
                    <Table
                      // size="middle"
                      columns={columns}
                      dataSource={data}
                      scroll={{
                        x: 900,
                        y: 300,
                      }}
                      pagination={{
                        defaultPageSize: 6,
                      }}
                    />
                  </div>
                </div>
                <Modal
                  title={id ? "Update Department" : "Add Department"}
                  open={isModalOpen}
                  onOk={id ? handleUpdate : handleAdd}
                  onCancel={handleCancel}
                  maskClosable={false}
                  okText={id ? <>Update</> : <>Add</>}
                  className="[&>div_p]:tw-mb-1"
                >
                  <div className="tw-mb-3">
                    <p>
                      Department Name{" "}
                      <span className="tw-text-red-500 tw-font-bold">*</span>
                    </p>
                    <Input
                      name="department_name"
                      // className="tw-capitalize"
                      value={capitalizeFunc(dataForm?.department_name || "")}
                      onChange={handleChange}
                      status={
                        isInputError(
                          dataForm.department_name,
                          "department_name"
                        )
                          ? "error"
                          : ""
                      }
                    />
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Department;
