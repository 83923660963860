import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);
let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}
const addDocument = (data) => {
  const { document_type, document_html } = data;

  return axios({
    method: "post",
    url: document_type && document_html && "/api/add-employee-document",
    headers: headers,
    data: data,
  });
};
const updateDocument = (data) => {
  const { document_type, document_html, id } = data;

  return axios({
    method: "post",
    url:
      document_type && document_html && `/api/update-employee-document/${id}`,
    headers: headers,
    data: data,
  });
};
const getDocumentList = (data) => {
  return axios({
    method: "get",
    url: "/api/document-type-list",
    headers: headers,
    data: data,
  });
};

const deleteDocument = (params) => {
  const { id, data } = params;
  return axios({
    method: "post",
    url: id && `/api/delete-employee-document/${id}`,
    headers: headers,
    data: data,
  });
};

export default { addDocument, getDocumentList, deleteDocument, updateDocument };
