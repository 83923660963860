import React, { Component } from "react";
import { useEffect, useState } from "react";

import { Progress } from "antd";

const BreakTimerComp = () => {
  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    let interval;
    if (running) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else if (!running) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [running]);

  return (
    <div className="col-md-6 mx-auto tw-text-center tw-p-4">
      <div className="tw-w-[50%] card tw-mx-auto p-2 border">
        <div className="tw-text-[2.5rem] tw-font-bold tw-mb-8 tw-mt-8">
          <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
          <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
          {/* <span>{("0" + ((time / 10) % 100)).slice(-2)}</span> */}
        </div>
        <div className="buttons tw-flex tw-justify-center tw-gap-8">
          <button className="btn btn-success" onClick={() => setRunning(true)}>
            Punch in
          </button>
          <button className="btn btn-danger" onClick={() => setRunning(false)}>
            Punch out
          </button>
          {/* <button onClick={() => setTime(0)}>Reset</button> */}
        </div>
      </div>
    </div>
  );
};

class StopWatch extends Component {
  state = {
    isRunning: false,
    hh: 0,
    mm: 0,
    ss: 0,
    ms: 0,
  };

  timerID = 0;

  clickHandler = () => {
    let { isRunning } = this.state;
    if (isRunning) {
      // Running => Stop
      clearInterval(this.timerID);
    } else {
      // Stop => Running
      let { hh, mm, ss, ms } = this.state;

      this.timerID = setInterval(() => {
        ms++;
        if (ms >= 100) {
          ss++;
          ms = 0;
        }
        if (ss >= 60) {
          mm++;
          ss = 0;
        }
        this.setState({ mm, ss, ms });
      }, 10);
    }
    this.setState({ isRunning: !isRunning });
  };

  // 1 => 01
  format(num) {
    return (num + "").length === 1 ? "0" + num : num + "";
  }

  render() {
    return (
      <div className="col-md-6 mx-auto tw-text-center tw-p-4">
        <div className="tw-w-[50%] card tw-mx-auto p-2 border">
          <div className="tw-text-[2.5rem] tw-font-bold tw-mb-8 tw-mt-8">
            <span>{this.format(this.state.mm)}</span>:
            <span>{this.format(this.state.ss)}</span>:
            <span>{this.format(this.state.ms)}</span>
          </div>
          <div className="buttons tw-flex tw-justify-center tw-gap-8">
            <button className="btn btn-success" onClick={this.clickHandler}>
              {this.state.isRunning ? "Punch in" : "Punch out"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default { BreakTimerComp, StopWatch };
