import React, { memo, useState } from "react";

import {
  MinusCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Tabs, Form, Input, Select, Space, Button, Card } from "antd";
import WithForms from "../../../features/WithForms/WithForms";
import { random_unique_no } from "../../../functions";
import { v4 as uuidv4 } from "uuid";
import FieldComp from "./FieldComp";
import {
  optionObj,
  fieldsObj,
  headingObj,
  docObj,
  dynaForm_initialState,
} from "./initialState";
import { Switch } from "antd";

const UIbasedCompNew = ({
  handleFieldChange,
  dynaForm,
  setDynaForm,
  // jsonForm,
  edit,
}) => {
  function insertItem(array, id, objFunc) {
    const dupArray = [...array];
    dupArray.splice(id, 0, { ...objFunc(), id: uuidv4() });
    return dupArray;
  }

  const addOption = (each_field, each_heading) => {
    setDynaForm((prev_title) => {
      return prev_title.map((prev) => ({
        ...prev,
        docs: prev.docs.map((each_doc) => {
          return {
            ...each_doc,
            headings: each_doc.headings.map((prev_heading) => {
              if (prev_heading.id === each_heading.id) {
                return {
                  ...prev_heading,
                  fields: prev_heading.fields.map((each_f) => {
                    if (each_f.id === each_field.id) {
                      return {
                        ...each_f,
                        options: [
                          ...each_f.options,
                          {
                            ...optionObj(),
                            id: uuidv4(),
                          },
                        ],
                      };
                    }
                    return {
                      ...each_f,
                    };
                  }),
                };
              }
              return {
                ...prev_heading,
              };
            }),
          };
        }),
      }));
    });
  };

  const removeOption = (id, each_field, each_heading) => {
    setDynaForm((prev_title) => {
      return prev_title.map((prev) => ({
        ...prev,
        docs: prev.docs.map((each_doc) => {
          return {
            ...each_doc,
            headings: each_doc.headings.map((prev_heading) => {
              if (prev_heading.id === each_heading.id) {
                return {
                  ...prev_heading,
                  fields: prev_heading.fields.map((each_f) => {
                    if (each_f.id === each_field.id) {
                      return {
                        ...each_f,
                        options: each_f.options.filter(
                          (each) => each.id !== id
                        ),
                      };
                    }
                    return {
                      ...each_f,
                    };
                  }),
                };
              }
              return {
                ...prev_heading,
              };
            }),
          };
        }),
      }));
    });
  };

  const addField = (each_heading) => {
    setDynaForm((prev_title) => {
      return prev_title.map((prev) => ({
        ...prev,
        docs: prev.docs.map((each_doc) => {
          return {
            ...each_doc,
            headings: each_doc.headings.map((prev_heading) => {
              if (prev_heading.id === each_heading.id) {
                return {
                  ...prev_heading,
                  fields: [
                    ...prev_heading.fields,
                    {
                      ...fieldsObj(),
                      id: uuidv4(),
                    },
                  ],
                };
              }
              return {
                ...prev_heading,
              };
            }),
          };
        }),
      }));
    });
  };

  const removeField = (id, each_heading) => {
    setDynaForm((prev_title) => {
      return prev_title.map((prev) => ({
        ...prev,
        docs: prev.docs.map((each_doc) => {
          return {
            ...each_doc,
            headings: each_doc.headings.map((prev_heading) => {
              if (prev_heading.id === each_heading.id) {
                return {
                  ...prev_heading,
                  fields: prev_heading.fields.filter((each) => each.id !== id),
                };
              }
              return {
                ...prev_heading,
              };
            }),
          };
        }),
      }));
    });
  };
  const insertField = (id, each_heading) => {
    setDynaForm((prev_title) => {
      return prev_title.map((prev) => ({
        ...prev,
        docs: prev.docs.map((each_doc) => {
          return {
            ...each_doc,
            headings: each_doc.headings.map((prev_heading) => {
              if (prev_heading.id === each_heading.id) {
                return {
                  ...prev_heading,
                  fields: insertItem(prev_heading.fields, id, fieldsObj),
                };
              }
              return {
                ...prev_heading,
              };
            }),
          };
        }),
      }));
    });
  };

  const addHeading = (each_doc) => {
    setDynaForm((prev_title) => {
      return prev_title.map((prev) => ({
        ...prev,
        docs: prev.docs.map((prev_doc) => {
          if (prev_doc.id === each_doc.id) {
            return {
              ...prev_doc,
              headings: [
                ...prev_doc.headings,
                { ...headingObj(), id: uuidv4() },
              ],
            };
          }
          return {
            ...prev_doc,
          };
        }),
      }));
    });
  };

  const insertHeading = (id, each_doc) => {
    setDynaForm((prev_title) => {
      return prev_title.map((prev) => ({
        ...prev,
        docs: prev.docs.map((prevDoc) => {
          if (prevDoc.id === each_doc.id) {
            return {
              ...prevDoc,
              headings: insertItem(prevDoc.headings, id, headingObj),
            };
          }
          return {
            ...prevDoc,
          };
        }),
      }));
    });
  };
  const removeHeading = (id, each_doc) => {
    setDynaForm((prev_title) => {
      return prev_title.map((prev) => ({
        ...prev,
        docs: prev.docs.map((prevDoc) => {
          if (prevDoc.id === each_doc.id) {
            return {
              ...prevDoc,
              headings: prevDoc.headings.filter((each) => each.id !== id),
            };
          }
          return {
            ...prevDoc,
          };
        }),
      }));
    });
  };

  // const addDocs = (each_doc) => {
  //   setDynaForm((prev_title) => {
  //     return prev_title.map((prev) => ({
  //       ...prev,
  //       docs: [...prev.docs, { ...docObj(), id: uuidv4() }],
  //     }));
  //   });
  // };

  // const removeDocs = (id) => {
  //   setDynaForm((prev_title) => {
  //     return prev_title.map((prev) => ({
  //       ...prev,
  //       docs: prev.docs.filter((each) => each.id !== id),
  //     }));
  //   });
  // };
  console.log(dynaForm);
  return (
    <div className="row tw-flex tw-items-center tw-flex-col tw-w-full  ">
      <div className="tw-w-full lg:tw-w-[50%]">
        {/* title */}
        {dynaForm.map((each_title, title_idx) => {
          return (
            <React.Fragment key={`${each_title}-${title_idx}`}>
              <div className="mb-3 row">
                <label htmlFor="title" className="col-sm-5 col-form-label">
                  Document Name
                </label>
                <div className="col-sm-7 ">
                  <input
                    type="text"
                    className="form-control tw-capitalize"
                    id="title"
                    name="title"
                    onChange={(e) => handleFieldChange(e, each_title.id)}
                    value={(each_title?.title || "").toLowerCase()}
                    placeholder="Passport, Visa etc."
                  />
                </div>
              </div>
              {/* doc */}
              {each_title.docs.map((each_doc, doc_idx) => {
                return (
                  <div
                    className="-tw-mx-4 tw-p-4 tw-relative rounded tw-mb-8"
                    key={`Document-${doc_idx + 1}`}
                  >
                    {/* <div className="mb-3 row">
                      <label
                        htmlFor="doc_label"
                        className="col-sm-5 col-form-label"
                      >
                        {`Document-${doc_idx + 1}`}
                      </label>
                      <div className="col-sm-7">
                        <div className="mb-3 tw-flex tw-items-center tw-justify-between">
                          <div className="col-sm-2 tw-flex-[2]">
                            <Switch
                              onChange={onSwitchChangeDoc}
                              defaultChecked={showDoc}
                            />
                          </div>
                          <div className="col-sm-10 tw-flex-[8]">
                            <input
                              type="text"
                              className={`form-control ${
                                showDoc ? "tw-visible" : "tw-invisible"
                              } `}
                              id="document"
                              name={"doc"}
                              value={each_doc.doc && each_doc.doc}
                              onChange={(e) =>
                                handleFieldChange(e, each_doc.id)
                              }
                              placeholder="Passport1, Passport2 etc."
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* heading */}
                    {each_doc.headings.map((each_heading, hd_idx) => {
                      return (
                        <div
                          key={`Section-${hd_idx + 1}`}
                          className="tw-relative border -tw-mx-4 tw-p-4 rounded tw-mb-8 "
                        >
                          <div className="mb-3 row ">
                            <label
                              htmlFor="doc_label"
                              className="col-sm-3 col-form-label"
                            >
                              {`Section-${hd_idx + 1}`}
                            </label>
                            <div className="col-sm-7">
                              <div className="mb-3 tw-flex tw-items-center tw-justify-between">
                                <div className="col-sm-2 tw-flex-[2]">
                                  <Switch
                                    onChange={(checked) => {
                                      handleFieldChange(
                                        {
                                          target: {
                                            name: "isHeading",
                                            value: checked,
                                          },
                                        },
                                        each_heading.id
                                      );
                                    }}
                                    checked={
                                      each_heading.isHeading &&
                                      each_heading.isHeading
                                    }
                                  />
                                </div>
                                <div className="col-sm-10 tw-flex-[8] tw-transition-all">
                                  <input
                                    type="text"
                                    className={`form-control tw-capitalize ${
                                      each_heading.isHeading
                                        ? "tw-visible"
                                        : "tw-invisible"
                                    } `}
                                    id="heading"
                                    name={"heading"}
                                    value={(
                                      each_heading?.heading || ""
                                    ).toLowerCase()}
                                    onChange={(e) =>
                                      handleFieldChange(e, each_heading.id)
                                    }
                                    placeholder="Family info, Children info etc."
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-2"></div>
                          </div>
                          {/* fields */}
                          {each_heading.fields.map((each_field, field_idx) => {
                            return (
                              <FieldComp
                                key={`H${hd_idx + 1}-Label${field_idx + 1}`}
                                handleFieldChange={handleFieldChange}
                                headingName={`heading#${hd_idx + 1}`}
                                documentName={`document#${doc_idx + 1}`}
                                each_heading={each_heading}
                                hd_idx={hd_idx}
                                each_field={each_field}
                                field_idx={field_idx}
                                removeField={removeField}
                                addField={addField}
                                addOption={addOption}
                                removeOption={removeOption}
                                insertField={insertField}
                                // jsonForm={jsonForm}
                              />
                            );
                          })}
                          {each_doc.headings.length - 1 === hd_idx ? (
                            <div
                              className="tw-absolute -tw-bottom-2 tw-right-2 tw-bg-white tw-px-2 tw-flex tw-justify-center tw-items-center tw-cursor-pointer hover:tw-text-blue-500 tw-text-cyan-500"
                              onClick={() => addHeading(each_doc)}
                            >
                              <PlusCircleOutlined />
                              <span className="tw-ml-2">Add Section</span>
                            </div>
                          ) : (
                            <div className="tw-absolute -tw-bottom-2 tw-right-2 tw-flex tw-justify-center tw-items-center ">
                              <div
                                className="tw-bg-white tw-px-2 tw-flex tw-justify-center tw-items-center tw-cursor-pointer hover:tw-text-blue-500 tw-text-cyan-500"
                                onClick={() =>
                                  insertHeading(hd_idx + 1, each_doc)
                                }
                              >
                                <PlusCircleOutlined />
                                <span className="tw-ml-2">Insert Section</span>
                              </div>
                              <div
                                className="tw-bg-white tw-px-2 tw-flex tw-justify-center tw-items-center tw-cursor-pointer hover:tw-text-blue-500 tw-text-fuchsia-700"
                                onClick={() =>
                                  removeHeading(each_heading.id, each_doc)
                                }
                              >
                                <MinusCircleOutlined />
                                <span className="tw-ml-2">Remove Section</span>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}

                    {/* {each_title.docs.length - 1 === doc_idx ? (
                      <div
                        className="tw-absolute -tw-bottom-2 tw-right-2 tw-bg-white tw-px-2 tw-flex tw-justify-center tw-items-center tw-cursor-pointer hover:tw-text-blue-500"
                        onClick={() => addDocs(each_doc)}
                      >
                        <PlusCircleOutlined />
                        <span className="tw-ml-2">Add more document</span>
                      </div>
                    ) : (
                      <div
                        className="tw-absolute -tw-bottom-2 tw-right-2 tw-bg-white tw-px-2 tw-flex tw-justify-center tw-items-center tw-cursor-pointer hover:tw-text-blue-500"
                        onClick={() => removeDocs(each_doc.id)}
                      >
                        <MinusCircleOutlined />
                        <span className="tw-ml-2">remove document</span>
                      </div>
                    )} */}
                  </div>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default memo(UIbasedCompNew);
