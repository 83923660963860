import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import api from "./api";
import { useHistory } from "react-router-dom";
import WithForms from "../../../features/WithForms/WithForms";
import regex from "../../../features/Services/validator";
import SingleCalendar from "react-single-calendar";
import { Link } from "react-router-dom";
import {
  message,
  Modal,
  Table,
  Button,
  Input,
  Space,
  DatePicker,
  Avatar,
  Radio,
} from "antd";
import {
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { capitalizeFunc, deleteFunc } from "../../../functions";
const { confirm } = Modal;
const ManageLeave = (props) => {
  const { fixNavbar } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSubmit, set_isSubmit] = useState(false);
  const [hasTyped, set_hasTyped] = useState({
    leave_type: false,
    no_of_leave: false,
    carry_forward_leave: false,
    max_total_leave: false,
  });

  const [id, set_Id] = useState(null);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [leaveTypeForm, set_leaveTypeForm] = useState({
    leave_type: "",
    no_of_leave: "",
    carry_forward_leave: "",
    max_total_leave: "",
    leave_calculation: "month",
  });
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  const [leaveTypeList, set_leaveTypeList] = useState([]);

  useEffect(() => {
    getLeaveTypeList();
  }, [isSubmit]);

  const reset = () => {
    set_Id(null);
    set_leaveTypeForm({
      leave_type: "",
      no_of_leave: "",
      carry_forward_leave: "",
      max_total_leave: "",
      leave_calculation: "month",
    });
    set_isSubmit(false);
    set_hasTyped({
      leave_type: false,
      no_of_leave: false,
      carry_forward_leave: false,
      max_total_leave: false,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const selectedName = [
      "no_of_leave",
      "carry_forward_leave",
      "carry_forward_leave",
      "max_total_leave",
    ];
    if (selectedName.includes(name)) {
      return set_leaveTypeForm((prev) => {
        return {
          ...prev,
          [name]: Number(value) > 180 ? String(180) : value,
        };
      });
    }

    set_leaveTypeForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    set_hasTyped((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleAdd = () => {
    set_isSubmit(true);
    for (let key in leaveTypeForm) {
      if (!leaveTypeForm[key].length) {
        return;
      }
    }
    addLeaveType();
    setIsModalOpen(false);
  };
  const handleUpdate = () => {
    set_isSubmit(true);
    updateLeaveType(id, leaveTypeForm);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    set_isSubmit(false);
    reset();
  };

  const updateLeaveType = async (id, data) => {
    try {
      const res = await api.updateLeaveType({ id, data });
      console.log("authres.data", res.data);
      if (res.data.resCode === 200) {
        message.success(capitalizeFunc(res.data.resMessage));
        setRes({
          resMsg: res.data.resMessage,
          type: "success",
        });
      } else {
        message.error(capitalizeFunc(res.data.resMessage));
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    reset();
    getLeaveTypeList();
  };
  const getLeaveTypeList = async () => {
    try {
      const res = await api.getLeaveTypeList().then((data) => data.data);
      if (res.resCode === 200) {
        set_leaveTypeList(res?.data?.data || []);
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.resMessage,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const addLeaveType = async () => {
    try {
      const res = await api.addLeaveType(leaveTypeForm);
      if (res.data.resCode === 200) {
        message.success(res.data.resMessage);
        setRes({
          resMsg: res.data.resMessage,
          type: "success",
        });
      } else {
        message.error(res.data.resMessage);
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    reset();
    getLeaveTypeList();
  };
  const deleteHandler = async (id) => {
    try {
      const authres = await api.deleteLeaveType({ id });
      if (authres.data.resCode === 200) {
        message.info(capitalizeFunc(authres.data.resMessage));
        setRes({
          resMsg: authres.data.resMessage,
          type: "success",
        });
      } else {
        message.error(capitalizeFunc(authres.data.resMessage));
        setRes({
          message: authres.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    reset();
    getLeaveTypeList();
  };

  const showConfirm = (id) => {
    deleteFunc(() => {
      deleteHandler(id);
      set_isSubmit(!isSubmit);
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, close, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
    close();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          allowClear
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "flex",
          }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, close, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      );
    },
  });

  const data = leaveTypeList.map((each, idx) => {
    return {
      key: each.id,
      leave_type: capitalizeFunc(each.leave_type || "-"),
      no_of_leave: each.no_of_leave || "-",
      carry_forward_leave: each.carry_forward_leave || "-",
      max_total_leave: each.max_total_leave || "-",
      leave_calculation: capitalizeFunc(each.leave_calculation || "-"),
      action: (
        <>
          <button
            type="button"
            className="btn btn-icon btn-sm"
            title="Edit"
            onClick={() => {
              set_Id(each.id);
              setIsModalOpen(true);
              set_leaveTypeForm(each);
            }}
          >
            <i className="fa fa-edit" />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-sm js-sweetalert"
            title="Delete"
            data-type="confirm"
            onClick={() => showConfirm(each.id)}
          >
            <i className="fa fa-trash-o text-danger" />
          </button>
        </>
      ),
    };
  });

  const columns = [
    {
      title: "Leave Type",
      dataIndex: "leave_type",
      key: "leave_type",
      ...getColumnSearchProps("leave_type"),
    },
    {
      title: "No.of Leave",
      dataIndex: "no_of_leave",
      key: "no_of_leave",
    },
    {
      title: "Carry Forward",
      dataIndex: "carry_forward_leave",
      key: "carry_forward_leave",
    },
    {
      title: "Max Total Leave",
      dataIndex: "max_total_leave",
      key: "max_total_leave",
    },
    {
      title: "Leave Calculation",
      dataIndex: "leave_calculation",
      key: "leave_calculation",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const isInputError = (str, key) => {
    str = String(str);
    return (
      (hasTyped[key] && !str.trim().length > 0) ||
      (!str.trim().length && isSubmit)
    );
  };
  return (
    <>
      <div>
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center">
              <Link to={"/dashboard"} className="btn btn-primary mb-3">
                <i className="fa fa-long-arrow-left" /> Back
              </Link>
              <div className="header-action">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    showModal();
                  }}
                >
                  <i className="fe fe-plus mr-2" /> Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-3 p-1">
          <div className="container-fluid">
            <div className="tab-content mt-3">
              <div className="tab-pane fade show active">
                <div className="card">
                  <div className="card-body">
                    <Table
                      // size="middle"
                      columns={columns}
                      dataSource={data}
                      scroll={{
                        x: 900,
                        y: 300,
                      }}
                      pagination={{
                        defaultPageSize: 6,
                      }}
                    />
                  </div>
                </div>
                <Modal
                  title={id ? "Update Leave Type" : "Add Leave Type"}
                  open={isModalOpen}
                  onOk={id ? handleUpdate : handleAdd}
                  onCancel={handleCancel}
                  maskClosable={false}
                  okText={id ? <>Update</> : <>Add</>}
                  className="[&>div_p]:tw-mb-1"
                >
                  <div className="tw-mb-3">
                    <p>
                      Leave Type{" "}
                      <span className="tw-text-red-500 tw-font-bold">*</span>
                    </p>
                    <Input
                      name="leave_type"
                      // className="tw-capitalize"
                      value={capitalizeFunc(leaveTypeForm?.leave_type || "")}
                      onChange={handleChange}
                      status={
                        isInputError(leaveTypeForm.leave_type, "leave_type")
                          ? "error"
                          : ""
                      }
                    />
                  </div>
                  <div className="tw-mb-3">
                    <p>
                      Number of Leave{" "}
                      <span className="tw-text-red-500 tw-font-bold">*</span>
                    </p>
                    <Input
                      type="number"
                      name="no_of_leave"
                      value={leaveTypeForm.no_of_leave}
                      onChange={handleChange}
                      status={
                        isInputError(leaveTypeForm.no_of_leave, "no_of_leave")
                          ? "error"
                          : ""
                      }
                    />
                  </div>
                  <div className="tw-mb-3">
                    <p>
                      Carry Forward{" "}
                      <span className="tw-text-red-500 tw-font-bold">*</span>
                    </p>
                    <Input
                      type="number"
                      name="carry_forward_leave"
                      value={leaveTypeForm.carry_forward_leave}
                      onChange={handleChange}
                      status={
                        isInputError(
                          leaveTypeForm.carry_forward_leave,
                          "carry_forward_leave"
                        )
                          ? "error"
                          : ""
                      }
                    />
                  </div>
                  <div className="tw-mb-3">
                    <p>
                      Maximum Leave{" "}
                      <span className="tw-text-red-500 tw-font-bold">*</span>
                    </p>
                    <Input
                      type="number"
                      name="max_total_leave"
                      value={leaveTypeForm.max_total_leave}
                      onChange={handleChange}
                      status={
                        isInputError(
                          leaveTypeForm.max_total_leave,
                          "max_total_leave"
                        )
                          ? "error"
                          : ""
                      }
                    />
                  </div>
                  <div className="tw-mb-3">
                    <p>
                      Leave Calculation{" "}
                      <span className="tw-text-red-500 tw-font-bold">*</span>
                    </p>
                    <Radio.Group
                      name="leave_calculation"
                      value={leaveTypeForm.leave_calculation}
                      onChange={handleChange}
                    >
                      <Radio value={"month"}>Month</Radio>
                      <Radio value={"year"}>Year</Radio>
                    </Radio.Group>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageLeave;
