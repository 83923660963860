import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import WithForms from "../../../features/WithForms/WithForms";
import api from "./api";
import SalaryButton from "./SalaryButton";
import SalaryInfoForm from "./SalaryInfoForm";
import { sampleData } from "./sampleData";
import { capitalizeFunc, randomItem } from "../../../functions";
import {
  message,
  Modal,
  Table,
  Button,
  Input,
  Space,
  DatePicker,
  Avatar,
  Tag,
} from "antd";
import {
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
const Payroll = (props) => {
  const { fixNavbar, form, handleForm } = props;
  const [view, set_view] = useState("");
  const [reFetch, set_reFetch] = useState("");
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });
  const [isSubmit, set_isSubmit] = useState(false);
  const [salaryStructure, set_SalaryStructure] = useState({});
  const [totalSalary, set_TotalSalary] = useState({});
  const [salary, set_salary] = useState();
  const [employeeList, set_employeeList] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [salaryRes, set_salaryRes] = useState([]);
  const [empId, set_empId] = useState();

  const getSalaryCalculation = async () => {
    try {
      const res = await api
        .getSalaryCalculation({
          ctc: salary,
          data: salaryStructure,
        })
        .then((data) => data.data);
      if (res.resCode === 200) {
        set_SalaryStructure(res?.structure || {});

        set_TotalSalary(res?.total || {});
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const getEmployeeList = async (url) => {
    try {
      const res = await api.getEmployeeList({ url }).then((data) => data.data);
      if (res.resCode === 200) {
        set_employeeList(res?.data?.data || []);
        setPagination(res?.data?.links || []);
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const updateSalary = async () => {
    try {
      const res = await api
        .updateSalary({ ctc: salary, emp_id: empId, data: salaryRes })
        .then((data) => data.data);
      if (res.resCode === 200) {
        console.log(res);
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    set_reFetch("update refetch");
  };
  const reviseSalary = async () => {
    try {
      const res = await api
        .reviseSalary({ ctc: salary, emp_id: empId, data: salaryRes })
        .then((data) => data.data);
      if (res.resCode === 200) {
        console.log(res);
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    set_reFetch("revised update");
  };
  const getSalaryStructure = async (ctc) => {
    set_SalaryStructure({});
    set_TotalSalary({});

    try {
      const res = await api
        .getSalaryStructure({ ctc })
        .then((data) => data.data);
      if (res.resCode === 200) {
        set_salaryRes(res);
        set_SalaryStructure(res?.structure || {});
        set_TotalSalary(res?.total || {});
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  useEffect(() => {
    getEmployeeList();
  }, [view, reFetch]);

  useEffect(() => {
    set_salary(form?.ctc_cal?.value);
  }, [form?.ctc_cal?.value]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, close, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
    close();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {dataIndex === "cost_to_company" ? (
          <Input
            type="number"
            ref={searchInput}
            placeholder={`Search`}
            allowClear
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "flex",
            }}
          />
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search`}
            allowClear
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "flex",
            }}
          />
        )}

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, close, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      );
    },
  });

  const data = employeeList.map((each, idx) => {
    return {
      key: each.id || idx,
      employee_name: capitalizeFunc(
        `${each.first_name || ""} ${each.last_name || ""}`
      ),
      work_email: each.official_email.toLowerCase(),
      department: each.department_name,
      cost_to_company: each?.ctc || "",
      action: (
        <div className="tw-flex tw-gap-2">
          {!each.ctc ? (
            <SalaryButton
              text="Update salary"
              onClick={() => {
                set_empId(each.id);
                getSalaryStructure();
                set_salary("");
                set_view("revise");
              }}
              color="green"
            />
          ) : (
            <SalaryButton
              text="Revise salary"
              onClick={() => {
                set_empId(each.id);
                set_salary(each.ctc);
                getSalaryStructure(each.ctc || salary);
                set_view("update");
              }}
              color="orange"
            />
          )}
        </div>
      ),
    };
  });

  const columns = [
    {
      title: "Employee Name",
      width: 40,
      dataIndex: "employee_name",
      key: "employee_name",
      fixed: "left",
      ...getColumnSearchProps("employee_name"),
    },
    {
      title: "Work Email",
      width: 50,
      dataIndex: "work_email",
      key: "work_email",
      ...getColumnSearchProps("work_email"),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: 40,
      ...getColumnSearchProps("department"),
    },

    {
      title: "Cost To Company",
      width: 40,
      dataIndex: "cost_to_company",
      key: "cost_to_company",
      ...getColumnSearchProps("cost_to_company"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 40,
    },
  ];

  return (
    <>
      <div className={`section-body ${fixNavbar ? "marginTop" : ""}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="Payroll-tab"
                  data-toggle="tab"
                  href="#Payroll-Salary"
                >
                  Active Employee
                </a>
              </li>
              {/* <li className="nav-item">
             <a className="nav-link" id="Payroll-tab" data-toggle="tab" href="#Payroll-Payslip">
               Tax Deduction
             </a>
           </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header tw-flex tw-gap-8 tw-justify-between tw-items-center">
                <Link
                  to={!view ? "" : "/employee/payroll"}
                  className="btn btn-primary mb-3"
                  onClick={() => {
                    set_view("");
                  }}
                >
                  <i className="fa fa-long-arrow-left" /> Back
                </Link>
              </div>
              <div className="card-body">
                {view === "revise" && (
                  <SalaryInfoForm
                    isSubmit={isSubmit}
                    set_isSubmit={set_isSubmit}
                    form={form}
                    handleForm={handleForm}
                    salaryStructure={salaryStructure}
                    totalSalary={totalSalary}
                    set_SalaryStructure={set_SalaryStructure}
                    getSalaryCalculation={getSalaryCalculation}
                    getSalaryStructure={getSalaryStructure}
                    updateSalary={updateSalary}
                    reviseSalary={reviseSalary}
                    view={view}
                    set_view={set_view}
                  />
                )}
                {view === "update" && (
                  <SalaryInfoForm
                    isSubmit={isSubmit}
                    set_isSubmit={set_isSubmit}
                    form={form}
                    handleForm={handleForm}
                    salaryStructure={salaryStructure}
                    totalSalary={totalSalary}
                    set_SalaryStructure={set_SalaryStructure}
                    getSalaryCalculation={getSalaryCalculation}
                    getSalaryStructure={getSalaryStructure}
                    defaultSalary={salary}
                    updateSalary={updateSalary}
                    reviseSalary={reviseSalary}
                    view={view}
                    set_view={set_view}
                  />
                )}
                {!view && (
                  <div className="table-responsive">
                    {/* <table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>Employee Name</th>
                          <th>Work Email</th>
                          <th>Department</th>
                          <th className="tw-text-right">Cost to Company</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeeList.map((each, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{`${each.first_name} ${
                                each.last_name || ""
                              }`}</td>
                              <td>{each.official_email}</td>
                              <td>{each.department_name}</td>
                              <td className="tw-text-right">{each.ctc}</td>
                              <td>
                                <div className="tw-flex tw-gap-2">
                                  {!each.ctc ? (
                                    <SalaryButton
                                      text="Update salary"
                                      onClick={() => {
                                        set_empId(each.id);
                                        getSalaryStructure();
                                        set_salary("");
                                        set_view("revise");
                                      }}
                                      color="green"
                                    />
                                  ) : (
                                    <SalaryButton
                                      text="Revise salary"
                                      onClick={() => {
                                        set_empId(each.id);
                                        set_salary(each.ctc);
                                        getSalaryStructure(each.ctc || salary);
                                        set_view("update");
                                      }}
                                      color="orange"
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table> */}
                    <Table
                      // size="middle"
                      columns={columns}
                      dataSource={data}
                      scroll={{
                        x: 1200,
                        y: 300,
                      }}
                      pagination={{
                        defaultPageSize: 6,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* pagination data */}
            {/* <nav aria-label="Page navigation" className="tw-mb-4 tw-mr-4">
              <ul className="pagination mb-0 justify-content-end">
                {pagination.map((each) => {
                  return (
                    <li
                      className="page-item tw-cursor-pointer"
                      key={each.label}
                      onClick={() => getEmployeeList(each.url)}
                    >
                      <span
                        className="page-link"
                        dangerouslySetInnerHTML={{ __html: each.label }}
                      />
                    </li>
                  );
                })}
              </ul>
            </nav> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default WithForms(Payroll);
