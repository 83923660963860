import React from "react";
import ReactDOM from "react-dom";

const portal = document.getElementById("modal");

export class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.element = document.createElement("div");
    this.element.setAttribute("class", "modal-elem");
  }

  componentDidMount = () => {
    portal.appendChild(this.element);
  };

  componentWillUnmount = () => {
    portal.removeChild(this.element);
  };

  render() {
    return ReactDOM.createPortal(this.props.children, this.element);
  }
}

export class ApiModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const props = this.props;
    return (
      <React.Fragment>
        <div className="modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className={`modal-content alert-light`}>
              <div className="modal-header border-0">
                <h5 className={`modal-title text-${props.modalData.resType}`}>
                  <span className="text-capitalize">
                    {props.modalData.resTitle}
                  </span>
                </h5>
                {/* <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.dismiss()}>
                                    <i className="fa fa-times" />
                                </button> */}
              </div>
              <div className="modal-body border-0">
                <p className="text-dark">{props.modalData.resMessage}</p>
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className={`btn btn-${props.modalData.resType} ${
                    props.modalData.resType !== "light"
                      ? "text-light"
                      : "text-dark"
                  }`}
                  onClick={() => props.dismiss()}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export class LeaveStatusModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remarks: "",
    };
  }
  render() {
    const props = this.props;
    return (
      <React.Fragment>
        <div className="modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className={`modal-content alert-light`}>
              <div className="modal-header border-0">
                <h5 className={`modal-title text-gray`}>
                  <span className="text-capitalize">Leave Application</span>
                </h5>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => props.dismiss()}
                >
                  <i className="fa fa-times" />
                </button>
              </div>
              <div className="modal-body border-0">
                <div className="form-group mb-2">
                  <label htmlFor="remarks">
                    Remarks for {props.modalData.status}
                  </label>
                  <textarea
                    id="remarks"
                    name="remarks"
                    className="text-dark form-control tw-capitalize"
                    defaultValue=""
                    rows="3"
                    onChange={(e) => {
                      this.setState({ remarks: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className={`btn btn-secondary mr-2`}
                  onClick={() => props.dismiss()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`btn btn-primary`}
                  onClick={() => props.submit(this.state.remarks)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
