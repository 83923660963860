import React, { Component } from "react";
import LeaveChart from "./LeaveChart";
import Columnchart from "../../common/columnchart";
import { Link } from "react-router-dom";
import Donutchart from "../../common/donutchart";
import { connect } from "react-redux";

import SalaryForm from "./SalaryForm";
import LeaveBalanceComp from "../Leave/LeaveBalanceComp";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      today: new Date().toDateString(),
    };
  }
  componentDidMount() {
    const d = new Date();
  }

  render() {
    const { fixNavbar, form } = this.props;

    return (
      <>
        <div>
          <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
            <div className="container-fluid">
              <div className="row clearfix">
                <div className="col-lg-12">
                  <div
                    className={`section-body ${
                      fixNavbar ? "mb-4 mt-3" : "mb-4"
                    }`}
                  >
                    <h4 className="mt-3">Welcome to Ebro</h4>
                    {/* <small>
                      Get all your details from this dashboard.{" "}
                      <a href="fake_url">Learn More</a>
                    </small> */}
                  </div>
                </div>
              </div>
              <div className="row clearfix">
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="card">
                    <div className="card-body ribbon">
                      {/* <div className="ribbon-box green">5</div> */}
                      <Link
                        to="/admin/hr-users"
                        className="my_sort_cut text-muted"
                      >
                        <i className="icon-users" />
                        <span>Employees</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="card">
                    <div className="card-body">
                      <Link
                        to="/admin/hr-holidays"
                        className="my_sort_cut text-muted"
                      >
                        <i className="icon-like" />
                        <span>Holidays</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-xl-4">
                  <div className="card">
                    <div className="card-body ribbon">
                      <div className="ribbon-box orange">45/60</div>
                      <Link
                        to="/employee/team-leave"
                        className="my_sort_cut text-muted"
                      >
                        <i className="icon-calendar" />
                        <span>Leaves</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="card">
                    <div className="card-body ribbon">
                      {/* <div className="ribbon-box orange">45/60</div> */}
                      <Link
                        to="/employee/team-attendance"
                        className="my_sort_cut text-muted"
                      >
                        <i className="fa fa-adn" />
                        <span>Attendace</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="card">
                    <div className="card-body ribbon">
                      {/* <div className="ribbon-box orange">45/60</div> */}
                      <Link
                        to="/hr-overtime"
                        className="my_sort_cut text-muted"
                      >
                        <i className="icon-calculator" />
                        <span>Overtime</span>
                      </Link>
                    </div>
                  </div>
                </div>

                {/* <div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body">
											<Link to="/hr-accounts" className="my_sort_cut text-muted">
												<i className="icon-calculator" />
												<span>Accounts</span>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body">
											<Link to="/employee/report" className="my_sort_cut text-muted">
												<i className="icon-pie-chart" />
												<span>Report</span>
											</Link>
										</div>
									</div>
								</div> 
							</div>
						</div>
					</div>
					<div className="section-body">
						<div className="container-fluid">
							<div className="row clearfix row-deck">

								Leaves chart */}
                <div className="col-xl-4 col-lg-6 col-md-6">
                  <LeaveBalanceComp viewBtn />
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6">
                  <SalaryForm />
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                  <div className="card">
                    <div className="card-body">
                      <Link
                        to="/admin/hr-expenses"
                        className="my_sort_cut text-muted"
                      >
                        <i className="icon-credit-card" />
                        <span>Expense</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row clearfix row-deck">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Employee Structure</h3>
                    </div>
                    <div className="card-body text-center">
                      <Columnchart></Columnchart>
                    </div>

                    {/* <div className="card-body text-center">
												<div className="row clearfix">
													<div className="col-6">
														<h6 className="mb-0">50</h6>
														<small className="text-muted">Male</small>
													</div>
													<div className="col-6">
														<h6 className="mb-0">17</h6>
														<small className="text-muted">Female</small>
													</div>
												</div>
											</div> */}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Performance</h3>
                    </div>
                    <div className="card-body">
                      <span>
                        Measure How Fast You’re Growing Monthly Recurring
                        Revenue. <a href="fake_url">Learn More</a>
                      </span>
                      <ul className="list-group mt-3 mb-0">
                        <li className="list-group-item">
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>35%</strong>
                            </div>
                            <div className="float-right">
                              <small className="text-muted">Design Team</small>
                            </div>
                          </div>
                          <div className="progress progress-xs">
                            <div
                              className="progress-bar bg-azure"
                              role="progressbar"
                              style={{ width: "35%" }}
                              aria-valuenow={42}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>25%</strong>
                            </div>
                            <div className="float-right">
                              <small className="text-muted">
                                Developer Team
                              </small>
                            </div>
                          </div>
                          <div className="progress progress-xs">
                            <div
                              className="progress-bar bg-green"
                              role="progressbar"
                              style={{ width: "25%" }}
                              aria-valuenow={0}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>15%</strong>
                            </div>
                            <div className="float-right">
                              <small className="text-muted">Marketing</small>
                            </div>
                          </div>
                          <div className="progress progress-xs">
                            <div
                              className="progress-bar bg-orange"
                              role="progressbar"
                              style={{ width: "15%" }}
                              aria-valuenow={36}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>20%</strong>
                            </div>
                            <div className="float-right">
                              <small className="text-muted">Management</small>
                            </div>
                          </div>
                          <div className="progress progress-xs">
                            <div
                              className="progress-bar bg-indigo"
                              role="progressbar"
                              style={{ width: "20%" }}
                              aria-valuenow={6}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>11%</strong>
                            </div>
                            <div className="float-right">
                              <small className="text-muted">Other</small>
                            </div>
                          </div>
                          <div className="progress progress-xs">
                            <div
                              className="progress-bar bg-pink"
                              role="progressbar"
                              style={{ width: "11%" }}
                              aria-valuenow={6}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Growth</h3>
                    </div>
                    <Donutchart />

                    {/* <div className="card-footer text-center">
												<div className="row clearfix">
													<div className="col-6">
														<h6 className="mb-0">$3,095</h6>
														<small className="text-muted">Last Year</small>
													</div>
													<div className="col-6">
														<h6 className="mb-0">$2,763</h6>
														<small className="text-muted">This Year</small>
													</div>
												</div>
											</div> */}
                  </div>
                </div>
              </div>
              <div className="row clearfix">
                <div className="col-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Project Summary</h3>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-hover table-striped text-nowrap table-vcenter mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Client Name</th>
                              <th>Team</th>
                              <th>Project</th>
                              <th>Project Cost</th>
                              <th>Payment</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>#AD1245</td>
                              <td>Sean Black</td>
                              <td>
                                <ul className="list-unstyled team-info sm margin-0 w150">
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar1.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar2.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar3.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar4.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li className="ml-2">
                                    <span>2+</span>
                                  </li>
                                </ul>
                              </td>
                              <td>Angular Admin</td>
                              <td>$14,500</td>
                              <td>Done</td>
                              <td>
                                <span className="tag tag-success">
                                  Delivered
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>#DF1937</td>
                              <td>Sean Black</td>
                              <td>
                                <ul className="list-unstyled team-info sm margin-0 w150">
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar1.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar2.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar3.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar4.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li className="ml-2">
                                    <span>2+</span>
                                  </li>
                                </ul>
                              </td>
                              <td>Angular Admin</td>
                              <td>$14,500</td>
                              <td>Pending</td>
                              <td>
                                <span className="tag tag-success">
                                  Delivered
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>#YU8585</td>
                              <td>Merri Diamond</td>
                              <td>
                                <ul className="list-unstyled team-info sm margin-0 w150">
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar1.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar2.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                </ul>
                              </td>
                              <td>One page html Admin</td>
                              <td>$500</td>
                              <td>Done</td>
                              <td>
                                <span className="tag tag-orange">Submit</span>
                              </td>
                            </tr>
                            <tr>
                              <td>#AD1245</td>
                              <td>Sean Black</td>
                              <td>
                                <ul className="list-unstyled team-info sm margin-0 w150">
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar1.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar2.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar3.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar4.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                </ul>
                              </td>
                              <td>Wordpress One page</td>
                              <td>$1,500</td>
                              <td>Done</td>
                              <td>
                                <span className="tag tag-success">
                                  Delivered
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>#GH8596</td>
                              <td>Allen Collins</td>
                              <td>
                                <ul className="list-unstyled team-info sm margin-0 w150">
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar1.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar2.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar3.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/assets/images/xs/avatar4.jpg"
                                      alt="Avatar"
                                    />
                                  </li>
                                  <li className="ml-2">
                                    <span>2+</span>
                                  </li>
                                </ul>
                              </td>
                              <td>VueJs Application</td>
                              <td>$9,500</td>
                              <td>Done</td>
                              <td>
                                <span className="tag tag-success">
                                  Delivered
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  fixNavbar: state.settings.isFixNavbar,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
