import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);
let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}

const checkin = (params) => {
  const data = params;
  return axios({
    method: "POST",
    url: "/api/check-in",
    headers: headers,
    data: data,
  });
};
const checkout = (params) => {
  const { attendance_id } = params;
  return axios({
    method: "POST",
    url: `/api/checkout/${attendance_id}`,
    headers: headers,
  });
};
const breakin = (params) => {
  const { data } = params;
  return axios({
    method: "POST",
    url: `/api/employee-break-in`,
    headers: headers,
    data: data,
  });
};

const breakout = (params) => {
  const { break_id, data } = params;
  return axios({
    method: "POST",
    url: `/api/employee-break-out/${break_id}`,
    headers: headers,
    data: data,
  });
};

const breaklist = () => {
  return axios({
    method: "GET",
    url: `/api/break-list`,
    headers: headers,
    data: "",
  });
};
const isChecked_in = () => {
  return axios({
    method: "GET",
    url: `/api/is-checked-in`,
    headers: headers,
    data: "",
  });
};
const getEmployeeManager = () => {
  return axios({
    method: "GET",
    url: `/api/employee-manager`,
    headers: headers,
    data: "",
  });
};
const applyShortComing = (params) => {
  const data = params;
  return axios({
    method: "POST",
    url: `/api/apply-short-coming`,
    headers: headers,
    data: data,
  });
};

const getAttendanceList = () => {
  return axios({
    method: "GET",
    url: `/api/attendance-list`,
    headers: headers,
    data: "",
  });
};
const getTeamAttendanceList = (params) => {
  const { data_view } = params;
  return axios({
    method: "GET",
    url: `/api/manager-employee-attendance?attendance_filter=${data_view}`,
    headers: headers,
    data: "",
  });
};
const getTeamBreakManagementList = (params) => {
  const { data_view } = params;
  return axios({
    method: "GET",
    url: `/api/manager-employee-break-list?employee_breaks_filter=${data_view}`,
    headers: headers,
    data: "",
  });
};
const getTeamShortComingList = (params) => {
  const { data_view } = params;
  return axios({
    method: "GET",
    url: `/api/manager-employee-short-coming?employee_short_coming_filter=${data_view}`,
    headers: headers,
    data: "",
  });
};
const isBreakIn = (params) => {
  return axios({
    method: "GET",
    url: `/api/is-break-in`,
    headers: headers,
    data: "",
  });
};
const updateTeamShortComingAction = (params) => {
  const { short_coming_id, data } = params;
  return axios({
    method: "POST",
    url: `/api/update-short-coming/${short_coming_id}`,
    headers: headers,
    data: data,
  });
};
const updateTeamAttendanceAction = (params) => {
  const { attendance_id, data } = params;
  return axios({
    method: "POST",
    url: `/api/update-attendance/${attendance_id}`,
    headers: headers,
    data: data,
  });
};
const checkShortComing = (params) => {
  const { data } = params;
  return axios({
    method: "POST",
    url: `/api/check-short-coming-validation`,
    headers: headers,
    data: data,
  });
};
export default {
  checkin,
  checkout,
  breakin,
  breakout,
  breaklist,
  isChecked_in,
  getEmployeeManager,
  applyShortComing,
  getAttendanceList,
  getTeamAttendanceList,
  getTeamBreakManagementList,
  getTeamShortComingList,
  updateTeamShortComingAction,
  updateTeamAttendanceAction,
  checkShortComing,
  isBreakIn,
};
