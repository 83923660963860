import { Select } from "antd";
import React from "react";
import { useState } from "react";
import { lowerCase, toFixed, yearlyValue } from "../../../functions";
import CellComp from "../../general/CellComp";

const RowComp = ({
  salaryStructure,
  each,
  handleInputs,
  getSalaryCalculation,
  handleSelect,
  name,
  setShowReviseBtn,
}) => {
  const [isValueUpdate, setIsValueUpdate] = useState(false);
  return (
    <div className="tw-flex tw-mb-4 tw-flex-col sm:tw-flex-row">
      <CellComp className="tw-flex-1 tw-capitalize">
        <span className={` ${name ? "" : "tw-capitalize"}`}>
          {name ? name : lowerCase(salaryStructure[each].name)}
        </span>
      </CellComp>
      <CellComp
        justify={"space-between"}
        className="tw-flex-1 tw-flex tw-justify-between tw-items-center tw-gap-4 bg-red-500 "
      >
        {/* ctc */}
        <div className="tw-flex tw-items-center tw-justify-center tw-flex-1">
          <input
            type="text"
            name={salaryStructure[each].name}
            onChange={(e) => {
              setIsValueUpdate(true);
              handleInputs(e);
            }}
            defaultValue={`${salaryStructure[each].value}`}
            style={{
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
            className="tw-h-[2rem] tw-w-[5rem] tw-text-right tw-rounded-l-[5px] tw-rounded-r-[0px]
            tw-appearance-none 
            tw-border tw-bg-gray-50 tw-py-2 tw-px-3
        focus:tw-border-blue-300 focus:tw-outline-none focus:tw-ring-2"
          />
          <div
            style={{
              border: "1px solid rgba(0,0,0,0.5)",
              borderLeft: "none",
            }}
            className="tw-text-center tw-flex tw-justify-center tw-flex-1 tw-items-center tw-h-[2rem] tw-w-[3rem] tw-rounded-r-md "
          >
            {/* {`${salaryStructure[each].type}`} */}
            <div className="tw-flex tw-justify-center tw-w-full tw-h-full">
              <select
                name={salaryStructure[each].name}
                defaultValue={`${salaryStructure[each].type}`}
                onChange={(e) => {
                  setIsValueUpdate(true);
                  handleSelect(e);
                }}
                className="
                  tw-block
                  tw-h-[1.8rem] tw-w-full
                  tw-text-left
                  tw-px-2
                  tw-font-normal
                  text-gray-700
                  tw-text-[0.8rem]
                  tw-rounded
                  tw-transition
                  tw-ease-in-out
                  tw-outline-none
                  tw-border-none
                  focus:tw-outline-none"
              >
                <option value="%">%</option>
                <option value="fixed">fixed</option>
              </select>
            </div>
          </div>
        </div>
        <div className="tw-h-[2rem] tw-w-[3rem] ">
          <button
            onClick={() => {
              getSalaryCalculation();
              isValueUpdate && setShowReviseBtn(true);
            }}
            className="tw-py-[0.5rem] tw-px-[0.8rem] tw-rounded-md tw-text-[0.7rem] tw-outline-none tw-border-none tw-bg-orange-200 hover:tw-bg-orange-300 tw-text-orange-800"
          >
            Update
          </button>
        </div>
      </CellComp>
      <CellComp className="tw-flex-1" justify="flex-end">
        <div className="tw-text-right tw-flex tw-justify-end tw-items-center tw-h-[2rem] tw-rounded-md tw-px-4 tw-w-full">
          {toFixed(salaryStructure[each].monthly)}
        </div>
      </CellComp>
      <CellComp className="tw-flex-1" justify="flex-end">
        {yearlyValue(salaryStructure[each].monthly)}
      </CellComp>
    </div>
  );
};

export default RowComp;
