import { random_unique_no } from "../../functions";

export const professionalDetails = {
  title: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  gender: "",
  dob: "",
  office_email: "",
  office_contact_number: "",
  permanent_address: "",
  permanent_state: "",
  permanent_country: "",
  permanent_pincode: "",
  aadhar: "",
  pan: "",
  employee_id: "",
  date_of_joining: "",
  probation_period: "",
  working_days: "",
  relieving_date: "",
  designation_id: "",
  department_id: "",
  role_id: "",
  manager_id: "",
  branch_id: "",
};
export const bankDetails = {
  bank_name: "",
  bank_account: "",
  ifsc: "",
  bank_address: "",
};
export const personalDetails = {
  marital_status: "",
  nationality: "",
  blood_group: "",
  religion: "",
  alternate_contact_no: "",
  personal_email: "",
  residence_address: "",
};
export const familyDetails = () => ({
  // id: random_unique_no(5),
  name: "",
  dob: "",
  relation: "",
  phone_no: "",
});

export const emergencyContact = () => ({
  id: random_unique_no(5),
  name: "",
  relationship: "",
  phone: "",
});
export const educationalDetails = () => ({
  // id: random_unique_no(5),
  institute: "",
  address: "",
  qualification: "",
  start: "",
  end: "",
  grade: "",
});

export const workExperience = () => ({
  // id: random_unique_no(5),
  company_name: "",
  address: "",
  job_position: "",
  start_date: "",
  end_date: "",
  work_mode: "",
});
