import { DatePicker } from "antd";
import moment from "moment";
import React from "react";
import { capitalizeFunc, concatenate_str } from "../../../functions";
import { countryList } from "./countryList";
const RenderFeilds = ({
  each_heading,
  editDoc,
  form,
  infoFormOnChange,
  onDateChange,
  removeDisable,
  each_info,
  each_heading_key,
  datePicker,
}) => {
  const { RangePicker } = DatePicker;

  return (
    <>
      {each_heading?.heading && (
        <div className={"col-md-12 tw-font-bold tw-text-gray-500"}>
          <label>{capitalizeFunc(each_heading?.heading || "")}</label>
        </div>
      )}
      {each_heading?.fields?.map((field, index) => {
        return (
          <React.Fragment key={`${field?.id}-${index}`}>
            <div className={"col-md-6"}>
              <div className="form-group">
                <label
                  htmlFor={concatenate_str(
                    `${field?.label}#${each_heading_key}`
                  )}
                >
                  {capitalizeFunc(field.label || "")}
                  <span className="tw-text-red-500 tw-font-bold">
                    {field.mandatory ? "*" : null}
                  </span>
                </label>
                {field.type === "text" && (
                  <input
                    className="form-control"
                    type="text"
                    name={`${field?.label}#${each_heading_key}`}
                    defaultValue={
                      each_info &&
                      each_info[`${field?.label}#${each_heading_key}`]
                    }
                    onChange={infoFormOnChange}
                    id={concatenate_str(`${field?.label}#${each_heading_key}`)}
                    disabled={removeDisable && !editDoc}
                    placeholder={capitalizeFunc(
                      field.placeholder || field.label
                    )}
                  />
                )}
                {field.type === "number" && (
                  <input
                    className="form-control"
                    type="number"
                    name={`${field?.label}#${each_heading_key}`}
                    defaultValue={
                      each_info &&
                      each_info[`${field?.label}#${each_heading_key}`]
                    }
                    onChange={infoFormOnChange}
                    id={concatenate_str(`${field?.label}#${each_heading_key}`)}
                    disabled={removeDisable && !editDoc}
                    placeholder={capitalizeFunc(
                      field.placeholder || field.label
                    )}
                  />
                )}
                {field.type === "date" && (
                  <div className="tw-w-full">
                    <DatePicker
                      onChange={(date, dateString) =>
                        onDateChange(
                          date,
                          dateString,
                          `${field?.label}#${each_heading_key}`
                        )
                      }
                      disabled={removeDisable && !editDoc}
                      className="tw-w-full tw-rounded-[4px]"
                      defaultValue={
                        each_info &&
                        moment(
                          each_info[`${field?.label}#${each_heading_key}`] ||
                            "2000/01/01",
                          "YYYY-MM-DD"
                        )
                      }
                      placeholder="Select Date"
                    />
                  </div>
                )}
                {field.type === "date-range" && (
                  <div className="tw-w-full">
                    <RangePicker
                      disabled={removeDisable && !editDoc}
                      onChange={(date, dateString) =>
                        onDateChange(
                          date,
                          dateString,
                          `${field?.label}#${each_heading_key}`
                        )
                      }
                      className="tw-w-full tw-rounded-[4px]"
                      defaultValue={
                        each_info && [
                          moment(
                            each_info[
                              `${field?.label}#${each_heading_key}`
                            ][0] || "2000/01/01",
                            "YYYY-MM-DD"
                          ),
                          moment(
                            each_info[
                              `${field?.label}#${each_heading_key}`
                            ][1] || "2000/01/01",
                            "YYYY-MM-DD"
                          ),
                        ]
                      }
                      placeholder="Select Date"
                    />
                  </div>
                )}
                {field.type === "select" ? (
                  <div className="input-group">
                    <select
                      className="custom-select disabled:tw-bg-[#f7f6f6]"
                      disabled={removeDisable && !editDoc}
                      name={`${field?.label}#${each_heading_key}`}
                      onChange={infoFormOnChange}
                      defaultValue={
                        each_info &&
                        each_info[`${field?.label}#${each_heading_key}`]
                      }
                    >
                      <option>Choose...</option>
                      {!field.default_option &&
                        field.options.map((each) => {
                          return (
                            <option key={each.id} value={each.option}>
                              {each.option}
                            </option>
                          );
                        })}
                      {field.default_option === "country" &&
                        countryList.map((each) => {
                          return (
                            <option key={each} value={each.country}>
                              {each.country}
                            </option>
                          );
                        })}
                      {field.default_option === "nationality" &&
                        countryList.map((each) => {
                          return (
                            <option key={each} value={each.nationality}>
                              {each.nationality}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                ) : null}
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default RenderFeilds;
