export const employeeData = [
  {
    name: "Shamsher",
    total_present: 3,
    absent_employee: 3,
    month: 4,
    year: 2023,
    days_in_month: 30,
    emp_attendance: [
      {
        status: "P",
        date: `2023-04-01`,
      },
      {
        status: ["L", "P"],
        date: `2023-04-02`,
      },
      {
        status: "P",
        date: `2023-04-03`,
      },
      {
        status: "P",
        date: `2023-04-04`,
      },
      {
        status: "A",
        date: `2023-04-05`,
      },
      {
        status: "A",
        date: `2023-04-06`,
      },
      {
        status: null,
        date: `2023-04-07`,
      },
      {
        status: null,
        date: `2023-04-08`,
      },
      {
        status: null,
        date: `2023-04-09`,
      },
      {
        status: null,
        date: `2023-04-10`,
      },
      {
        status: null,
        date: `2023-04-11`,
      },
      {
        status: null,
        date: `2023-04-12`,
      },
      {
        status: null,
        date: `2023-04-13`,
      },
      {
        status: null,
        date: `2023-04-14`,
      },
      {
        status: null,
        date: `2023-04-15`,
      },
      {
        status: null,
        date: `2023-04-16`,
      },
      {
        status: null,
        date: `2023-04-17`,
      },
      {
        status: null,
        date: `2023-04-18`,
      },
      {
        status: null,
        date: `2023-04-19`,
      },
      {
        status: null,
        date: `2023-04-20`,
      },
      {
        status: null,
        date: `2023-04-21`,
      },
      {
        status: null,
        date: `2023-04-22`,
      },
      {
        status: null,
        date: `2023-04-23`,
      },
      {
        status: null,
        date: `2023-04-24`,
      },
      {
        status: null,
        date: `2023-04-25`,
      },
      {
        status: null,
        date: `2023-04-26`,
      },
      {
        status: null,
        date: `2023-04-27`,
      },
      {
        status: null,
        date: `2023-04-28`,
      },
      {
        status: null,
        date: `2023-04-29`,
      },
      {
        status: null,
        date: `2023-04-30`,
      },
    ],
    team_attendance: [
      {
        total_present: 10,
        absent_employee: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        absent_employee: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        absent_employee: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        absent_employee: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        absent_employee: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        absent_employee: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        absent_employee: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        absent_employee: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        absent_employee: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        total_absent: 15,
        date: "2023-04-01",
      },
    ],
  },
  {
    name: "Ali Raza",
    total_present: 4,
    total_absent: 2,
    month: 4,
    year: 2023,
    days_in_month: 30,
    emp_attendance: [
      {
        status: "P",
        date: `2023-04-01`,
      },
      {
        status: "A",
        date: `2023-04-02`,
      },
      {
        status: "A",
        date: `2023-04-03`,
      },
      {
        status: "P",
        date: `2023-04-04`,
      },
      {
        status: "A",
        date: `2023-04-05`,
      },
      {
        status: "A",
        date: `2023-04-06`,
      },
      {
        status: null,
        date: `2023-04-07`,
      },
      {
        status: null,
        date: `2023-04-08`,
      },
      {
        status: null,
        date: `2023-04-09`,
      },
      {
        status: null,
        date: `2023-04-10`,
      },
      {
        status: null,
        date: `2023-04-11`,
      },
      {
        status: null,
        date: `2023-04-12`,
      },
      {
        status: null,
        date: `2023-04-13`,
      },
      {
        status: null,
        date: `2023-04-14`,
      },
      {
        status: null,
        date: `2023-04-15`,
      },
      {
        status: null,
        date: `2023-04-16`,
      },
      {
        status: null,
        date: `2023-04-17`,
      },
      {
        status: null,
        date: `2023-04-18`,
      },
      {
        status: null,
        date: `2023-04-19`,
      },
      {
        status: null,
        date: `2023-04-20`,
      },
      {
        status: null,
        date: `2023-04-21`,
      },
      {
        status: null,
        date: `2023-04-22`,
      },
      {
        status: null,
        date: `2023-04-23`,
      },
      {
        status: null,
        date: `2023-04-24`,
      },
      {
        status: null,
        date: `2023-04-25`,
      },
      {
        status: null,
        date: `2023-04-26`,
      },
      {
        status: null,
        date: `2023-04-27`,
      },
      {
        status: null,
        date: `2023-04-28`,
      },
      {
        status: null,
        date: `2023-04-29`,
      },
      {
        status: null,
        date: `2023-04-30`,
      },
    ],
    team_attendance: [
      {
        total_present: 10,
        total_absent: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        total_absent: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        total_absent: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        total_absent: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        total_absent: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        total_absent: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        total_absent: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        total_absent: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        total_absent: 15,
        date: "2023-04-01",
      },
      {
        total_present: 10,
        total_absent: 15,
        date: "2023-04-01",
      },
    ],
  },
];

export const teamData = [
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-01",
  },
  {
    present_employee: {
      total: 1,
      employee: ["Shamsher"],
    },
    absent_employee: {
      total: 2,
      employee: ["Ali Raza", "Abdul Mateen"],
    },
    date: "2023-04-02",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-03",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-04",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-05",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-06",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-07",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-08",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-09",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-10",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-11",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-12",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-13",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-14",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-15",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-16",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-17",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-18",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-19",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-20",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-21",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-22",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-23",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-24",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-25",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-26",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-27",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-28",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-29",
  },
  {
    present_employee: {
      total: 2,
      employee: ["Shamsher", "Ali Raza"],
    },
    absent_employee: {
      total: 1,
      employee: ["Abdul Mateen"],
    },
    date: "2023-04-30",
  },
];
