import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import api from "./api";

const CheckinComp = ({ getCheckinId }) => {
  const [checkIn_option, set_checkIn_option] = useState(false);
  const [checkIn, set_checkIn] = useState(false);
  const [geoLocation, set_geoLocation] = useState("");
  const [checked_in_Id, set_checked_in_Id] = useState(0);
  const [checkInRes, set_checkInRes] = useState({});
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });
  const handleCheckin = async (workMode) => {
    const { latitude, longitude, IPv4 } = geoLocation;

    try {
      const res = await api.checkin({
        shift_mode: workMode,
        longitude: longitude,
        latitude: latitude,
        ip_address: IPv4,
      });
      if (res.data.resCode === 200) {
        set_checkInRes(res?.data?.data);
        getCheckinId(checked_in_Id, res?.data?.data);
        set_checkIn(true);
        set_checkIn_option(false);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    window.location.reload();
  };

  const handleCheckout = async () => {
    try {
      const res = await api.checkout({
        attendance_id: checked_in_Id || checkInRes.id,
      });
      if (res.data.resCode === 200) {
        console.log(res);
        set_checkIn(false);
        set_checkIn_option(false);
        set_checked_in_Id(0);
        set_checkInRes({});
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    window.location.reload();
  };

  const getGeoLocation = () => {
    axios
      .get("https://geolocation-db.com/json/")
      .then((res) => set_geoLocation(res.data));
  };
  const isChecked_in = async () => {
    try {
      const res = await api.isChecked_in();
      if (res.data.resCode === 200) {
        set_checked_in_Id(res.data.data.id);
        getCheckinId(res.data.data.id, checkInRes);
        set_checkIn(true);
        set_checkIn_option(false);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  useEffect(() => {
    getGeoLocation();
    isChecked_in();
  }, []);
  return (
    <>
      {checkIn_option && (
        <div className="tw-flex tw-justify-center">
          {checkIn ? (
            <button
              className={`btn btn-success`}
              onClick={() => {
                handleCheckout();
              }}
            >
              Checkout
            </button>
          ) : (
            <button
              className={`btn btn-danger`}
              onClick={() => {
                set_checkIn(false);
                set_checkIn_option(false);
              }}
            >
              <i className="fa fa-arrow-left me-2" /> Back
            </button>
          )}
        </div>
      )}

      {!checkIn_option && !checkIn && (
        <button
          className={`btn btn-primary`}
          onClick={() => {
            set_checkIn_option(true);
          }}
        >
          Checkin
        </button>
      )}

      {checkIn && !checkIn_option && (
        <button
          className={`btn btn-success`}
          onClick={() => {
            handleCheckout();
          }}
        >
          Checkout
        </button>
      )}

      <br />
      {checkIn_option && (
        <div className="d-block mt-1 mb-1">
          <button
            className="btn btn-primary mx-3"
            onClick={() => {
              handleCheckin("home");
            }}
          >
            {" "}
            <i className="fa fa-home tw-text-[1.1rem] " /> Home{" "}
          </button>
          <button
            className="btn btn-primary mx-3"
            onClick={() => {
              handleCheckin("office");
            }}
          >
            <i className="fa fa-building-o " /> Office
          </button>
          <button
            className="btn btn-primary mx-3"
            onClick={() => {
              console.log("Field");
            }}
          >
            <i className="fa fa-building-o " /> Field
          </button>
        </div>
      )}
    </>
  );
};

export default CheckinComp;
