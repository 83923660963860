import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);
let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}

const getDocumentList = (data) => {
  return axios({
    method: "get",
    url: "/api/document-type-list",
    headers: headers,
    data: data,
  });
};
const getSelectedDocument = (params) => {
  const { data, document_type } = params;
  return axios({
    method: "get",
    url:
      "/api/employee-document-html?document_type=" +
      document_type,
    headers: headers,
    data: data,
  });
};
const uploadDocument = (params) => {
  const { data } = params;

  return axios({
    method: "post",
    url: `/api/upload-document`,
    headers: headers,
    data: data,
  });
};
export default { getDocumentList, getSelectedDocument, uploadDocument };
