import React, { useEffect, useState } from "react";
import {
  message,
  Modal,
  Table,
  Button,
  Input,
  Space,
  DatePicker,
  Avatar,
  Tag,
  Collapse,
  Select,
} from "antd";
import regex from "../../../features/Services/validator";
import { capitalizeFunc, valueHandler } from "../../../functions";
import api from "./api";
import { countryList } from "../AddDocument/countryList";
import moment from "moment";
const { Panel } = Collapse;

const AddEmployeeComp = ({
  openModal,
  showModal,
  closeModal,
  formData,
  handleformData,
  isUpdating,
  saveNext,
  set_saveNext,
  hasTyped,
}) => {
  const [designation, set_Designation] = useState([]);
  const [department, set_Department] = useState([]);
  const [branch, set_Branch] = useState([]);
  const [managerList, set_managerList] = useState([]);
  const [roleList, set_roleList] = useState([]);
  const { emp_id, set_emp_id } = useState(null);
  const [isSubmit, set_isSubmit] = useState(false);
  const [titleOther, set_titleOther] = useState({ title: "", openOther: "" });
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  useEffect(() => {
    getDesignation();
    getDepartment();
    getBranch();
    getManagerList();
    getRoleList();
  }, []);

  const saveNextHandler = () => {
    set_saveNext((prev) => {
      if (prev === 4) {
        return 4;
      }
      return prev + 1;
    });
  };

  const getDesignation = async (url) => {
    try {
      const res = await api.getDesignation({ url }).then((data) => data.data);
      if (res.resCode === 200) {
        set_Designation(res?.data?.data || []);
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const getDepartment = async (url) => {
    try {
      const res = await api.getDepartment({ url }).then((data) => data.data);
      if (res.resCode === 200) {
        set_Department(res?.data?.data || []);

        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const getBranch = async (url) => {
    try {
      const res = await api.getBranch({ url }).then((data) => data.data);
      if (res.resCode === 200) {
        set_Branch(res?.data?.data || []);
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const getManagerList = async () => {
    try {
      const res = await api.getManagerList().then((data) => data.data);
      if (res.resCode === 200) {
        set_managerList(res?.data || []);

        setRes({
          message: "Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const getRoleList = async () => {
    try {
      const res = await api.getRoleList().then((data) => data.data);

      if (res.resCode === 200) {
        set_roleList(res?.data?.data || []);
        setRes({
          message: "Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const createEmployee = async (next) => {
    set_isSubmit(true);
    const {
      title,
      first_name,
      middle_name,
      last_name,
      phone_number,
      dob,
      gender,
      official_email,
      permanent_address,
      permanent_country,
      permanent_city,
      permanent_state,
      permanent_pincode,
    } = formData;
    if (!first_name || !official_email || !phone_number) {
      set_saveNext(next - 1);
      return;
    }
    try {
      const res = await api.createEmployee({
        data: {
          title,
          first_name,
          middle_name,
          last_name,
          phone_number,
          dob,
          gender,
          official_email,
          permanent_address,
          permanent_country,
          permanent_city,
          permanent_state,
          permanent_pincode,
        },
      });
      if (res.data.resCode === 200) {
        message.success(res.data.resMessage);
        set_emp_id(res.data?.emp_id);
        set_saveNext(next);
        setRes({
          message: "Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        message.error(res.data.resMessage);
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const updateEmployee = async (next) => {
    set_isSubmit(true);
    const {
      id,
      title,
      first_name,
      middle_name,
      last_name,
      phone_number,
      dob,
      gender,
      official_email,
      permanent_address,
      permanent_country,
      permanent_city,
      permanent_state,
      permanent_pincode,
    } = formData;

    if (!first_name || !official_email || !phone_number) {
      set_saveNext(next - 1);
      return;
    }
    try {
      const res = await api.updateEmployeePersonal({
        data: {
          title,
          first_name,
          middle_name,
          last_name,
          phone_number,
          dob,
          gender,
          official_email,
          permanent_address,
          permanent_country,
          permanent_city,
          permanent_state,
          permanent_pincode,
        },
        employee_id: id,
      });
      if (res.data.resCode === 200) {
        message.success(res.data.resMessage);
        set_saveNext(next);
        setRes({
          message: "Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        message.error(res.data.resMessage);
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };
  const saveProfessional = async (next) => {
    set_isSubmit(true);
    const { id } = formData;
    const {
      employee_code,
      joining_date,
      probation_period,
      working_days,
      relieving_date,
      department_id,
      designation_id,
      role_id,
      manager_id,
      office_id,
    } = formData?.employee_details;

    if (!employee_code || !designation_id) {
      set_saveNext(next - 1);
      return;
    }
    try {
      const res = await api.saveProfessional({
        data: {
          employee_id: id || emp_id,
          employee_code,
          joining_date,
          probation_period,
          working_days,
          relieving_date,
          department_id,
          designation_id,
          role_id,
          manager_id,
          office_id,
        },
        employee_id: id || emp_id,
      });

      if (res.data.resCode === 200) {
        message.success(res.data.resMessage);
        set_saveNext(next);
        setRes({
          message: "Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        message.error(res.data.resMessage);

        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");

      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };
  const saveDocumentDetails = async (next) => {
    set_isSubmit(true);
    const { id } = formData;
    const { adhar_no, pan_no, passport_no, national_id } =
      formData.employee_document_details;
    if (id || emp_id) {
      try {
        const res = await api.saveDocumentDetails({
          data: {
            employee_id: id || emp_id,
            adhar_no,
            pan_no,
            passport_no,
            national_id,
          },
          employee_id: id || emp_id,
        });
        if (res.data.resCode === 200) {
          message.success(res.data.resMessage);
          set_saveNext(next);
          setRes({
            message: "Found",
            type: "success",
            title: "SuccessFully",
          });
        } else {
          message.error(res.data.resMessage);

          setRes({
            message: res.data.message,
            type: "danger",
            title: "Error",
          });
        }
      } catch (err) {
        message.error("Something went wrong!");

        setRes({
          message: "Something went wrong!",
          type: "danger",
          title: "Error",
        });
      }
    }
  };
  const saveBankDetails = async (next) => {
    set_isSubmit(true);
    const { id } = formData;
    const { bank_name, bank_account, bank_ifsc, bank_address } =
      formData.employee_bank_details;
    if (id || emp_id) {
      try {
        const res = await api.saveBankDetails({
          data: {
            employee_id: id || emp_id,
            bank_name,
            bank_account,
            bank_ifsc,
            bank_address,
          },
          employee_id: id || emp_id,
        });
        if (res.data.resCode === 200) {
          message.success(res.data.resMessage);
          closeModal();
          set_saveNext(next);
          setRes({
            message: "Found",
            type: "success",
            title: "SuccessFully",
          });
        } else {
          message.error(res.data.resMessage);
          setRes({
            message: res.data.message,
            type: "danger",
            title: "Error",
          });
        }
      } catch (err) {
        message.error("Something went wrong!");
        setRes({
          message: "Something went wrong!",
          type: "danger",
          title: "Error",
        });
      }
    }
  };

  const buttonsAddEmployee = (
    <div className="tw-flex tw-gap-2 tw-justify-center tw-pb-4">
      <Button
        style={{
          background: "#5a5278",
          color: "white",
        }}
        onClick={() => {
          saveNextHandler();
          if (saveNext === 1) {
            isUpdating ? updateEmployee(2) : createEmployee(2);
          }
          if (saveNext === 2) {
            saveProfessional(3);
          }
          if (saveNext === 3) {
            saveDocumentDetails(4);
          }
          if (saveNext === 4) {
            saveBankDetails(4);
          }
        }}
      >
        {saveNext === 4 ? "Save" : "Save & Next"}
      </Button>
      <Button onClick={closeModal}>{"Close"}</Button>
    </div>
  );
  const isInputError = (
    str,
    key,
    regex = null,
    mandatory = "not_mandatory"
  ) => {
    let str1 = String(str);

    if (regex !== null && mandatory === "mandatory") {
      return (
        (hasTyped[key] && !regex.test(str)) || (!str1.trim().length && isSubmit)
      );
    }

    if (regex !== null) {
      return hasTyped[key] && !regex.test(str);
    }

    return (
      (hasTyped[key] && !str1.trim().length > 0) ||
      (!str1.trim().length && isSubmit)
    );
  };

  useEffect(() => {
    set_titleOther((prev) => ({
      ...prev,
      title:
        formData?.["title"] === "Mr." ||
        formData?.["title"] === "Ms." ||
        formData?.["title"] === "Mrs."
          ? ""
          : "Other",
      openOther: formData?.["title"] && true,
    }));
  }, [formData?.title]);
  return (
    <Modal
      title={isUpdating ? "Update Employee" : "Add Employee"}
      open={openModal}
      onCancel={closeModal}
      width={1000}
      footer={buttonsAddEmployee}
      maskClosable={false}
    >
      <div className="tw-w-full tw-h-[450px] tw-overflow-y-scroll ">
        <Collapse
          accordion
          bordered={false}
          activeKey={saveNext}
          onChange={(activeKey) => {
            set_saveNext(Number(activeKey));
          }}
        >
          <Panel
            header={
              <h3 className="card-title tw-font-bold tw-mt-1">
                {"Employee Details"}
              </h3>
            }
            key={1}
          >
            {/* name section */}
            <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
              {/* title */}
              <div className="col-sm-6 col-md-2">
                <p className="tw-mb-0">Title</p>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={valueHandler(formData?.title)}
                  onChange={(value) => {
                    handleformData({
                      target: { name: "title", value },
                    });
                  }}
                  options={[
                    {
                      value: "",
                      label: "Select",
                    },
                    {
                      value: "Mr.",
                      label: "Mr.",
                    },
                    {
                      value: "Ms.",
                      label: "Ms.",
                    },
                    {
                      value: "Mrs.",
                      label: "Mrs.",
                    },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                />
                {titleOther.title === "Other" && titleOther.openOther ? (
                  <Input
                    placeholder="Other Title"
                    name="title"
                    value={valueHandler(formData?.title, {
                      capitalize: true,
                    }).replace("Other", "")}
                    onChange={handleformData}
                    style={{ marginTop: "5px" }}
                  />
                ) : null}
              </div>
              <div className="col-sm-6 col-md-3">
                {/* first Name */}
                <p className="tw-mb-0">First Name</p>
                <Input
                  placeholder="First Name"
                  name="first_name"
                  value={valueHandler(formData?.first_name, {
                    capitalize: true,
                  })}
                  onChange={handleformData}
                  status={
                    isInputError(formData?.first_name, "first_name")
                      ? "error"
                      : ""
                  }
                />
              </div>
              <div className="col-sm-6 col-md-3">
                {/* Middle Name */}
                <p className="tw-mb-0">Middle Name</p>
                <Input
                  placeholder="Middle Name"
                  name="middle_name"
                  value={valueHandler(formData?.middle_name)}
                  onChange={handleformData}
                />
              </div>
              <div className="col-sm-6 col-md-3">
                {/* Last Name */}
                <p className="tw-mb-0">Last Name</p>
                <Input
                  placeholder="Last Name"
                  name="last_name"
                  value={valueHandler(formData?.last_name)}
                  onChange={handleformData}
                />
              </div>
            </div>
            {/* gender dob section */}
            <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
              {/* gender */}
              <div className="col-sm-6 col-md-2">
                <p className="tw-mb-0">Gender</p>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={valueHandler(formData?.gender)}
                  onChange={(value) => {
                    handleformData({
                      target: { name: "gender", value },
                    });
                  }}
                  options={[
                    {
                      value: "",
                      label: "Select",
                    },
                    {
                      value: "Male",
                      label: "Male",
                    },
                    {
                      value: "Female",
                      label: "Female",
                    },
                    {
                      value: "Transgender",
                      label: "Transgender",
                    },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                />
              </div>
              <div className="col-sm-6 col-md-3">
                {/* Dob */}
                <p className="tw-mb-0">Date of Birth</p>
                <DatePicker
                  allowClear={false}
                  format={"DD-MM-YYYY"}
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select DOB"
                  value={valueHandler(formData?.dob, { type: "date" })}
                  onChange={(date, dateString) => {
                    handleformData({
                      target: { name: "dob", value: date.format("YYYY-MM-DD") },
                    });
                  }}
                  disabledDate={(currentDate) => {
                    const inputDate = currentDate.startOf("day");
                    const todayDate = moment().startOf("day");
                    return inputDate.isAfter(todayDate);
                  }}
                />
              </div>
            </div>
            {/* contact section */}
            <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
              {/* Office Contact Number */}
              <div className="col-sm-6 col-md-4">
                <p className="tw-mb-0">Office Contact Number</p>
                <Input
                  type="number"
                  placeholder="Office Contact Number"
                  name="phone_number"
                  value={valueHandler(formData?.phone_number, { type: "num" })}
                  onChange={handleformData}
                  status={
                    isInputError(
                      formData?.phone_number,
                      "phone_number",
                      regex.value.mobile,
                      "mandatory"
                    )
                      ? "error"
                      : ""
                  }
                />
              </div>
              {/* Office Email */}
              <div className="col-sm-6 col-md-6">
                <p className="tw-mb-0">Office Email ID</p>
                <Input
                  placeholder="Office Email ID"
                  name="official_email"
                  value={valueHandler(formData?.official_email)}
                  onChange={handleformData}
                  status={
                    isInputError(
                      formData?.official_email,
                      "official_email",
                      regex.value.email,
                      "mandatory"
                    )
                      ? "error"
                      : ""
                  }
                />
              </div>
            </div>
            {/* Address  section */}
            <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
              {/* Address */}
              <div className="col-sm-6 col-md-6">
                <p className="tw-mb-0">Address</p>
                <Input
                  placeholder="Address"
                  name="permanent_address"
                  value={valueHandler(formData?.permanent_address)}
                  onChange={handleformData}
                />
              </div>
              {/* City */}
              <div className="col-sm-6 col-md-3">
                <p className="tw-mb-0">City</p>
                <Input
                  placeholder="City"
                  name="permanent_city"
                  value={valueHandler(formData?.permanent_city)}
                  onChange={handleformData}
                />
              </div>
              {/* State */}
              <div className="col-sm-6 col-md-3">
                <p className="tw-mb-0">State</p>
                <Input
                  placeholder="State"
                  name="permanent_state"
                  value={valueHandler(formData?.permanent_state)}
                  onChange={handleformData}
                />
              </div>
            </div>
            <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
              {/* country */}
              <div className="col-sm-6 col-md-3">
                <p className="tw-mb-0">Country</p>
                <Select
                  showSearch
                  placeholder="Country"
                  optionFilterProp="children"
                  value={valueHandler(formData?.permanent_country)}
                  onChange={(value) => {
                    handleformData({
                      target: { name: "permanent_country", value },
                    });
                  }}
                  style={{
                    width: "100%",
                  }}
                  filterOption={(input, option) => {
                    return (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  options={[
                    {
                      value: "",
                      label: "Select",
                    },
                    ...countryList.map((each) => ({
                      value: each.country.toLowerCase(),
                      label: each.country,
                    })),
                  ]}
                />
              </div>
              {/* pincode */}
              <div className="col-sm-6 col-md-3">
                <p className="tw-mb-0">Pincode</p>
                <Input
                  type="number"
                  placeholder="Pincode"
                  name="permanent_pincode"
                  value={valueHandler(formData?.permanent_pincode, {
                    type: "num",
                  })}
                  onChange={handleformData}
                />
              </div>
            </div>
          </Panel>
          <Panel
            header={
              <h3 className="card-title tw-font-bold tw-mt-1">
                {"Profession Details"}
              </h3>
            }
            key={2}
          >
            {/* Employee section */}
            <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
              <div className="col-sm-6 col-md-2">
                {/* Employee ID */}
                <p className="tw-mb-0">Employee Code</p>
                <Input
                  placeholder="Employee Code"
                  name="employee_code"
                  id="employee_details"
                  value={valueHandler(
                    formData?.employee_details?.employee_code
                  )}
                  onChange={handleformData}
                  status={
                    isInputError(
                      formData?.employee_details?.employee_code,
                      "employee_code"
                    )
                      ? "error"
                      : ""
                  }
                />
              </div>
            </div>
            <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
              <div className="col-sm-6 col-md-3">
                {/* Date of joining */}
                <p className="tw-mb-0">Date of Joining</p>
                <DatePicker
                  allowClear={false}
                  format={"DD-MM-YYYY"}
                  style={{
                    width: "100%",
                  }}
                  value={valueHandler(
                    formData?.employee_details?.joining_date,
                    { type: "date" }
                  )}
                  onChange={(date, dateString) => {
                    handleformData({
                      target: {
                        id: "employee_details",
                        name: "joining_date",
                        value: date.format("YYYY-MM-DD"),
                      },
                    });
                  }}
                  placeholder="Select date"
                />
              </div>
              <div className="col-sm-6 col-md-3">
                {/* Probation period */}
                <p className="tw-mb-0">Probation Period</p>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={valueHandler(
                    formData?.employee_details?.probation_period
                  )}
                  onChange={(value) => {
                    handleformData({
                      target: {
                        id: "employee_details",
                        name: "probation_period",
                        value,
                      },
                    });
                  }}
                  options={[
                    {
                      value: "",
                      label: "Select",
                    },
                    {
                      value: "15 days",
                      label: "15 days",
                    },
                    {
                      value: "1 month",
                      label: "1 month",
                    },
                    {
                      value: "3 months",
                      label: "3 months",
                    },
                    {
                      value: "6 months",
                      label: "6 months",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
              <div className="col-sm-6 col-md-3">
                {/* Working Days */}
                <p className="tw-mb-0">Working Days</p>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={valueHandler(formData?.employee_details?.working_days)}
                  onChange={(value) => {
                    handleformData({
                      target: {
                        id: "employee_details",
                        name: "working_days",
                        value,
                      },
                    });
                  }}
                  options={[
                    {
                      value: "",
                      label: "Select",
                    },
                    {
                      value: "5",
                      label: "5 days",
                    },
                    {
                      value: "6",
                      label: "6 days",
                    },
                  ]}
                />
              </div>
              <div className="col-sm-6 col-md-3">
                {/* Relieving Date  */}
                <p className="tw-mb-0">Relieving Date</p>
                <DatePicker
                  allowClear={false}
                  format={"DD-MM-YYYY"}
                  style={{
                    width: "100%",
                  }}
                  value={valueHandler(
                    formData?.employee_details?.relieving_date,
                    {
                      type: "date",
                    }
                  )}
                  onChange={(date, dateString) => {
                    handleformData({
                      target: {
                        id: "employee_details",
                        name: "relieving_date",
                        value: date.format("YYYY-MM-DD"),
                      },
                    });
                  }}
                  placeholder="Relieving Date"
                />
              </div>
            </div>
            {/* Department Designation Role section */}
            <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
              {/* Department */}
              <div className="col-sm-6 col-md-3">
                <p className="tw-mb-0">Department</p>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={valueHandler(
                    formData?.employee_details?.department_id,
                    { type: "num" }
                  )}
                  onChange={(value) => {
                    handleformData({
                      target: {
                        id: "employee_details",
                        name: "department_id",
                        value,
                      },
                    });
                  }}
                  options={[
                    {
                      value: "",
                      label: "Select",
                    },
                    ...department.map((each) => ({
                      value: each.id ?? "",
                      label: capitalizeFunc(each?.department_name || ""),
                    })),
                  ]}
                />
              </div>
              {/* Designation */}
              <div className="col-sm-6 col-md-3">
                <p className="tw-mb-0">Designation</p>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={valueHandler(
                    formData?.employee_details?.designation_id,
                    {
                      type: "num",
                    }
                  )}
                  onChange={(value) => {
                    handleformData({
                      target: {
                        id: "employee_details",
                        name: "designation_id",
                        value,
                      },
                    });
                  }}
                  options={[
                    {
                      value: "",
                      label: "Select",
                    },
                    ...designation.map((each) => ({
                      value: each.id ?? "",
                      label: capitalizeFunc(each?.designation_name || ""),
                    })),
                  ]}
                  status={
                    isInputError(
                      formData?.employee_details?.designation_id,
                      "designation_id"
                    )
                      ? "error"
                      : ""
                  }
                />
              </div>
              {/* Role */}
              <div className="col-sm-6 col-md-3">
                <p className="tw-mb-0">Role</p>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={valueHandler(formData?.employee_details?.role_id, {
                    type: "num",
                  })}
                  onChange={(value) => {
                    handleformData({
                      target: {
                        id: "employee_details",
                        name: "role_id",
                        value,
                      },
                    });
                  }}
                  options={[
                    {
                      value: "",
                      label: "Select",
                    },
                    ...roleList.map((each) => ({
                      value: each.id ?? "",
                      label: capitalizeFunc(each?.name || ""),
                    })),
                  ]}
                />
              </div>
            </div>
            {/* manager branch section */}
            <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
              {/* Manager */}
              <div className="col-sm-6 col-md-3">
                <p className="tw-mb-0">Reporting Manager</p>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={valueHandler(formData?.employee_details?.manager_id, {
                    type: "num",
                  })}
                  onChange={(value) => {
                    handleformData({
                      target: {
                        id: "employee_details",
                        name: "manager_id",
                        value,
                      },
                    });
                  }}
                  options={[
                    {
                      value: "",
                      label: "Select",
                    },
                    ...managerList.map((each) => ({
                      value: each.id ?? "-",
                      label: capitalizeFunc(
                        `${each?.first_name || ""} ${each?.last_name || ""}`
                      ),
                    })),
                  ]}
                />
              </div>
              {/* Branch */}
              <div className="col-sm-6 col-md-3">
                <p className="tw-mb-0">Branch</p>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={valueHandler(formData?.employee_details?.office_id, {
                    type: "num",
                  })}
                  onChange={(value) => {
                    handleformData({
                      target: {
                        id: "employee_details",
                        name: "office_id",
                        value,
                      },
                    });
                  }}
                  options={[
                    {
                      value: "",
                      label: "Select",
                    },
                    ...branch.map((each) => ({
                      value: each.id ?? "",
                      label: capitalizeFunc(each?.office_name || ""),
                    })),
                  ]}
                />
              </div>
            </div>
          </Panel>
          <Panel
            header={
              <h3 className="card-title tw-font-bold tw-mt-1">
                {"Document Details"}
              </h3>
            }
            key={3}
          >
            {/* document section */}
            <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
              {/* Aadhar Number */}
              <div className="col-sm-6 col-md-3">
                <p className="tw-mb-0">Aadhaar</p>
                <Input
                  placeholder="Aadhaar"
                  id="employee_document_details"
                  name="adhar_no"
                  value={valueHandler(
                    formData?.employee_document_details?.adhar_no
                  )}
                  onChange={handleformData}
                  status={
                    isInputError(
                      formData?.employee_document_details?.adhar_no,
                      "adhar_no",
                      regex.value.aadhaar
                    )
                      ? "error"
                      : ""
                  }
                />
              </div>
              {/* PAN */}
              <div className="col-sm-6 col-md-3">
                <p className="tw-mb-0">PAN</p>
                <Input
                  placeholder="PAN"
                  id="employee_document_details"
                  name="pan_no"
                  value={valueHandler(
                    formData?.employee_document_details?.pan_no
                  )}
                  onChange={handleformData}
                  status={
                    isInputError(
                      formData?.employee_document_details?.pan_no,
                      "pan_no",
                      regex.value.pan
                    )
                      ? "error"
                      : ""
                  }
                />
              </div>
              {/* Passport */}
              <div className="col-sm-6 col-md-3">
                <p className="tw-mb-0">Passport</p>
                <Input
                  placeholder="Passport"
                  id="employee_document_details"
                  name="passport_no"
                  value={valueHandler(
                    formData?.employee_document_details?.passport_no
                  )}
                  onChange={handleformData}
                />
              </div>
            </div>
            <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
              {/* Other */}
              <div className="col-sm-6 col-md-3">
                <p className="tw-mb-0">National IDs</p>
                <Input
                  placeholder="National IDs"
                  id="employee_document_details"
                  name="national_id"
                  value={valueHandler(
                    formData?.employee_document_details?.national_id
                  )}
                  onChange={handleformData}
                />
              </div>
            </div>
          </Panel>
          <Panel
            header={
              <h3 className="card-title tw-font-bold tw-mt-1">
                {"Bank Details"}
              </h3>
            }
            key={4}
          >
            {/* Bank name, account */}
            <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
              {/* Bank Name */}
              <div className="col-sm-6 col-md-5">
                <p className="tw-mb-0">Bank Name</p>
                <Input
                  placeholder="Bank Name"
                  id="employee_bank_details"
                  name="bank_name"
                  value={valueHandler(
                    formData?.employee_bank_details?.bank_name
                  )}
                  onChange={handleformData}
                />
              </div>
              {/* Bank Account */}
              <div className="col-sm-6 col-md-4">
                <p className="tw-mb-0">Bank Account</p>
                <Input
                  placeholder="Bank Account"
                  id="employee_bank_details"
                  name="bank_account"
                  value={valueHandler(
                    formData?.employee_bank_details?.bank_account
                  )}
                  onChange={handleformData}
                />
              </div>
            </div>
            {/* Bank ifsc, address */}
            <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
              {/* Bank Name */}
              <div className="col-sm-6 col-md-3">
                <p className="tw-mb-0">IFSC</p>
                <Input
                  placeholder="Bank Name"
                  id="employee_bank_details"
                  name="bank_ifsc"
                  value={valueHandler(
                    formData?.employee_bank_details?.bank_ifsc
                  )}
                  onChange={handleformData}
                />
              </div>
              {/* Bank Account */}
              <div className="col-sm-6 col-md-6">
                <p className="tw-mb-0">Bank Address</p>
                <Input
                  placeholder="Bank Address"
                  id="employee_bank_details"
                  name="bank_address"
                  value={valueHandler(
                    formData?.employee_bank_details?.bank_address
                  )}
                  onChange={handleformData}
                />
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    </Modal>
  );
};

export default React.memo(AddEmployeeComp);
