import CountUp from 'react-countup';
import React, { useState, useEffect } from 'react';

const Payrun = (props) => {

    const { fixNavbar } = props;
		return (
			<>
				<div className={`section-body ${fixNavbar ? "marginTop" : ""}`}>
					 <div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center">
							<ul className="nav nav-tabs page-header-tab">
								<li className="nav-item">
									<a
										className="nav-link active"
										id="Payroll-tab"
										data-toggle="tab"
										href="#Payroll-Salary"
									>
										Employee Summary
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" id="Payroll-tab" data-toggle="tab" href="#Payroll-Payslip">
										Tax Deduction
									</a>
								</li>
							</ul>
							
						 {/* <div className="header-action">
								<button
									type="button"
									className="btn btn-primary"
									
									data-target="#exampleModal" id="Payroll-tab" data-toggle="tab" href="#Payroll-Payslip" 
								>
									<i className="fe fe-plus mr-2" />
									Add
								</button>
							</div> */}
						</div>
					</div>
				</div>
				<div className="container-fluid">
              
                        <div className="row">
								<div className="col-12">
									<div className="card">
                  <div className="card-header">
             <div className="card-title">
             <select className="form-control" defaultValue="">
             <option value="All Employee">All Employee</option>
             <option value="Active Employee">Active Employee</option>
             <option value="Active Employee">Employee with LOP</option>
             <option value="Active Employee">Tax overriden Employee</option>
             <option value="Active Employee">SKipped Employee</option>
             <option value="Active Employee">Salary withheld Employee</option>
             <option value="Active Employee">yet to pay Employee</option>
             <option value="Active Employee">Paid Employee</option>
              </select>
              </div>&nbsp;&nbsp;&nbsp;&nbsp;              <form>
													<div className="input-group">
														<input
															type="text"
															className="form-control form-control-sm"
															placeholder="Search something..."
															name="s"
														/>
														<span className="input-group-btn ml-2">
															<button className="btn btn-sm btn-default" type="submit">
																<span className="fe fe-search" />
															</button>
														</span>
													</div>
												</form>
                  </div>
										<div className="card-body">
											<div className="table-responsive">
                      <table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
													<thead>
														<tr>
                            <th>EmployeeName</th>
                            <th>Paid Days</th>
                            <th>Gross Pay</th>
                            <th>Deduction</th>
                            <th>Taxes</th>
                            <th>BEnefits</th>
                            <th>Reimbursement</th>
                            
                            </tr>
                            </thead>
                            <tbody>
                              <tr>
                              <td>Marshall</td>
                                <td>25</td>
                                <td>98100</td>
                                <td>1500</td>
                                <td>7%</td>
                                <td>1400</td>
                                <td>2000</td>
                              </tr>
                              <tr>
                              <td>Montee</td>
                                <td>27</td>
                                <td>98100</td>
                                <td>1500</td>
                                <td>4%</td>
                                <td>1500</td>
                                <td>3000</td>
                              </tr>
                              <tr>
                              <td>John Deo</td>
                                <td>28</td>
                                <td>50000</td>
                                <td>7000</td>
                                <td>10%</td>
                                <td>1000</td>
                                <td>2000</td>
                              </tr>
                              <tr>
                              <td>Anurag</td>
                                <td>31</td>
                                <td>45000</td>
                                <td>4000</td>
                                <td>15%</td>
                                <td>1000</td>
                                <td>4000</td>
                              </tr>
                              <tr>
                              <td>Jayant</td>
                                <td>31</td>
                                <td>59000</td>
                                <td>1000</td>
                                <td>20%</td>
                                <td>1400</td>
                                <td>2000</td>
                              </tr>

                            </tbody>
                            </table>
                            <button type="button" class="btn btn-primary pull-right">Submit Payrun</button>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
											</div>
                    
									
										
										{/* <nav aria-label="Page navigation">
											<ul className="pagination mb-0 justify-content-end">
												<li className="page-item">
													<a className="page-link" href="/#">
														Previous
													</a>
												</li>
												<li className="page-item active">
													<a className="page-link" href="/#">
														1
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="/#">
														2
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="/#">
														3
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="/#">
														Next
													</a>
												</li>
											</ul>
										</nav> */}

							{/* <div className="tab-pane fade" id="Payroll-Payslip" role="tabpanel">
								<div className="card">
									<div className="card-body">
										<div className="media mb-4">
											<div className="mr-3">
												<img
													className="rounded"
													src="../assets/images/xs/avatar4.jpg"
													alt="fake_url"
												/>
											</div>

										</div>
										<div className="table-responsive">
<table class="empDetail"  style={{width: "100%",textalign: "left", border: "2px solid black", bordercollapse: "collapse",tablelayout: "fixed" }}>
 <tr style={{backgroundcolor:"#c2d69b"}}>
              </tr>
              <tr  >
                <th>
                 Employe Name
      </th>
                <td>
                  Example
      </td>
                <td></td>
                <th>
                  Bank Code
      </th>
                <td>
                  ABC123
      </td>
                <td></td>
                <th>
                  Branch Name
      </th>
                <td>
                  ABC123
      </td>
              </tr>

              <tr>
                <th>
                  Employee Id
      </th>
                <td>
                  XXXXXXXXXXX
      </td>
                <td></td>
                <th>
                  Bank Name
      </th>
                <td>
                  XXXXXXXXXXX
      </td>
                <td></td>
                <th>
                  Payslip no:
      </th>
                <td>
                  XXXXXXXXXX
      </td>
              </tr>
              <tr>
                <th>
                  Aadhar Card No:
      </th>
                <td>
                  XXXXXXXXXXX
      </td><td></td>
                <th>
                  Bank Branch
      </th>
                <td>
                  XXXXXXXXXX
      </td><td></td>
                <th>
                  Pay Period
      </th>
                <td>
                  XXXXXXXXXXX
      </td>
              </tr>
              <tr>
                <th>
                  CC Description:
      </th>
                <td>
                  XXXXXXXXXXX
      </td><td></td>
                <th>
                  Bank A/C no.
      </th>
                <td>
                  XXXXXXXXXX
      </td><td></td>
                <th>
				DATE JOINED 
      </th>
                <td>
                  XXXXXXXXXX
      </td>
              </tr>
              <tr>
                <th>
                  Grade:
      </th>
                <td>
                  18
      </td><td></td>
                <th>
                  Employee Group
      </th>
                <td>
                  Sales Manager
      </td><td></td>
                <th>
                  PAN No:
      </th>
                <td>
                  MOP72182E
      </td>
              </tr>
              <tr class="myBackground" style={ {textalign: "center", border: "2px solid black",}}>
                <th colspan="2">
                  Payments
      </th>
                <th >
                  Particular
      </th>
                <th class="table-border-right" style={{textalign: "center",borderright: "1px solid black"}}>
                  Amount (Rs.)
      </th>
                <th colspan="2" style={{textalign: "center",borderright: "1px solid black"}}>
                  Deductions
      </th>
                <th style={{textalign: "center",borderright: "1px solid black"}}>
                  Particular
      </th>
                <th style={{textalign: "center",borderright: "1px solid black"}}>
                  Amount (Rs.)
      </th>
              </tr>
              <tr>
                <th colspan="2" style={{textalign: "center",borderright: "1px solid black"}}>
                  Basic Salary
      </th>
                <td></td>
                <td class="myAlign" style={{textalign: "center",borderright: "1px solid black"}}>
                  4935.00
      </td>
	  <th colspan="2" >
                  Loan
      </th>
                <td></td>

                <td class="myAlign" style={{textalign: "center",borderright: "1px solid black"}}>
                  00.00
      </td>
              </tr>
              <tr>
                <th colspan="2" style={{textalign: "center",borderright: "1px solid black"}}>
                  Variable Dearness Allowance
      </th>
                <td></td>

                <td class="myAlign" style={{textalign: "center",borderright: "1px solid black"}}>
                  00.00
      </td>
               
              </tr>
              <tr>
                <th colspan="2">
                  House Rent Allowance
      </th>
                <td></td>
                <td class="myAlign" style={{textalign: "center",borderright: "1px solid black"}}>
                  00.00
      </td>
              </tr>
              <tr>
                <td colspan="4" class="table-border-right" style={{textalign: "center",borderright: "1px solid black"}}></td>
                <th colspan="2" >
                  Income Tax
      </th> <td></td>
                <td class="myAlign" style={{textalign: "center",borderright: "1px solid black"}}>
                  00.00
      </td>
              </tr>
              <tr class="myBackground" style={{paddingtop: "10px",textalign: "left",backgroundcolor: "#EBF1DE",borderspacing: "0px"}}>
                <th colspan="3">
                  Total Payments
      </th>
                <td class="myAlign" style={{textalign: "center",borderright: "1px solid black"}}>
                  10000
      </td>
                <th colspan="3" style={{textalign: "center",borderright: "1px solid black"}}>
                  Total Deductions
      </th>
                <td class="myAlign" style={{textalign: "center",borderright: "1px solid black"}}>
                  1000
      </td>
              </tr>
              <tr height="40px" style={{textalign: "center",borderright: "1px solid black"}}>
                <th colspan="2" style={{textalign: "center",borderright: "1px solid black"}}>
             </th>
			 </tr>
			 <tr>
                <td colspan="2">
                  Gross Salary
                </td> <td style={{textalign: "center",borderright: "1px solid black"}}></td>
                <td class="myAlign" style={{textalign: "center",borderright: "1px solid black"}}>
                  00.00
      </td><td colspan="4"></td>
              </tr>
              <tr>
                <td colspan="2" style={{textalign: "center",borderright: "1px solid black"}}>
                  
      </td> <td></td>
                <td class="myAlign" style={{textalign: "center",borderright: "1px solid black"}}>
                  
      </td>
                <th colspan="2" style={{textalign: "center",borderright: "1px solid black"}} >
                
      </th>
                <td colspan="2" style={{textalign: "center",borderright: "1px solid black"}}></td>
              </tr>
              <tr>
                <td colspan="2" style={{textalign: "center",borderright: "1px solid black"}}>
                  Gross Total Income
      </td> <td></td>
                <td class="myAlign" style={{textalign: "center",borderright: "1px solid black"}}>
                  00.00
      </td>
                <td colspan="2" >
                  
      </td> <td></td>
                <td class="myAlign" style={{textalign: "center",borderright: "1px solid black"}}>
                  
      </td>
              </tr>
              <tr>
                <td colspan="2" style={{textalign: "center",borderright: "1px solid black"}}>
                  
      </td> <td></td>
                <td class="myAlign" style={{textalign: "center",borderright: "1px solid black"}} >
                
      </td><td colspan="4"></td>
              </tr>
              <tr>
                <td colspan="2" style={{textalign: "center",borderright: "1px solid black"}} >
                  Total Income
      </td> <td></td>
                <td class="myAlign" style={{textalign: "center",borderright: "1px solid black"}}>
                  00.00
      </td>
                <td colspan="4"></td>
              </tr>
              <tbody class="border-center" style={{textalign: "center",borderright: "1px solid black"}}>
                <tr>
                  <th style={{border: "1px solid black"}}>
                    Attend/ Absence
      </th>
                  <th style={{border: "1px solid black"}}>
                    Days in Month
      </th>
                  <th style={{border: "1px solid black"}}>
                    Days Paid
      </th>
                  <th style={{border: "1px solid black"}}>
                    Days Not Paid
      </th>
                  <th style={{border: "1px solid black"}}>
                    Leave Position
      </th>
                  <th style={{border: "1px solid black"}}>
                    Privilege Leave
      </th>
                  <th style={{border: "1px solid black"}}>
                    Sick Leave
      </th>
                  <th style={{border: "1px solid black"}}>
                    Casual Leave
      </th>
                </tr>
                <tr style={{textalign: "center",borderright: "1px solid black"}}>
                  <td  style={{border: "1px solid black"}}></td>
                  <td  style={{border: "1px solid black"}}></td>
                  <td  style={{border: "1px solid black"}}></td>
                  <td  style={{border: "1px solid black"}}></td>
                  <td style={{border: "1px solid black"}}>Yrly Open Balance</td>
                  <td style={{border: "1px solid black"}}>0.0</td> 
				  <td style={{border: "1px solid black"}}>0.0</td>
                  <td style={{border: "1px solid black"}}>0.0</td>
                </tr>
                <tr style={{border: "1px solid black"}}>
                  <th style={{border: "1px solid black"}} >Current Month</th>
                  <td style={{border: "1px solid black"}} >31.0</td>
                  <td  style={{border: "1px solid black"}}>31.0</td>
                  <td  style={{border: "1px solid black"}}>31.0</td>
                  <td style={{border: "1px solid black"}}>Availed</td>
                  <td style={{border: "1px solid black"}}>0.0</td> 
				  <td style={{border: "1px solid black"}}>0.0</td>
                  <td style={{border: "1px solid black"}}>0.0</td>
                </tr>
                <tr>
                  <td colspan="4" style={{border: "1px solid black"}}></td>
                  <td style={{border: "1px solid black"}}>Closing Balance</td>
                  <td style={{border: "1px solid black"}}>0.0</td> 
				  <td style={{border: "1px solid black"}}>0.0</td>
                  <td style={{border: "1px solid black"}}>0.0</td>
                </tr>
                <tr>
                  <td colspan="4" style={{border: "1px solid black"}}> &nbsp; </td>
                  <td style={{textalign: "center",borderright: "1px solid black"}}> </td>
                  <td style={{textalign: "center",borderright: "1px solid black"}} > </td>
                  <td style={{textalign: "center",borderright: "1px solid black"}}> </td>
                  <td style={{textalign: "center",borderright: "1px solid black"}}> </td>
                </tr>
                <tr>
                  <td colspan="4" style={{border: "1px solid black"}}></td>
                  <td style={{border: "1px solid black"}}>Company Pool Leave Balance</td>
                  <td style={{border: "1px solid black"}}>1500</td>
                  <td  style={{border: "1px solid black"}}></td>
                  <td style={{border: "1px solid black"}}></td>
                </tr>
              </tbody>
            </table>
			

											<button className="btn btn-info float-right">
												<i className="icon-printer" /> Print
											</button>
										</div>
									</div>
								</div>
								</div> */}



			</>
		);
	}
    export default Payrun;

// const mapStateToProps = state => ({
// 	fixNavbar: state.settings.isFixNavbar
// })

// const mapDispatchToProps = dispatch => ({})
// export default connect(mapStateToProps, mapDispatchToProps)(Payroll);