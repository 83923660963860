import { Radio } from "antd";
import React from "react";
import { useState } from "react";

const RadioComp = ({
  handleFieldChange,
  headingName,
  hd_idx,
  field_idx,
  each_field,
}) => {
  const [value, setValue] = useState(each_field.default_option);

  const onChange = (e) => {
    setValue(e.target.value);
    handleFieldChange(e, each_field.id);
  };

  return (
    <Radio.Group onChange={onChange} value={value} name={"default_option"}>
      <Radio value={"country"}>Country</Radio>
      <Radio value={"nationality"}>Nationality</Radio>
    </Radio.Group>
  );
};

export default RadioComp;
