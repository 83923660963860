import React, { useState, useEffect } from 'react';
// HOC
import WithForms from '../../../features/WithForms/WithForms';
import regex from '../../../features/Services/validator';
import SingleCalendar from 'react-single-calendar';

const SearchForm = (props) => {
    const { form, handleForm } = props;
    const [isSubmit, set_isSubmit] = useState(false);
    const [dateString, set_dateString] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        let errorCount = 0;

        for (const key in form) {
            if (form[key].error) {
                ++errorCount;
            }
        }
        set_isSubmit(true);
        console.log('form', form);
        // User will be set after getting success result in api
        // navigate(redirectPath, { replace: true })

    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-2">
                        <h3 className="card-title pt-3">Filter by  </h3>
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Serial Number</label>
                                    <input type="text" className={`form-control`} name="srnumber" placeholder="Serial Number" onChange={(e) => handleForm(e, null)} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Employee Name</label>
                                    <input type="text" className={`form-control`} name="empname" placeholder="Employe Name" onChange={(e) => handleForm(e, null)} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Overtime Date</label>
                                    <input type="text" className={`form-control bg-white`} placeholder={'yyyy-mm-dd'} readOnly value={form.otdate && form.otdate.value ? form.otdate.value : ''} onFocus={() => { set_dateString('otdate') }} />
                                    {dateString === 'otdate' &&
                                        <>
                                            <div style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, backgroundColor: 'rgba(255,0,0,0)' }} onClick={() => { set_dateString('') }}></div>
                                            <SingleCalendar
                                                selectedDate={(date) => {
                                                    let ev = {
                                                        target: {
                                                            name: "otdate",
                                                            value: date
                                                        }
                                                    }
                                                    handleForm(ev);
                                                    set_dateString('');
                                                }}
                                                format={'yyyy-mm-dd'}
                                                currentDate={form.otdate && form.otdate.value ? form.otdate.value : ''}
                                                range={false}
                                                upcoming={true}
                                            />
                                        </>
                                    }


                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <div className="form-group">
                                        <label>Overtime Hour</label>
                                        <select className={`form-control custom-select`} name="othours" onChange={(e) => handleForm(e)} >
                                            <option>select</option>
                                            {Array.from(Array(10), (e, i) => {
                                                return <option key={i} value={i + 1}>{i + 1}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <div className="form-group">
                                        <label>Status</label>
                                        <select className={`form-control custom-select`} name="status" onChange={(e) => handleForm(e)} >
                                            <option>select</option>
                                            <option value="pending">Pending</option>
                                            <option value="approved">Approved</option>
                                            <option value="rejected">Rejected</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <div className="form-group">
                                        <label>Approved By</label>
                                        <select className={`form-control custom-select`} name="approvedby" onChange={(e) => handleForm(e)} >
                                            <option>select</option>
                                            <option value="manager">Manager</option>
                                            <option value="hr">HR</option>
                                            <option value="supervisor">Supervisoe</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-3"></div> */}
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="d-block">&nbsp;&nbsp;&nbsp;</label>
                                    <button className="btn btn-primary ">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </form>
        </>
    )
}

export default WithForms(SearchForm);