import React, { Component, useState, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../features/auth/auth";
import { Modal, LeaveStatusModal } from "../../features/modal/Modal";
import CheckinComp from "../HRMS/Attendance/CheckinComp";

const Header = (props) => {
  const { fixNavbar, darkHeader } = props;
  const darkmode = useSelector((state) => state.settings.isDarkMode);
  let [modal, setModal] = useState(false);
  const [checked_in_Id, set_checked_in_Id] = useState(0);
  const auth = useAuth();

  const handleDarkMode = (e) => {
    props.darkModeAction(e.target.checked);
  };

  const handleLogout = (e) => {
    auth.logout();
  };

  const handleSubmit = (data) => {
    setModal(false);
  };

  const leavModal = modal ? (
    <Modal>
      <LeaveStatusModal
        modalData={{ status: "Approve" }}
        dismiss={() => setModal(false)}
        submit={(data) => handleSubmit(data)}
      />
    </Modal>
  ) : null;

  const getCheckinId = (id, data) => {
    set_checked_in_Id(id);
  };

  useEffect(() => {}, []);

  return (
    <div>
      <div
        id="page_top"
        // className={isFixNavbar ? "sticky-top" : "" + this.props.dataFromParent === 'dark' ? 'section-body top_dark' : 'section-body'}
        className={`section-body ${fixNavbar ? "sticky-top" : ""} ${
          darkHeader ? "top_dark" : ""
        }`}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between p-2 py-3">
            <div className="left">
              <h1 className="page-title">{props.dataFromSubParent}</h1>
            </div>
            <div>
              <CheckinComp getCheckinId={getCheckinId} />
            </div>
            <div className="right">
              <div className="notification d-flex tw-justify-center tw-items-center tw-gap-4">
                {/* changed d-flex to dnone */}
                <div className="dropdown d-none">
                  <a
                    href="/#"
                    className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
                    data-toggle="dropdown"
                  >
                    <i className="fa fa-envelope" />
                    <span className="badge badge-success nav-unread" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                    <ul className="right_chat list-unstyled w250 p-0">
                      <li className="online">
                        <a href="fake_url">
                          <div className="media">
                            <img
                              className="media-object "
                              src="../assets/images/xs/avatar4.jpg"
                              alt="fake_url"
                            />
                            <div className="media-body">
                              <span className="name">Donald Gardner</span>
                              <span className="message">Designer, Blogger</span>
                              <span className="badge badge-outline status" />
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="online">
                        <a href="fake_url">
                          <div className="media">
                            <img
                              className="media-object "
                              src="../assets/images/xs/avatar5.jpg"
                              alt="fake_url"
                            />
                            <div className="media-body">
                              <span className="name">Wendy Keen</span>
                              <span className="message">Java Developer</span>
                              <span className="badge badge-outline status" />
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="offline">
                        <a href="fake_url">
                          <div className="media">
                            <img
                              className="media-object "
                              src="../assets/images/xs/avatar2.jpg"
                              alt="fake_url"
                            />
                            <div className="media-body">
                              <span className="name">Matt Rosales</span>
                              <span className="message">CEO, Epic Theme</span>
                              <span className="badge badge-outline status" />
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="online">
                        <a href="fake_url">
                          <div className="media">
                            <img
                              className="media-object "
                              src="../assets/images/xs/avatar3.jpg"
                              alt="fake_url"
                            />
                            <div className="media-body">
                              <span className="name">Phillip Smith</span>
                              <span className="message">
                                Writter, Mag Editor
                              </span>
                              <span className="badge badge-outline status" />
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                    <div className="dropdown-divider" />
                    <a className="dropdown-item text-center text-muted-dark readall">
                      Mark all as read
                    </a>
                  </div>
                </div>
                <div className="dropdown d-none">
                  <a
                    href="/#"
                    className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
                    data-toggle="dropdown"
                  >
                    <i className="fa fa-bell" />
                    <span className="badge badge-primary nav-unread" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                    <ul className="list-unstyled feeds_widget">
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-check" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title text-danger">
                            Issue Fixed{" "}
                            <small className="float-right text-muted">
                              11:05
                            </small>
                          </h4>
                          <small>
                            WE have fix all Design bug with Responsive
                          </small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-user" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            New User{" "}
                            <small className="float-right text-muted">
                              10:45
                            </small>
                          </h4>
                          <small>I feel great! Thanks team</small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-thumbs-o-up" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            7 New Feedback{" "}
                            <small className="float-right text-muted">
                              Today
                            </small>
                          </h4>
                          <small>
                            It will give a smart finishing to your site
                          </small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-question-circle" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title text-warning">
                            Server Warning{" "}
                            <small className="float-right text-muted">
                              10:50
                            </small>
                          </h4>
                          <small>Your connection is not private</small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-shopping-cart" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            7 New Orders{" "}
                            <small className="float-right text-muted">
                              11:35
                            </small>
                          </h4>
                          <small>You received a new oder from Tina.</small>
                        </div>
                      </li>
                    </ul>
                    <div className="dropdown-divider" />
                    <a
                      href="fake_url"
                      className="dropdown-item text-center text-muted-dark readall"
                    >
                      Mark all as read
                    </a>
                  </div>
                </div>

                <span>
                  <label className="custom-switch">
                    {/* <button type="button" className="btn btn-primary" onClick={()=>setModal(true)}> check modal</button> */}
                    <span className="custom-switch-description">
                      <i
                        className={`fa fa-${darkmode ? "sun" : "moon"}-o mr-3`}
                      />
                    </span>
                    <input
                      type="checkbox"
                      name="custom-switch-checkbox"
                      className="custom-switch-input btn-darkmode"
                      onChange={(e) => handleDarkMode(e)}
                    />
                    <span className="custom-switch-indicator" />
                  </label>
                </span>
                <div className="dropdown d-flex">
                  <a
                    href="/#"
                    className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
                    data-toggle="dropdown"
                  >
                    <i className="fa fa-user" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                    <NavLink to="/employee/profile" className="dropdown-item">
                      <i className="dropdown-icon fe fe-user" /> Profile
                    </NavLink>
                    {/* <a className="dropdown-item" >
												<i className="dropdown-icon fe fe-settings" /> Settings
											</a>
											<a className="dropdown-item">
												<span className="float-right">
													<span className="badge badge-primary">6</span>
												</span>
												<i className="dropdown-icon fe fe-mail" /> Inbox
											</a>
											<a className="dropdown-item" >
												<i className="dropdown-icon fe fe-send" /> Message
											</a>
											<div className="dropdown-divider" />
											<a className="dropdown-item" >
												<i className="dropdown-icon fe fe-help-circle" /> Need help?
											</a> */}

                    <NavLink to="/employee/reset" className="dropdown-item">
                      <i className="dropdown-icon fe fe-shield" /> Reset
                    </NavLink>
                    <p
                      onClick={handleLogout}
                      className="dropdown-item tw-cursor-pointer"
                    >
                      <i className="dropdown-icon fe fe-log-out" /> Sign out
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {leavModal} */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  fixNavbar: state.settings.isFixNavbar,
  darkHeader: state.settings.isDarkHeader,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
