import React from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

const OptionComp = ({
  each_field,
  each_option,
  addOption,
  removeOption,
  options,
  option_idx,
  field_idx,
  handleFieldChange,
  each_heading,
  fieldsList_idx,
  headingName,
  documentName,
}) => {
  return (
    <div className="mb-1 row">
      <div className="col-sm-10">
        <input
          type="text"
          className="form-control tw-capitalize"
          id="field_select_options"
          placeholder="Yes, No etc."
          name={"option"}
          value={(each_option?.option || "").toLowerCase()}
          onChange={(e) => handleFieldChange(e, each_option.id)}
        />
      </div>

      {options.length - 1 === option_idx ? (
        <div
          onClick={() => addOption(each_field, each_heading)}
          className="col-sm-2 tw-mt-1 tw-cursor-pointer"
        >
          <PlusCircleOutlined />
        </div>
      ) : (
        <div
          onClick={() => removeOption(each_option.id, each_field, each_heading)}
          className="col-sm-2 tw-mt-1 tw-cursor-pointer"
        >
          <MinusCircleOutlined />
        </div>
      )}
    </div>
  );
};

export default OptionComp;
