import { Modal, Button } from "antd";
import React, { useState, useCallback } from "react";
import Comp80c from "./Comp80c";
import HRAcomp from "./HRAcomp";
import Comp80d from "./Comp80d";
import OtherVIADeduction from "./OtherVIADeduction";
import api from "./api";
import CompVIA from "./CompVIA";
import CompOI from "./CompOI";
import CompHP from "./CompHP";

const ModalComp = ({ show_Modal, set_show_Modal, selected_module }) => {
  const { code, name } = selected_module;

  return (
    <Modal
      title={name}
      open={show_Modal}
      onCancel={() => set_show_Modal(false)}
      width={1000}
      footer={null}
      maskClosable={false}
    >
      <div className="tw-w-full tw-h-[450px] tw-overflow-y-scroll ">
        {code === "80C" ? <Comp80c set_show_Modal={set_show_Modal} /> : null}
        {code === "HRA" ? <HRAcomp set_show_Modal={set_show_Modal} /> : null}
        {code === "80D" ? <Comp80d set_show_Modal={set_show_Modal} /> : null}
        {code === "VI-A" ? <CompVIA set_show_Modal={set_show_Modal} /> : null}
        {code === "HP" ? <CompHP set_show_Modal={set_show_Modal} /> : null}
        {code === "OI" ? <CompOI set_show_Modal={set_show_Modal} /> : null}
      </div>
    </Modal>
  );
};

export default ModalComp;
