import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);
let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}
const ApplyLeave = (params) => {
  const data = params;
  return axios({
    method: "post",
    url: "/api/leave-apply",
    headers: headers,
    data: data,
  });
};
const getleavetype = (params) => {
  const data = params;
  return axios({
    method: "get",
    url: "/api/leave-type-list",
    headers: headers,
    data: data,
  });
};
const getLeaves = (params) => {
  const { url, data } = params;
  return axios({
    method: "get",
    url: url || "/api/leave-listing",
    headers: headers,
    data: data,
  });
};
const getleavebalance = (params) => {
  const data = params;
  return axios({
    method: "get",
    url: "/api/leave-balance",
    headers: headers,
    data: data,
  });
};
const getTotalLeaveApproved = (params) => {
  const { from_date, to_date, data } = params;
  return axios({
    method: "get",
    url: `/api/total-leave-approved?from_date=${from_date}&to_date=${to_date}`,
    headers: headers,
    data: data,
  });
};
const getManagerList = () => {
  return axios({
    method: "get",
    url: "/api/manager-list",
    headers: headers,
  });
};

const recallLeave = (params) => {
  const { data, leave_id } = params;
  return axios({
    method: "post",
    url: `/api/update-leave/${leave_id}`,
    headers: headers,
    data: data,
  });
};
const deleteLeave = (params) => {
  const { leave_id } = params;
  return axios({
    method: "post",
    url: `/api/leave-recall/${leave_id}`,
    headers: headers,
  });
};

export default {
  ApplyLeave,
  getLeaves,
  getleavetype,
  getleavebalance,
  getTotalLeaveApproved,
  getManagerList,
  recallLeave,
  deleteLeave,
};
