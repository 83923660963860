import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);

let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}
const addHoliday = (params) => {
  const { data } = params;
  return axios({
    method: "post",
    url: "/api/add-public-holiday",
    headers: headers,
    data: data,
  });
};
const getHolidayList = () => {
  return axios({
    method: "get",
    url: "/api/public-holiday-list",
    headers: headers,
  });
};
const updateHoliday = (params) => {
  const { id, data } = params;
  return axios({
    method: "post",
    url: `/api/update-public-holiday/${id}`,
    headers: headers,
    data: data,
  });
};
const deleteHoliday = (params) => {
  const { id, data } = params;
  return axios({
    method: "post",
    url: `/api/delete-public-holiday/${id}`,
    headers: headers,
    data: data,
  });
};

export default {
  addHoliday,
  getHolidayList,
  updateHoliday,
  deleteHoliday,
};
