import { Table, Button, Input, Space, DatePicker, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import api from "./api";
import { capitalizeFunc } from "../../../functions";

const TeamBreakManagementComp = () => {
  const [data_view, set_data_view] = useState("week");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [dataSource, set_dataSource] = useState([
    {
      key: `1`,
      name: `ali raza`,
      date: `2022-10-25`,
      type: "long break",
      duration: `00:30:00`,
      start_time: `12:10 pm`,
      end_time: `12:40 pm`,
      comment: ``,
    },
    {
      key: `2`,
      name: `shams ali`,
      date: `2022-11-20`,
      type: "short break",
      duration: `00:10:00`,
      start_time: `1:10 pm`,
      end_time: `1:20 pm`,
      comment: ``,
    },
    {
      key: `3`,
      name: `mateen ali`,
      date: `2022-12-20`,
      type: "other",
      duration: `00:10:00`,
      start_time: `10:00 am`,
      end_time: `11:20 am`,
      comment: `traffic`,
    },
    {
      key: `4`,
      name: `ali raza`,
      date: `2022-12-20`,
      type: "short break",
      duration: `00:10:00`,
      start_time: `2:10 pm`,
      end_time: `2:20 pm`,
      comment: ``,
    },
    {
      key: `5`,
      name: `shams ali`,
      date: `2022-11-20`,
      type: "short break",
      duration: `00:10:00`,
      start_time: `4:10 pm`,
      end_time: `4:20 pm`,
      comment: ``,
    },
    {
      key: `6`,
      name: `mateen ali`,
      date: `2022-12-25`,
      type: "other",
      duration: `01:11:00`,
      start_time: `11:30 am`,
      end_time: `12:41 am`,
      comment: `bike disorder`,
    },
  ]);
  const searchInput = useRef(null);
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });
  const [teamAttendenceList, set_teamAttendenceList] = useState([]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, close, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
    close();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {dataIndex === "name" && (
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            allowClear
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "flex",
            }}
          />
        )}
        {dataIndex === "date" && (
          <div className="tw-w-full tw-mb-2">
            <DatePicker
              className="tw-w-full"
              onChange={(_, dateString) =>
                setSelectedKeys(dateString ? [dateString] : [])
              }
            />
          </div>
        )}
        {dataIndex === "type" && (
          <div className="tw-w-full tw-mb-2">
            <Select
              showSearch
              placeholder={`Search ${dataIndex}`}
              optionFilterProp="children"
              className="tw-w-full"
              onChange={(value) => setSelectedKeys(value ? [value] : [])}
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: "short break",
                  label: "Short Break",
                },
                {
                  value: "long break",
                  label: "Long Break",
                },
                {
                  value: "other",
                  label: "Other",
                },
              ]}
            />
          </div>
        )}
        {dataIndex === "duration" && (
          <div className="tw-w-full tw-mb-2">
            <Select
              showSearch
              placeholder={`Search ${dataIndex}`}
              optionFilterProp="children"
              className="tw-w-full"
              onChange={(value) => setSelectedKeys(value ? [value] : [])}
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: "0-15",
                  label: "< 0-15min",
                },
                {
                  value: "15-30",
                  label: "< 15-30 min",
                },
                {
                  value: `30-1440`,
                  label: "> 30+ min",
                },
              ]}
            />
          </div>
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, close, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (dataIndex === `duration`) {
        const [start_val, end_val] = value.split("-");
        const recordTime = moment.duration(record[dataIndex]).asMilliseconds();
        const valueTime_start = moment
          .duration(start_val, "minutes")
          .asMilliseconds();
        const valueTime_end = moment
          .duration(end_val, "minutes")
          .asMilliseconds();

        return (
          recordTime > valueTime_start &&
          recordTime <= valueTime_end &&
          record[dataIndex]
        );
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Name",
      width: 100,
      dataIndex: "name",
      key: "name",
      fixed: "left",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Date",
      width: 100,
      dataIndex: "date",
      key: "date",
      ...getColumnSearchProps("date"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 100,
      ...getColumnSearchProps("type"),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      width: 100,
      ...getColumnSearchProps("duration"),
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      width: 100,
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
      width: 100,
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      width: 100,
    },
  ];

  const getTeamBreakManagementList = async (data_view) => {
    try {
      const res = await api.getTeamBreakManagementList({ data_view });
      if (res.data.resCode === 200) {
        set_teamAttendenceList(res?.data?.data?.data || []);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  useEffect(() => {
    getTeamBreakManagementList(data_view);
  }, [data_view]);

  const data = teamAttendenceList.map((each) => {
    return {
      key: each.id,
      name: capitalizeFunc(
        `${each.first_name || "-"} ${each.last_name || "-"}`
      ),
      date: moment(each.break_date).format("DD-MM-YYYY") || "-",
      type: capitalizeFunc(each.break_type || "-"),
      duration: each.break_duration || "-",
      start_time: (each.break_start_time || "").split(" ")[1] || "-",
      end_time: (each.break_end_time || "").split(" ")[1] || "-",
      comment: `-`,
    };
  });

  return (
    <div className="relative">
      <Table
        columns={columns}
        dataSource={data}
        scroll={{
          x: 1500,
          y: 300,
        }}
        pagination={{
          defaultPageSize: 6,
        }}
      />
      <div className="tw-w-[7rem] tw-absolute -tw-top-[3.5rem] tw-left-[2.2rem]">
        <Select
          className="tw-w-full"
          onChange={(value) => set_data_view(value)}
          defaultValue="week"
          options={[
            {
              value: "day",
              label: "Daily",
            },
            {
              value: "week",
              label: "Weekly",
            },
            {
              value: "month",
              label: "Monthly",
            },
            {
              value: "year",
              label: "Yearly",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default TeamBreakManagementComp;
