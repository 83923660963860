import React, { useState } from "react";

const WithForms = (WrapperComponent) => {
  const WithForms = (props) => {
    const [form, setForm] = useState({});
    const handleForm = (e, reg = null, type = "not-required") => {
      let name = e.target.name;
      let value = e.target.checked ? e.target.checked : e.target.value;
      let loc_form = { ...form };
      if (type === "required") {
        loc_form[name] = {
          value: value,
          error: (() => {
            if (reg === null) {
              return value && value !== "" ? false : true;
            } else {
              return value && value !== "" && reg.test(value) ? false : true;
            }
          })(),
        };
      } else {
        loc_form[e.target.name] = {
          value: value,
          error: (() => {
            if (reg === null) {
              return value && value !== "" ? false : true;
            } else {
              return value && value !== "" && reg.test(value) ? false : true;
            }
          })(),
        };
      }

      setForm(loc_form);
    };
    return (
      <WrapperComponent
        form={form}
        handleForm={handleForm}
        setForm={setForm}
        {...props}
      />
    );
  };
  return WithForms;
};

export default WithForms;
