import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import api from "./api";
import { Avatar, Button, message, Steps, Tabs } from "antd";
import PersonalInfoForm from "./PersonalInfoForm";
import ProfessionalInfoForm from "./ProfessionalInfoForm";
import SalaryInfoForm from "./SalaryInfoForm";
import WithForms from "../../features/WithForms/WithForms";
import SalaryInfoForm2 from "./SalaryInfoForm2";
import { useHistory } from "react-router-dom";
import ProfileFormComp from "./ProfileFormComp";
import { capitalizeFunc, randomItem } from "../../functions";
import AvatarComp from "../general/AvatarComp";
import moment from "moment";
import InfoRowComp from "../general/InfoRowComp";
import ProfileComp from "./ProfileComp";

const Profile = (props) => {
  const { fixNavbar } = props;
  const [myProfile, set_myProfile] = useState({});
  const [isSubmit, set_isSubmit] = useState(false);
  // const [sameAsResidencial, setSameAsResidencial] = useState(0);
  // const [profile, setProfile] = useState(null);
  // const [formData, set_formData] = useState();
  // const [selectedDept, set_selectedDept] = useState([]);
  // const [permanentAddress, set_permanentAddress] = useState({
  //   permanent_address: "",
  //   permanent_city: "",
  //   permanent_state: "",
  //   permanent_country: "",
  //   permanent_pincode: "",
  // });
  // const [updateForm, set_updateForm] = useState(null);
  const [selectedModule, set_selectedModule] = useState("profile");
  const [fileList, setFileList] = useState([]);
  const [updateAttachmentList, set_updateAttachmentList] = useState([]);
  const [attachmentList, set_attachmentList] = useState([]);

  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });
  const getAttachURL = (res) => {
    set_attachmentList(res);
  };
  // const onChangeSwitch = (checked) => {
  //   if (checked) {
  //     setSameAsResidencial(1);
  //   } else {
  //     setSameAsResidencial(0);
  //   }
  // };

  const onUploadChange = ({ fileList }) => {
    const newFileList = fileList.filter((each) => each.url);
    setFileList(fileList);
    set_updateAttachmentList([...newFileList, ...attachmentList]);
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const res = await api.getProfile().then((data) => data.data);
      if (res.resCode === 200) {
        set_myProfile(res ? res.data[0] : {});
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  return (
    <>
      <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
        <div
          className="container-fluid"
          style={{
            fontSize: "0.8rem",
          }}
        >
          <div className="row clearfix">
            <div className="col-md-12">
              <div className="card">
                <div className="row clearfix p-3">
                  <div className="col-md-5">
                    <div className="row clearfix p-2 tw-font-semibold">
                      <div className="col-md-3">
                        <AvatarComp
                          info={myProfile}
                          size={100}
                          changeAvatar
                          getAttachURL={getAttachURL}
                        />
                      </div>
                      <div className="col-md-9">
                        <h5 className="tw-mb-0">
                          {capitalizeFunc(
                            `${myProfile?.first_name || ""} ${
                              myProfile?.last_name || ""
                            }`
                          )}
                        </h5>
                        <p className="tw-mb-0 tw-opacity-75">
                          {capitalizeFunc(
                            myProfile?.employee_details?.department_name || ""
                          )}
                        </p>
                        <p className="tw-my-2 tw-opacity-75">
                          {capitalizeFunc(
                            myProfile?.employee_details?.designation || ""
                          )}
                        </p>
                        <p className="tw-mb-0 tw-font-semibold">{`Employee Id: ${
                          myProfile?.employee_details?.employee_code || ""
                        }`}</p>
                        <p className="tw-mb-0 tw-opacity-75">{`Date of Join: ${myProfile?.employee_details?.joining_date}`}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-7 pl-4"
                    style={{
                      borderLeft: "1px dashed gray",
                    }}
                  >
                    <InfoRowComp
                      name="Phone"
                      value={myProfile?.["phone_number"]}
                      imp
                    />
                    <InfoRowComp
                      name="Email"
                      value={myProfile?.["official_email"]}
                      imp
                    />
                    <InfoRowComp
                      name="Birthday"
                      value={
                        myProfile.dob &&
                        moment(myProfile?.["dob"]).format("Do MMMM")
                      }
                    />
                    <InfoRowComp
                      name="Address"
                      value={
                        myProfile?.permanent_address
                          ? capitalizeFunc(
                              `${myProfile?.permanent_address + "," || ""} ${
                                myProfile?.permanent_city + "," || ""
                              } ${myProfile?.permanent_state + "," || ""} ${
                                myProfile?.permanent_country + "-" || ""
                              }${myProfile?.permanent_pincode || ""}`
                            )
                          : ""
                      }
                    />
                    <InfoRowComp
                      name="Gender"
                      value={capitalizeFunc(myProfile?.["gender"] || "")}
                    />
                    <InfoRowComp
                      name="Reports to"
                      value={capitalizeFunc(
                        `${
                          myProfile?.employee_details?.manager_first_name || ""
                        } ${
                          myProfile?.employee_details?.manager_last_name || ""
                        }`
                      )}
                      imp
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="card px-3">
                <Tabs
                  defaultActiveKey={selectedModule}
                  tabBarStyle={null}
                  items={[
                    {
                      label: `Profile`,
                      key: "profile",
                      children: null,
                    },
                  ]}
                  onChange={(key) => set_selectedModule(key)}
                />
              </div>
            </div>
            <div className="col-md-12 ">
              {selectedModule === "profile" ? (
                <ProfileComp data={myProfile} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  fixNavbar: state.settings.isFixNavbar,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
