import React from "react";
import { useState } from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Checkbox } from "antd";
import OptionComp from "./OptionComp";
import RadioComp from "./RadioComp";

const FieldComp = ({
  handleFieldChange,
  headingName,
  documentName,
  each_heading,
  hd_idx,
  each_field,
  field_idx,
  removeField,
  addField,
  addOption,
  removeOption,
  insertField,
}) => {
  const [showOptions, set_showOptions] = useState("");

  const fieldSelectHandler = (e, id) => {
    handleFieldChange(e, each_field.id);
    const { value } = e.target;
    set_showOptions(value);
  };

  return (
    <div className="tw-bg-gray-50 tw-rounded -tw-mx-2 tw-px-2 tw-py-2 tw-mb-8 tw-relative">
      <div className="mb-3 row tw-flex tw-items-center">
        <label htmlFor="field_label" className="col-sm-3 col-form-label">
          {`S${hd_idx + 1}-Label${field_idx + 1}`}
        </label>
        <div className="col-sm-7">
          <input
            type="text"
            className="form-control tw-capitalize"
            id="field_label"
            name={"label"}
            value={(each_field?.label || "").toLowerCase()}
            onChange={(e) => handleFieldChange(e, each_field.id)}
            placeholder="Name, Date of birth etc."
          />
        </div>
        <div className="col-sm-2 tw-flex tw-justify-end">
          <Checkbox
            className="tw-text-[0.7rem]"
            name="mandatory"
            checked={each_field.mandatory && each_field.mandatory}
            onChange={(e) => {
              handleFieldChange(e, each_field.id);
            }}
          >
            Mandatory
          </Checkbox>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="field_type" className="col-sm-3 col-form-label">
          {`S${hd_idx + 1}-Type${field_idx + 1}`}
        </label>
        <div className="col-sm-7">
          <select
            onChange={(e) => fieldSelectHandler(e, each_field.id)}
            className="custom-select"
            name={"type"}
            value={each_field.type || ""}
          >
            <option value="">Select field type</option>
            <option value="text">Text</option>
            <option value="number">Number</option>
            <option value="select">Select</option>
            <option value="date">Date</option>
            <option value="date-range">Date Range</option>
          </select>
        </div>
        <div className="col-sm-2"></div>
      </div>
      {showOptions === "select" || each_field.type === "select" ? (
        <div className="mb-3 row">
          <label
            htmlFor="field_select_options"
            className="col-sm-4 col-form-label"
          >
            Options
          </label>
          <div className="col-sm-7">
            {Array.isArray(each_field?.options) &&
              each_field?.options.map((each_option, option_idx) => {
                return (
                  <OptionComp
                    key={`st-${hd_idx + 1}#${field_idx + 1}_option-${
                      option_idx + 1
                    }`}
                    headingName={headingName}
                    documentName={documentName}
                    each_option={each_option}
                    options={each_field?.options}
                    each_field={each_field}
                    option_idx={option_idx}
                    addOption={addOption}
                    removeOption={removeOption}
                    field_idx={field_idx}
                    handleFieldChange={handleFieldChange}
                    each_heading={each_heading}
                    hd_idx={hd_idx}
                  />
                );
              })}
          </div>
          <div className="mb-3 tw-px-4 tw-flex tw-justify-center tw-gap-4 tw-w-full tw-mt-4">
            <RadioComp
              headingName={headingName}
              handleFieldChange={handleFieldChange}
              hd_idx={hd_idx}
              field_idx={field_idx}
              each_field={each_field}
            />
          </div>
        </div>
      ) : null}
      {each_heading.fields.length - 1 === field_idx ? (
        <div className="tw-relative sm:tw-absolute tw-bottom-[1.1rem] tw-right-0 tw-flex sm:tw-flex-col tw-justify-end tw-items-center sm:tw-justify-start sm:tw-items-start tw-gap-2">
          <div
            className="tw-px-2 tw-flex tw-justify-center tw-items-center tw-cursor-pointer hover:tw-text-blue-500 tw-text-emerald-500"
            onClick={() => addField(each_heading)}
          >
            <PlusCircleOutlined />
            <span className="tw-ml-2">Add</span>
          </div>
        </div>
      ) : (
        <div className="tw-relative sm:tw-absolute tw-bottom-[1.1rem] tw-right-0 tw-flex sm:tw-flex-col tw-justify-end tw-items-center sm:tw-justify-start sm:tw-items-start tw-gap-2">
          <div
            className=" tw-px-2 tw-flex tw-justify-center tw-items-center tw-cursor-pointer hover:tw-text-blue-500 tw-text-emerald-500"
            onClick={() => insertField(field_idx + 1, each_heading)}
          >
            <PlusCircleOutlined />
            <span className="tw-ml-2">Insert</span>
          </div>
          <div
            className="tw-px-2 tw-flex tw-justify-center tw-items-center tw-cursor-pointer hover:tw-text-blue-500 tw-text-rose-600"
            onClick={() => removeField(each_field.id, each_heading)}
          >
            <MinusCircleOutlined />
            <span className="tw-ml-2">Remove</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FieldComp;
