import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import React, { useEffect } from "react";
import { capitalizeFunc, deleteFunc } from "../../functions";

import regex from "../../features/Services/validator";
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useState } from "react";

import { familyDetails } from "./profile_state";
import moment from "moment";
import api from "./api";

const FamilyInfo = ({ data }) => {
  const [show_Modal, set_show_Modal] = useState(false);
  const [isSubmit, set_isSubmit] = useState(false);
  const [tableData, set_tableData] = useState(
    data?.employee_family_details && data?.employee_family_details.length
      ? data?.employee_family_details
      : [familyDetails()]
  );
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  useEffect(() => {
    set_tableData(
      data?.employee_family_details && data?.employee_family_details.length
        ? data?.employee_family_details
        : [familyDetails()]
    );
  }, [data]);

  const [hasTyped, set_hasTyped] = useState({
    phone_no: false,
  });
  const handleChange = (e, id) => {
    const { name, value } = e.target;

    set_tableData((prev) => {
      return prev.map((each, idx) => {
        if (idx === id) {
          return {
            ...each,
            [name]: value,
          };
        }
        return each;
      });
    });
    set_hasTyped((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const handleSubmit = () => {
    set_show_Modal(false);
    set_isSubmit(true);
    saveFamilyInfo();
  };

  const handleAddMore = () => {
    set_tableData((prev) => {
      return [...prev, familyDetails()];
    });
  };

  const handleDelete = (id) => {
    deleteFunc(() => {
      deleteHandler(id);
    });
  };

  const deleteHandler = (id) => {
    set_tableData((prev) => {
      return prev.filter((each, idx) => idx !== id);
    });
  };

  const saveFamilyInfo = async () => {
    if (!data?.id) {
      return;
    }

    try {
      const res = await api.saveFamilyInfo({
        data: { employee_family_details: JSON.stringify(tableData) },
        employee_id: data?.id,
      });
      if (res.data.resCode === 200) {
        message.success(res.data.resMessage);
        setRes({
          message: "Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        message.error(res.data.resMessage);
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };
  const isInputError = (str, key, regex = null) => {
    let str1 = String(str);
    if (regex !== null) {
      return hasTyped[key] && !regex.test(str);
    }
    return (
      (hasTyped[key] && !str1.trim().length > 0) ||
      (!str1.trim().length && isSubmit)
    );
  };

  return (
    <>
      <div className="card p-3 tw-relative tw-h-[94%]">
        <div className="row tw-mb-2">
          <div className="col-11 tw-font-bold tw-text-[1rem] ">
            Family Information
          </div>
          <div className="col-1 ">
            <Button
              shape="circle"
              size="small"
              className="tw-flex tw-justify-center tw-items-center"
              onClick={() => set_show_Modal(true)}
            >
              <EditOutlined />
            </Button>
          </div>
        </div>
        {Object.values(tableData[0]).filter(Boolean).length > 2 ? (
          <Row
            className="tw-p-[5px] tw-font-semibold"
            style={{
              borderTop: "1px solid rgba(0,0,0,0.2)",
              borderBottom: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            <Col span={6}>Name</Col>
            <Col span={6}>Date of Birth</Col>
            <Col span={6}>Relationship</Col>
            <Col span={6}>Phone</Col>
          </Row>
        ) : null}

        {tableData.map((each, idx) => {
          return (
            <React.Fragment key={idx}>
              {Object.values(each).filter(Boolean).length > 2 ? (
                <Row className="tw-p-[5px]">
                  <Col span={6}>{each.name}</Col>
                  <Col span={6}>
                    {each.dob && moment(each.dob).format("DD-MM-YYYY")}
                  </Col>
                  <Col span={6}>{each.relation}</Col>
                  <Col span={6}>{each.phone_no}</Col>
                </Row>
              ) : (
                <div className="tw-flex tw-justify-center tw-items-center">
                  <Button
                    type="text"
                    size="small"
                    onClick={() => set_show_Modal(true)}
                    className="tw-flex tw-justify-center tw-items-center"
                  >
                    <PlusCircleOutlined />
                    <span className="tw-mt-1">Please add details</span>
                  </Button>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <Modal
        title={
          <div className="tw-flex tw-justify-center tw-items-center tw-font-bold tw-text-[1.1rem]">
            Family Information
          </div>
        }
        open={show_Modal}
        onCancel={() => {
          set_show_Modal(false);
        }}
        footer={
          <div className="tw-flex tw-justify-center tw-items-center">
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button
              onClick={() => {
                set_show_Modal(false);
              }}
            >
              Close
            </Button>
          </div>
        }
        width={600}
        maskClosable={false}
      >
        <div className="tw-w-full tw-max-h-[500px] tw-overflow-y-scroll">
          {tableData.map((each, idx) => {
            return (
              <div className="card border p-2 px-4" key={idx}>
                <div className="row clearfix tw-mb-4">
                  <div className="col-11 tw-font-bold ">
                    <p className="tw-mb-0">Family Member</p>
                  </div>
                  <div className="col-1">
                    <Button
                      shape="circle"
                      size="small"
                      className="tw-flex tw-justify-center tw-items-center"
                      onClick={() => handleDelete(idx)}
                    >
                      <DeleteOutlined className="tw-text-rose-600" />
                    </Button>
                  </div>
                </div>
                <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
                  {/* Name */}
                  <div className="col-md-6">
                    <p className="tw-mb-0">Name</p>
                    <Input
                      placeholder="Name"
                      name="name"
                      value={capitalizeFunc(each.name || "")}
                      onChange={(e) => {
                        handleChange(e, idx);
                      }}
                    />
                  </div>
                  {/* Date of Birth */}
                  <div className="col-md-6">
                    <p className="tw-mb-0">Date of Birth</p>
                    <DatePicker
                      allowClear={false}
                      format={"DD-MM-YYYY"}
                      style={{
                        width: "100%",
                      }}
                      defaultValue={each.dob && moment(each.dob)}
                      onChange={(date, dateString) => {
                        handleChange(
                          {
                            target: {
                              name: "dob",
                              value: date.format("YYYY-MM-DD"),
                            },
                          },
                          idx
                        );
                      }}
                      placeholder="Select DOB"
                      disabledDate={(currentDate) => {
                        const inputDate = currentDate.startOf("day");
                        const todayDate = moment().startOf("day");
                        return inputDate.isAfter(todayDate);
                      }}
                    />
                  </div>
                </div>
                <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
                  {/* Relationship */}
                  <div className="col-md-6">
                    <p className="tw-mb-0">Relationship</p>
                    <Select
                      showSearch
                      style={{
                        width: "100%",
                      }}
                      value={each.relation}
                      onChange={(value) => {
                        handleChange(
                          {
                            target: {
                              name: "relation",
                              value,
                            },
                          },
                          idx
                        );
                      }}
                      options={[
                        { label: "Select", value: "" },
                        ...[
                          "Father",
                          "Mother",
                          "Son",
                          "Daughter",
                          "Husband",
                          "Wife",
                          "Brother",
                          "Sister",
                          "Grandfather",
                          "Grandmother",
                          "Grandson",
                          "Guardian",
                          "Uncle",
                          "Aunt",
                          "Nephew",
                          "Niece",
                          "Cousins",
                          "Other",
                        ].map((each) => {
                          return {
                            value: each,
                            label: each,
                          };
                        }),
                      ]}
                      filterOption={(input, option) => {
                        return (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      placeholder="Search"
                    />
                  </div>
                  {/* contact */}
                  <div className="col-md-6">
                    <p className="tw-mb-0">Phone</p>
                    <Input
                      type="number"
                      name="phone_no"
                      placeholder="Phone"
                      onChange={(e) => handleChange(e, idx)}
                      value={each.phone_no && Number(each.phone_no)}
                      status={
                        isInputError(
                          each.phone_no && Number(each.phone_no),
                          "phone_no",
                          regex.value.mobile
                        )
                          ? "error"
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="row tw-justify-end ">
                  <Button
                    type="text"
                    className="tw-flex tw-justify-center tw-items-center tw-text-green-600"
                    onClick={handleAddMore}
                  >
                    <PlusCircleOutlined />{" "}
                    <span className="tw-mt-1">Add more</span>
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

export default FamilyInfo;
