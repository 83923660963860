import React from "react";

const JsonbasedComp = ({ documentJson, handleDocChange }) => {
  return (
    <div className="row">
      <div className="col-md-4">
        <div className="form-group">
          <label className="form-label">Name</label>
          <input
            className="form-control"
            type="text"
            placeholder={"document name"}
            name="document_type"
            onChange={handleDocChange}
            value={documentJson.document_type}
          />
        </div>
      </div>
      <div className="col-md-8">
        <div className="form-group">
          {/* <label className="form-label">&nbsp;</label> */}
          <label className="form-label">Json</label>
          <textarea
            className="form-control"
            aria-label="json textarea"
            placeholder={"documenɴ json"}
            name="document_html"
            onChange={handleDocChange}
            rows="8"
            value={documentJson.document_html}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default JsonbasedComp;
