import { Table, Button, Input, Space, DatePicker, Select, Avatar } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import ChangeStatusComp from "./ChangeStatusComp";
import EmployeeIDNameComp from "./EmployeeIDNameComp";
import RandomAvatar from "./RandomAvatar";
import api from "./api";
import { capitalizeFunc } from "../../../functions";

const TeamListComp = () => {
  const [data_view, set_data_view] = useState("week");
  const [updateTeamList, set_updateTeamList] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [teamAttendenceList, set_teamAttendenceList] = useState([]);
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, close, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
    close();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {dataIndex === "date" ? (
          <div className="tw-w-full tw-mb-2">
            <DatePicker
              className="tw-w-full"
              onChange={(_, dateString) =>
                setSelectedKeys(dateString ? [dateString] : [])
              }
            />
          </div>
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            allowClear
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "flex",
            }}
          />
        )}

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, close, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (dataIndex === `duration`) {
        const [start_val, end_val] = value.split("-");
        const recordTime = moment.duration(record[dataIndex]).asMilliseconds();
        const valueTime_start = moment
          .duration(start_val, "minutes")
          .asMilliseconds();
        const valueTime_end = moment
          .duration(end_val, "minutes")
          .asMilliseconds();

        return (
          recordTime > valueTime_start &&
          recordTime <= valueTime_end &&
          record[dataIndex]
        );
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const data = teamAttendenceList.map((each, idx) => {
    return {
      key: each.id,
      date: moment(each?.attendance_date).format("DD-MM-YYYY"),
      // avatar: <RandomAvatar gender={"female"} />,
      // employee: (
      //   <EmployeeIDNameComp
      //     name={`${each.first_name || ""} ${each.last_name || ""}`}
      //     emp_id={`${each.employee_id}`}
      //   />
      // ),
      avatar:
        each.avatar && JSON.parse(each.avatar).length ? (
          <Avatar src={each.avatar} />
        ) : (
          <Avatar>
            {`${each?.first_name?.[0]}${
              each?.last_name?.[0] || ""
            }`.toUpperCase()}
          </Avatar>
        ),
      employee: `${capitalizeFunc(each.first_name)} ${capitalizeFunc(
        each.last_name
      )} \n ${each.official_email || "".toLowerCase()}`,
      department: capitalizeFunc(each.department || `-`),
      checkin: each.checkin || `-`,
      checkout: each.checkout || `-`,
      duration: each.duration || `-`,
      reporting_manager: capitalizeFunc(each.reporting_manager || `-`),
      status: capitalizeFunc(
        each.override_availability || each.availability || "-"
      ),
      work_mode: capitalizeFunc(`${each.shift_mode}`),
      // action: (
      //   <ChangeStatusComp
      //     attendance_id={each.id}
      //     status={each.override_availability || each.availability}
      //     updateTeamList={updateTeamList}
      //     set_updateTeamList={set_updateTeamList}
      //   />
      // ),
    };
  });

  const columns = [
    {
      title: "",
      width: 25,
      dataIndex: "avatar",
      key: "avatar",
      fixed: "left",
    },
    {
      title: "Employee",
      width: 60,
      dataIndex: "employee",
      key: "employee",
      fixed: "left",
      ...getColumnSearchProps("employee"),
    },
    {
      title: "Date",
      width: 50,
      dataIndex: "date",
      key: "date",
      fixed: "left",
      ...getColumnSearchProps("date"),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: 60,
      ...getColumnSearchProps("department"),
    },
    {
      title: "Reporting Manager",
      dataIndex: "reporting_manager",
      key: "reporting_manager",
      width: 60,
      ...getColumnSearchProps("reporting_manager"),
    },
    {
      title: "Checkin",
      dataIndex: "checkin",
      key: "checkin",
      width: 50,
    },
    {
      title: "Checkout",
      dataIndex: "checkout",
      key: "checkout",
      width: 50,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      width: 50,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 50,
    },
    {
      title: "Work Mode",
      dataIndex: "work_mode",
      key: "work_mode",
      width: 50,
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   width: 60,
    // },
  ];

  const getTeamAttendanceList = async (data_view) => {
    try {
      const res = await api.getTeamAttendanceList({ data_view });
      if (res.data.resCode === 200) {
        set_teamAttendenceList(res?.data?.data?.data || []);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  useEffect(() => {
    getTeamAttendanceList(data_view);
  }, [data_view, updateTeamList]);

  return (
    <div className="relative">
      <Table
        columns={columns}
        dataSource={data}
        scroll={{
          x: 1500,
          y: 300,
        }}
        pagination={{
          defaultPageSize: 6,
        }}
      />
      <div className="tw-w-[7rem] tw-absolute -tw-top-[3.5rem] tw-left-[2.2rem]">
        <Select
          className="tw-w-full"
          onChange={(value) => set_data_view(value)}
          defaultValue="week"
          options={[
            {
              value: "day",
              label: "Daily",
            },
            {
              value: "week",
              label: "Weekly",
            },
            {
              value: "month",
              label: "Monthly",
            },
            {
              value: "year",
              label: "Yearly",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default TeamListComp;
