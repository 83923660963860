import { Table, Button, Upload, message } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import HRAForm from "./HRAForm";
import api from "./api";
import { DatePicker, Input } from "antd";
import {
  UploadOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  FileImageFilled,
} from "@ant-design/icons";
import moment from "moment";

const initialState_Owner = {
  from_date: "",
  to_date: "",
  owner_name: "",
  address: "",
  pan_card: "",
};

const HRAcomp = ({ set_show_Modal }) => {
  const [tableData, set_tableData] = useState([]);
  const [isModuleUpdate, set_isModuleUpdate] = useState(0);
  const [isLoading, set_isLoading] = useState(true);
  const [ownerInfo, set_ownerInfo] = useState({ owner_name: "" });
  const [ownerInfoRes, set_ownerInfoRes] = useState([
    {
      from_date: "",
      to_date: "",
      owner_name: "",
      address: "",
      pan_card: "",
    },
  ]);
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  const onChangeOwner = useCallback((event, id) => {
    const { name, value } = event.target;
    set_ownerInfoRes((prev) => {
      const updated = prev.map((each, idx) => {
        if (idx === id) {
          return {
            ...each,
            [name]: value,
          };
        }
        return { ...each };
      });
      return updated;
    });
  }, []);

  const addOwnerHandler = () => {
    set_ownerInfoRes((prev) => {
      return [...prev, initialState_Owner];
    });
  };
  const removeOwnerHandler = (idx) => {
    set_ownerInfoRes((prev) => {
      return prev.filter((each, id) => id !== idx);
    });
  };

  const getITModule = async () => {
    try {
      const res = await api.getITModule({ module: "HRA" });
      console.log(res);
      if (res.data.resCode === 200) {
        set_tableData(res?.data?.data || []);
        set_isModuleUpdate(res?.data?.data_found || 0);
        set_ownerInfoRes(
          res?.data?.hra_address.length > 0
            ? res?.data?.hra_address
            : [initialState_Owner]
        );
        set_isLoading(false);
        setRes({
          message: res.data.resMessage,
          type: "success",
          title: "Success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const handleChange = useCallback((event, id, each_obj) => {
    const { name, value } = event.target;
    set_tableData((prev) => {
      const updated = prev.map((each, idx) => {
        if (idx === id) {
          return {
            ...each,
            [name]: value,
          };
        }
        return { ...each };
      });
      return updated;
    });
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        const res = await api.saveITModule({
          module: "HRA",
          data: {
            data: JSON.stringify(tableData),
            address: JSON.stringify(ownerInfoRes),
          },
        });

        if (res.data.resCode === 200) {
          message.success(res.data?.resMessage);
          setRes({
            message: res.data.resMessage,
            type: "success",
            title: "Success",
          });
        } else {
          message.error(res.data?.resMessage);
          setRes({
            message: res.data.message,
            type: "danger",
            title: "Error",
          });
        }
      } catch (err) {
        message.error("Something went wrong!");
        setRes({
          message: "Something went wrong!",
          type: "danger",
          title: "Error",
        });
      }
      set_show_Modal(false);
    },
    [tableData, set_show_Modal, ownerInfoRes]
  );

  const resetForm = () => {
    const emptInputs = tableData.map((each) => ({
      ...each,
      planned_amount: 0,
      actual_amount: 0,
    }));
    set_tableData(emptInputs);
  };

  const modalfooter = (isModuleUpdate) => {
    return (
      <div className="tw-flex tw-gap-2 tw-justify-center">
        {isModuleUpdate ? (
          <Button onClick={handleSubmit}>Update</Button>
        ) : (
          <Button onClick={handleSubmit}>Save</Button>
        )}
        <Button onClick={resetForm}>Clear</Button>
      </div>
    );
  };

  const columns = [
    {
      title: "Months",
      width: 50,
      dataIndex: "months",
      key: "months",
      fixed: "left",
    },
    // {
    //   title: "Year Limit",
    //   dataIndex: "year_limit",
    //   key: "year_limit",
    //   width: 40,
    // },
    {
      title: "Planned",
      dataIndex: "planned",
      key: "planned",
      width: 40,
    },
    {
      title: "Actual",
      dataIndex: "actual",
      key: "actual",
      width: 40,
    },
    {
      title: "Approved",
      dataIndex: "approved",
      key: "approved",
      width: 40,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 40,
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      width: 40,
    },
  ];
  const data = tableData.map((each, i) => {
    return {
      key: i,
      months: each.particular,
      // year_limit: `₹${each.year_limit}`,
      planned: (
        <input
          name={"planned_amount"}
          onChange={(e) => handleChange(e, i, each)}
          type="text"
          className="form-control"
          placeholder="Enter Amount"
          value={each.planned_amount}
        />
      ),
      actual: (
        <input
          name={"actual_amount"}
          onChange={(e) => handleChange(e, i, each)}
          type="text"
          className="form-control"
          placeholder="Enter Amount"
          value={each.actual_amount}
        />
      ),
      approved: each.approved_amount,
      status: "-",
      remark: (
        <Upload>
          <Button type="link" icon={<PaperClipOutlined />} />
        </Upload>
      ),
    };
  });

  const ownerData = ownerInfoRes.map((each) => ({ ...each }));

  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  useEffect(() => {
    getITModule();
  }, []);

  return (
    <div className="tw-text-[0.8rem]">
      <div>
        <p className="tw-text-[1rem] tw-font-bold">Owner's Information</p>
        {ownerData.map((each, idx) => {
          return (
            <div
              key={idx}
              className="tw-flex tw-gap-2 tw-justify-center tw-items-center tw-mb-3 [&_div_p]:tw-mb-1"
            >
              <div className="">
                <p>From</p>
                <DatePicker
                  value={each.from_date && moment(each.from_date, "YYYY-MM-DD")}
                  // format={"DD-MM-YYYY"}
                  onChange={(date, dateString) => {
                    onChangeOwner(
                      {
                        target: {
                          name: "from_date",
                          value: dateString,
                        },
                      },
                      idx
                    );
                  }}
                />
              </div>
              <div className="">
                <p>To</p>
                <DatePicker
                  value={each.to_date && moment(each.to_date, "YYYY-MM-DD")}
                  // format={"DD-MM-YYYY"}
                  onChange={(date, dateString) => {
                    onChangeOwner(
                      {
                        target: {
                          name: "to_date",
                          value: dateString,
                        },
                      },
                      idx
                    );
                  }}
                />
              </div>
              <div className="">
                <p>Name</p>
                <Input
                  name={"owner_name"}
                  onChange={(e) => onChangeOwner(e, idx)}
                  placeholder="Enter Name"
                  value={each.owner_name}
                />
              </div>
              <div className="">
                <p>Address</p>
                <Input
                  name={"address"}
                  onChange={(e) => onChangeOwner(e, idx)}
                  placeholder="Enter Address"
                  value={each.address}
                />
              </div>
              <div className="">
                <p>PAN</p>
                <Input
                  name={"pan_card"}
                  onChange={(e) => onChangeOwner(e, idx)}
                  placeholder="Enter PAN"
                  value={each.pan_card}
                />
              </div>

              <div className="tw-max-w-[4rem]">
                <p></p>
                <Upload {...props}>
                  <Button type="text" icon={<FileImageFilled />} />
                </Upload>
              </div>
              <div className="">
                {ownerData.length - 1 === idx ? (
                  <Button
                    type="text"
                    icon={<PlusCircleOutlined />}
                    onClick={addOwnerHandler}
                  />
                ) : (
                  <Button
                    type="text"
                    icon={<MinusCircleOutlined />}
                    onClick={() => removeOwnerHandler(idx)}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>

      <p className="tw-text-[1rem] tw-font-bold">Rent Declaration</p>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        scroll={{
          x: 1100,
          y: 305,
        }}
        pagination={false}
      />

      <div className="tw-p-4">{modalfooter(isModuleUpdate)}</div>
    </div>
  );
};

export default HRAcomp;
