import React, { useState, useRef, useEffec } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../features/auth/auth";
import regex from "../../features/Services/validator";
import WithForms from "../../features/WithForms/WithForms";
import api from "./api";

function ForgotPassword(props) {
  const { form, handleForm } = props;
  const [isSubmit, set_isSubmit] = useState(false);
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorCount = 0;
    for (const key in form) {
      if (form[key].error) {
        ++errorCount;
      }
    }
    set_isSubmit(true);
    if (errorCount > 0 || Object.keys(form).length < 2) {
      return;
    } else {
      try {
        const authres = await api.ForgetPasswordLink({
          username: form.username.value,
        });
        if (authres.data.resCode === 200) {
        } else {
          setRes({
            message: authres.data.message,
            type: "danger",
            title: "Error",
          });
        }
      } catch (err) {
        setRes({
          message: "Something went wron!",
          type: "danger",
          title: "Error",
        });
      }
    }
  };
  return (
    <div className="auth">
      <div className="auth_left">
        <div className="card">
          <div className="text-center mb-5">
            <Link className="header-brand" to="/">
              <i className="fe fe-command brand-logo" />
            </Link>
          </div>
          {res.message === "" && (
            <form className="card-body" onSubmit={handleSubmit}>
              <div className="card-title">Forgot password</div>
              <div className="form-content_text text-left text-muted">
                <p>We will send you an email to reset your password.</p>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="reset_email">
                  {" "}
                  Email address{" "}
                </label>
                <input
                  type="text"
                  id="username"
                  className={`form-control ${
                    (form.username && form.username.error && isSubmit) ||
                    (!form.username && isSubmit)
                      ? "border-danger"
                      : ""
                  }`}
                  placeholder="Enter email address"
                  defaultValue=""
                  name="username"
                  onChange={(e) => handleForm(e, regex.value.email, "required")}
                />
                {form.username && form.username.error && isSubmit && (
                  <small className="text-danger">
                    Please enter correct email id
                  </small>
                )}
                {!form.username && isSubmit && (
                  <small className="text-danger">Email id is required</small>
                )}
              </div>
              <div className="form-footer">
                <button type="submit" className="btn btn-primary btn-block">
                  {" "}
                  Send me new password{" "}
                </button>
              </div>
            </form>
          )}
          <div className="text-center text-muted">
            {" "}
            Forget it, Send me Back to the{" "}
            <Link to="/login" className="btn btn-secondary">
              Sign in
            </Link>{" "}
            screen.{" "}
          </div>
        </div>
      </div>
      {res.message !== "" && (
        <div className={`alert alert-${res.type}`}>
          <a
            href="#!"
            className="float-right fa fa-times"
            aria-hidden="true"
            onClick={() => {
              setRes({ message: "", type: "", title: "" });
            }}
          />
          <h4>{res.title}</h4>
          <p>{res.message}</p>
        </div>
      )}
      <div className="auth_right">
        <div
          className="carousel slide"
          data-ride="carousel"
          data-interval={3000}
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="assets/images/slider1.svg"
                className="img-fluid"
                alt="login page"
              />
              <div className="px-4 mt-4">
                <h4>Fully Responsive</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="assets/images/slider2.svg"
                className="img-fluid"
                alt="login page"
              />
              <div className="px-4 mt-4">
                <h4>Quality Code and Easy Customizability</h4>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="assets/images/slider3.svg"
                className="img-fluid"
                alt="login page"
              />
              <div className="px-4 mt-4">
                <h4>Cross Browser Compatibility</h4>
                <p>
                  Overview We're a group of women who want to learn JavaScript.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WithForms(ForgotPassword);
