import { Select, Table } from "antd";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { capitalizeFunc } from "../../../functions";
import api from "./api";
import RequestStatusComp from "./RequestStatusComp";

const TeamShortComingComp = () => {
  const [data_view, set_data_view] = useState("week");
  const [updateShortComing, set_updateShortComing] = useState(false);
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });
  const [teamAttendenceList, set_teamAttendenceList] = useState([]);
  const [dataSource, set_dataSource] = useState([]);

  const columns = [
    {
      title: "Name",
      width: 70,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Date",
      width: 50,
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Reason",
      width: 50,
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Duration",
      children: [
        {
          title: "Time",
          dataIndex: "time",
          key: "time",
          width: 50,
        },
        {
          title: "Requested Time",
          dataIndex: "requested_time",
          key: "requested_time",
          width: 50,
        },
      ],
    },
    {
      title: "Captured Time",
      dataIndex: "captured_time",
      key: "captured_time",
      width: 60,
    },
    {
      title: "Admin Comment",
      dataIndex: "admin_comment",
      key: "admin_comment",
      width: 60,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 60,
    },
  ];
  const data1 = [];
  for (let i = 0; i < 10; i++) {
    data1.push({
      key: i,
      name: `name ${i}`,
      date: `date ${i}`,
      reason: `reason ${i}`,
      time: `to ${i}`,
      requested_time: `reason ${i}`,
      captured_time: `duration ${i}`,
      admin_comment: `admin comment${i}`,
      action: (
        <RequestStatusComp
          updateShortComing={updateShortComing}
          set_updateShortComing={set_updateShortComing}
        />
      ),
    });
  }

  const getTeamShortComingList = async (data_view) => {
    try {
      const res = await api.getTeamShortComingList({ data_view });
      if (res.data.resCode === 200) {
        set_teamAttendenceList(res?.data?.data?.data || []);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  useEffect(() => {
    getTeamShortComingList(data_view);
  }, [data_view, updateShortComing]);

  const data = teamAttendenceList.map((each, i) => {
    return {
      key: each.id,
      name: capitalizeFunc(
        `${each.first_name || "-"} ${each.last_name || "-"}`
      ),
      date: moment(each.date).format("DD-MM-YYYY") || "-",
      reason: each.remark
        ? capitalizeFunc(`${each.reason}-${each.remark}` || "-")
        : capitalizeFunc(`${each.reason}` || "-"),
      time: each.from_time,
      requested_time: each.to_time,
      captured_time: `-`,
      admin_comment: capitalizeFunc(each.manager_remark || ""),
      action: (
        <RequestStatusComp
          status={each.status}
          short_coming_id={each.id}
          updateShortComing={updateShortComing}
          set_updateShortComing={set_updateShortComing}
        />
      ),
    };
  });

  return (
    <div className="relative">
      <Table
        columns={columns}
        dataSource={data}
        scroll={{
          x: 1500,
          y: 300,
        }}
        pagination={{
          defaultPageSize: 6,
        }}
        bordered
      />
      <div className="tw-w-[7rem] tw-absolute -tw-top-[3.5rem] tw-left-[2.2rem]">
        <Select
          className="tw-w-full"
          onChange={(value) => set_data_view(value)}
          defaultValue="week"
          options={[
            {
              value: "day",
              label: "Daily",
            },
            {
              value: "week",
              label: "Weekly",
            },
            {
              value: "month",
              label: "Monthly",
            },
            {
              value: "year",
              label: "Yearly",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default TeamShortComingComp;
