import React from "react";

import { message, Upload } from "antd";
import { useState } from "react";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";

import api from "./api";
import { useEffect } from "react";
const UploadComp = ({
  fileLength = 5,
  onUploadChange,
  fileList,
  set_attachmentList,
  uploadType,
  disabled,
}) => {
  const beforeUploadHandler = (file) => {
    const isPNG = file.type === "image/png";
    const isJPEG = file.type === "image/jpeg";
    const isPDF = file.type === "application/pdf";
    const isWORD =
      file.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

    if (!isPNG && !isJPEG && !isPDF && !isWORD) {
      message.error(`${file.name} is not a png/jpeg/pdf/word file`);
    }

    return isPNG || isJPEG || isPDF || isWORD || Upload.LIST_IGNORE;
  };

  const onDownloadHandler = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const link = document.createElement("a");
    link.href = src;
    link.setAttribute("download", `${file.name}`);
    document.body.appendChild(link);
    link.click();
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
    }
    window.open(src);
  };

  const dummyRequest = async ({ file, onSuccess }) => {
    const data = new FormData();
    data.append("attachment", file);
    try {
      const res = await api.uploadAttachment({
        data: data,
        uploadType: uploadType || "img",
      });
      if (res.status === 200) {
        set_attachmentList((prev) => [
          ...prev,
          { name: file.name, status: "done", url: res.data },
        ]);
      }
    } catch (err) {
      console.log(err);
    }
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Upload
      disabled={disabled}
      customRequest={dummyRequest}
      listType="picture-card"
      fileList={fileList}
      onChange={onUploadChange}
      multiple
      onPreview={onPreview}
      onDownload={onDownloadHandler}
      beforeUpload={beforeUploadHandler}
      showUploadList={{
        showRemoveIcon: true,
        showDownloadIcon: true,
        showPreviewIcon: true,
        previewIcon: (file) => {
          const isPNG = file.type === "image/png";
          const isJPEG = file.type === "image/jpeg";
          if (isPNG || isJPEG || file.url) {
            return (
              <>
                <EyeOutlined />
              </>
            );
          }
          return <></>;
        },
        downloadIcon: (file) => {
          const isPDF = file.type === "application/pdf";
          const isWORD =
            file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          if (isPDF || isWORD) {
            return (
              <>
                <DownloadOutlined />
              </>
            );
          }
          return <></>;
        },
      }}
    >
      {fileList.length < fileLength && "+ Upload"}
    </Upload>
  );
};

export default UploadComp;
