import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);
let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}
const getLeaves = (params) => {
  const { data_filter, pageNumber, data } = params;
  console.log(params);
  return axios({
    method: "get",
    url: `/api/leave-listing?leave_data_filter=${data_filter}&page=${pageNumber}`,
    headers: headers,
    data: data,
  });
};
const getAttendance = (params) => {
  const data = params;
  return axios({
    method: "get",
    url: "/api/today-attendance",
    headers: headers,
    data: data,
  });
};
const getleavetype = (params) => {
  const data = params;

  return axios({
    method: "get",
    url: "/api/leave-type-list",
    headers: headers,
    data: data,
  });
};

const leaveAccept = (params) => {
  return axios({
    method: "post",
    url: `/api/leave-accept`,
    headers: headers,
    data: params,
  });
};
const leaveReject = (params) => {
  return axios({
    method: "post",
    url: `/api/leave-reject`,
    headers: headers,
    data: params,
  });
};

export default {
  getLeaves: getLeaves,
  getAttendance: getAttendance,
  getleavetype: getleavetype,
  leaveAccept: leaveAccept,
  leaveReject: leaveReject,
};
