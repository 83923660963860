import React, { useState, useEffect } from "react";
import { Select, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";

// HOC
import WithForms from "../../../features/WithForms/WithForms";
import regex from "../../../features/Services/validator";
import Fullcalender from "../../common/fullcalender";
import SingleCalendar from "react-single-calendar";
import { Modal, ApiModal } from "../../../features/modal/Modal";
import { useSelector } from "react-redux";
import axios from "axios";
import api from "./api";
import BreakComp from "./BreakComp";
import BreakManagementComp from "./BreakManagementComp";
import BreakTimerComp from "./BreakTimerComp";
import ShortComing from "./ShortComing";
import TeamListComp from "./TeamListComp";
import AttendanceHistoryComp from "./AttendanceHistoryComp";
import TeamAttendanceHistoryComp from "./TeamAttendanceHistoryComp";
import TeamBreakManagementComp from "./TeamBreakManagementComp";
import TeamShortComingComp from "./TeamShortComingComp";
import TestComp from "./TestComp";
import OldTeamListComp from "./OldTeamListComp";
import CheckinComp from "./CheckinComp";
import AttendanceView from "./AttendanceView";
const Attendance = (props) => {
  const { fixNavbar, form, handleForm } = props;

  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  let [modal, setModal] = useState("");

  const [checked_in_Id, set_checked_in_Id] = useState(0);
  const [checkInRes, set_checkInRes] = useState({});

  const [view, set_view] = useState("attendance history");

  const reduxUser = useSelector((state) =>
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : state.settings.user
  );
  //    console.log(reduxUser);
  // const leavModal= modal==='leave' ? <Modal><LeaveStatusModal modalData={{ status: temp }} dismiss={() => {setModal(''); setTemp('')}} submit={(data) => handleModalSubmit(data)} /></Modal> : null;
  const apiModal =
    modal === "api" ? (
      <Modal>
        <ApiModal
          modalData={{
            resType: "success",
            resMessage: `Request has been accepted successfully`,
            resTitle: "Accept Status",
          }}
          dismiss={() => {
            setModal("");
          }}
        />
      </Modal>
    ) : null;

  const getCheckinId = (id, data) => {
    set_checked_in_Id(id);
    set_checkInRes(data);
  };

  return (
    <React.Fragment>
      <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
        <div className="container-fluid">
          <div className="d-block justify-content-between align-items-center mb-3 text-center">
            <div className="row">
              {/* Pie chart of leaves */}
              <div className="col-lg-12 col-md-12 ">
                <div className="card">
                  <div className="text-left">
                    <div></div>
                  </div>
                  <div className="card-body">
                    <h3 className="card-title">Mark Attendance</h3>
                  </div>
                  <div className="card-body">
                    <CheckinComp getCheckinId={getCheckinId} />
                  </div>
                </div>
              </div>
              {/* Leave application form */}
            </div>
          </div>
          <div className="justify-content-between align-items-center mb-3">
            <div className="row">
              {/* Leave History Table */}
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <button
                      className={`btn ${
                        view === "attendance history"
                          ? "btn-secondary"
                          : "btn-primary"
                      } mx-3`}
                      onClick={() => {
                        set_view("attendance history");
                      }}
                    >
                      Attendance History
                    </button>

                    <button
                      className={`btn ${
                        view === "break management"
                          ? "btn-secondary"
                          : "btn-primary"
                      } mx-3`}
                      onClick={() => {
                        set_view("break management");
                      }}
                    >
                      Break Management
                    </button>
                    <button
                      className={`btn ${
                        view === "short coming"
                          ? "btn-secondary"
                          : "btn-primary"
                      } mx-3`}
                      onClick={() => {
                        set_view("short coming");
                      }}
                    >
                      Short Coming
                    </button>
                    <button
                      className={`btn ${
                        view === "attendance view"
                          ? "btn-secondary"
                          : "btn-primary"
                      } mx-3`}
                      onClick={() => {
                        set_view("attendance view");
                      }}
                    >
                      Attendance View
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {view === "attendance history" && (
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <AttendanceHistoryComp />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {view === "break management" && (
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <BreakManagementComp
                        attendance_id={checked_in_Id || checkInRes.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {view === "short coming" && (
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <ShortComing />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {view === "attendance view" && (
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="card justify-content-center">
                    <div className="card-body d-flex justify-content-center">
                      <AttendanceView />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {apiModal}
    </React.Fragment>
  );
};

export default WithForms(Attendance);
