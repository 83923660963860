import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);

let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}
const addBranch = (params) => {
  const data = params;

  return axios({
    method: "post",
    url: "/api/save-office",
    headers: headers,
    data: data,
  });
};
const getBranch = () => {
  return axios({
    method: "get",
    url: "/api/office-list",
    headers: headers,
  });
};
const getHeadquarters = (params) => {
  const data = params;

  return axios({
    method: "get",
    url: "/api/headquarter-list",
    headers: headers,
    data: data,
  });
};

const updateBranch = (params) => {
  const { id, data } = params;
  return axios({
    method: "post",
    url: "/api/update-office/" + id,
    headers: headers,
    data: data,
  });
};
const deleteBranch = (params) => {
  const { id, data } = params;
  return axios({
    method: "post",
    url: "/api/delete-office/" + id,
    headers: headers,
    data: data,
  });
};

export default {
  addBranch,
  getBranch,
  updateBranch,
  deleteBranch,
  getHeadquarters,
};
