import { Modal, Input } from "antd";
import React from "react";
import { useState } from "react";
import api from "./api";
import { useEffect } from "react";
const { TextArea } = Input;
const RequestStatusComp = ({
  status,
  short_coming_id,
  updateShortComing,
  set_updateShortComing,
}) => {
  const [selectValue, set_selectValue] = useState(status);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [textAreaValue, set_textAreaValue] = useState("");
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    updateTeamShortComingAction();
    setIsModalOpen(false);
    set_updateShortComing(!updateShortComing);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    set_selectValue(status);
  };

  const selectValueHandler = (e) => {
    const { value } = e.target;
    set_selectValue(value);
    value !== "pending" && showModal();
  };

  const handleTextAreaValue = (e) => {
    set_textAreaValue(e.target.value);
  };

  const updateTeamShortComingAction = async () => {
    try {
      const res = await api.updateTeamShortComingAction({
        short_coming_id,
        data: {
          manager_remark: textAreaValue,
          status: selectValue,
        },
      });
      if (res.data.resCode === 200) {
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  return (
    <>
      <select
        className="form-control"
        value={selectValue}
        onChange={selectValueHandler}
      >
        <option value="pending">Pending</option>
        <option value="accept">Accept</option>
        <option value="reject">Reject</option>
      </select>
      <Modal
        title="Admin Comment"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <TextArea
          rows={4}
          onChange={handleTextAreaValue}
          value={textAreaValue}
        />
      </Modal>
    </>
  );
};

export default RequestStatusComp;
