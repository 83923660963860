import { Button, Input, message, Modal, Select } from "antd";
import React, { useEffect } from "react";
import { capitalizeFunc, valueHandler } from "../../functions";
import DividerComp from "../general/DividerComp";
import InfoRowComp from "../general/InfoRowComp";
import UploadComp from "../HRMS/MyDocuments/UploadComp";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { countryList } from "../HRMS/AddDocument/countryList";
import regex from "../../features/Services/validator";
import api from "./api";

const PersonalInfo = ({ data }) => {
  const [show_Modal, set_show_Modal] = useState(false);
  const [tableData, set_tableData] = useState(data);
  const [isSubmit, set_isSubmit] = useState(false);
  const [hasTyped, set_hasTyped] = useState({
    alternate_contact_no: false,
    personal_email: false,
  });

  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  useEffect(() => {
    set_tableData(data);
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    set_tableData((prev) => ({
      ...prev,
      [name]: value,
    }));
    set_hasTyped((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const isInputError = (str, key, regex = null) => {
    let str1 = String(str);
    if (regex !== null) {
      return hasTyped[key] && !regex.test(str);
    }
    return (
      (hasTyped[key] && !str1.trim().length > 0) ||
      (!str1.trim().length && isSubmit)
    );
  };

  const handleSubmit = () => {
    updateEmployee();
    set_show_Modal(false);
  };

  const updateEmployee = async () => {
    set_isSubmit(true);
    const {
      id,
      marital_status,
      nationality,
      blood_group,
      religion,
      alternate_contact_no,
      personal_email,
      residence_address,
    } = tableData;

    if (!id) {
      return;
    }
    try {
      const res = await api.updateEmployeePersonal({
        data: {
          // employee_id: id,
          marital_status,
          nationality,
          blood_group,
          religion,
          alternate_contact_no,
          personal_email,
          residence_address,
        },
        employee_id: id,
      });
      if (res.data.resCode === 200) {
        message.success(res.data.resMessage);
        setRes({
          message: "Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        message.error(res.data.resMessage);
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };
  const {
    marital_status,
    nationality,
    blood_group,
    religion,
    residence_address,
    alternate_contact_no,
    personal_email,
  } = tableData;
  const emp_personal = {
    marital_status,
    nationality,
    blood_group,
    religion,
    residence_address,
    alternate_contact_no,
    personal_email,
  };
  return (
    <>
      <div className="card p-3 tw-relative tw-h-[94%]">
        <div className="row tw-mb-2">
          <div className="col-11 tw-font-bold tw-text-[1rem] ">
            Personal Information
          </div>
          <div className="col-1 ">
            <Button
              shape="circle"
              size="small"
              className="tw-flex tw-justify-center tw-items-center"
              onClick={() => set_show_Modal(true)}
            >
              <EditOutlined />
            </Button>
          </div>
        </div>
        {Object.values(emp_personal).filter(Boolean).length ? (
          <>
            <InfoRowComp
              name="Marital Status"
              value={capitalizeFunc(tableData?.["marital_status"] || "")}
            />
            <InfoRowComp
              name="Nationality"
              value={capitalizeFunc(tableData?.["nationality"] || "")}
            />
            <InfoRowComp
              name="Blood Group"
              value={capitalizeFunc(tableData?.["blood_group"] || "")}
            />
            <InfoRowComp
              name="Religion"
              value={capitalizeFunc(tableData?.["religion"] || "")}
            />
            <InfoRowComp
              name="Residence Address"
              value={capitalizeFunc(tableData?.["residence_address"] || "")}
            />
            <InfoRowComp
              name="Contact Number"
              value={capitalizeFunc(tableData?.["alternate_contact_no"] || "")}
            />
            <InfoRowComp
              name="E-mail"
              value={tableData?.["personal_email"] || ""}
            />
          </>
        ) : (
          <div className="tw-flex tw-justify-center tw-items-center">
            <Button
              type="text"
              size="small"
              onClick={() => set_show_Modal(true)}
              className="tw-flex tw-justify-center tw-items-center"
            >
              <PlusCircleOutlined />
              <span className="tw-mt-1">Please add details</span>
            </Button>
          </div>
        )}
      </div>
      <Modal
        title={
          <div className="tw-flex tw-justify-center tw-items-center tw-font-bold tw-text-[1.1rem]">
            Personal Information
          </div>
        }
        open={show_Modal}
        onCancel={() => {
          set_show_Modal(false);
        }}
        footer={
          <div className="tw-flex tw-justify-center tw-items-center">
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button
              onClick={() => {
                set_show_Modal(false);
              }}
            >
              Close
            </Button>
          </div>
        }
        // width={600}
        maskClosable={false}
      >
        <div className="row tw-mb-4 [&>div_p]:tw-font-semibold">
          <div className="col-md-6 ">
            <p className="tw-mb-0">Marital Status</p>
            <Select
              value={valueHandler(tableData?.["marital_status"])}
              style={{
                width: "100%",
              }}
              onChange={(value) => {
                handleChange({
                  target: {
                    name: "marital_status",
                    value,
                  },
                });
              }}
              options={[
                {
                  value: "",
                  label: "Select",
                },
                {
                  value: "Single",
                  label: "Single",
                },
                {
                  value: "Married",
                  label: "Married",
                },
              ]}
            />
          </div>
          <div className="col-md-6 ">
            <p className="tw-mb-0">Blood Group</p>
            <Select
              value={valueHandler(tableData?.["blood_group"])}
              style={{
                width: "100%",
              }}
              onChange={(value) => {
                handleChange({
                  target: {
                    name: "blood_group",
                    value,
                  },
                });
              }}
              options={[
                {
                  value: "",
                  label: "Select",
                },
                ...["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"].map(
                  (each) => {
                    return {
                      value: each,
                      label: each,
                    };
                  }
                ),
              ]}
            />
          </div>
        </div>
        <div className="row tw-mb-4 [&>div_p]:tw-font-semibold">
          <div className="col-md-6 ">
            <p className="tw-mb-0">Nationality</p>
            <Select
              value={
                tableData?.["nationality"] &&
                valueHandler(tableData?.["nationality"])
              }
              showSearch
              style={{
                width: "100%",
              }}
              onChange={(value) => {
                handleChange({
                  target: {
                    name: "nationality",
                    value,
                  },
                });
              }}
              filterOption={(input, option) => {
                return (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              options={countryList.map((each) => ({
                value: each.nationality,
                label: each.nationality,
              }))}
              placeholder="Search"
            />
          </div>
          <div className="col-md-6 ">
            <p className="tw-mb-0">Religion</p>
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              value={
                tableData?.["religion"] && valueHandler(tableData?.["religion"])
              }
              onChange={(value) => {
                handleChange({
                  target: {
                    name: "religion",
                    value,
                  },
                });
              }}
              options={[
                "Christianity",
                "Islam",
                "Secular/Nonreligious/Agnostic/Atheist",
                "Hinduism",
                "Buddhism",
                "Chinese traditional religion",
                "Ethnic religions",
                "African traditional religions",
                "Sikhism",
                "Spiritism",
                "Judaism",
                "Bahá?í",
                "Jainism",
                "Shinto",
                "Cao Dai",
                "Zoroastrianism",
                "Tenrikyo",
                "Animism",
                "Neo-Paganism",
                "Unitarian Universalism",
                "Rastafari",
              ].map((each) => {
                return {
                  value: each,
                  label: each,
                };
              })}
              filterOption={(input, option) => {
                return (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              placeholder="Search"
            />
          </div>
        </div>
        <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
          {/* Contact Number */}
          <div className="col-md-6">
            <p className="tw-mb-0">Contact Number</p>
            <Input
              type="number"
              placeholder="Contact Number"
              name="alternate_contact_no"
              value={valueHandler(tableData?.["alternate_contact_no"], {
                type: "num",
              })}
              onChange={(e) => {
                handleChange(e);
              }}
              status={
                isInputError(
                  tableData?.alternate_contact_no,
                  "alternate_contact_no",
                  regex.value.mobile
                )
                  ? "error"
                  : ""
              }
            />
          </div>
          {/* Email */}
          <div className="col-md-6">
            <p className="tw-mb-0">Email ID</p>
            <Input
              placeholder="Email ID"
              name="personal_email"
              value={valueHandler(tableData?.["personal_email"])}
              onChange={(e) => {
                handleChange(e);
              }}
              status={
                isInputError(
                  tableData?.personal_email,
                  "personal_email",
                  regex.value.email
                )
                  ? "error"
                  : ""
              }
            />
          </div>
        </div>
        <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
          {/* Address */}
          <div className="col-md-12">
            <p className="tw-mb-0">Resi. Address</p>
            <Input
              placeholder="Resi. Address"
              name="residence_address"
              value={valueHandler(
                capitalizeFunc(tableData?.["residence_address"] ?? "")
              )}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PersonalInfo;
