import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import WithForms from "../../../features/WithForms/WithForms";

import {
  Form,
  Table,
  Button,
  Input,
  Space,
  DatePicker,
  Modal,
  message,
} from "antd";
import {
  SearchOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { useRef } from "react";
import api from "./api";
import { capitalizeFunc, deleteFunc } from "../../../functions";
const { confirm } = Modal;

const Holidays = (props) => {
  const { fixNavbar, form, handleForm } = props;
  const [isError, set_isError] = useState(false);
  const [holidayData, set_holidayData] = useState({
    holiday: "",
    date: "",
  });
  const [update, set_update] = useState({
    isUpdate: false,
    updateId: "",
  });
  const [show_Modal, set_show_Modal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [teamAttendenceList, set_teamAttendenceList] = useState([]);
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, close, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
    close();
  };
  const showConfirm = (id) => {
    deleteFunc(() => {
      deleteHoliday(id);
    });
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {dataIndex === "date" ? (
          <div className="tw-w-full tw-mb-2">
            <DatePicker
              className="tw-w-full"
              onChange={(_, dateString) =>
                setSelectedKeys(dateString ? [dateString] : [])
              }
            />
          </div>
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            allowClear
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "flex",
            }}
          />
        )}

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, close, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Sr.No.",
      width: "5%",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Day",
      width: "20%",
      dataIndex: "day",
      key: "day",
    },
    {
      title: "Date",
      width: "20%",
      dataIndex: "date",
      key: "date",
      ...getColumnSearchProps("date"),
    },
    {
      title: "Holiday",
      dataIndex: "holiday",
      key: "holiday",
      width: "35%",
      ...getColumnSearchProps("holiday"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "20%",
    },
  ];

  const data = teamAttendenceList.map((each, idx) => {
    return {
      key: idx,
      serial: idx + 1,
      date: moment(each.holiday_date).format("DD-MM-YYYY"),
      day: capitalizeFunc(each.weekday),
      holiday: capitalizeFunc(each.holiday_name),
      action: (
        <div>
          <button
            type="button"
            className="btn btn-icon btn-sm"
            title="Edit"
            onClick={() => {
              set_holidayData({
                holiday: each.holiday_name,
                date: each.holiday_date,
              });
              updateHolidayHandler(each.id);
            }}
          >
            <i className="fa fa-edit" />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-sm js-sweetalert"
            title="Delete"
            data-type="confirm"
            onClick={() => showConfirm(each.id)}
          >
            <i className="fa fa-trash-o text-danger" />
          </button>
        </div>
      ),
    };
  });

  const getHolidayList = async () => {
    try {
      const res = await api.getHolidayList();
      if (res.data.resCode === 200) {
        set_teamAttendenceList(res?.data?.data?.data || []);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };
  const addHoliday = async () => {
    try {
      const res = await api.addHoliday({
        data: {
          holiday_name: holidayData.holiday,
          holiday_date: holidayData.date.format("YYYY-MM-DD"),
        },
      });
      if (res.data.resCode === 200) {
        message.success("Holiday added successfully");
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    getHolidayList();
  };
  const updateHoliday = async (id) => {
    try {
      const res = await api.updateHoliday({
        data: {
          holiday_name: holidayData.holiday,
          holiday_date: holidayData.date.format("YYYY-MM-DD"),
        },
        id,
      });
      if (res.data.resCode === 200) {
        message.success(res.data.resMessage || "Holiday updated successfully");
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        message.error(res.data.resMessage);
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    getHolidayList();
  };
  const deleteHoliday = async (id) => {
    try {
      const res = await api.deleteHoliday({
        id,
      });
      if (res.data.resCode === 200) {
        message.info(res.data.resMessage || "Holiday deleted");
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        message.error(res.data.resMessage);
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    set_update({ isUpdate: !update.isUpdate });
    getHolidayList();
  };

  const handleSubmit = (update) => {
    if (!holidayData.holiday) {
      set_isError(true);
      message.error("Please input holiday name");
      return;
    }
    if (update.isUpdate) {
      updateHoliday(update.updateId);
    } else {
      addHoliday();
    }
    set_isError(false);
    set_show_Modal(false);
  };

  const handleHolidayChange = (name, value) => {
    set_holidayData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addHolidayHandler = () => {
    set_holidayData({});
    set_update({
      isUpdate: false,
    });
    set_show_Modal(true);
  };
  const updateHolidayHandler = (id) => {
    set_update({
      isUpdate: true,
      updateId: id,
    });
    set_show_Modal(true);
  };

  useEffect(() => {
    getHolidayList();
  }, []);

  return (
    <>
      <div>
        <div className={`section-body ${fixNavbar ? "marginTop" : ""}`}>
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="col-md-12">
                <div className="alert alert-light tw-flex tw-justify-between">
                  <Link to={"/"} className="btn btn-primary mb-3">
                    <i className="fa fa-long-arrow-left" /> Back
                  </Link>
                  <button
                    className="btn btn-primary mb-3"
                    onClick={addHolidayHandler}
                  >
                    <i className="fe fe-plus mr-2" /> Add
                  </button>
                </div>

                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <Table
                          columns={columns}
                          dataSource={data}
                          pagination={{
                            defaultPageSize: 6,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <Modal
                  title={"Add Holiday"}
                  open={show_Modal}
                  onCancel={() => set_show_Modal(false)}
                  width={400}
                  footer={
                    <div className="tw-flex tw-gap-2 tw-justify-center tw-pb-4">
                      <Button
                        style={{
                          background: "#5a5278",
                          color: "white",
                        }}
                        onClick={() => {
                          handleSubmit(update);
                        }}
                      >
                        {update.isUpdate ? "Update" : "Add"}
                      </Button>
                      <Button onClick={() => set_show_Modal(false)}>
                        Cancel
                      </Button>
                    </div>
                  }
                >
                  <Form layout="vertical" className="tw-px-8">
                    <Form.Item label="Holiday Name">
                      <Input
                        placeholder="Holiday Name"
                        value={holidayData.holiday}
                        status={isError && "error"}
                        onChange={(e) =>
                          handleHolidayChange("holiday", e.target.value)
                        }
                      />
                    </Form.Item>

                    <Form.Item label="Holiday Date">
                      <DatePicker
                        format="DD-MM-YYYY"
                        className="tw-w-full"
                        allowClear={false}
                        value={
                          holidayData.date
                            ? moment(holidayData.date)
                            : undefined
                        }
                        onChange={(date, dateString) => {
                          handleHolidayChange("date", date);
                        }}
                        placeholder="Select Date"
                      />
                    </Form.Item>
                  </Form>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Holidays;
