import React from "react";
import {
  Form,
  Table,
  Button,
  Input,
  Space,
  DatePicker,
  Modal,
  message,
} from "antd";
import {
  SearchOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";

export const yearlyValue = (monthlyValue) => {
  return (monthlyValue * 12).toFixed(2);
};
export const toFixed = (val) => {
  return Number(val).toFixed(2);
};
export const lowerCase = (str) => {
  return str && str.toLowerCase();
};
export const concatenate_str = (str) => {
  return str && str.replace(/\s/g, "-");
};
export const pageNumberFromUrl = (url) => {
  const number = url ? url.split("=")[1] : 1;
  return number;
};

export function random_unique_no(num) {
  const randomChar =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz#@$";
  let randomString = "";

  function rand(length) {
    return Math.floor(Math.random() * length);
  }

  for (let i = 0; i < num; i++) {
    const randStr = randomChar[`${rand(randomChar.length)}`];
    randomString += randStr;
  }

  return randomString;
}

export const randomItem = (arr) => {
  const randNum = Math.floor(Math.random() * arr.length);
  return arr[randNum];
};

export const formatTime = (timer) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

  return `${getHours} : ${getMinutes} : ${getSeconds}`;
};

export const capitalizeFunc = (str) => {
  const strArray = String(str)
    .split(" ")
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase());
  return strArray.join(" ");
};

export const deleteFunc = (cb) => {
  const { confirm } = Modal;
  confirm({
    title: "Delete action?",
    icon: <ExclamationCircleOutlined />,
    content: "Once deleted cannot be recovered.",
    okText: "Delete",
    okType: "danger",
    onOk() {
      cb();
    },
  });
};
export const confirmFunc = (cb) => {
  const { confirm } = Modal;
  confirm({
    title: "Confirm action?",
    icon: <ExclamationCircleOutlined />,
    content: "Are you sure you want to do this?",
    okText: "Yes",
    onOk() {
      cb();
    },
  });
};

export const valueHandler = (val, options = {}) => {
  const { type = "str", capitalize = false } = options;
  if (type === "num") {
    return val ? Number(val) : "";
  }
  if (type === "str") {
    if (capitalize) {
      return val && capitalizeFunc(val);
    }
    return val ? String(val) : "";
  }
  if (type === "date") {
    return val && moment(val);
  }
  return val && val;
};

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
