import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useAuth } from "../../features/auth/auth";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import regex from "../../features/Services/validator";
import WithForms from "../../features/WithForms/WithForms";
import { setUserAction } from "../../actions/settingsAction";
import { useSelector, useDispatch, connect } from "react-redux";
import api from "./api";
import login_image from "../../assets/images/login_img.svg";
import login_image1 from "../../assets/images/login_img1.svg";

const Login = (props) => {
  const { form, handleForm } = props;
  const dispatch = useDispatch();
  const [showPass, set_showPass] = useState(false);
  // forms
  const [user, setUser] = useState(null);
  const [isSubmit, set_isSubmit] = useState(false);

  const [errData, set_errData] = useState({
    message: "",
    type: "",
  });
  // routes
  const history = useHistory();
  const auth = useAuth();
  const handleShowPass = () => {
    set_showPass(!showPass);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    let errorCount = 0;
    for (const key in form) {
      if (form[key].error) {
        ++errorCount;
      }
    }
    set_isSubmit(true);
    if (errorCount > 0 || Object.keys(form).length < 2) {
      return;
    } else {
      try {
        const authres = await api.Login({
          username: form.username.value,
          password: form.password.value,
        });
        if (authres.data.resCode === 200) {
          let userdata = authres.data.data;
          auth.login(userdata);
          userdata.type = userdata.role_type;
          setUser(userdata);
          dispatch(setUserAction(userdata));
          history.push("/dashboard");
        } else {
          set_errData({
            message: authres.data.message,
            type: "danger",
          });
        }
      } catch (err) {
        set_errData({
          message: "Something went wrong!",
          type: "danger",
        });
      }
    }
    window.location.reload();
  };
  return (
    <div className="auth">
      <div className="auth_left">
        <div className="card">
          <div className="text-center mb-2">
            <Link className="header-brand" to="/">
              <i className="fe fe-command brand-logo" />
            </Link>
          </div>
          <div className="card-body">
            {errData.message !== "" && (
              <div className={`alert alert-${errData.type}`}>
                <a
                  href="#!"
                  className="float-right fa fa-times"
                  aria-hidden="true"
                  onClick={() => {
                    set_errData({ message: "", type: "" });
                  }}
                />
                <p className="m-0 p-0">{errData.message}</p>
              </div>
            )}
            <div className="card-title">Login to your account</div>

            {/* <div className="form-group">
								<select className="custom-select">
									<option>HR Dashboard</option>
									<option>Project Dashboard</option>
									<option>Job Portal</option>
								</select>
							</div> */}
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <input
                  type="text"
                  id="username"
                  className={`form-control ${
                    (form.username && form.username.error && isSubmit) ||
                    (!form.username && isSubmit)
                      ? "border-danger"
                      : ""
                  }`}
                  placeholder="username"
                  defaultValue=""
                  name="username"
                  onChange={(e) => handleForm(e, regex.value.email, "required")}
                />
                {form.username && form.username.error && isSubmit && (
                  <small className="text-danger">
                    Please enter correct email id
                  </small>
                )}
                {!form.username && isSubmit && (
                  <small className="text-danger">Email id is required</small>
                )}
              </div>

              <div className="form-group tw-relative">
                <input
                  type={showPass ? "text" : "password"}
                  id="password"
                  className={`form-control ${
                    (form.password && form.password.error && isSubmit) ||
                    (!form.password && isSubmit)
                      ? "border-danger"
                      : ""
                  }`}
                  placeholder="Password"
                  defaultValue=""
                  name="password"
                  onChange={(e) => handleForm(e, null, "required")}
                />
                {/* {(form.password && form.password.error && isSubmit) && <small className="text-danger">Please enter correct email id</small>} */}
                {!form.password && isSubmit && (
                  <small className="text-danger">Please enter password</small>
                )}
                <div
                  className="tw-absolute tw-right-1 tw-top-[0.4rem] tw-flex tw-justify-center tw-items-center p-1"
                  onClick={handleShowPass}
                >
                  {showPass ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              </div>
              {/* <div className="form-group">
							<label className="custom-control custom-checkbox">
								<input type="checkbox" className="custom-control-input" />
								<span className="custom-control-label">Remember me</span>
							</label>
						</div> */}
              <Link className="float-right small mb-3" to="/forgotpassword">
                {" "}
                I forgot password{" "}
              </Link>
              <div className="form-footer">
                <button type="submit" className="btn btn-primary btn-block">
                  Click to login
                </button>
              </div>
            </form>
            {/* {resMsg !== '' &&
							<div className={`text-center alert alert-${SetresMsgType}`} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, width: '200px', height: '200px', margin: 'auto', zIndex: 11 }}>
								<p>{resMsg}</p><br></br>
								<button type="button" className={`btn btn-${SetresMsgType}`} onClick={(e) => SetresMsg(e.target.value)}>Okay</button>
							</div>
						} */}
          </div>
          <div className="text-center text-muted">
            Don't have account yet? <Link to="/signup">Sign Up</Link>
          </div>
        </div>
      </div>
      <div className="auth_right">
        <div
          className="carousel slide"
          data-ride="carousel"
          data-interval={3000}
        >
          <div className="carousel-inner [&div_img]:tw-w-[200px]">
            <div className="carousel-item active">
              <img
                // src="assets/images/slider1.svg"
                src={login_image}
                className="img-fluid"
                alt="login page"
              />
              <div className="px-4 mt-4">
                {/* <h4>Fully Responsive</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
              </div>
            </div>
            <div className="carousel-item">
              <img
                // src="assets/images/slider2.svg"
                src={login_image}
                className="img-fluid"
                alt="login page"
              />
              <div className="px-4 mt-4">
                {/* <h4>Quality Code and Easy Customizability</h4>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available.
                </p> */}
              </div>
            </div>
            <div className="carousel-item">
              <img
                // src="assets/images/slider3.svg"
                src={login_image}
                className="img-fluid"
                alt="login page"
              />
              <div className="px-4 mt-4">
                {/* <h4>Cross Browser Compatibility</h4>
                <p>
                  Overview We're a group of women who want to learn JavaScript.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithForms(Login);

//WithForms
