import React, { useState, useEffect } from "react";
// HOC
import WithForms from "../../../features/WithForms/WithForms";
import regex from "../../../features/Services/validator";
import SingleCalendar from "react-single-calendar";
import moment from "moment";

const SalaryForm = (props) => {
  const { form, handleForm, setForm } = props;
  const [isSubmit, set_isSubmit] = useState(false);
  const [dateString, set_dateString] = useState("");
  const [basicSalary, set_basicSalary] = useState("");
  const [formatedBasicSalary, set_formatedBasicSalary] = useState(
    form?.basic?.value || ""
  );
  const [contract, set_contract] = useState("");

  const totalDays = (start, end) => {
    const startDate = moment(start);
    const lastDate = moment(end);
    const duration = moment.duration(lastDate.diff(startDate));
    return {
      days: parseFloat(duration.asDays().toFixed(2)),
      months: parseFloat(duration.asMonths().toFixed(2)),
      years: parseFloat(duration.asYears().toFixed(2)),
    };
  };

  const gratuityDays = (years, gratuityRules) => {
    if (years < 1) {
      return 0;
    }
    let result = years * gratuityRules;
    return result;
  };

  const gratuityLimited = (years, per_day_wages, maximum_gratuity) => {
    const fixed_yearly21 = 21;
    const fixed_yearly30 = 30;
    if (years >= 5) {
      const secondfive = years - 5;
      const amt21 = per_day_wages * gratuityDays(5, fixed_yearly21);
      const amt30 = per_day_wages * gratuityDays(secondfive, fixed_yearly30);
      const above5 = amt21 + amt30;
      return above5 < maximum_gratuity ? above5 : maximum_gratuity;
    }
    const under5 = per_day_wages * gratuityDays(years, fixed_yearly21);
    return under5 < maximum_gratuity ? under5 : maximum_gratuity;
  };
  const gratuityUnlimited = (years, per_day_wages, maximum_gratuity) => {
    const fixed_yearly21 = 21;
    const fixed_yearly30 = 30;
    if (years > 5) {
      const secondfive = years - 5;
      const amt21 = per_day_wages * gratuityDays(5, fixed_yearly21);
      const amt30 = per_day_wages * gratuityDays(secondfive, fixed_yearly30);
      const above5 = amt21 + amt30;
      return above5 < maximum_gratuity ? above5 : maximum_gratuity;
    }
    if (years > 3 && years <= 5) {
      const under5 = per_day_wages * gratuityDays(years, fixed_yearly21) * 0.64;
      return under5 < maximum_gratuity ? under5 : maximum_gratuity;
    }
    const under3 = per_day_wages * gratuityDays(years, fixed_yearly21) * 0.34;
    return under3 < maximum_gratuity ? under3 : maximum_gratuity;
  };

  const handleSubmit = () => {
    const { contract, sepration, fwd, lwd, basic } = form;
    const basicValue = parseInt(basic.value.replace(/,/g, ""));
    let errorCount = 0;

    for (const key in form) {
      if (form[key].error) {
        ++errorCount;
      }
    }
    set_isSubmit(true);
    if (errorCount > 0 || Object.keys(form).length < 1) {
      return;
    }

    const basic_yearly = Number(basicValue);
    const maximum_gratuity = Number(Math.floor(basicValue * 2));

    const { days, months, years } = totalDays(fwd?.value, lwd?.value);
    const per_day_wages = basic_yearly / 365;

    if (contract.value === "limited") {
      const value = gratuityLimited(years, per_day_wages, maximum_gratuity);
      set_basicSalary(value.toFixed(2));
    }
    if (contract.value === "unlimited") {
      const value = gratuityUnlimited(years, per_day_wages, maximum_gratuity);
      set_basicSalary(value.toFixed(2));
    }

    set_isSubmit(true);
    setForm({});
    // User will be set after getting success result in api
    // navigate(redirectPath, { replace: true })
  };

  useEffect(() => {
    set_formatedBasicSalary(
      form?.basic?.value
        .replace(/\D/g, "")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }, [form?.basic?.value]);

  const [amt, dec] = basicSalary.split(".");

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Gratuity Calculator</h3>
        </div>
        <div className="card-body text-center">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group text-left">
                <label>Contract</label>
                <select
                  className={`form-control custom-select`}
                  name="contract"
                  onChange={(e) => handleForm(e)}
                  value={
                    form.contract && form.contract.value
                      ? form.contract.value
                      : ""
                  }
                >
                  <option value="">Select</option>
                  <option value="limited">Limited</option>
                  <option value="unlimited">Unlimited</option>
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group text-left">
                <label>Sepration</label>
                <select
                  className={`form-control custom-select`}
                  name="sepration"
                  onChange={(e) => handleForm(e)}
                  value={
                    form.sepration && form.sepration.value
                      ? form.sepration.value
                      : ""
                  }
                >
                  <option value="">Select</option>
                  <option value="resignated">Resignated</option>
                  <option value="terminated">Terminated</option>
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group text-left">
                <label>First Working Day</label>
                <input
                  type="text"
                  className={`form-control bg-white`}
                  placeholder={"DD-MM-YYYY"}
                  readOnly
                  value={form.fwd && form.fwd.value ? form.fwd.value : ""}
                  onFocus={() => {
                    set_dateString("fwd");
                  }}
                />
                {dateString === "fwd" && (
                  <>
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        backgroundColor: "rgba(255,0,0,0)",
                      }}
                      onClick={() => {
                        set_dateString("");
                      }}
                    ></div>
                    <SingleCalendar
                      selectedDate={(date) => {
                        let ev = {
                          target: {
                            name: "fwd",
                            value: date,
                          },
                        };
                        handleForm(ev);
                        set_dateString("");
                      }}
                      format={"dd-mm-yyyy"}
                      currentDate={
                        form.fwd && form.fwd.value ? form.fwd.value : ""
                      }
                      range={false}
                      upcoming={true}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group text-left">
                <label>Last Working Day</label>
                <input
                  type="text"
                  className={`form-control bg-white`}
                  placeholder={"DD-MM-YYYY"}
                  readOnly
                  value={form.lwd && form.lwd.value ? form.lwd.value : ""}
                  onFocus={() => {
                    set_dateString("lwd");
                  }}
                />
                {dateString === "lwd" && (
                  <>
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        backgroundColor: "rgba(255,0,0,0)",
                      }}
                      onClick={() => {
                        set_dateString("");
                      }}
                    ></div>
                    <SingleCalendar
                      selectedDate={(date) => {
                        let ev = {
                          target: {
                            name: "lwd",
                            value: date,
                          },
                        };
                        handleForm(ev);
                        set_dateString("");
                      }}
                      format={"dd-mm-yyyy"}
                      currentDate={
                        form.lwd && form.lwd.value ? form.lwd.value : ""
                      }
                      range={false}
                      upcoming={true}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group text-left">
                <label>Salary</label>
                <input
                  type="text"
                  className={`form-control ${
                    form?.basic?.error ? "border-danger" : ""
                  }`}
                  placeholder={"Enter Yearly Salary i.e. 800,000"}
                  name="basic"
                  value={formatedBasicSalary}
                  onChange={(e) => {
                    handleForm(e);
                  }}
                />
              </div>
            </div>
          </div>
          <h3 className="mb-0 mt-2 font300">
            <span className="counter">
              {basicSalary
                ? `${
                    amt
                      .replace(/\D/g, "")
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") || ""
                  }.${dec || ""}`
                : ""}
            </span>
          </h3>
        </div>
        <div className="card-footer">
          <button
            className="btn btn-primary btn-block"
            onClick={() => {
              handleSubmit();
            }}
          >
            <i className="fa fa-calculator" /> Calculate
          </button>
        </div>
      </div>
    </>
  );
};

export default WithForms(SalaryForm);
