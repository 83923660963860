import React, { useState, useEffect } from "react";
import { Select, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";

// HOC
import WithForms from "../../../features/WithForms/WithForms";
import regex from "../../../features/Services/validator";
import Fullcalender from "../../common/fullcalender";
import SingleCalendar from "react-single-calendar";
import { Modal, ApiModal } from "../../../features/modal/Modal";
import { useSelector } from "react-redux";
import axios from "axios";
import api from "./api";
import BreakComp from "./BreakComp";
import BreakManagementComp from "./BreakManagementComp";
import BreakTimerComp from "./BreakTimerComp";
import ShortComing from "./ShortComing";
import TeamListComp from "./TeamListComp";
import AttendanceHistoryComp from "./AttendanceHistoryComp";
import TeamAttendanceHistoryComp from "./TeamAttendanceHistoryComp";
import TeamBreakManagementComp from "./TeamBreakManagementComp";
import TeamShortComingComp from "./TeamShortComingComp";
import TestComp from "./TestComp";
import OldTeamListComp from "./OldTeamListComp";
import CheckinComp from "./CheckinComp";
import TeamAttendanceSheet from "./TeamAttendanceSheet";

const Attendance = (props) => {
  const { fixNavbar, form, handleForm } = props;

  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  let [modal, setModal] = useState("");

  const [checked_in_Id, set_checked_in_Id] = useState(0);
  const [checkInRes, set_checkInRes] = useState({});

  const [showTeamControl, set_showTeamControl] = useState(false);

  const [view, set_view] = useState("team attendance sheet");

  const reduxUser = useSelector((state) =>
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : state.settings.user
  );
  //    console.log(reduxUser);
  // const leavModal= modal==='leave' ? <Modal><LeaveStatusModal modalData={{ status: temp }} dismiss={() => {setModal(''); setTemp('')}} submit={(data) => handleModalSubmit(data)} /></Modal> : null;
  const apiModal =
    modal === "api" ? (
      <Modal>
        <ApiModal
          modalData={{
            resType: "success",
            resMessage: `Request has been accepted successfully`,
            resTitle: "Accept Status",
          }}
          dismiss={() => {
            setModal("");
          }}
        />
      </Modal>
    ) : null;

  const getCheckinId = (id, data) => {
    set_checked_in_Id(id);
    set_checkInRes(data);
  };

  return (
    <React.Fragment>
      <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
        <div className="container-fluid">
          <div className="d-block justify-content-between align-items-center mb-3 text-center">
            <div className="row">
              {/* Pie chart of leaves */}
              <div className="col-lg-12 col-md-12 ">
                <div className="card">
                  <div className="text-left">
                    <div></div>
                  </div>
                  {/* <div className="card-body">
                    <h3 className="card-title">Mark Attendance</h3>
                  </div>
                  <div className="card-body">
                    <CheckinComp getCheckinId={getCheckinId} />
                  </div> */}
                </div>
              </div>

              {/* Leave application form */}
            </div>
          </div>
          <div className="justify-content-between align-items-center mb-3">
            <div className="row">
              {/* Leave History Table */}
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header tw-justify-end">
                    <button
                      className={`btn ${
                        view === "team attendance sheet"
                          ? "btn-secondary"
                          : "btn-primary"
                      } mx-3`}
                      onClick={() => set_view("team attendance sheet")}
                    >
                      Team Attedance Sheet
                    </button>

                    <button
                      className={`btn ${
                        view === "team list" ? "btn-secondary" : "btn-primary"
                      } mx-3`}
                      onClick={() => set_view("team list")}
                    >
                      Team Attendance List
                    </button>

                    <button
                      className={`btn ${
                        view === "team break management"
                          ? "btn-secondary"
                          : "btn-primary"
                      } mx-3`}
                      onClick={() => set_view("team break management")}
                    >
                      Team Break Management
                    </button>
                    <button
                      className={`btn ${
                        view === "team short coming"
                          ? "btn-secondary"
                          : "btn-primary"
                      } mx-3`}
                      onClick={() => set_view("team short coming")}
                    >
                      Team Short Coming
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {view === "team list" && (
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <TeamListComp />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {view === "team break management" && (
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <TeamBreakManagementComp />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {view === "team short coming" && (
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <TeamShortComingComp />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {view === "team attendance sheet" && (
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <TeamAttendanceSheet />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {apiModal}
    </React.Fragment>
  );
};

export default WithForms(Attendance);
