import React, { useState, useEffect } from "react";
import WithForms from "../../../features/WithForms/WithForms";
import regex from "../../../features/Services/validator";
import SingleCalendar from "react-single-calendar";
import axios from "axios";
import api from "./api";
import DocComp from "../MyDocuments/DocComp";
import {
  bank_account_details,
  current_employee_letters,
  driving_license,
  educational_certificates,
  emirates_id,
  medical_insurance,
  passport,
  previous_employment_details,
  visa_details,
} from "../AddDocument/documentsData";
import { concatenate_str } from "../../../functions";
import { message } from "antd";
import { tjson } from "../AddDocument/initialState";

const DocumentUpload = (props) => {
  const { fixNavbar, form, handleForm } = props;
  const [isSubmit, set_isSubmit] = useState(false);
  const [dateString, set_dateString] = useState("");
  const [state, setState] = useState({
    selectedForm: "",
  });

  //   new
  const [documentList, set_documentList] = useState([]);
  const [selectedDocID, set_selectedDocID] = useState();
  const [selectedDoc, set_selectedDoc] = useState({
    document_type: "",
    document_html: [],
  });
  const [docForm, set_docForm] = useState({
    document_info: "",
    document_type: 0,
    attachment: "",
  });
  const [attachmentList, set_attachmentList] = useState([]);
  const [datePicker, set_datePicker] = useState({});
  const [sr_no, set_sr_no] = useState(2);
  const [infoForm, set_infoForm] = useState({});

  const [testJson, set_testJson] = useState(tjson);

  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  const onDateChange = (date, dateString, feild_label) => {
    set_datePicker((prev) => ({
      ...prev,
      [feild_label]: { value: dateString },
    }));
  };

  const infoFormOnChange = (e) => {
    handleForm(e);
  };

  useEffect(() => {
    getDocumentList();
  }, []);

  useEffect(() => {
    set_sr_no(2);
    set_datePicker({});
  }, [selectedDocID]);

  useEffect(() => {
    set_docForm((prev) => ({
      ...prev,
      attachment: attachmentList,
    }));
  }, [attachmentList]);

  const getDocumentList = async () => {
    try {
      const res = await api.getDocumentList();
      if (res.data.resCode === 200) {
        set_documentList(res?.data?.data);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const uploadDocument = async () => {
    const obj = { ...form, ...datePicker };
    const jsonForm = {};
    for (let key in obj) {
      jsonForm[key] = obj[key].value;
    }
    try {
      const res = await api.uploadDocument({
        data: {
          document_info: JSON.stringify(jsonForm),
          document_type: docForm.document_type,
          attachment: JSON.stringify(docForm.attachment),
        },
      });
      if (res.data.resCode === 200) {
        message.success(`${selectedDoc.document_type} added successfully`);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    set_datePicker({});
  };

  const selectHandler = (e) => {
    const value = e.target.value;
    set_selectedDocID(value);
    set_docForm((prev) => ({ ...prev, document_type: value }));
  };

  const getSelectedDocument = async () => {
    try {
      const res = await api
        .getSelectedDocument({ document_type: selectedDocID })
        .then((data) => data.data);
      if (res.resCode === 200) {
        const docType = res?.data[0]?.document_type;
        const docHtml = JSON.parse(res?.data[0]?.document_html);
        set_selectedDoc({
          document_type: docType || "",
          document_html: docHtml || [],
        });
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const addMoreHandler = (each_doc) => {
    const newdocs = each_doc.docs[0];
    const title = newdocs.doc_title.split("#")[0];

    set_selectedDoc((prev) => {
      return {
        ...prev,
        document_html: prev.document_html.map((each) => {
          return {
            ...each,
            docs: [
              ...each.docs,
              { ...newdocs, doc_title: `${title}#${sr_no}` },
            ],
          };
        }),
      };
    });
    set_sr_no((prev) => prev + 1);
  };
  const deleteDocHandler = (selected_doc_title) => {
    set_selectedDoc((prev) => {
      return {
        ...prev,
        document_html: prev.document_html.map((each) => {
          return {
            ...each,
            docs: each.docs.filter(
              (doc) => doc.doc_title !== selected_doc_title
            ),
          };
        }),
      };
    });
  };
  return (
    <>
      <div
        className={`section-body tw-min-h-[calc(100vh-9rem)] ${
          fixNavbar ? "marginTop" : ""
        } `}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-fields-center mb-3">
            <div className="col-lg-12 col-md-12 ">
              <div className="card">
                {/* <div className="card-header"><h3 className="card-title">Upload Documnets</h3></div> */}
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-md-4">
                        {/* <p className="text-secondary">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Proin luctus mollis pharetra. Phasellus congue
                          vitae est non fringilla.
                        </p> */}
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Select Document Type
                          </label>
                          <select
                            name="select_document"
                            id="select_document"
                            className="form-control custom-select tw-capitalize"
                            onChange={selectHandler}
                          >
                            <option>Select Document</option>
                            {documentList.map((each, idx) => (
                              <option key={idx} value={`${each.id}`}>
                                {(each.document_type || "").toLowerCase()}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button
                            className="btn btn-primary"
                            onClick={() => getSelectedDocument()}
                            type="button"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    {selectedDoc.document_html &&
                      selectedDoc.document_html.map((each_doc, idx) => {
                        return (
                          <div className="tw-w-full" key={idx}>
                            <div className="tw-flex tw-justify-center tw-items-center tw-bg-gray-100 tw-w-full tw-py-1">
                              <h3 className="card-title tw-font-bold tw-mt-1">
                                {selectedDoc.document_type}
                              </h3>
                            </div>
                            <DocComp
                              removeEdit
                              each_doc={each_doc}
                              addMoreHandler={addMoreHandler}
                              deleteDocHandler={deleteDocHandler}
                              form={form}
                              datePicker={datePicker}
                              infoFormOnChange={infoFormOnChange}
                              onDateChange={onDateChange}
                              attachmentList={attachmentList}
                              set_attachmentList={set_attachmentList}
                            />
                            <div className="row d-flex justify-content-center align-items-center">
                              <button
                                type="button"
                                className="btn btn-primary"
                                style={{ width: "10rem", height: "2.5rem" }}
                                onClick={uploadDocument}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithForms(DocumentUpload);
