import React, { memo } from "react";
import { useState, useEffect } from "react";
import BreakComp from "./BreakComp";
import { Col, Row, message, Modal } from "antd";
import moment from "moment";
import api from "./api";
import useTimer from "./timerHook";
import { capitalizeFunc, formatTime } from "../../../functions";
import StopWatchComp from "./StopWatchComp";

const BreakManagementComp = ({ attendance_id }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [breakValue, set_breakValue] = useState({
    type: "",
    start: "",
    duration: "",
    end: "",
    comment: "",
  });
  const [showTimerComp, set_showTimerComp] = useState(false);
  const [breakListRes, set_breakListRes] = useState([]);
  const [breakInRes, set_breakInRes] = useState("");
  const [breakoutRes, set_breakoutRes] = useState(false);
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  const { timer, handleStart, handleReset } = useTimer(0);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    set_breakValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    getBreakList();
  }, [breakoutRes]);

  useEffect(() => {
    isBreakIn();
  }, [showTimerComp]);

  const isBreakIn = async () => {
    try {
      const res = await api.isBreakIn().then((res) => res.data);
      console.log(res);
      if (res.resCode === 200) {
        // set_showTimerComp(true);
        setRes({
          message: res.resMessage,
          type: "success",
        });
      } else {
        // set_showTimerComp(false);
        setRes({
          message: res.resMessage,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
      console.log(err);
    }
  };

  const handleBreakin = async () => {
    if (!attendance_id) {
      message.error("Please check-in first");
      return;
    }
    try {
      const res = await api.breakin({
        data: {
          break_type: breakValue.type,
          remark: breakValue.comment,
          attendance_id: attendance_id,
        },
      });

      if (res.data.resCode === 200) {
        set_breakInRes(res?.data?.data);
        handleStart();
        set_showTimerComp(true);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const handleBreakout = async () => {
    try {
      const res = await api.breakout({
        break_id: breakInRes.id,
      });

      if (res.data.resCode === 200) {
        set_breakoutRes(!breakoutRes);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };
  const getBreakList = async () => {
    try {
      const res = await api.breaklist();
      if (res.data.resCode === 200) {
        set_breakListRes(res?.data?.data);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const addTodayBreak = () => {
    handleReset();
    set_showTimerComp(false);
    handleBreakout();
    getBreakList();
  };

  return (
    <div className="row mt-2">
      <div className="col-md-8 mx-auto ">
        {!showTimerComp ? (
          <>
            <div className="mb-3 row gx-5">
              <label htmlFor="type" className="col-sm-5 col-form-label">
                Break Type
              </label>
              <div className="col-sm-7">
                <select
                  className="form-control custom-select"
                  name="type"
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="short break">Short Break </option>
                  <option value="long break">Long Break </option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>

            {breakValue.type === "other" && (
              <div className="mb-3 row gx-5">
                <label htmlFor="comment" className="col-sm-5 col-form-label">
                  Reason
                </label>
                <div className="col-sm-7">
                  <textarea
                    rows={2}
                    className="form-control no-resize tw-capitalize"
                    placeholder="Enter here your comment"
                    name="comment"
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
            <div className="tw-mt-8 tw-flex tw-justify-center tw-mb-8">
              <button
                className="btn btn-secondary"
                onClick={() => {
                  breakValue && handleBreakin();
                }}
              >
                Take Break
              </button>
            </div>
          </>
        ) : (
          <>
            <Modal
              title={capitalizeFunc(breakValue.type)}
              open={isModalOpen}
              closeIcon={<></>}
              centered
              maskClosable={false}
              footer={null}
              width={350}
            >
              <div className="card tw-mx-auto p-2 border">
                <div className="tw-text-[2.5rem] tw-font-bold tw-text-center tw-my-4">
                  <span>{formatTime(timer)}</span>
                </div>
                <div className="buttons tw-flex tw-justify-center tw-gap-8 tw-mb-4 ">
                  <button
                    className="btn btn-success"
                    onClick={() => addTodayBreak()}
                  >
                    Punch out
                  </button>
                </div>
              </div>
            </Modal>
          </>
        )}
      </div>
      <div className="col-md-8 mx-auto tw-text-center">
        <div className=" tw-mx-auto tw-text-[12px]">
          <Row
            style={{
              backgroundColor: "#6c757d",
              color: "rgb(255, 255, 255)",
            }}
          >
            <Col
              style={{
                border: "1px solid #5a6168",
                padding: "0.5rem",
              }}
              span={4}
            >
              Type
            </Col>
            <Col
              style={{
                border: "1px solid #5a6168",
                padding: "0.5rem",
                borderLeft: "none",
              }}
              span={4}
            >
              Duration
            </Col>
            <Col
              style={{
                border: "1px solid #5a6168",
                padding: "0.5rem",
                borderLeft: "none",
              }}
              span={4}
            >
              Start
            </Col>
            <Col
              style={{
                border: "1px solid #5a6168",
                padding: "0.5rem",
                borderLeft: "none",
              }}
              span={4}
            >
              End
            </Col>
            <Col
              style={{
                border: "1px solid #5a6168",
                padding: "0.5rem",
                borderLeft: "none",
              }}
              span={8}
            >
              Comment
            </Col>
          </Row>
          {breakListRes.map((each, idx) => {
            return (
              <Row key={idx}>
                <Col
                  style={{
                    border: "1px solid #5a6168",
                    padding: "0.5rem",
                    borderTop: "none",
                  }}
                  span={4}
                >
                  {capitalizeFunc(each.break_type || "")}
                </Col>
                <Col
                  style={{
                    border: "1px solid #5a6168",
                    padding: "0.5rem",
                    borderLeft: "none",
                    borderTop: "none",
                  }}
                  span={4}
                >
                  {each.break_duration || ""}
                </Col>
                <Col
                  style={{
                    border: "1px solid #5a6168",
                    padding: "0.5rem",
                    borderLeft: "none",
                    borderTop: "none",
                  }}
                  span={4}
                >
                  {(each.break_start_time || "").split(" ")[1]}
                </Col>
                <Col
                  style={{
                    border: "1px solid #5a6168",
                    padding: "0.5rem",
                    borderLeft: "none",
                    borderTop: "none",
                  }}
                  span={4}
                >
                  {(each.break_end_time || "").split(" ")[1]}
                </Col>
                <Col
                  style={{
                    border: "1px solid #5a6168",
                    padding: "0.5rem",
                    borderLeft: "none",
                    borderTop: "none",
                  }}
                  span={8}
                >
                  {capitalizeFunc(each.remark || "")}
                </Col>
              </Row>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(BreakManagementComp);
