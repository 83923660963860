// /api/logout

import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);

let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}
const getLogout = () => {
  return axios({
    method: "post",
    url: "/api/logout",
    headers: headers,
  });
};

export default {
  getLogout,
};
