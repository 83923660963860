import React, { useState, useEffect, useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import api from "./api";
// HOC
import WithForms from "../../../features/WithForms/WithForms";
import regex from "../../../features/Services/validator";
import { Button, DatePicker, Input, message, Modal, Space, Table } from "antd";
import LeaveBalanceComp from "./LeaveBalanceComp";
import moment from "moment";
import Highlighter from "react-highlight-words";
import { capitalizeFunc, confirmFunc, deleteFunc } from "../../../functions";
import recall_icon from "../../../assets/images/recall_edit.svg";

const Leave = (props) => {
  // const {form, handleForm} = props;
  const { fixNavbar, form, handleForm, setForm } = props;
  const [managerList, set_managerList] = useState([]);
  const [updateForm, set_updateForm] = useState({});
  const [isSubmit, set_isSubmit] = useState(false);
  const [isUpdate, set_isUpdate] = useState(false);
  const [show_Modal, set_show_Modal] = useState(false);
  const [validatonModal, setValidatonModal] = useState({
    message: "",
    type: "",
    title: "",
    validity: false,
  });
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });
  const [Leave, set_Leaves] = useState([]);
  const [type, set_Type] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [totalLeaveApproved, setTotalLeaveApproved] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  useEffect(() => {
    getLeaves();
    getleavetype();
    getManagerList();
  }, []);

  const openModal = () => set_show_Modal(true);
  const closeModal = () => set_show_Modal(false);

  const showConfirm_Revoke = () => {
    deleteFunc(() => {
      deleteLeave();
    });
  };
  const showConfirm_Recall = () => {
    confirmFunc(() => {
      recallLeave();
    });
  };

  const recallLeave = async () => {
    const {
      leave_type_id,
      from_date,
      to_date,
      reporting_head,
      employee_remark,
      id,
    } = updateForm;
    set_isUpdate(true);
    if (
      !leave_type_id ||
      !from_date ||
      !to_date ||
      !reporting_head ||
      !employee_remark ||
      !id
    ) {
      return;
    } else {
      try {
        const res = await api.recallLeave({
          data: {
            leave_type: leave_type_id,
            from_date: moment(from_date).format("YYYY-MM-DD"),
            to_date: moment(to_date).format("YYYY-MM-DD"),
            reporting_head,
            employee_remark,
          },
          leave_id: id,
        });
        if (res.data.resCode === 200) {
          message.success(res.data.resMessage);
          closeModal();
          setRes({
            message: res.data.resMessage,
            type: "success",
          });
          resetHandler();
        } else {
          message.error(res.data.resMessage);
          setRes({
            message: res.data.resMessage,
            type: "danger",
            title: "Error",
          });
        }
      } catch (err) {
        message.error("Something went wrong!");
        setRes({
          message: "Something went wrong!",
          type: "danger",
          title: "Error",
        });
      }
    }
    getLeaves();
  };

  const deleteLeave = async () => {
    const { id } = updateForm;
    set_isUpdate(true);
    if (!id) {
      return;
    } else {
      try {
        const res = await api.deleteLeave({
          leave_id: id,
        });
        if (res.data.resCode === 200) {
          message.info(res.data.resMessage);
          closeModal();
          setRes({
            message: res.data.resMessage,
            type: "success",
          });
        } else {
          message.error(res.data.resMessage);
          setRes({
            message: res.data.resMessage,
            type: "danger",
            title: "Error",
          });
        }
      } catch (err) {
        message.error("Something went wrong!");
        setRes({
          message: "Something went wrong!",
          type: "danger",
          title: "Error",
        });
      }
    }
    getLeaves();
  };

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, close, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
    close();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {dataIndex === "applied_on" ||
        dataIndex === "from" ||
        dataIndex === "to" ? (
          <div className="tw-w-full tw-mb-2">
            <DatePicker
              format="DD-MM-YYYY"
              className="tw-w-full"
              onChange={(_, dateString) =>
                setSelectedKeys(dateString ? [dateString] : [])
              }
              placeholder="Select Date"
            />
          </div> //leaves
        ) : dataIndex === "leaves" ? (
          <Input
            type="number"
            ref={searchInput}
            placeholder={`Search`}
            allowClear
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "flex",
            }}
          />
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search`}
            allowClear
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "flex",
            }}
          />
        )}

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, close, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (dataIndex === `duration`) {
        const [start_val, end_val] = value.split("-");
        const recordTime = moment.duration(record[dataIndex]).asMilliseconds();
        const valueTime_start = moment
          .duration(start_val, "minutes")
          .asMilliseconds();
        const valueTime_end = moment
          .duration(end_val, "minutes")
          .asMilliseconds();

        return (
          recordTime > valueTime_start &&
          recordTime <= valueTime_end &&
          record[dataIndex]
        );
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const data = Leave.map((each, idx) => {
    return {
      key: each.id,
      leave_type: capitalizeFunc(each.leave_type || "-"),
      from: moment(each.from_date).format("DD-MM-YYYY") || "-",
      to: moment(each.to_date).format("DD-MM-YYYY") || `-`,
      leaves: each.no_of_days || `-`,
      status:
        each?.leave_status.toLowerCase() === "pending" ? (
          <div className="tw-flex tw-gap-1 tw-justify-between tw-items-center">
            {capitalizeFunc(each.leave_status || "-")}
            <Button
              type="text"
              onClick={() => {
                openModal();
                set_updateForm(each);
              }}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                  viewBox="0 0 539.44 577.14"
                  className="tw-fill-sky-500 tw-h-4 tw-w-4"
                >
                  <path d="M539.4 289.1v216.33c0 33.08-17.89 59-47.4 68.13a77.12 77.12 0 0 1-22.37 3.39q-139 .34-278 .1c-39.73-.06-69.12-29.55-69.57-69.3q-.17-14.49 0-29c.14-13.71 9.58-23.48 22.46-23.44s22.2 9.77 22.35 23.57c.13 9.24-.05 18.49.06 27.73.17 15.44 9.23 24.57 24.63 24.58q137.78.06 275.55 0c16 0 24.65-9.36 24.65-26.43V104.15c0-11.09.07-22.19 0-33.28-.12-15.8-9.16-24.9-24.93-24.9q-138.11-.09-276.17.01c-15.49 0-24.34 9-24.45 24.56-.09 14.18.15 28.36-.1 42.53-.19 10.86-5.74 18.52-15 21.78-8.84 3.1-18.92.78-24.53-6.6-2.61-3.45-5.14-8-5.2-12.15-.28-19.28-1.26-38.75 1-57.82 3.78-33.4 32.34-57.85 66-58.08 53-.35 106-.12 159-.13H467c42.19 0 72.28 29.82 72.36 72.08q.21 108.47.04 216.95Z" />
                  <path d="M193.96 212.88h8.34c47.67 0 95.34-.07 143 0 22.25.05 38.28 11.43 43.62 30.9a52.81 52.81 0 0 1 1.8 13.38c.18 21.16.16 42.32.07 63.48-.12 26.53-18 44.45-44.56 44.51q-72.42.15-144.86 0h-7.38c0 5.92.05 11.2 0 16.48-.2 16.77-7.6 29.39-22.87 36.36s-30 5.44-43.29-5.37q-55.15-45-110.21-90.1c-23.55-19.35-23.37-49 .23-68.13q54.84-44.42 109.69-88.79c13.49-10.93 28.32-12.7 43.8-5.48 15.22 7.1 22.52 19.74 22.66 36.51-.01 5.13-.04 10.25-.04 16.25Zm.49 45.32v60.83h149.63v-60.86Zm-45.59-51.53L48 288.67l100.86 81.9Z" />
                </svg>
              }
            />
          </div>
        ) : (
          capitalizeFunc(each.leave_status || "-")
        ),
      applied_on: moment(each.created_at).format("DD-MM-YYYY") || "-",
      reasons: capitalizeFunc(each.employee_remark || ""),
      comments: capitalizeFunc(each.reporting_head_remark || ""),
    };
  });

  //date difference
  const [numberOfDays, setNumberOfDays] = useState("0");

  const getManagerList = async () => {
    try {
      const res = await api.getManagerList().then((data) => data.data);
      if (res.resCode === 200) {
        set_managerList(res?.data || []);

        setRes({
          message: "Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const getleavetype = async () => {
    try {
      const res = await api.getleavetype().then((data) => data.data);
      if (res.resCode === 200) {
        set_Type(res?.data?.data || []);
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const getLeaves = async (url) => {
    try {
      const res = await api
        .getLeaves({
          url,
        })
        .then((data) => data.data);
      if (res.resCode === 200) {
        set_Leaves(res?.data?.data || []);
        setPagination(res?.data?.links);
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errorCount = 0;

    for (const key in form) {
      if (form[key].error) {
        ++errorCount;
      }
    }

    set_isSubmit(true);
    if (errorCount > 0 || Object.keys(form).length < 5) {
      return;
    } else {
      try {
        const res = await api.ApplyLeave({
          leave_type: form.leave_type.value,
          from_date: moment(form?.from?.value).format("YYYY-MM-DD"),
          to_date: moment(form.to.value).format("YYYY-MM-DD"),
          reporting_head: form.manager.value,
          employee_remark: form.reason.value,
        });
        if (res.data.resCode === 200) {
          message.success(res.data.resMessage);
          setRes({
            message: res.data.resMessage,
            type: "success",
          });
          resetHandler();
        } else {
          message.error(res.data.resMessage);
          setRes({
            message: res.data.resMessage,
            type: "danger",
            title: "Error",
          });
        }
      } catch (err) {
        message.error("Something went wrong!");
        setRes({
          message: "Something went wrong!",
          type: "danger",
          title: "Error",
        });
      }
    }
    getLeaves();
  };

  const resetHandler = () => {
    setForm({});
    set_isSubmit(false);
  };

  const getTotalLeaveApproved = async (e) => {
    let from_date = form?.from?.value || updateForm.from_date;
    let to_date = e.target.value;

    try {
      const res = await api
        .getTotalLeaveApproved({ from_date, to_date })
        .then((data) => data.data);
      if (res.resCode === 200) {
        setTotalLeaveApproved(res?.data[0].total_leave || 0);
        setRes({
          message: "Record Found",
          type: "success",
          title: "SuccessFully",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const columns = [
    {
      title: "Leave Type",
      width: 30,
      dataIndex: "leave_type",
      key: "leave_type",
      ...getColumnSearchProps("leave_type"),
    },
    {
      title: "From",
      width: 30,
      dataIndex: "from",
      key: "from",
      ...getColumnSearchProps("from"),
    },
    {
      title: "To",
      width: 30,
      dataIndex: "to",
      key: "to",
      ...getColumnSearchProps("to"),
    },
    {
      title: "Leaves",
      dataIndex: "leaves",
      key: "leaves",
      width: 20,
      ...getColumnSearchProps("leaves"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 30,
      ...getColumnSearchProps("status"),
    },
    {
      title: "Applied On",
      width: 30,
      dataIndex: "applied_on",
      key: "applied_on",
      ...getColumnSearchProps("applied_on"),
    },
    {
      title: "Reasons",
      dataIndex: "reasons",
      key: "reasons",
      width: 50,
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      width: 50,
    },
  ];

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;

    set_updateForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <React.Fragment>
      <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="row">
              {/* Pie chart of leaves */}
              <div className="col-lg-4 col-md-4 ">
                <LeaveBalanceComp />
              </div>
              {/* Leave application form */}
              <div className="col-lg-8 col-md-8">
                <form className="card" onSubmit={handleSubmit}>
                  <div className="card-body">
                    <h3 className="card-title">Apply Leave</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Leave Type</label>
                          <select
                            value={(form.leave_type?.value || "").toLowerCase()}
                            className={`form-control custom-select tw-capitalize ${
                              (form.leave_type &&
                                form.leave_type.error &&
                                isSubmit) ||
                              (!form.leave_type && isSubmit)
                                ? "border-danger"
                                : ""
                            }`}
                            name="leave_type"
                            onChange={(e) => {
                              return handleForm(
                                e,
                                regex.value.allName,
                                "required"
                              );
                            }}
                          >
                            <option value="">Leave Type</option>
                            {type.map((leave_type) => (
                              <option
                                value={`${leave_type.id}`}
                                key={leave_type.id}
                              >
                                {leave_type.leave_type}
                              </option>
                            ))}
                          </select>
                          {form.leave_type &&
                            form.leave_type.error &&
                            isSubmit && (
                              <small className="text-danger">
                                Please select correct leave type
                              </small>
                            )}
                          {!form.leave_type && isSubmit && (
                            <small className="text-danger">
                              Leave type is required
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Reporting Manager
                          </label>
                          <select
                            value={(form.manager?.value || "").toLowerCase()}
                            className={`form-control custom-select tw-capitalize ${
                              (form.manager &&
                                form.manager.error &&
                                isSubmit) ||
                              (!form.manager && isSubmit)
                                ? "border-danger"
                                : ""
                            }`}
                            name="manager"
                            onChange={(e) =>
                              handleForm(e, regex.value.allName, "required")
                            }
                          >
                            <option value="">Select</option>
                            {managerList.map((each) => {
                              return (
                                <option value={each?.id} key={each?.id}>
                                  {`${each?.first_name || ""} ${
                                    each?.last_name || ""
                                  }`.toLowerCase()}
                                </option>
                              );
                            })}
                          </select>

                          {form.manager && form.manager.error && isSubmit && (
                            <small className="text-danger">
                              Please select correct
                            </small>
                          )}
                          {!form.manager && isSubmit && (
                            <small className="text-danger">
                              Reporting Manager is required
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <div className="form-group calendar-box date-top">
                          <label className="form-label">From</label>
                          <DatePicker
                            style={{
                              width: "100%",
                            }}
                            format="DD-MM-YYYY"
                            onChange={(date, dateString) => {
                              let ev = {
                                target: {
                                  name: "from",
                                  value: date,
                                },
                              };
                              handleForm(ev, null, "required");
                            }}
                            value={
                              form.from && form.from.value && form.from.value
                            }
                            placeholder="Select Date"
                            status={
                              (form.from && form.from.error && isSubmit) ||
                              (!form.from && isSubmit)
                                ? "error"
                                : ""
                            }
                          />

                          {form.from && form.from.error && isSubmit && (
                            <small className="text-danger">
                              Incorrect Date
                            </small>
                          )}
                          {!form.from && isSubmit && (
                            <small className="text-danger">
                              Please provide from date
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <div className="form-group">
                          <label className="form-label">To</label>
                          <DatePicker
                            style={{
                              width: "100%",
                            }}
                            format="DD-MM-YYYY"
                            onChange={(date, dateString) => {
                              let ev = {
                                target: {
                                  name: "to",
                                  value: date,
                                },
                              };
                              getTotalLeaveApproved(ev);
                              handleForm(ev, null, "required");
                            }}
                            value={form.to && form.to.value && form.to.value}
                            placeholder="Select Date"
                            status={
                              (form.to && form.to.error && isSubmit) ||
                              (!form.to && isSubmit)
                                ? "error"
                                : ""
                            }
                          />

                          {form.to && form.to.error && isSubmit && (
                            <small className="text-danger">
                              Incorrect Date
                            </small>
                          )}
                          {!form.to && isSubmit && (
                            <small className="text-danger">
                              Please provide to date
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Leave Applied</label>
                          {/* leave approved */}
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            placeholder="Leave Meter"
                            value={`${totalLeaveApproved} Leaves on selected date`}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group mb-0">
                          <label className="form-label">Reason</label>
                          <textarea
                            rows={5}
                            className={`form-control bg-white tw-capitalize ${
                              (form.reason && form.reason.error && isSubmit) ||
                              (!form.reason && isSubmit)
                                ? "border-danger"
                                : ""
                            }`}
                            placeholder="Description for Leave"
                            // defaultValue=""
                            name="reason"
                            value={form.reason?.value.toLowerCase() || ""}
                            onChange={(e) => handleForm(e, null, "required")}
                          />
                          {form.reason && form.reason.error && isSubmit && (
                            <small className="text-danger">
                              Please enter leave reason
                            </small>
                          )}
                          {!form.reason && isSubmit && (
                            <small className="text-danger">
                              Leave reason is required
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-right">
                    <button type="button" className="btn btn-secondary mr-2">
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Apply
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="justify-content-between align-items-center mb-3">
            <div className="row">
              {/* Leave History Table */}

              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Leave History</h3>
                    {/* <div className="card-options">
                      <form>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Search something..."
                            name="s"
                          />
                          <span className="input-group-btn ml-2">
                            <button
                              className="btn btn-icon btn-sm"
                              type="submit"
                            >
                              <span className="fe fe-search" />
                            </button>
                          </span>
                        </div>
                      </form>
                    </div> */}
                  </div>
                  <div className="card-body">
                    <Table
                      columns={columns}
                      dataSource={data}
                      scroll={{
                        x: 1500,
                        y: 300,
                      }}
                      pagination={{
                        defaultPageSize: 6,
                      }}
                    />
                    {/* <div className="table-responsive">
                      <table className="table table-hover table-striped table-vcenter text-nowrap mb-0">
                        <thead>
                          <tr>
                            <th>Applied on</th>
                            <th>Leave Type</th>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th>Leaves</th>
                            <th>Reasons</th>
                            <th>Status</th>
                            <th>Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Leave.map((data) => (
                            <tr key={data.created_at}>
                              <td>{data.created_at.split("T")[0]}</td>
                              <td>{data.leave_type}</td>
                              <td>{data.from_date}</td>
                              <td>{data.to_date}</td>
                              <td>{data.no_of_days}</td>
                              <td>{data.employee_remark}</td>
                              <td>{data.leave_status}</td>
                              <td>{data.reporting_head_remark}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div> */}
                  </div>
                  {/* pagination data */}
                  {/* <nav aria-label="Page navigation" className="tw-mb-4 tw-mr-4">
                    <ul className="pagination mb-0 justify-content-end">
                      {pagination.map((each) => {
                        return (
                          <li
                            className="page-item tw-cursor-pointer"
                            key={each.label}
                            onClick={() => getLeaves(each?.url)}
                          >
                            <span
                              className="page-link"
                              dangerouslySetInnerHTML={{ __html: each.label }}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </nav> */}
                  <Modal
                    title={
                      <div className="tw-flex tw-justify-center tw-items-center tw-font-bold tw-text-[1.1rem]">
                        Leave Request
                      </div>
                    }
                    open={show_Modal}
                    onCancel={closeModal}
                    footer={
                      <div className="tw-flex tw-justify-center tw-items-center tw-gap-4">
                        <Button
                          type="primary"
                          onClick={showConfirm_Revoke}
                          danger
                        >
                          Revoke
                        </Button>
                        <Button type="primary" onClick={showConfirm_Recall}>
                          Recall
                        </Button>
                        {/* <Button onClick={closeModal}>Close</Button> */}
                      </div>
                    }
                    width={600}
                    maskClosable={false}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Leave Type</label>

                          <select
                            value={updateForm?.leave_type_id || ""}
                            className={`form-control custom-select tw-capitalize ${
                              !updateForm.leave_type_id && isUpdate
                                ? "border-danger"
                                : ""
                            }`}
                            name="leave_type_id"
                            onChange={handleUpdateChange}
                          >
                            <option value="">Leave Type</option>

                            {type.map((each) => (
                              <option value={`${each.id}`} key={each.id}>
                                {each.leave_type}
                              </option>
                            ))}
                          </select>

                          {!updateForm.leave_type_id && isUpdate && (
                            <small className="text-danger">
                              Leave type is required
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Reporting Manager
                          </label>
                          <select
                            value={updateForm?.reporting_head || ""}
                            className={`form-control custom-select tw-capitalize ${
                              !updateForm.reporting_head && isUpdate
                                ? "border-danger"
                                : ""
                            }`}
                            name="reporting_head"
                            onChange={handleUpdateChange}
                          >
                            <option value="">Select</option>
                            {managerList.map((each) => {
                              return (
                                <option value={each?.id} key={each?.id}>
                                  {`${each?.first_name || ""} ${
                                    each?.last_name || ""
                                  }`.toLowerCase()}
                                </option>
                              );
                            })}
                          </select>

                          {!updateForm.reporting_head && isUpdate && (
                            <small className="text-danger">
                              Reporting Manager is required
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <div className="form-group calendar-box date-top">
                          <label className="form-label">From</label>
                          <DatePicker
                            style={{
                              width: "100%",
                            }}
                            format="DD-MM-YYYY"
                            onChange={(date, dateString) => {
                              let ev = {
                                target: {
                                  name: "from_date",
                                  value: date,
                                },
                              };
                              handleUpdateChange(ev);
                            }}
                            value={
                              updateForm.from_date &&
                              moment(updateForm.from_date)
                            }
                            placeholder="Select Date"
                            status={
                              !updateForm.from_date && isUpdate ? "error" : ""
                            }
                          />

                          {!updateForm.from_date && isUpdate && (
                            <small className="text-danger">
                              Please provide from date
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <div className="form-group">
                          <label className="form-label">To</label>
                          <DatePicker
                            style={{
                              width: "100%",
                            }}
                            format="DD-MM-YYYY"
                            onChange={(date, dateString) => {
                              let ev = {
                                target: {
                                  name: "to_date",
                                  value: date,
                                },
                              };
                              getTotalLeaveApproved(ev);
                              handleUpdateChange(ev);
                            }}
                            value={
                              updateForm.to_date && moment(updateForm.to_date)
                            }
                            placeholder="Select Date"
                            status={
                              !updateForm.to_date && isUpdate ? "error" : ""
                            }
                          />

                          {!updateForm.to_date && isUpdate && (
                            <small className="text-danger">
                              Please provide to date
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Leave Applied</label>
                          {/* leave approved */}
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            placeholder="Leave Meter"
                            value={`${totalLeaveApproved} Leaves on selected date`}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group mb-0">
                          <label className="form-label">Reason</label>
                          <textarea
                            rows={5}
                            className={`form-control bg-white tw-capitalize ${
                              !updateForm.employee_remark && isUpdate
                                ? "border-danger"
                                : ""
                            }`}
                            placeholder="Description for Leave"
                            // defaultValue=""
                            name="employee_remark"
                            value={
                              (
                                updateForm.employee_remark || ""
                              ).toLowerCase() || ""
                            }
                            onChange={handleUpdateChange}
                          />

                          {!updateForm.employee_remark && isUpdate && (
                            <small className="text-danger">
                              Leave reason is required
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WithForms(Leave);
