import React, { createContext, useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { setUserAction, removeUserAction } from "../../actions/settingsAction";
import axios from "axios";
import api from "./api";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) =>
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : state.settings.user
  );
  const [user, set_user] = useState(reduxUser ?? null);

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      dispatch(setUserAction(user));
    }
  }, [user]);

  const login = (user) => {
    sessionStorage.setItem("user", JSON.stringify(user));
    set_user(user);
  };

  const logout = async () => {
    try {
      const res = await api.getLogout();
      if (res.data.resCode === 200) {
        dispatch(removeUserAction(null));
        sessionStorage.removeItem("user");
        set_user(null);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
