import React from "react";
import PersonalInfo from "./PersonalInfo";
import BankInfo from "./BankInfo";
import FamilyInfo from "./FamilyInfo";
import EducationInfo from "./EducationInfo";
import ExperienceInfo from "./ExperienceInfo";

const ProfileComp = ({ data }) => {
  return (
    <div className="row">
      <div className="col-md-6">
        <PersonalInfo data={data} />
      </div>
      {/* <div className="col-md-6">
        <EmergencyInfo data={data} />
      </div> */}
      <div className="col-md-6">
        <BankInfo data={data?.employee_bank_details || {}} />
      </div>
      <div className="col-md-6">
        <FamilyInfo data={data} />
      </div>

      <div className="col-md-6">
        <EducationInfo data={data} />
      </div>
      <div className="col-md-6">
        <ExperienceInfo data={data} />
      </div>
    </div>
  );
};
export default ProfileComp;
