import { Button, Input, Modal, Select, message } from "antd";
import React, { useEffect } from "react";
import { capitalizeFunc, valueHandler } from "../../functions";
import DividerComp from "../general/DividerComp";
import InfoRowComp from "../general/InfoRowComp";
import UploadComp from "../HRMS/MyDocuments/UploadComp";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { countryList } from "../HRMS/AddDocument/countryList";
import { bankDetails, personalDetails } from "./profile_state";
import api from "./api.js";
const BankInfo = ({ data }) => {
  const [show_Modal, set_show_Modal] = useState(false);
  const [isSubmit, set_isSubmit] = useState(false);
  const [tableData, set_tableData] = useState(data);
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    set_tableData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    set_isSubmit(true);
    saveBankDetails();
    set_show_Modal(false);
  };

  const saveBankDetails = async (next) => {
    const { id } = data;
    const { employee_id, bank_name, bank_account, bank_ifsc, bank_address } =
      tableData;
    if (id) {
      try {
        const res = await api.saveBankDetails({
          data: {
            employee_id,
            bank_name,
            bank_account,
            bank_ifsc,
            bank_address,
          },
          employee_id,
        });
        if (res.data.resCode === 200) {
          message.success(res.data.resMessage);
          setRes({
            message: "Found",
            type: "success",
            title: "SuccessFully",
          });
        } else {
          message.error(res.data.resMessage);
          setRes({
            message: res.data.message,
            type: "danger",
            title: "Error",
          });
        }
      } catch (err) {
        message.error("Something went wrong!");
        setRes({
          message: "Something went wrong!",
          type: "danger",
          title: "Error",
        });
      }
    }
  };

  useEffect(() => {
    set_tableData(data);
  }, [data]);

  return (
    <>
      <div className="card p-3 tw-relative tw-h-[94%]">
        <div className="row tw-mb-2">
          <div className="col-11 tw-font-bold tw-text-[1rem] ">
            Bank Information
          </div>
          <div className="col-1 ">
            {/* edit option disabled */}
            <Button
              shape="circle"
              size="small"
              className="tw-flex tw-justify-center tw-items-center"
              onClick={() => set_show_Modal(true)}
            >
              <EditOutlined />
            </Button>
          </div>
        </div>
        {Object.values(tableData).filter(Boolean).length ? (
          <>
            <InfoRowComp
              name="Bank Name"
              value={capitalizeFunc(tableData?.["bank_name"] || "")}
            />
            <InfoRowComp
              name="Bank Account"
              value={tableData?.["bank_account"] || ""}
            />
            <InfoRowComp name="IFSC" value={tableData?.["bank_ifsc"] || ""} />
            <InfoRowComp
              name="Bank Address"
              value={capitalizeFunc(tableData?.["bank_address"] || "")}
            />
          </>
        ) : (
          <div className="tw-flex tw-justify-center tw-items-center">
            <Button
              type="text"
              size="small"
              onClick={() => set_show_Modal(true)}
              className="tw-flex tw-justify-center tw-items-center"
            >
              <PlusCircleOutlined />
              <span className="tw-mt-1">Please add details</span>
            </Button>
          </div>
        )}
      </div>
      <Modal
        title={
          <div className="tw-flex tw-justify-center tw-items-center tw-font-bold tw-text-[1.1rem]">
            Bank Information
          </div>
        }
        open={show_Modal}
        onCancel={() => {
          set_show_Modal(false);
        }}
        footer={
          <div className="tw-flex tw-justify-center tw-items-center">
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button
              onClick={() => {
                set_show_Modal(false);
              }}
            >
              Close
            </Button>
          </div>
        }
        // width={600}
        maskClosable={false}
      >
        <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
          {/* Bank Name */}
          <div className="col-md-6">
            <p className="tw-mb-0">Bank Name</p>
            <Input
              placeholder="Bank Name"
              name="bank_name"
              onChange={(e) => {
                handleChange(e);
              }}
              value={valueHandler(
                capitalizeFunc(tableData?.["bank_name"] || "")
              )}
            />
          </div>
          {/* Bank Account */}
          <div className="col-md-6">
            <p className="tw-mb-0">Bank Account</p>
            <Input
              placeholder="Bank Account"
              name="bank_account"
              onChange={(e) => {
                handleChange(e);
              }}
              value={valueHandler(
                capitalizeFunc(tableData?.["bank_account"] || "")
              )}
            />
          </div>
        </div>
        <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
          {/* IFSC */}
          <div className="col-md-6">
            <p className="tw-mb-0">IFSC</p>
            <Input
              placeholder="IFSC"
              name="ifsc"
              onChange={(e) => {
                handleChange(e);
              }}
              value={valueHandler(tableData?.["bank_ifsc"] || "")}
            />
          </div>
        </div>
        <div className="row clearfix tw-mb-4 [&>div_p]:tw-font-semibold">
          {/* Bank Address */}
          <div className="col-md-12">
            <p className="tw-mb-0">Bank Address</p>
            <Input
              placeholder="Bank Address"
              name="bank_address"
              onChange={(e) => {
                handleChange(e);
              }}
              value={valueHandler(
                capitalizeFunc(tableData?.["bank_address"] || "")
              )}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BankInfo;
