import React, { useState, useEffect } from 'react';
import SearchForm from './SearchForm';

const Payslip = (props) => {
    const { fixNavbar } = props;
    return (
        <React.Fragment>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="col-md-12">
                            <div className="alert alert-light">
                                <SearchForm/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3">

                        <div className="col-md-12">
                            <div className="text-center alert alert-light lh-1 mb-2">
                                <h6 className="fw-bold">Payslip</h6> <span className="fw-normal">Payment slip for the month of June 2021</span>
                            </div>
                            {/* <div className="d-flex justify-content-end"> <span>Working Branch:ROHINI</span> </div> */}
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="pl-4 pr-4 pt-4">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="d-flex">
                                                        <div className="fw-bolder text-capitalize font-weight-bold">Employee ID</div>
                                                        <div className="ml-3">39124</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="d-flex">
                                                        <div className="fw-bolder text-capitalize font-weight-bold">Joining Date</div>
                                                        <div className="ml-3">12th August, 2012</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="d-flex">
                                                        <div className="fw-bolder text-capitalize font-weight-bold">Department</div>
                                                        <div className="ml-3">Adminstration</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="d-flex">
                                                        <div className="fw-bolder text-capitalize font-weight-bold">Designation</div>
                                                        <div className="ml-3">HR Manager</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="d-flex">
                                                        <div className="fw-bolder text-capitalize font-weight-bold">Payment Mode</div>
                                                        <div className="ml-3">Bank Transfer</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="d-flex">
                                                        <div className="fw-bolder text-capitalize font-weight-bold">Bank Name</div>
                                                        <div className="ml-3">HDFC</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="d-flex">
                                                        <div className="fw-bolder text-capitalize font-weight-bold">Bank IBAN No.</div>
                                                        <div className="ml-3">HDFC00009812</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="d-flex">
                                                        <div className="fw-bolder text-capitalize font-weight-bold">BankAc No.</div>
                                                        <div className="ml-3">*******0701</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="d-flex">
                                                        <div className="fw-bolder text-capitalize font-weight-bold">PAN</div>
                                                        <div className="ml-3">GNFFD1234K</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="d-flex">
                                                        <div className="fw-bolder text-capitalize font-weight-bold">UID</div>
                                                        <div className="ml-3">VRAC123456K</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="d-flex">
                                                        <div className="fw-bolder text-capitalize font-weight-bold">Location</div>
                                                        <div className="ml-3">Bhoopal</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="d-flex">
                                                        <div className="fw-bolder text-capitalize font-weight-bold">Aadhar No.</div>
                                                        <div className="ml-3">3456 2345 1992</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-hover table-striped table-vcenter text-nowrap mb-0">
                                                    <thead className="bg-dark text-light">
                                                        <tr>
                                                            <th scope="col" className="text-light">Earnings</th>
                                                            <th scope="col" className="text-light">Amount</th>
                                                            <th scope="col" className="text-light">Deductions</th>
                                                            <th scope="col" className="text-light">Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Actual Payble Days</th>
                                                            <td>22</td>
                                                            <td>Total Working Days</td>
                                                            <td>26</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Loss of pay days</th>
                                                            <td>4</td>
                                                            <td>Days payble</td>
                                                            <td>22</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">CTC</th>
                                                            <td>16250.00</td>
                                                            <td>Basic</td>
                                                            <td>1800.00</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Allowance</th>
                                                            <td>550.00</td>
                                                            <td>House Rent Allowance</td>
                                                            <td>142.00</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Convenyance Allowance</th>
                                                            <td>1650.00 </td>
                                                            <td>Medical Allowance</td>
                                                            <td>0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Overtime Pay</th>
                                                            <td>120.00 </td>
                                                            <td>Bonus</td>
                                                            <td>0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Bonus</th>
                                                            <td>0.00 </td>
                                                            <td>Gross Salary</td>
                                                            <td>0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Deduction</th>
                                                            <td>0.00 </td>
                                                            <td>VAT 5%</td>
                                                            <td>500.00</td>
                                                        </tr>

                                                        <tr className="border-top">
                                                            <th scope="row">Net Payble Salary</th>
                                                            <td>25970.00</td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="row">
                                                <div className="col-md-4"> <br /> <span className="fw-bold">Net Pay : 24528.00</span> </div>
                                                <div className="border col-md-8">
                                                    <div className="d-flex flex-column"> <span>In Words</span> <span>Twenty Five thousand nine hundred seventy only</span> </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <div className="d-flex flex-column mt-2"> <span className="fw-bolder">For VRAC</span> <span className="mt-4">Authorised Signatory</span> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Payslip;