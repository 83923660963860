import { random_unique_no } from "../../../functions";
import { v4 as uuidv4 } from "uuid";
export const optionObj = () => ({
  id: uuidv4(),
  option: "",
});

export const fieldsObj = () => ({
  id: uuidv4(),
  label: "",
  type: "",
  options: [optionObj()],
  default_option: "",
  mandatory: false,
});

export const headingObj = () => ({
  id: uuidv4(),
  isHeading: false,
  heading: "",
  fields: [fieldsObj()],
});

export const docObj = () => ({
  id: uuidv4(),
  doc: "",
  headings: [headingObj()],
});

export const dynaForm_initialState = [
  {
    id: uuidv4(),
    title: "",
    docs: [docObj()],
  },
];
// export const dynaForm_initialState = {
//   title: "",
//   docs: [
//     {
//       id: random_unique_no(10),
//       doc: "",
//       headings: [
//         {
//           id: random_unique_no(10),
//           heading: "",
//           fields: [
//             {
//               id: random_unique_no(10),
//               label: "",
//               type: "",
//               options: [
//                 {
//                   id: random_unique_no(10),
//                   option: "",
//                 },
//               ],
//             },
//           ],
//         },
//       ],
//     },
//   ],
// };

export const tjson = [
  {
    id: "83a17ea5-3afd-49c7-81ec-9838df322078",
    title: "Document",
    docs: [
      {
        id: "7965bf64-dc1e-430b-b3d3-1f90f882e330",
        doc: "",
        headings: [
          {
            id: "8d616ace-f14e-4d70-8923-b094124775aa",
            heading: "Full Name",
            fields: [
              {
                id: "ac10971f-eff5-48d7-8f3a-a68b7319570f",
                label: "First",
                type: "text",
                options: [
                  { id: "b5791549-a4fb-498c-8f51-1fdf46753e92", option: "" },
                ],
                default_option: "",
                mandatory: true,
              },
              {
                id: "82330e2c-a1d5-4f31-aa32-d41c84011635",
                label: "Middle",
                type: "text",
                options: [
                  { id: "2974adf9-45e3-4fa3-9a79-c4bcb4801847", option: "" },
                ],
                default_option: "",
                mandatory: false,
              },
              {
                id: "dd096948-af0a-4a91-bf44-7ee61aefa56e",
                label: "Last",
                type: "text",
                options: [
                  { id: "6c61f6af-e233-41fd-9891-bcf9ad0d43e0", option: "" },
                ],
                default_option: "",
                mandatory: true,
              },
            ],
          },
          {
            id: "3dd09d15-d104-4ee1-b56c-275b235dd5a0",
            heading: "Family",
            fields: [
              {
                id: "68b29a67-4905-4617-a3e4-5c8992caf126",
                label: "Father Name",
                type: "text",
                options: [
                  { id: "bc1f7457-852a-41f9-9578-aa62f6e93989", option: "" },
                ],
                default_option: "",
                mandatory: true,
              },
              {
                id: "0edf5989-2962-4caa-b27f-78a553909bc1",
                label: "Mother Name",
                type: "text",
                options: [
                  { id: "1137a885-e05f-43e0-bbca-8ace874cf66f", option: "" },
                ],
                default_option: "",
                mandatory: false,
              },
            ],
          },
          {
            id: "47764a46-dccb-427e-9a65-62d54207b114",
            heading: "Other Info",
            fields: [
              {
                id: "1ac0cfe2-2ca3-418a-afff-e63d3b74ff84",
                label: "Gender",
                type: "select",
                options: [
                  { id: "8922402c-28e8-4f20-aa3c-df9e1afc83e7", option: "" },
                  {
                    id: "7a453fa1-384f-467c-a23c-b1629c71b7fc",
                    option: "Female",
                  },
                  {
                    id: "25c0056f-8108-4570-b0aa-56f621e7d100",
                    option: "Other",
                  },
                ],
                default_option: "",
                mandatory: false,
              },
              {
                id: "47861da3-d477-4c09-8299-e6f3a1c285d6",
                label: "Mobile Number",
                type: "number",
                options: [
                  { id: "c829ca17-2986-43aa-8c3e-53e473d2d945", option: "" },
                ],
                default_option: "",
                mandatory: false,
              },
            ],
          },
        ],
      },
    ],
  },
];
