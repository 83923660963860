import React, { useState, useEffect, memo } from "react";
import WithForms from "../../../features/WithForms/WithForms";
import regex from "../../../features/Services/validator";
import SingleCalendar from "react-single-calendar";
import axios from "axios";
import api from "./api";

import {
  emirates_id,
  bank_account_details,
  current_employee_letters,
  driving_license,
  educational_certificates,
  medical_insurance,
  passport,
  previous_employment_details,
  visa_details,
  countries,
  nationality,
  medical_insurance1,
} from "./documentsData";

import UIbasedComp from "./UIbasedComp";
import { message, Tabs } from "antd";
import { random_unique_no } from "../../../functions";
import JsonbasedComp from "./JsonbasedComp";
import { dynaForm_initialState } from "./initialState";
import CheckableTag from "antd/lib/tag/CheckableTag";
import UIbasedCompNew from "./UIbasedCompNew";

const AddDocument = (props) => {
  const { fixNavbar } = props;
  const [documentJson, set_documentJson] = useState({
    document_type: "",
    document_html: "",
  });
  const [isEdit, set_isEdit] = useState(false);
  const [documentList, set_documentList] = useState([]);
  const [docId, set_docId] = useState();
  const [submit, set_submit] = useState(false);

  const [jsonForm, setJsonForm] = useState({});
  const [dynaForm, setDynaForm] = useState(dynaForm_initialState);

  const [defaultOptions, set_defaultOptions] = useState({
    countryList: countries,
    nationalityList: nationality,
  });

  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  const handleFieldChange = (e, idx) => {
    const { name, value, checked } = e.target;

    setDynaForm((prev) => {
      return prev.map((each_title) => {
        if (each_title.id === idx) {
          return {
            ...each_title,
            [name]: value,
          };
        }
        return {
          ...each_title,
          docs: each_title.docs.map((each_doc) => {
            if (each_doc.id === idx) {
              return {
                ...each_doc,
                [name]: value,
              };
            }
            return {
              ...each_doc,
              headings: each_doc.headings.map((each_heading) => {
                if (each_heading.id === idx) {
                  return {
                    ...each_heading,
                    [name]: value,
                  };
                }
                return {
                  ...each_heading,
                  fields: each_heading.fields.map((each_f) => {
                    if (each_f.id === idx) {
                      return {
                        ...each_f,
                        [name]: value || checked,
                      };
                    }
                    return {
                      ...each_f,
                      options: each_f.options.map((each_option) => {
                        if (each_option.id === idx) {
                          return {
                            ...each_option,
                            [name]: value,
                          };
                        }
                        return {
                          ...each_option,
                        };
                      }),
                    };
                  }),
                };
              }),
            };
          }),
        };
      });
    });
  };

  useEffect(() => {
    getDocumentList();
  }, [docId, submit]);

  const getDocumentList = async () => {
    try {
      const res = await api.getDocumentList();
      if (res.data.resCode === 200) {
        set_documentList(res?.data?.data);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const handleDocChange = (e) => {
    const { name, value } = e.target;
    set_documentJson((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const addDocument = async () => {
    const docjson = documentJson.document_html;
    try {
      const res = await api.addDocument({
        document_type: dynaForm[0].title || documentJson.document_type,
        document_html: dynaForm[0].title
          ? JSON.stringify(dynaForm)
          : JSON.stringify(JSON.parse(docjson)),
      });

      if (res.data.resCode === 200) {
        message.success("Document added successfully");
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    set_documentJson({
      document_type: "",
      document_html: "",
    });

    set_submit(true);
    setDynaForm(dynaForm_initialState);
    setJsonForm({});
  };
  const updateDocument = async () => {
    const docjson = documentJson.document_html;
    try {
      const res = await api.updateDocument({
        document_type: dynaForm[0].title || documentJson.document_type,
        document_html: dynaForm[0].title
          ? JSON.stringify(dynaForm)
          : JSON.stringify(JSON.parse(docjson)),
        id: docId,
      });

      if (res.data.resCode === 200) {
        message.success("Document updated successfully");
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        message.error(res.data.resMessage || "Something went wrong!");
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      message.error("Something went wrong!");
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    set_documentJson({
      document_type: "",
      document_html: "",
    });

    set_submit(true);
    setDynaForm(dynaForm_initialState);
    setJsonForm({});
  };

  const deleteDocument = async (id) => {
    try {
      const res = await api.deleteDocument({ id });
      if (res.data.resCode === 200) {
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
    set_docId(id);
  };
  const editDocument = async (obj, id) => {
    const docObj = JSON.parse(obj);
    set_isEdit(true);
    setDynaForm(docObj);
    set_docId(id);
  };

  return (
    <>
      <div
        className={`section-body tw-min-h-[calc(100vh-9rem)]  ${
          fixNavbar ? "marginTop" : ""
        } `}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-fields-center mb-3">
            <div className="col-lg-12 col-md-12 ">
              <div className="card">
                <div className="card-body">
                  <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size="middle"
                    items={[
                      {
                        label: `UI based`,
                        key: "1",
                        children: (
                          <UIbasedCompNew
                            handleFieldChange={handleFieldChange}
                            dynaForm={dynaForm}
                            setDynaForm={setDynaForm}
                            // jsonForm={jsonForm}
                            edit={isEdit}
                          />
                        ),
                      },
                      {
                        label: `Json based`,
                        key: "2",
                        children: (
                          <JsonbasedComp
                            documentJson={documentJson}
                            handleDocChange={handleDocChange}
                          />
                        ),
                      },
                    ]}
                  />

                  <div className="row d-flex justify-content-center tw-mt-8">
                    <div className="col-md-3 d-flex justify-content-center align-items-end py-4 ">
                      <div className="form-group mb-0">
                        <button
                          className="btn btn-primary tw-font-bold"
                          onClick={isEdit ? updateDocument : addDocument}
                          type="button"
                          style={{ width: "10rem", height: "2.5rem" }}
                        >
                          {isEdit ? "Update" : "Add"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row d-block">
                    <p className="tw-underline">List of Added Documents</p>
                    {documentList.map((each) => {
                      return (
                        <div
                          key={each.id}
                          className="tw-flex tw-items-center tw-gap-2 "
                        >
                          <div className="tw-flex-[2] tw-flex tw-items-center tw-gap-2">
                            <span className="check tw-text-green-700">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg>
                            </span>
                            <span className="tw-mt-1 tw-text-green-600">
                              {each.document_type}
                            </span>
                          </div>
                          <div className="tw-flex-[8] tw-flex tw-items-center tw-gap-8">
                            <div
                              className=" tw-flex tw-items-center tw-justify-start tw-text-red-400 hover:tw-text-red-600 tw-cursor-pointer"
                              onClick={() =>
                                editDocument(each.document_html, each.id)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fillRule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                              </svg>
                            </div>
                            <div
                              className=" tw-flex tw-items-center tw-justify-start tw-text-red-400 hover:tw-text-red-600 tw-cursor-pointer"
                              onClick={() => deleteDocument(each.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                              >
                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default memo(AddDocument);
