import React, { useState } from "react";
import api from "./api";
import { useEffect } from "react";
import { DatePicker, message, Select } from "antd";
import moment from "moment";

const ShortComing = () => {
  const [manager, set_manager] = useState(null);
  const [checked_in_Id, set_checked_in_Id] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [shortComingValue, set_shortComingValue] = useState({
    applying_to: "",
    break_date: "",
    from_hh: "",
    from_mm: "",
    from_am_pm: "AM",
    to_hh: "",
    to_mm: "",
    to_am_pm: "AM",
    reason: "",
    other_reason: "",
  });

  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  useEffect(() => {
    getEmployeeManager();
  }, []);

  useEffect(() => {
    checkShortComing(shortComingValue.break_date);
  }, [shortComingValue.break_date]);

  const checkShortComing = async (break_date) => {
    try {
      const res = await api.checkShortComing({
        data: {
          date: break_date,
        },
      });
      if (res.data.resCode !== 200) {
        setDateError(true);
        message.error(res.data.resMessage);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setDateError(false);
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const isChecked_in = async () => {
    try {
      const res = await api.isChecked_in();
      if (res.data.resCode === 200) {
        set_checked_in_Id(res.data.data.id);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    set_shortComingValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getEmployeeManager = async () => {
    try {
      const res = await api.getEmployeeManager();
      console.log(res);
      if (res.data.resCode === 200) {
        set_manager(res.data.data[0]);
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const applyShortComing = async () => {
    if (!checked_in_Id) {
      message.error("please check-in first");
      return;
    }
    if (dateError) {
      message.error("you cannot apply for this date");
      return;
    }
    const {
      break_date,
      from_am_pm,
      from_hh,
      from_mm,
      other_reason,
      reason,
      to_am_pm,
      to_hh,
      to_mm,
    } = shortComingValue;

    try {
      const res = await api.applyShortComing({
        date: moment(break_date).format("YYYY-MM-DD"),
        from_time: `${from_hh}:${from_mm} ${from_am_pm}`,
        to_time: `${to_hh}:${to_mm} ${to_am_pm}`,
        reason: reason,
        remark: other_reason,
      });

      if (res.data.resCode === 200) {
        message.success("Applied successfully");
        set_shortComingValue({
          applying_to: "",
          break_date: "",
          from_hh: "",
          from_mm: "",
          from_am_pm: "AM",
          to_hh: "",
          to_mm: "",
          to_am_pm: "AM",
          reason: "",
          other_reason: "",
        });
        setRes({
          message: res.data.resMessage,
          type: "success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };
  useEffect(() => {
    isChecked_in();
  }, []);

  const timeOptions_hh = [];
  for (let i = 0; i < 12; i++) {
    timeOptions_hh.push({
      value: `${i}`,
      label: `${i}`,
    });
  }
  const timeOptions_mm = [];
  for (let i = 0; i < 60; i++) {
    timeOptions_mm.push({
      value: `${i}`,
      label: `${i}`,
    });
  }

  return (
    <div className={`row mt-2`}>
      <div className="col-md-6 md:tw-pr-16 ">
        <div className="row mb-3 ">
          <label htmlFor="type" className="col-sm-4 col-form-label">
            Applying to
          </label>
          <div className="col-sm-8">
            <select
              className="form-control custom-select"
              name="applying_to"
              disabled
            >
              <option value="">
                {manager
                  ? `${manager?.first_name || ""} ${manager?.last_name || ""}`
                  : `Loading...`}
              </option>
            </select>
          </div>
        </div>
        <div className="mb-3 row ">
          <label htmlFor="reason" className="col-sm-4 col-form-label">
            Reason
          </label>
          <div className="col-sm-8">
            <select
              className="form-control custom-select"
              name="reason"
              onChange={handleChange}
              value={shortComingValue.reason}
            >
              <option value="">Select reason</option>
              <option value="access card not available">
                Access card not available{" "}
              </option>
              <option value="domestic work">Domestic work</option>
              <option value="emergency">Emergency</option>
              <option value="late-in">Late-in</option>
              <option value="late-out">Late-out</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>
        {shortComingValue.reason === "other" && (
          <div className="mb-3 row ">
            <label htmlFor="comment" className="col-sm-4 col-form-label ">
              Other Reason
            </label>
            <div className="col-sm-8">
              <textarea
                rows={2}
                className="form-control no-resize tw-capitalize"
                placeholder="Enter here your comment"
                name="other_reason"
                onChange={handleChange}
              />
            </div>
          </div>
        )}
      </div>
      <div className="col-md-6">
        <div className="mb-3 row">
          <label htmlFor="type" className="col-sm-4 col-form-label md:tw-pl-16">
            Date
          </label>
          <div className="col-sm-8">
            <DatePicker
              format="DD-MM-YYYY"
              style={{
                width: "100%",
              }}
              value={shortComingValue.break_date}
              onChange={(date, dateString) => {
                handleChange({ target: { name: "break_date", value: date } });
              }}
              placeholder="Select Date"
              disabledDate={(currentDate) => {
                const inputDate = currentDate.startOf("day");
                const todayDate = moment().startOf("day");
                return inputDate.isAfter(todayDate);
              }}
            />
            {/* <input
              type="date"
              className="form-control"
              name="break_date"
              onChange={handleChange}
            /> */}
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="type" className="col-sm-4 col-form-label md:tw-pl-16">
            Time
          </label>
          <div className="col-sm-8 ">
            <div className="mb-3 row justify-content-between">
              {/* from */}
              <div className="col-sm-6 ">
                <label htmlFor="type" className="col-form-label">
                  From
                </label>

                <div className="mb-3 row tw-gap-1">
                  <Select
                    style={{
                      width: "3.5rem",
                    }}
                    showSearch
                    placeholder="HH"
                    value={shortComingValue.from_hh}
                    optionFilterProp="children"
                    onChange={(value) =>
                      handleChange({ target: { name: "from_hh", value } })
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[{ label: "HH", value: "" }, ...timeOptions_hh]}
                    showArrow={false}
                  />
                  <Select
                    style={{
                      width: "3.5rem",
                    }}
                    showSearch
                    placeholder="MM"
                    value={shortComingValue.from_mm}
                    optionFilterProp="children"
                    onChange={(value) =>
                      handleChange({ target: { name: "from_mm", value } })
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[{ label: "MM", value: "" }, ...timeOptions_mm]}
                    showArrow={false}
                  />
                  <Select
                    style={{
                      width: "3.5rem",
                    }}
                    showSearch
                    optionFilterProp="children"
                    value={shortComingValue.from_am_pm}
                    onChange={(value) =>
                      handleChange({ target: { name: "from_am_pm", value } })
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        label: "AM",
                        value: "AM",
                      },
                      {
                        label: "PM",
                        value: "PM",
                      },
                    ]}
                    showArrow={false}
                  />
                </div>
              </div>

              {/* to */}
              <div className="col-sm-6 tw-pl-4">
                <label htmlFor="type" className="col-form-label">
                  To
                </label>

                <div className="mb-3 row tw-gap-1">
                  <Select
                    style={{
                      width: "3.5rem",
                    }}
                    showSearch
                    placeholder="HH"
                    value={shortComingValue.to_hh}
                    optionFilterProp="children"
                    onChange={(value) =>
                      handleChange({ target: { name: "to_hh", value } })
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[{ label: "HH", value: "" }, ...timeOptions_hh]}
                    showArrow={false}
                  />
                  <Select
                    style={{
                      width: "3.5rem",
                    }}
                    showSearch
                    placeholder="MM"
                    value={shortComingValue.to_mm}
                    optionFilterProp="children"
                    onChange={(value) =>
                      handleChange({ target: { name: "to_mm", value } })
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[{ label: "MM", value: "" }, ...timeOptions_mm]}
                    showArrow={false}
                  />
                  <Select
                    style={{
                      width: "3.5rem",
                    }}
                    showSearch
                    optionFilterProp="children"
                    defaultValue={shortComingValue.to_am_pm}
                    onChange={(value) =>
                      handleChange({ target: { name: "to_am_pm", value } })
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        label: "AM",
                        value: "AM",
                      },
                      {
                        label: "PM",
                        value: "PM",
                      },
                    ]}
                    showArrow={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`mb-3 row `}>
          <button
            className="btn btn-primary"
            onClick={() => {
              applyShortComing();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ShortComing);
