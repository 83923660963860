import React, { useEffect } from "react";
import { Card, Avatar } from "antd";
import { useState } from "react";
import ModalComp from "./ModalComp";
import api from "./api";

const ITDeclaration = () => {
  const [show_Modal, set_show_Modal] = useState(false);
  const [selected_module, set_selected_module] = useState({});
  const [it_modules_tiles, set_it_modules_tiles] = useState([]);
  const [res, setRes] = useState({
    message: "",
    type: "",
    title: "",
  });

  const getITModuleTile = async () => {
    try {
      const res = await api.getITModuleTile();
      if (res.data.resCode === 200) {
        set_it_modules_tiles(res?.data?.data || []);
        setRes({
          message: res.data.resMessage,
          type: "success",
          title: "Success",
        });
      } else {
        setRes({
          message: res.data.message,
          type: "danger",
          title: "Error",
        });
      }
    } catch (err) {
      setRes({
        message: "Something went wrong!",
        type: "danger",
        title: "Error",
      });
    }
  };

  const handleShowModal = (data) => {
    set_show_Modal(true);
    set_selected_module(data);
  };

  useEffect(() => {
    getITModuleTile();
  }, []);

  return (
    <div className="tw-flex tw-justify-center">
      <div className="tw-p-4 tw-grid tw-grid-cols-2 tw-gap-8">
        {it_modules_tiles.map((each, idx) => {
          return (
            <Card
              key={idx}
              size="small"
              style={{
                width: 300,
                height: 150,
              }}
              className="tw-flex tw-justify-center tw-items-center hover:tw-shadow-lg tw-transition-all"
            >
              <div className="tw-flex tw-flex-col tw-justify-between tw-items-center tw-min-h-[90px]">
                <Avatar>{each.code}</Avatar>
                <p className="mb-0">{each.name}</p>
                <p
                  onClick={() => handleShowModal(each)}
                  className="mb-0 tw-text-cyan-500 hover:tw-text-blue-500 tw-cursor-pointer"
                >
                  Add to Declaration
                </p>
              </div>
            </Card>
          );
        })}
      </div>
      <ModalComp
        selected_module={selected_module}
        show_Modal={show_Modal}
        set_show_Modal={set_show_Modal}
      />
    </div>
  );
};

export default ITDeclaration;
