import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);

let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}
const getProfile = (params) => {
  const data = params;
  return axios({
    method: "get",
    url: "/api/profile",
    headers: headers,
  });
};

const getDesignation = (params) => {
  const { url, data } = params;
  return axios({
    method: "get",
    url: url || "/api/designation-list",
    headers: headers,
    data: data,
  });
};

const getDepartment = (params) => {
  const { url, data } = params;
  return axios({
    method: "get",
    url: url || "/api/department-list",

    headers: headers,
    data: data,
  });
};

const getBranch = (params) => {
  const { url, data } = params;
  return axios({
    method: "get",
    url: url || "/api/office-list",
    headers: headers,
    data: data,
  });
};
const getManagerList = () => {
  return axios({
    method: "get",
    url: "/api/manager-list",
    headers: headers,
  });
};
const getRoleList = () => {
  return axios({
    method: "get",
    url: "/api/role-list",
    headers: headers,
  });
};

const getSalaryStructure = (params) => {
  let { ctc, data } = params;
  return axios({
    method: "get",
    url: ctc && `/api/salary-structure?ctc=${ctc}`,
    headers: headers,
    data: data,
  });
};

const getSalaryCalculation = (params) => {
  const { ctc, data } = params;
  return axios({
    method: "post",
    url: `/api/salary-calculation?ctc=${ctc}`,
    headers: headers,
    data: JSON.stringify(data),
  });
};
const createEmployee = (params) => {
  const { data } = params;

  return axios({
    method: "post",
    url: `/api/create-employee`,
    headers: headers,
    data: JSON.stringify(data),
  });
};

const updateEmployeePersonal = (params) => {
  const { data, employee_id } = params;
  return axios({
    method: "post",
    url: `/api/update-employee-personal-details/${employee_id}`,
    headers: headers,
    data: data,
  });
};
const saveFamilyInfo = (params) => {
  const { data, employee_id } = params;

  return axios({
    method: "post",
    url: `/api/save-employee-family-details/${employee_id}`,
    headers: headers,
    data: data,
  });
};
const saveEducationInfo = (params) => {
  const { data, employee_id } = params;
  return axios({
    method: "post",
    url: `/api/save-employee-education-details/${employee_id}`,
    headers: headers,
    data: data,
  });
};
const saveExperienceInfo = (params) => {
  const { data, employee_id } = params;
  return axios({
    method: "post",
    url: `/api/save-employee-experience-details/${employee_id}`,
    headers: headers,
    data: data,
  });
};
const saveBankDetails = (params) => {
  const { data, employee_id } = params;
  return axios({
    method: "post",
    url: `/api/save-employee-bank-details/${employee_id}`,
    headers: headers,
    data: data,
  });
};

export default {
  getProfile,
  getDesignation,
  getDepartment,
  getBranch,
  getSalaryStructure,
  getSalaryCalculation,
  createEmployee,
  getManagerList,
  getRoleList,
  updateEmployeePersonal,
  saveFamilyInfo,
  saveEducationInfo,
  saveExperienceInfo,
  saveBankDetails,
};
