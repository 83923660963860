import React, { useState, useEffect } from 'react';
// HOC
import WithForms from '../../../features/WithForms/WithForms';
import regex from '../../../features/Services/validator';

const SearchForm = (props) => {
    const { form, handleForm } = props;
    const [isSubmit, set_isSubmit] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        let errorCount = 0;

        for (const key in form) {
            if (form[key].error) {
                ++errorCount;
            }
        }
        set_isSubmit(true);
        console.log('form', form);
        // User will be set after getting success result in api
        // navigate(redirectPath, { replace: true })

    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="card-title pt-3">Select Month and Year </h3>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <select type="text" className={`form-control custom-select ${(form.year && form.year.error && isSubmit) || (!form.year && isSubmit) ? 'border-danger' : ''}`} name="year" onChange={(e) => handleForm(e, regex.value.allName, 'required')} >
                                        <option>select</option>
                                        {Array.from(Array(20), (e, i) => {
                                            let d = new Date();
                                            let curr_year = d.getFullYear();
                                            return <option key={curr_year - i} value={curr_year - i}>{curr_year - i}</option>
                                        })}
                                    </select>
                                    {(form.year && form.year.error && isSubmit) && <small className="text-danger">Please select correct Year</small>}
                                    {(!form.year && isSubmit) && <small className="text-danger">Year is required</small>}

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <select type="text" className={`form-control custom-select ${(form.month && form.month.error && isSubmit) || (!form.month && isSubmit) ? 'border-danger' : ''}`} name="month" onChange={(e) => handleForm(e, regex.value.allName, 'required')} >
                                        <option>select</option>
                                        <option value="January">January</option>
                                        <option value="February">February</option>
                                        <option value="March">March</option>
                                        <option value="April">April</option>
                                        <option value="May">May</option>
                                        <option value="June">June</option>
                                        <option value="July">July</option>
                                        <option value="August">August</option>
                                        <option value="September">September</option>
                                        <option value="October">October</option>
                                        <option value="November">November</option>
                                        <option value="December">December</option>
                                    </select>
                                    {(form.month && form.month.error && isSubmit) && <small className="text-danger">Please select correct Month</small>}
                                    {(!form.month && isSubmit) && <small className="text-danger"> Month is required</small>}

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <button className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </form>
        </>
    )
}

export default WithForms(SearchForm);