import React, { Component } from 'react';
import regex from '../../features/Services/validator';
import api from './api';
import { Link } from 'react-router-dom';

class CreatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      errors: {},
      resp: {
        message: '',
        type: ''
      }

    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.validate()) {
      let input = {};
      input["password"] = "";
      input["confirm_password"] = "";
      this.setState({ input: input }, () => {
        api.ForgetPassword({
          new_password: this.state.input.password,
          confirm_new_password: this.state.input.confirm_password
        }).then(res => {
          if (res.data.resCode === '200') {
            this.setState(prep => ({
              ...prep,
              resp: {
                ...prep.resp,
                message: res.data.resMessage,
                type: 'success'
              }
            }))
          } else {
            this.setState(prep => ({
              ...prep,
              resp: {
                ...prep.resp,
                message: res.data.resMessage,
                type: 'danger'
              }
            }))
          }
        }).catch(err => {
          this.setState(prep => ({
            ...prep,
            resp: {
              ...prep.resp,
              message: "Something went wrong! Please try again",
              type: 'danger'
            }
          }))
        })
      });

    }

  }
  validate() {
    let reg = regex.value.password;//reg.test(value)
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    let passwordValue = input["password"];
    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Please enter your password.";
    } else if (passwordValue !== undefined && passwordValue !== '' && !reg.test(passwordValue)) {
      isValid = false;
      errors["password"] = "Passwords didn't follow the password rule.";
    } else if (!input["confirm_password"]) {
      isValid = false;
      errors["confirm_password"] = "Please enter your confirm password.";
    } else if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
      if (input["password"] != input["confirm_password"]) {
        isValid = false;
        errors["confirm_password"] = "Passwords don't match.";
      }
    }

    this.setState({
      errors: errors
    });
    return isValid;
  }
  render() {
    return (
      <div className="auth">
        <div className="auth_left">
          <div className="card pt-0 px-0" style={{overflow:'hidden'}}>
            {this.state.resp.message === '' &&
              <form onSubmit={this.handleSubmit}>
                <div className=" mb-2">
                  <div className="card-title text-center mb-1 text-light bg-primary py-3">Create Password</div>
                  <div className="card-body text-left">
                    <div className="form-group">
                      <small className="text-muted">Password should contain</small>
                      <ul className="text-muted">
                        <li><small>Upper case letter <strong>A-Z</strong></small></li>
                        <li><small>Smaller case letter <strong>a-z</strong></small></li>
                        <li><small>At least one special character <strong>!,@,#,$,%,^,&amp;,*</strong></small></li>
                        <li><small>At least one numaric character <strong>0-9</strong></small></li>
                        <li><small>Minimum <strong>8</strong> characters</small></li>
                        <li><small>maximum <strong>16</strong> characters</small></li>
                      </ul>
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password:</label>
                      <input type="password" name="password" value={this.state?.input?.password ?? ''} onChange={(e) => this.handleChange(e)} className="form-control"
                        placeholder="Enter password" id="password" />
                      <small className="text-danger">{this.state.errors.password}</small>
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Confirm Password:</label>
                      <input type="password" name="confirm_password" value={this.state?.input?.confirm_password ?? ''} onChange={(e) => this.handleChange(e)}
                        className="form-control" placeholder="Enter confirm password" id="confirm_password" />
                      <small className="text-danger">{this.state.errors.confirm_password}</small>
                    </div>
                    <input type="submit" value="Submit" className="btn btn-primary float-right" />
                  </div>
                </div>
              </form>
            }
            {this.state.resp.message !== '' &&
              <div className={`alert alert-${this.state.resp.type}`}>
                <h3>{this.state.resp.message}</h3>
                {this.state.resp.type === 'success' && <Link to="/login" className="btn btn-primary">Login</Link>}
                {this.state.resp.type !== 'success' && <button type="button"
                  onClick={() => this.setState(prep => ({
                    ...prep,
                    resp: {
                      ...prep.resp,
                      message: "",
                      type: ''
                    }
                  }))} className="btn btn-danger">Okay</button>}
              </div>
            }
          </div>
          <div className="auth_right">
            <div className="carousel slide" data-ride="carousel" data-interval={3000}>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src="assets/images/slider1.svg" className="img-fluid" alt="login page" />
                  <div className="px-4 mt-4">
                    <h4>Fully Responsive</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src="assets/images/slider2.svg" className="img-fluid" alt="login page" />
                  <div className="px-4 mt-4">
                    <h4>Quality Code and Easy Customizability</h4>
                    <p>There are many variations of passages of Lorem Ipsum available.</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src="assets/images/slider3.svg" className="img-fluid" alt="login page" />
                  <div className="px-4 mt-4">
                    <h4>Cross Browser Compatibility</h4>
                    <p>Overview We're a group of women who want to learn JavaScript.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  }

}



export default CreatePassword;