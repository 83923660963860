import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);
let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}

// const CreateEmployee = (params) => {
//   const data = params;
//   return axios({
//     method: "post",
//     url: "/api/create-employee",
//     headers: headers,
//     data: data,
//   });
// };
const getEmployeeList = (params) => {
  const data = params;
  return axios({
    method: "get",
    url: "/api/employee-list",
    headers: headers,
    data: data,
  });
};
const updateEmployee = (params) => {
  const { employee_id, data } = params;

  return axios({
    method: "post",
    url: employee_id && `/api/update-employee/${employee_id}`,
    headers: headers,
    data: data,
  });
};
const deleteEmployee = (params) => {
  const { employee_id, data } = params;
  return axios({
    method: "post",
    url: employee_id && `/api/delete-employee/${employee_id}`,
    headers: headers,
    data: data,
  });
};
const getDesignation = (params) => {
  const data = params;

  return axios({
    method: "get",
    url: "/api/designation-list",
    headers: headers,
    data: data,
  });
};
const getDepartment = (params) => {
  const data = params;
  return axios({
    method: "get",
    url: "/api/department-list",

    headers: headers,
    data: data,
  });
};
const getBranch = (params) => {
  const data = params;
  return axios({
    method: "get",
    url: "/api/office-list",

    headers: headers,
  });
};

const createEmployee = (params) => {
  const { data } = params;
  return axios({
    method: "post",
    url: "/api/create-employee",
    headers: headers,
    data: data,
  });
};
const updateEmployeePersonal = (params) => {
  const { data, employee_id } = params;
  console.log(data);
  return axios({
    method: "post",
    url: `/api/update-employee-personal-details/${employee_id}`,
    headers: headers,
    data: data,
  });
};
const saveBankDetails = (params) => {
  const { data, employee_id } = params;
  return axios({
    method: "post",
    url: `/api/save-employee-bank-details/${employee_id}`,
    headers: headers,
    data: data,
  });
};
const saveDocumentDetails = (params) => {
  const { data, employee_id } = params;
  return axios({
    method: "post",
    url: `/api/save-employee-document-details/${employee_id}`,
    headers: headers,
    data: data,
  });
};

const saveProfessional = (params) => {
  const { data, employee_id } = params;
  return axios({
    method: "post",
    url: `/api/save-employee-professional-details/${employee_id}`,
    headers: headers,
    data: data,
  });
};

const getRoleList = () => {
  return axios({
    method: "get",
    url: "/api/role-list",
    headers: headers,
  });
};
const getManagerList = () => {
  return axios({
    method: "get",
    url: "/api/manager-list",
    headers: headers,
  });
};

export default {
  getEmployeeList,
  updateEmployee,
  deleteEmployee,
  getDesignation,
  getBranch,
  getDepartment,
  createEmployee,
  getRoleList,
  getManagerList,
  saveProfessional,
  updateEmployeePersonal,
  saveBankDetails,
  saveDocumentDetails,
};
