import axios from 'axios';
const Login = params => {
    const data = params;
    return axios({
        method: "post",
        url: '/api/login',
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    });
}
const ForgetPasswordLink = params => {
    const data = params;
    return axios({
        method: "post",
        url: '/api/forget-password-link',
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    });
}
const ForgetPassword = params => {
    const data = params;
    return axios({
        method: "post",
        url: '/api/forget-password',
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    });
}

export default {
    Login: Login,
    ForgetPasswordLink:ForgetPasswordLink,
    ForgetPassword:ForgetPassword,

}