import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);

let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}

const addLeaveType = (params) => {
  const data = params;
  return axios({
    method: "post",
    url: "/api/add-leave-type",
    headers: headers,
    data: data,
  });
};

const getLeaveTypeList = () => {
  return axios({
    method: "get",
    url: "/api/leave-type-list",
    headers: headers,
  });
};

const updateLeaveType = (params) => {
  const { id, data } = params;
  return axios({
    method: "post",
    url: `/api/update-leave-type/${id}`,
    headers: headers,
    data: data,
  });
};

const deleteLeaveType = (params) => {
  const { data, id } = params;
  return axios({
    method: "post",
    url: `/api/delete-leave-type/${id}`,
    headers: headers,
    data: data,
  });
};
export default {
  addLeaveType,
  getLeaveTypeList,
  updateLeaveType,
  deleteLeaveType,
};
