import axios from "axios";
let { token } = JSON.parse(
  sessionStorage.getItem("user") || JSON.stringify({ token: "" })
);

let headers = {
  "Content-Type": "application/json",
};

if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}

const getMenuItems = () => {
  return axios({
    method: "get",
    url: "/api/menubar",
    headers: headers,
  });
};

export default {
  getMenuItems,
};
