import { countryList } from "./countryList";
export const countries = countryList.map((each) => each.country);
export const nationality = countryList.map((each) => each.nationality);

export const medical_insurance1 = [
  {
    title: "Medical Insurance",
    docs: [
      {
        id: null,
        name: "Insurance#1",
        heading: [
          {
            id: null,
            name: null,
            fields: [
              {
                id: null,
                label: "First Name",
                type: "text",
                options: null,
              },
              {
                id: null,
                label: "Last Name",
                type: "text",
                options: null,
              },
              {
                id: null,
                label: "DOB",
                type: "date",
                options: null,
              },
              {
                id: null,
                label: "Graduation",
                type: "date-range",
                options: null,
              },
              {
                id: null,
                label: "Gender",
                type: "select",
                options: [
                  {
                    id: null,
                    value: "Male",
                  },
                  {
                    id: null,
                    value: "Female",
                  },
                ],
              },
            ],
          },
          {
            id: null,
            name: "Father's Name",
            fields: [
              {
                id: null,
                label: "First Name",
                type: "text",
                options: null,
              },
              {
                id: null,
                label: "Last Name",
                type: "text",
                options: null,
              },
            ],
          },
          {
            id: null,
            name: "Mother's Name",
            fields: [
              {
                id: null,
                label: "First Name",
                type: "text",
                options: null,
              },
              {
                id: null,
                label: "Husband",
                type: "text",
                options: null,
              },
              {
                id: null,
                label: "Last Name",
                type: "text",
                options: null,
              },
            ],
          },
          {
            id: null,
            name: "Wife's Name",
            fields: [],
          },
        ],
      },
    ],
  },
];

export const emirates_id = {
  title: "Emirates Id",
  docs: [
    {
      id: null,
      name: null,
      heading: [
        {
          id: null,
          name: null,
          fields: [
            {
              id: null,
              label: "ID No.",
              type: "text",
              options: null,
            },
            {
              id: null,
              label: "Nationality",
              type: "select",
              options: nationality,
            },
            {
              id: null,
              label: "First Name",
              type: "text",
              options: null,
            },
            {
              id: null,
              label: "Last Name",
              type: "text",
              options: null,
            },
            {
              id: null,
              label: "Date of Issue",
              type: "date",
              options: null,
            },
            {
              id: null,
              label: "Date of Expiry",
              type: "date",
              options: null,
            },
            {
              id: null,
              label: "Place of Issue",
              type: "text",
              options: null,
            },
          ],
        },
      ],
    },
  ],
};

export const bank_account_details = {
  title: "Bank Account Details",
  docs: [
    {
      id: null,
      name: null,
      fields: [
        {
          label: "Bank A/c No.",
          type: "text",
        },
        {
          label: "Name (As per the Bank Records)",
          type: "text",
        },
        {
          label: "Bank Name",
          type: "text",
        },
        {
          label: "Branch",
          type: "text",
        },
        {
          label: "IBAN",
          type: "text",
        },
      ],
    },
  ],
};
export const educational_certificates = {
  title: "Educational Certificates",
  docs: [
    {
      doc_title: "EC#1",
      fields: [
        {
          label: "Select Course",
          type: "select",
          options: [
            "Secondary School",
            "Higher Secondary School",
            "Graduation",
            "Post Graduation",
            "Master's degree",
            "Diploma",
            "Certificates",
          ],
        },
        {
          label: "Name of Institution",
          type: "text",
        },
        {
          label: "Country",
          type: "select",
          options: countries,
        },
        {
          label: "Year Range (from - to)",
          type: "date-range",
        },
      ],
    },
  ],
};
export const passport = {
  title: "Passport",
  docs: [
    {
      doc_title: null,
      fields: [
        {
          label: "Passport No.",
          type: "text",
        },
        {
          label: "Nationality",
          type: "select",
          options: nationality,
        },
        {
          label: "First Name",
          type: "text",
        },
        {
          label: "Last Name",
          type: "text",
        },
        {
          label: "Date of Issue",
          type: "date",
        },
        {
          label: "Date of Expiry",
          type: "date",
        },
        {
          label: "Place of Issue",
          type: "text",
        },
      ],
    },
  ],
};
export const medical_insurance = {
  title: "Medical Insurance",
  docs: [
    {
      doc_title: null,
      fields: [
        {
          label: "Medical Insurance No.",
          type: "text",
        },
        {
          label: "Insurance Type",
          type: "text",
        },
        {
          label: "First Name",
          type: "text",
        },
        {
          label: "Last Name",
          type: "text",
        },
        {
          label: "Date of Issue",
          type: "date",
        },
        {
          label: "Date of Expiry",
          type: "date",
        },
        {
          label: "Place of Issue",
          type: "date",
        },
        {},
        {
          doc_title: "Dependent Details",
          fields: [
            {
              doc_title: "Father/Mother/Wife/Child",
              fields: [
                {
                  label: "First Name",
                  type: "text",
                },
                {
                  label: "Last Name",
                  type: "text",
                },
                {
                  label: "DOB of Dependent",
                  type: "date",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
export const driving_license = {
  title: "Driving License",
  docs: [
    {
      doc_title: null,
      fields: [
        {
          label: "Driving License No.",
          type: "text",
        },
        {
          label: "Nationality",
          type: "select",
          options: nationality,
        },
        {
          label: "First Name",
          type: "text",
        },
        {
          label: "Last Name",
          type: "text",
        },
        {
          label: "Date of Issue",
          type: "date",
        },
        {
          label: "Date of Expiry",
          type: "date",
        },
        {
          label: "Place of Issue",
          type: "text",
        },
      ],
    },
  ],
};
export const visa_details = {
  title: "Visa Details",
  docs: [
    {
      doc_title: null,
      fields: [
        {
          label: "Entry Permit No.",
          type: "text",
        },
        {
          label: "Date of Issue",
          type: "date",
        },
        {
          label: "Valid Until",
          type: "date",
        },
        {
          label: "UID No.",
          type: "text",
        },
        {
          label: "First Name",
          type: "text",
        },
        {
          label: "Last Name",
          type: "text",
        },
        {
          doc_title: "Son of",
          fields: [
            {
              label: "First Name",
              type: "text",
            },
            {
              label: "Last Name",
              type: "text",
            },
          ],
        },
        {
          label: "Nationality",
          type: "select",
          options: nationality,
        },
        {
          label: "Place of Birth",
          type: "text",
        },
        {
          label: "Date of Birth",
          type: "date",
        },
        {
          label: "Passport No.",
          type: "text",
        },
        {
          label: "Profession",
          type: "text",
        },
        {},
        {
          doc_title: "Acompanied by",
          fields: [
            {
              doc_title: "Wife",
              fields: [
                {
                  label: "First Name",
                  type: "text",
                },
                {
                  label: "Last Name",
                  type: "text",
                },
              ],
            },
            {
              doc_title: "Child 1",
              fields: [
                {
                  label: "First Name",
                  type: "text",
                },
                {
                  label: "Last Name",
                  type: "text",
                },
              ],
            },
            {
              doc_title: "Child 2",
              fields: [
                {
                  label: "First Name",
                  type: "text",
                },
                {
                  label: "Last Name",
                  type: "text",
                },
              ],
            },
            {
              doc_title: "Child 3",
              fields: [
                {
                  label: "First Name",
                  type: "text",
                },
                {
                  label: "Last Name",
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          doc_title: "Sponser Details",
          fields: [
            {
              label: "First Name",
              type: "text",
            },
            {
              label: "Last Name",
              type: "text",
            },
            {
              label: "Sponser ID",
              type: "text",
            },
            {
              label: "Address",
              type: "text",
            },
            {
              label: "Telephone",
              type: "text",
            },
          ],
        },
      ],
    },
  ],
};
export const current_employee_letters = {
  title: "Current Employment Letters",
  docs: [
    {
      doc_title: null,
      fields: [
        {
          label: "Current Employment Offer Letter",
          type: "select",
          options: ["Yes", "No"],
        },
      ],
    },
  ],
};

export const previous_employment_details = {
  title: "Previous Employment Details",
  docs: [
    {
      doc_title: "prev_org#1",
      fields: [
        {
          label: "Name of Company",
          type: "text",
        },
        {
          label: "Position",
          type: "text",
        },
        {
          label: "Year Range (from - to)",
          type: "date-range",
        },
        {
          label: "Offer Letter",
          type: "select",
          options: ["Yes", "No"],
        },
        {
          label: "Experience Letter",
          type: "select",
          options: ["Yes", "No"],
        },
        {
          label: "Salary Slips",
          type: "select",
          options: ["Yes", "No"],
        },
      ],
    },
  ],
};
